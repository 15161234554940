import React from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: '#f3f4f7',
    minHeight: '100vh',
    overflow: 'scroll',
  },
  box: {
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '80px',
    background: 'white',
    boxShadow: '0px 0px 5px 5px #ddd',
  },
  heading: {
    color: blue[500],
  },
  link: {
    // cursor: 'pointer',
  },
}));

export function PermissionsError() {
  const classes = useStyles();
  const history = useHistory();

  const attemptedPath = history?.location?.state?.prevPathname;
  return (
    <div className={classes.background}>
    <Grid container direction="column" className={classes.box}>
      <Grid item>
        <Box p={3} py={4}>
          <h2 className={classes.heading}>
            The Daily Beast Publishing Toolkit
          </h2>
        </Box>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item className={classes.link}>
        <Box p={3} py={2}>
          {attemptedPath ? (<span>
            You need permission to access <b>{attemptedPath}</b>. Please contact your manager.
          </span>) : (<span>
            You have navigated directly to the permissions error page. Please try a different url.
          </span>)}
        </Box>
      </Grid>
    </Grid>
  </div>
  );
}
