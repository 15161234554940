import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const Context = React.createContext({});

function ValidationContext({ children }) {
  const [errors, setErrors] = useState([]);

  const updateValidationContext = useCallback((updatedErrors = []) => {
    setErrors(updatedErrors);
  });

  const getErrorByFieldName = fieldName => {
    return errors.find(error => error.fieldName === fieldName);
  };

  return (
    <Context.Provider
      value={{ errors, updateValidationContext, getErrorByFieldName }}
    >
      {children}
    </Context.Provider>
  );
}

ValidationContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ValidationContext;
