import React, { useState, useEffect, useContext } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import { LABEL } from '../../labels';
import franchisesQueryGQL from './graphql/franchisesQuery.gql';
import franchiseMutationGQL from './graphql/franchiseMutation.gql';
import franchiseQueryGQL from './graphql/franchiseQuery.gql';

const franchiseQuery = gql`
  ${franchiseQueryGQL}
`;

const franchisesQuery = gql`
  ${franchisesQueryGQL}
`;

const updateFranchiseMutation = gql`
  ${franchiseMutationGQL}
`;

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      'font-size': '12px',
    },
    menuItem: {
      'min-height': '36px',
    },
  })
);

function FranchiseSection() {
  const classes = useStyles();
  const { id: storyId } = useContext(StoryContext);
  const [franchises, setFranchises] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const [loadFranchises] = useLazyQuery(franchisesQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const franchisesData = data && data.franchises;
      if (franchisesData) {
        franchisesData.unshift({ id: null });
        setFranchises(franchisesData);
      }
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [loadStoryFranchise] = useLazyQuery(franchiseQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const storyFranchise = data && data.story && data.story.franchise;
      if (storyFranchise) setSelectedFranchise(storyFranchise);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateFranchise] = useMutation(updateFranchiseMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const franchiseData = data && data.story && data.story.franchise;
      setSelectedFranchise(franchiseData || {});
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyId) {
      loadFranchises();
      loadStoryFranchise({
        variables: {
          versionId: storyId,
        },
      });
    }
  }, [storyId]);

  const onFranchiseChange = (e, selectedComponent) => {
    const {
      props: { franchise },
    } = selectedComponent;

    updateFranchise({
      variables: {
        articleId: storyId,
        franchiseId: franchise.id,
      },
    });
  };

  return (
    <Grid item xs={12} md={8}>
      <InputLabel className={classes.inputLabel} id='label'>
        {LABEL.FRANCHISE}
      </InputLabel>
      <SelectField
        id='select-field'
        fullWidth
        onChange={onFranchiseChange}
        value={selectedFranchise.id || ''}
      >
        {franchises.map((franchise) => (
          <MenuItem
            className={classes.menuItem}
            value={franchise.id}
            key={franchise.id}
            franchise={franchise}
          >
            {franchise.name}
          </MenuItem>
        ))}
      </SelectField>
      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Grid>
  );
}

export default FranchiseSection;
