mutation AddImageMutation($articleId: ID!, $image: ImageInput!) {
  image: addImage(articleId: $articleId, image: $image) {
    id
    title
    caption
    credit
    url
    owner
    rights
    crops
    mobiledocCaption: mobiledoc_caption
    altText: alt_text
    fileName: file_name
    publicId: public_id
    version
    imgDataFormat: img_data_format
    displayType: display_type
  }
}
