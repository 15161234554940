import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client'
import { useMutation, useLazyQuery } from '@apollo/client';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import updateNoCrawl from '../../mutations/updateNoCrawl.gql';
import noCrawl from '../../queries/noCrawl.gql';
import { StoryContext } from '../../../../../context/StoryContext';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      'margin-top': '10px',
      display: 'flex',
    },
    heading: {
      'margin-top': '30px',
      'margin-bottom': '10px',
      'font-weight': '600',
    },
    checkbox: {
      display: 'flex',
    },
  }),
);

const noCrawlMutation = gql`
  ${updateNoCrawl}
`;

const noCrawQuery = gql`
  ${noCrawl}
`;

export default function NoCrawl() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  const [noCrawlData, setNoCrawlData] = useState(false);
  const [message, setMessage] = useState(null);
  const [updateNoCrawlMutation] = useMutation(noCrawlMutation, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error auto-saving story no crawl data.');
    },
    onCompleted: data => {
      const { metadata } = data;
      setNoCrawlData(metadata.no_crawl);
    },
  });

  const [loadNoCrawl] = useLazyQuery(noCrawQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error loading story no crawl data.');
    },
    onCompleted: data => {
      const {
        storyData: { metadata },
      } = data;
      if (metadata) {
        setNoCrawlData(metadata.no_crawl);
      }
    },
  });

  useEffect(() => {
    if (id) {
      loadNoCrawl({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  function handleNoCrawlChecked(event, isChecked) {
    updateNoCrawlMutation({
      variables: {
        id,
        metadataInputData: {
          no_crawl: isChecked,
        },
      },
    });
  }

  return (
    <>
      <h3 className={classes.heading}>No-Crawl for Search Engines</h3>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox checked={noCrawlData} onChange={handleNoCrawlChecked} />
        }
        label="Ask search engines to not crawl this article"
      />
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
