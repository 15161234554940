.errorWrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem auto;
  width: 100%;
}

.errorInner {
  background-color: yellow;
}

.keyWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem auto;
  width: 100%;
}

.keyInner {
  padding: 0.8rem;
  margin-top: 0.8rem;
  width: 100%;
}

.keyText {
  font-size: 0.72rem;
  line-height: 1.35;
}
