import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LABEL, PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import { Context as ValidationContext } from '../../../../../context/ValidationContext';
import longHeadlineQueryGQL from './graphql/longHeadline.gql';
import longHeadlineMutationGQL from './graphql/longHeadlineMutation.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';

const longHeadlineQuery = gql`
  ${longHeadlineQueryGQL}
`;

const longHeadlineMutation = gql`
  ${longHeadlineMutationGQL}
`;

function ArticleHeadlineSection() {
  const storyContext = useContext(StoryContext);
  const [longHeadline, setLongHeadline] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { errors, getErrorByFieldName } = useContext(ValidationContext);

  const [loadLongHeadline] = useLazyQuery(longHeadlineQuery, {
    onCompleted: (data) => {
      const longHeadlineData = data && data.story && data.story.longHeadline;

      if (errorMessage) setErrorMessage('');
      if (longHeadlineData) setLongHeadline(data.story.longHeadline);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateLongHeadline] = useMutation(longHeadlineMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });
  useEffect(() => {
    if (getErrorByFieldName('long_headline')) {
      setErrorMessage(getErrorByFieldName('long_headline').message);
    }
  }, [errors]);
  useEffect(() => {
    if (storyContext.id) {
      loadLongHeadline({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) => {
    const value = e && e.target && e.target.value;
    setLongHeadline(value);
    updateLongHeadline({
      variables: {
        id: storyContext.id,
        story: {
          long_headline: value,
        },
      },
    });
  };

  let helperMessage = `${longHeadline.length}/110`;
  let hasError = false;

  if (errorMessage) {
    hasError = true;
    helperMessage = `${longHeadline.length}/110 ${errorMessage}`;
  }

  return (
    <Grid item xs={12} md={8}>
      <TextField
        label={LABEL.LONG_HEADLINE}
        placeholder={PLACEHOLDER.LONG_HEADLINE}
        fullWidth
        onChange={onChange}
        value={longHeadline}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={onChange}
        error={hasError}
        helperText={helperMessage}
      />
    </Grid>
  );
}

export default ArticleHeadlineSection;
