import PropTypes from 'prop-types';
import React, { useEffect, useState, useReducer, useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Editor } from 'mobiledoc-kit';
import FormHelperText from '@material-ui/core/FormHelperText';
import MobiledocToolbar, {
  TOOLBAR_BUTTONS,
} from '../MobiledocToolbar/MobiledocToolbar';
import InputDialog, { DIALOG_TYPE } from '../InputDialog/InputDialog';
import {
  updateHyperlinkMarkup,
  EMPTY_MOBILEDOC,
} from '../../helpers/mobiledoc-helpers';
import { colors } from '../../styles/global/variables';
import isValidUrl from '../../utils/is-valid-url';
import addDefaultProtocol from '../../utils/add-default-protocol';

const MOBILEDOC_VERSION = '0.3.0';

SlimMobiledoc.propTypes = {
  label: PropTypes.string,
  mobiledoc: PropTypes.object,
  placeholder: PropTypes.string,
  updateDoc: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

const useStyles = makeStyles(() =>
  createStyles({
    editorContainer: {
      border: `1px solid ${colors.lighterGrey}`,
    },
    mobiledocEditor: {
      margin: '10px',
      minHeight: '250px',
      '&:focus, textarea:focus': {
        outline: 'none' /** For Safari, etc * */,
      },
      position: 'relative',
      '&.__mobiledoc-editor.__has-no-content:after': {
        content: 'attr(data-placeholder)',
        color: '#afafae',
        cursor: 'text',
        position: 'absolute',
        top: '0 !important', // important is needed here for some reason... - DF
      },
    },
  }),
);

function linkReducer(state, action) {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return { dialogOpen: true, href: action.href, error: false };
    case 'CLOSE_DIALOG':
      return { dialogOpen: false, error: false };
    case 'UPDATE_HREF':
      return { ...state, href: action.value };
    case 'UPDATE_ERROR':
      return { ...state, error: true };
    default:
      throw new Error('Unknown action passed to linkReducer');
  }
}

function SlimMobiledoc(props) {
  const classes = useStyles();
  const { label, mobiledoc, placeholder = '', updateDoc, errorMessage } = props;
  const [editor, setEditor] = useState(null);
  const [selection, setSelection] = useState(null);
  const [linkState, dispatch] = useReducer(linkReducer, {
    dialogOpen: false,
    href: null,
    error: false,
  });
  const wrapper = useRef(null);

  // Create editor using props.mobiledoc
  // Only runs once when props.mobiledoc is null or an object
  useEffect(() => {
    if (
      typeof mobiledoc === 'undefined' ||
      (typeof mobiledoc === 'object' && editor)
    )
      return;

    let mobiledocEditor;

    if (mobiledoc) {
      mobiledocEditor = new Editor({
        mobiledoc,
        placeholder: placeholder || '',
      });
    } else {
      mobiledocEditor = new Editor({
        EMPTY_MOBILEDOC,
        placeholder: placeholder || '',
      });
    }

    mobiledocEditor.postDidChange(() => {
      const serializedDoc = mobiledocEditor.serialize(MOBILEDOC_VERSION);
      updateDoc(serializedDoc);
    });

    if (wrapper.current) {
      mobiledocEditor.render(wrapper.current);
    }

    setEditor(mobiledocEditor);
  }, [mobiledoc]);

  // control the hyperlink dialog
  const onLinkClick = href => {
    setSelection(editor.range);
    dispatch({ type: 'OPEN_DIALOG', href });
  };

  const mobiledocToolbarFeatures = [
    TOOLBAR_BUTTONS.BOLD,
    TOOLBAR_BUTTONS.ITALIC,
    TOOLBAR_BUTTONS.UNDERLINE,
    TOOLBAR_BUTTONS.LINE_THROUGH,
    TOOLBAR_BUTTONS.LINK,
  ];

  return (
    <>
      <div className={classes.editorContainer}>
        {label && <div>{label}</div>}
        <MobiledocToolbar
          orientation="horizontal"
          features={mobiledocToolbarFeatures}
          linkClickHandler={onLinkClick}
          editor={editor}
        />
        <div className={classes.mobiledocEditor} ref={wrapper} />
        <InputDialog
          dialogType={DIALOG_TYPE.ADD}
          error={linkState.error}
          open={linkState.dialogOpen}
          title="Add Link"
          inputLabel="URL"
          inputPlaceholder="e.g. www.example.com"
          inputValue={null}
          onClose={() => {
            dispatch({ type: 'CLOSE_DIALOG' });
          }}
          onSave={() => {
            const linkHref = addDefaultProtocol(linkState.href);

            if (!isValidUrl(linkHref)) {
              dispatch({ type: 'UPDATE_ERROR' });
              return;
            }

            editor.selectRange(selection);

            editor.run(postEditor => {
              updateHyperlinkMarkup(editor, postEditor, linkHref);
            });

            dispatch({ type: 'CLOSE_DIALOG' });
          }}
          onInputChange={event => {
            dispatch({ type: 'UPDATE_HREF', value: event.target.value });
          }}
        />
      </div>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
}

export default SlimMobiledoc;
