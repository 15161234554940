mutation UpdateNewsletterCallout(
  $sectionId: ID!
  $text: String
  $lists: [String]
) {
  updateNewsletterCallout(
    sectionId: $sectionId
    callout: { text: $text, lists: $lists }
  ) {
    productCallout: product_callout {
      ... on NewsletterProductCallout {
        lists
        type
      }
    }
  }
}
