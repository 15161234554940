import PropTypes from 'prop-types';
import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '../../../../../../common/Input/Input';
import { UPDATE_VIDEO } from '../EmbedPicker';

export const VIDEO_TYPE = {
  YOUTUBE: 'youtube',
  JW: 'JW',
  OTHER: 'other',
};

export default function VideoPickerForm(props) {
  const {
    inputValue,
    inputError,
    selectedVideoType,
    onVideoTypeChange,
    onInputUpdate,
  } = props;

  const placeholderText =
    selectedVideoType === VIDEO_TYPE.YOUTUBE
      ? 'Paste your URL here...'
      : 'Paste your code here...';

  return (
    <FormControl component="fieldset" fullWidth>
      <RadioGroup
        name="Video"
        aria-label="Video Picker Radio Group"
        value={selectedVideoType}
        onChange={onVideoTypeChange}
        row
      >
        <FormControlLabel
          control={<Radio />}
          value={VIDEO_TYPE.YOUTUBE}
          label="Youtube"
        />
        <FormControlLabel
          control={<Radio />}
          value={VIDEO_TYPE.JW}
          label="JW"
        />
        <FormControlLabel
          control={<Radio />}
          value={VIDEO_TYPE.OTHER}
          label="Other Video Players"
        />
      </RadioGroup>
      <Input
        placeholder={placeholderText}
        keyToUpdate={UPDATE_VIDEO}
        updateFieldAction={onInputUpdate}
        value={inputValue}
        multiline={selectedVideoType !== VIDEO_TYPE.YOUTUBE}
        errorText={inputError}
        focus
        fullWidth
      />
    </FormControl>
  );
}

VideoPickerForm.propTypes = {
  inputValue: PropTypes.string,
  inputError: PropTypes.string,
  selectedVideoType: PropTypes.string,
  onVideoTypeChange: PropTypes.func,
  onInputUpdate: PropTypes.func,
};
