import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { StoryContext } from '../../../../../context/StoryContext';
import verticalsAndFranchisesQueryGQL from './graphql/verticalsAndFranchisesQuery.gql';
import crossPromotionQueryGQL from './graphql/crossPromotionQuery.gql';
import addVerticalMutationGQL from './graphql/addVerticalMutation.gql';
import resetCrossPromotionMutationGQL from './graphql/resetCrossPromotionMutation.gql';
import addFranchiseMutationGQL from './graphql/addFranchiseMutation.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import { LABEL } from '../../labels';

const CROSS_PROMOTION = {
  FRANCHISE: 'franchise',
  NONE: 'none',
  SUBVERTICAL: 'subvertical',
  VERTICAL: 'vertical',
};

const verticalsAndFranchisesQuery = gql`
  ${verticalsAndFranchisesQueryGQL}
`;

const crossPromotionQuery = gql`
  ${crossPromotionQueryGQL}
`;

const addVerticalMutation = gql`
  ${addVerticalMutationGQL}
`;

const resetCrossPromotionMutation = gql`
  ${resetCrossPromotionMutationGQL}
`;

const addFranchiseMutation = gql`
  ${addFranchiseMutationGQL}
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    group: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
    inputLabel: {
      'font-size': '12px',
    },
    root: {
      display: 'flex',
    },
  })
);

export default function CrossPromotionSection() {
  const classes = useStyles();
  const { id: storyId } = useContext(StoryContext);
  const [crossPromotionType, setCrossPromotionType] = useState(
    CROSS_PROMOTION.NONE
  );
  const [verticals, setVerticals] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState({});
  const [selectedSubvertical, setSelectedSubvertical] = useState({});
  const [selectedFranchise, setSelectedFranchise] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const [loadCrossPromotion] = useLazyQuery(crossPromotionQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const crossPromotionVertical =
        data &&
        data.story &&
        data.story.crossPromotion &&
        data.story.crossPromotion.vertical;
      const crossPromotionSubvertical =
        data &&
        data.story &&
        data.story.crossPromotion &&
        data.story.crossPromotion.subvertical;
      const crossPromotionFranchise =
        data &&
        data.story &&
        data.story.crossPromotion &&
        data.story.crossPromotion.franchise;

      if (crossPromotionFranchise) {
        setCrossPromotionType(CROSS_PROMOTION.FRANCHISE);
        setSelectedFranchise(crossPromotionFranchise);
      } else if (crossPromotionSubvertical && crossPromotionVertical) {
        setCrossPromotionType(CROSS_PROMOTION.SUBVERTICAL);
        setSelectedVertical(crossPromotionVertical);
        setSelectedSubvertical(crossPromotionSubvertical);
      } else if (crossPromotionVertical) {
        setCrossPromotionType(CROSS_PROMOTION.VERTICAL);
        setSelectedVertical(crossPromotionVertical);
      } else {
        setCrossPromotionType(CROSS_PROMOTION.NONE);
        setSelectedFranchise({});
        setSelectedVertical({});
        setSelectedSubvertical({});
      }
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [loadVerticalsAndFranchises] = useLazyQuery(
    verticalsAndFranchisesQuery,
    {
      onCompleted: (data) => {
        if (errorMessage) setErrorMessage('');

        const verticalsData = data && data.verticals;
        const franchisesData = data && data.franchises;
        if (verticalsData) setVerticals(verticalsData);
        if (franchisesData) setFranchises(franchisesData);
      },
      onError: (err) => {
        setErrorMessage(getGraphqlErrorMessage(err));
      },
    }
  );

  const [addFranchise] = useMutation(addFranchiseMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const franchiseData =
        data && data.crossPromotion && data.crossPromotion.franchise;
      if (franchiseData) setSelectedFranchise(franchiseData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [addVertical] = useMutation(addVerticalMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [resetCrossPromotion] = useMutation(resetCrossPromotionMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');

      setSelectedVertical({});
      setSelectedSubvertical({});
      setSelectedFranchise({});
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyId) {
      loadVerticalsAndFranchises();
      loadCrossPromotion({
        variables: {
          versionId: storyId,
        },
      });
    }
  }, [storyId]);

  const onChange = (e) => {
    resetCrossPromotion({
      variables: {
        articleId: storyId,
      },
    });
    setCrossPromotionType(e.target.value);
  };

  const renderVerticalItems = () =>
    verticals.map((vertical) => (
      <MenuItem value={vertical.id} key={vertical.id} vertical={vertical}>
        {vertical.name}
      </MenuItem>
    ));

  const renderSubverticalItems = () => {
    if (!selectedVertical || !selectedVertical.subverticals) return null;

    return selectedVertical.subverticals.map((subvertical) => (
      <MenuItem
        value={subvertical.id}
        key={subvertical.id}
        vertical={subvertical}
      >
        {subvertical.name}
      </MenuItem>
    ));
  };

  const renderFranchiseItems = () =>
    franchises.map((franchise) => (
      <MenuItem value={franchise.id} key={franchise.id} franchise={franchise}>
        {franchise.name}
      </MenuItem>
    ));

  const onVerticalChange = (e, selectedComponent) => {
    const {
      props: { vertical },
    } = selectedComponent;

    addVertical({
      variables: {
        articleId: storyId,
        verticalId: vertical.id,
      },
    }).then(({ data }) => {
      const verticalData =
        data && data.crossPromotion && data.crossPromotion.vertical;
      if (verticalData) setSelectedVertical(verticalData);
    });
  };

  const onSubverticalChange = (e, selectedComponent) => {
    const {
      props: { vertical },
    } = selectedComponent;

    addVertical({
      variables: {
        articleId: storyId,
        verticalId: vertical.id,
      },
    }).then(({ data }) => {
      const subverticalData =
        data && data.crossPromotion && data.crossPromotion.subvertical;
      if (subverticalData) setSelectedSubvertical(subverticalData);
    });
  };

  const onFranchiseChange = (e, selectedComponent) => {
    const {
      props: { franchise },
    } = selectedComponent;

    addFranchise({
      variables: {
        articleId: storyId,
        franchiseId: franchise.id,
      },
    });
  };

  const renderCrossPromotionForm = () => {
    let form = null;

    if (crossPromotionType === CROSS_PROMOTION.VERTICAL) {
      form = (
        <Grid item xs={12} md={8}>
          <InputLabel
            error={!selectedVertical.id}
            className={classes.inputLabel}
            id='label'
          >
            {LABEL.VERTICAL}
          </InputLabel>
          <SelectField
            id='select-field'
            fullWidth
            onChange={onVerticalChange}
            value={selectedVertical.id || ''}
            error={!selectedVertical.id}
          >
            {renderVerticalItems()}
          </SelectField>
        </Grid>
      );
    } else if (crossPromotionType === CROSS_PROMOTION.SUBVERTICAL) {
      form = (
        <>
          <Grid item xs={12} md={8}>
            <InputLabel
              error={!selectedVertical.id}
              className={classes.inputLabel}
              id='label'
            >
              {LABEL.VERTICAL}
            </InputLabel>
            <SelectField
              id='select-field'
              fullWidth
              onChange={onVerticalChange}
              value={selectedVertical.id || ''}
              error={!selectedVertical.id}
            >
              {renderVerticalItems()}
            </SelectField>
          </Grid>
          <Grid item xs={12} md={8}>
            <InputLabel
              error={!selectedSubvertical.id}
              className={classes.inputLabel}
              id='label'
            >
              {LABEL.SUBVERTICAL}
            </InputLabel>
            <SelectField
              id='select-field'
              fullWidth
              onChange={onSubverticalChange}
              value={selectedSubvertical.id || ''}
              error={!selectedSubvertical.id}
            >
              {renderSubverticalItems()}
            </SelectField>
          </Grid>
        </>
      );
    } else if (crossPromotionType === CROSS_PROMOTION.FRANCHISE) {
      form = (
        <Grid item xs={12} md={8}>
          <InputLabel
            error={!selectedFranchise.id}
            className={classes.inputLabel}
            id='label'
          >
            {LABEL.FRANCHISE}
          </InputLabel>
          <SelectField
            id='select-field'
            fullWidth
            onChange={onFranchiseChange}
            value={selectedFranchise.id || ''}
            error={!selectedFranchise.id}
          >
            {renderFranchiseItems()}
          </SelectField>
        </Grid>
      );
    }

    return form;
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <div className={classes.root}>
          <FormControl error={!!errorMessage} component='fieldset' fullWidth>
            <FormLabel component='legend'>Cross Promotion</FormLabel>
            <RadioGroup
              aria-label='Cross Promotion'
              value={crossPromotionType}
              className={classes.group}
              onChange={onChange}
            >
              <FormControlLabel
                value={CROSS_PROMOTION.NONE}
                control={<Radio />}
                label='None'
              />
              <FormControlLabel
                value={CROSS_PROMOTION.VERTICAL}
                control={<Radio />}
                label='Vertical'
              />
              <FormControlLabel
                value={CROSS_PROMOTION.SUBVERTICAL}
                control={<Radio />}
                label='Subvertical'
              />
              <FormControlLabel
                value={CROSS_PROMOTION.FRANCHISE}
                control={<Radio />}
                label='Franchise'
              />
            </RadioGroup>
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </FormControl>
        </div>
      </Grid>
      {renderCrossPromotionForm()}
    </>
  );
}
