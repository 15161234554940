import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gql } from '@apollo/client'
import { useLazyQuery } from '@apollo/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import Navbar from '../../common/Navbar/Navbar';
import SearchByAuthorNameGQL from '../../queries/searchByAuthorName.gql';
import Suggestion from './Suggestion';
import { errorMessageAction } from '../../redux/actions/messages';

Authors.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appBarSpacer: theme.mixins.toolbar,
    container: {
      padding: theme.spacing(4),
    },
    linkCreateAuthor: {
      textDecoration: 'none',
    },
  }),
);

const authorQuery = gql`
  ${SearchByAuthorNameGQL}
`;

export default function Authors({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);
  const [authorsData, setAuthorsData] = useState([]);
  const [querySearchTerm, setQuerySearchTerm] = useState(null);
  const [message, setMessage] = useState(null);
  const [loadAuthors] = useLazyQuery(authorQuery, {
    onError: err => {
      errorMessageAction(err);
      setMessage('Error fetching author');
    },
    onCompleted: data => {
      setAuthorsData(data.searchAuthorByName);
    },
    variables: {
      name: querySearchTerm,
    },
  });

  const handleNavigateHome = () => {
    history.push('/');
  };

  const goToAuthor = author => {
    history.push(`/author/${author.slug}`);
  };

  function updateQuery(searchTerm) {
    if (!searchTerm) return;
    setQuerySearchTerm(searchTerm);
    loadAuthors();
  }

  const selectionHandler = async (event, value) => {
    goToAuthor(value);
  };

  return (
    <Container maxWidth="md">
      <div className="Authors">
        <nav className="Authors__header">
          <Navbar handleNavigateHome={handleNavigateHome} title="Authors">
            <div className="Authors__button-container">
              <a href="/author" className={classes.linkCreateAuthor}>
                <Button label="Create Author" primary />
              </a>
            </div>
          </Navbar>
        </nav>
        <div className={classes.appBarSpacer} />
        <main className={`Authors__main ${classes.container}`}>
          <div className="Authors__input-container">
            <Autocomplete
              getOptionLabel={x => x.name}
              options={authorsData}
              renderOption={option => (
                <Suggestion image={option.image} name={option.name} />
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Search Author"
                />
              )}
              onChange={selectionHandler}
              onInputChange={(e, inputString) => updateQuery(inputString)}
            />
          </div>
        </main>
        <Snackbar
          open={!!message}
          message={message}
          onClose={() => {
            setMessage(null);
          }}
        />
      </div>
    </Container>
  );
}
