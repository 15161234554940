import PropTypes from 'prop-types';
import React from 'react';

const UnknownAtom = (props) => (
  <span >Unknown Mobiledoc Atom: {props.name}</span>
);

UnknownAtom.propTypes = {
  name: PropTypes.string,
};

export default UnknownAtom;
