import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment-timezone';
import { colors } from '../../styles/global/variables';
import { StoryContext } from '../../context/StoryContext';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    'box-shadow': 'none',
    'border-bottom': `1px solid ${colors.lighterGrey}`,
  },
  toolbar: {
    'justify-content': 'space-between',
    'padding-left': '10px',
    'padding-right': 0,
  },
  toolbarLeft: {
    cursor: 'pointer',
    'text-align': 'left',
    display: 'inline-flex',
    'align-items': 'center',
  },
  title: {
    'line-height': '1.3',
  },
  draftBar: {
    height: '43px',
    color: colors.black,
    backgroundColor: colors.neonYellow,
    justifyContent: 'center',
    fontWeight: 'bold',
  },
}));

export default function Navbar(props) {
  const storyContext = useContext(StoryContext);
  const { children, handleNavigateHome, title, inStory } = props;
  const scheduleDatePending =
    storyContext && moment(storyContext.publicationDate).isAfter(moment());
  const formattedPublishDate =
    storyContext &&
    moment(storyContext.publicationDate).format('MM/DD/YY h:mma');

  let bannerMessage = 'THIS ARTICLE IS A DRAFT';

  if (storyContext && storyContext.scheduled && scheduleDatePending) {
    bannerMessage += ` THAT IS SCHEDULED TO BE PUBLISHED AT ${formattedPublishDate}`;
  }

  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarLeft}>
          <Tooltip title="Back">
            <ArrowBackIosIcon onClick={handleNavigateHome} />
          </Tooltip>
          <Hidden xsDown>
            <Typography className={classes.title} variant="body1">
              {inStory ? storyContext.seoHeadline : title}
            </Typography>
          </Hidden>
        </div>
        {children}
      </Toolbar>
      {bannerMessage && storyContext && !storyContext.hasPublishedVersion && (
        <Alert variant="filled" className={classes.draftBar} severity="warning">
          {bannerMessage}
        </Alert>
      )}
    </AppBar>
  );
}

Navbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleNavigateHome: PropTypes.func,
  title: PropTypes.string,
  inStory: PropTypes.bool,
};
