mutation DeleteStoryAuthorMutation($articleId: ID!, $authorId: ID!) {
  story: deleteStoryAuthor(articleId: $articleId, authorId: $authorId) {
    authors {
      id
      name
      email
      slug
    }
  }
}
