import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import CSVReader from 'react-csv-reader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import fetchRefreshExclusions from './queries/fetchRefreshExclusions.gql';
import addRefreshExclusion from './queries/addRefreshExclusion.gql';
import removeRefreshExclusion from './queries/removeRefreshExclusion.gql';
import replaceAllRefreshExclusions from './queries/replaceAllRefreshExclusions.gql';
import isNumber from '../isNumber';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #333',
    padding: '30px',
  },
  wrapper: {
    flex: '50%',
    padding: '15px',
    boxSizing: 'border-box',
  },
  item: { margin: '15px 17px' },
}));

export default function ExclusionsList() {
  const classes = useStyles();

  const [lineItemIdInput, setLineItemIdInput] = useState('');
  const [refreshExclusions, setRefreshExclusions] = useState([]);

  useQuery(
    gql`
      ${fetchRefreshExclusions}
    `,
    {
      onCompleted: data => {
        setRefreshExclusions(data.refreshExclusions.map(r => r.lineItemId));
      },
    },
  );

  const [runAddRefreshExclusionMutation] = useMutation(
    gql`
      ${addRefreshExclusion}
    `,
    {
      onCompleted: data => {
        setRefreshExclusions(prevState => [
          ...prevState,
          data.refreshExclusion.lineItemId,
        ]);
        setLineItemIdInput('');
      },
      variables: { lineItemId: lineItemIdInput },
    },
  );

  const [runRemoveRefreshExclusionMutation] = useMutation(
    gql`
      ${removeRefreshExclusion}
    `,
  );

  const [runReplaceAllRefreshExclusionsMutation] = useMutation(
    gql`
      ${replaceAllRefreshExclusions}
    `,
  );

  function handleLineItemIdInput(value) {
    if (value === '' || (isNumber(value) && value.toString().length <= 15)) {
      setLineItemIdInput(value);
    }
  }

  function handleXButtonClick(id) {
    const refreshExclusionsClone = [...refreshExclusions];
    const index = refreshExclusionsClone.indexOf(id);
    if (index > -1) {
      refreshExclusionsClone.splice(index, 1);
    }

    runRemoveRefreshExclusionMutation({
      onCompleted: setRefreshExclusions(refreshExclusionsClone),
      variables: { lineItemId: id },
    });
  }

  function handleCSVInput(ids) {
    const flatIds = ids.flat();
    const areIdsOK =
      flatIds.flat().findIndex(id => {
        if (typeof id === 'string' && id.length > 0 && id.length <= 15) {
          // eslint-disable-next-line eqeqeq
          return parseInt(id, 10) != id;
        }
        return true;
      }) < 0;
    if (areIdsOK) {
      runReplaceAllRefreshExclusionsMutation({
        onCompleted: setRefreshExclusions(flatIds),
        variables: { lineItemIds: flatIds },
      });
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <TextField
          label="Add Line Item Id"
          placeholder="1234567890"
          onChange={event => handleLineItemIdInput(event.target.value)}
          value={lineItemIdInput}
          InputLabelProps={{ shrink: true }}
        />
        <button
          type="button"
          onClick={
            lineItemIdInput !== '' ? runAddRefreshExclusionMutation : () => {}
          }
        >
          +
        </button>
        <List>
          {refreshExclusions.map(id => (
            <ListItem key={id}>
              <ListItemText primary={id} />
              <button type="button" onClick={() => handleXButtonClick(id)}>
                x
              </button>
            </ListItem>
          ))}
        </List>
      </div>
      <CSVReader
        className={classes.wrapper}
        cssClass="ExclusionsList__csv-input"
        // eslint-disable-next-line react/jsx-no-bind
        onFileLoaded={handleCSVInput}
      />
    </div>
  );
}
