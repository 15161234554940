import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export function useHandleNavigateHome() {
  const history = useHistory();
  return useCallback(() => {
    history.push('/');
  }, [history]);
}
