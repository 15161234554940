import _ from 'lodash';
import React, { useEffect, useState, useReducer } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Button from '../../common/ButtonWrapper/ButtonWrapper';
import ChipsContainer from '../../common/ChipsContainer/ChipsContainer';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: '70px',
    },
    textField: {
      width: '100%',
      marginTop: '16px',
    },
    saveButton: {
      fontWeight: 'bold',
      margin: '5px',
    },
    autocomplete: {
      minWidth: 500,
    },
  })
);

const TagOption = option => <div id={option.id}>{option.name}</div>;

export default function TagTag ({
  tag, 
  sources = [],
  genres = [],
  onChange = _.noop,
}) {

  const classes = useStyles();

  const [localGenres, setLocalGenres] = useState([]);
  const [localSources, setLocalSources] = useState([]);
  const [genreSearchText, setGenreSearchText] = useState('');
  const [sourceSearchText, setSourceSearchText] = useState('');

  useEffect(() => {
    setLocalGenres(tag.children.filter(t => t.type === 'genre'));
    setLocalSources(tag.children.filter(t => t.type === 'source'));
  }, [tag.children.length])

  const menuStyle = {
    maxHeight: '250px',
    width: '100%',
    display: 'block',
  };

  const localOnChange = (type, tags) => {
    if (type === 'genre') {
      onChange([...tags, ...localSources]);
    } else if (type === 'source') {
      console.log('change', tags, localSources, localGenres);
      onChange([...tags, ...localGenres]);
    }
  };

  const localOnDelete = (type, deletedId) => {
    const tags = [...localSources, ...localGenres];
    const filtered = tags.filter(tag => tag.id !== deletedId);
    console.log('delete', deletedId, tags, filtered);
    onChange(filtered);
  };

  return (
    <>
        <ListItem>
          <Autocomplete
            multiple
            className={classes.autocomplete}
            disableClearable
            getOptionLabel={tag => tag.name}
            getOptionSelected={(t1, t2) => t1.id === t2.id}
            menustyle={menuStyle}
            onChange={(e, value) => localOnChange('source', value)}
            options={sources}
            renderOption={TagOption}
            renderTags={() => null}
            value={localSources}

            renderInput={params => (
              <TextField
                className={classes.textField}
                {...params}
                onChange={e => setSourceSearchText(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Where To Watch"
              />
            )}
          />
        </ListItem>

        <ListItem>
          <ChipsContainer
            removeAction={e => localOnDelete('source', e)}
            valueName="name"
            values={localSources}
          />
        </ListItem>

        <ListItem>
          <Autocomplete
            multiple
            menustyle={menuStyle}
            className={classes.autocomplete}
            getOptionLabel={tag => tag.name}
            getOptionSelected={(t1, t2) => t1.id === t2.id}
            value={localGenres}
            options={genres}
            onChange={(e, value) => localOnChange('genre', value)}
            renderOption={TagOption}
            renderTags={() => null}
            renderInput={params => (
              <TextField
                className={classes.textField}
                {...params}
                onChange={e => setGenreSearchText(e.target.value || null)}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Genres*"
              />
            )}
          />

        </ListItem>
        <ListItem>
          <ChipsContainer
            removeAction={e => localOnDelete('genre', e)}
            valueName="name"
            values={localGenres}
          />
        </ListItem>
    </>

      
  );
}
