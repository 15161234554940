import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LABEL, PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import { Context as ValidationContext } from '../../../../../context/ValidationContext';
import ChipsContainer from '../../../../../common/ChipsContainer/ChipsContainer';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import tagsQueryGQL from './graphql/tagsQuery.gql';
import searchTagByNameQueryGQL from './graphql/searchTagByNameQuery.gql';
import addStoryTagMutationGQL from './graphql/addStoryTagMutation.gql';
import deleteStoryTagMutationGQL from './graphql/deleteStoryTagMutation.gql';

const useStyles = makeStyles(() =>
  createStyles({
    dontScaleFont: {
      '& label': {
        fontSize: '16px!important',
      },
    },
  })
);

const tagsQuery = gql`
  ${tagsQueryGQL}
`;

const searchTagByNameQuery = gql`
  ${searchTagByNameQueryGQL}
`;

const addStoryTagMutation = gql`
  ${addStoryTagMutationGQL}
`;

const deleteStoryTagMutation = gql`
  ${deleteStoryTagMutationGQL}
`;

function KeywordSection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [queriedTags, setQueriedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [keywordSearchText, setKeywordSearchText] = useState('');

  const { errors, getErrorByFieldName } = useContext(ValidationContext);

  const [loadTags] = useLazyQuery(tagsQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const tagsData = data && data.story && data.story.tags;
      if (tagsData) setTags(tagsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [loadQueriedTags] = useLazyQuery(searchTagByNameQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const queriedTagsData = data && data.queriedTags;
      if (queriedTagsData) setQueriedTags(queriedTagsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [addStoryTag] = useMutation(addStoryTagMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const tagsData = data && data.story && data.story.tags;
      if (tagsData) setTags(tagsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [deleteStoryTag] = useMutation(deleteStoryTagMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const tagsData = data && data.story && data.story.tags;
      if (tagsData) setTags(tagsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (getErrorByFieldName('keywords')) {
      setErrorMessage(getErrorByFieldName('keywords').message);
    }
  }, [errors]);

  useEffect(() => {
    if (storyContext.id) {
      loadTags({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e, value) => {
    const tagId = value.id;

    setKeywordSearchText('');

    addStoryTag({
      variables: {
        articleId: storyContext.id,
        tagId,
      },
    });
  };

  const onInputChange = (e, inputString) => {
    loadQueriedTags({
      variables: {
        limit: 25,
        name: inputString,
      },
    });
  };

  const onDeleteTag = (tagId) => {
    deleteStoryTag({
      variables: {
        articleId: storyContext.id,
        tagId,
      },
    });
  };

  return (
    <Grid item xs={12} md={8} className={classes.dontScaleFont}>
      <Autocomplete
        menustyle={{ maxHeight: '250px', overflowY: 'auto' }}
        getOptionLabel={(option) => option.name}
        hinttext={PLACEHOLDER.TAGS}
        options={queriedTags}
        onChange={onChange}
        inputValue={keywordSearchText}
        onInputChange={onInputChange}
        disableClearable
        renderOption={(option) => <div id={option.id}>{option.name}</div>}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setKeywordSearchText(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errorMessage}
            helperText={errorMessage}
            label={LABEL.TAGS}
          />
        )}
      />
      <ChipsContainer
        removeAction={onDeleteTag}
        valueName='name'
        values={tags}
      />
    </Grid>
  );
}

export default KeywordSection;
