import PropTypes from 'prop-types';
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import React, { useState, useContext, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import FormHelperText from '@material-ui/core/FormHelperText';
import DragAndDrop from '../../../../../utils/dnd-context';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import Modal from '../../../../../common/Modal/Modal';
import Input from '../../../../../common/Input/Input';
import SlimMobiledoc from '../../../../../common/SlimMobiledoc/SlimMobiledoc';
import partnerList from './queries/partnerListQuery.gql';
import productCategoryGQL from './queries/productCategory.gql';
import conversionCard from './queries/conversionCardQuery.gql';
import createConversionCardMutationGQL from './mutations/createConversionCardMutation.gql';
import updateConversionCardMutationGQL from './mutations/updateConversionCardMutation.gql';
import addConversionCardImageGQL from './mutations/addConversionCardImageMutation.gql';
import deleteConversionCardImageMutationGQL from './mutations/deleteConversionCardImageMutation.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import { errorMessageAction } from '../../../../../redux/actions/messages';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import { StoryContext } from '../../../../../context/StoryContext';
import { CONVERSION_CARD_NAME } from '../ConversionCard/ConversionCard';
import PromoImagePicker from '../../../images/components/PromoImagePicker/PromoImagePicker';
import { colors } from '../../../../../styles/global/variables';

const conversionCardQuery = gql`
  ${conversionCard}
`;
const productCategoryQuery = gql`
  ${productCategoryGQL}
`;
const createConversionCardMutation = gql`
  ${createConversionCardMutationGQL}
`;
const updateConversionCardMutation = gql`
  ${updateConversionCardMutationGQL}
`;
const partnerListQuery = gql`
  ${partnerList}
`;

const addConversionCardImageMutation = gql`
  ${addConversionCardImageGQL}
`;

const deleteConversionCardImageMutation = gql`
  ${deleteConversionCardImageMutationGQL}
`;

ConversionPicker.propTypes = {
  selection: PropTypes.object,
};

const PLACEHOLDERS = {
  promoItemName: 'Name of the product indicated in the headline.',
  specialInfo: 'Use Code SCOUTED to get up to 50-70% off',
  partnerUrl: 'Paste URL here',
  callToAction: 'Select Call to Action',
  priceAtCheckout: '1000',
  partner: 'Select Partner',
};
const LABELS = {
  FREE_SHIPPING: 'Free Shipping',
  FREE_RETURNS: 'Free Returns',
};

const BUTTON_ORDER = ['Primary', 'Secondary'];
const BUTTON_LIMIT = 2;

const CONVERSION_CARD_TYPES = {
  SIMPLE: 'simple',
  EXPANDED: 'expanded',
};

const CALL_TO_ACTIONS = [
  'Buy At',
  'Shop At',
  'Subscribe At',
  'Watch On',
  'Book On',
];

const useStyles = makeStyles(theme =>
  createStyles({
    promoItem: {
      paddingBottom: '30px',
    },
    partnerButtonTitle: {
      fontSize: '12px',
      fontStyle: 'bold',
      color: colors.materialBlue,
    },
    requiredLabel: {
      '& label': {
        fontWeight: 'bold',
      },
      '& label::before': {
        content: `'*'`,
        color: colors.beastRed,
        paddingRight: '5px',
        fontSize: '18px',
      },
    },
    standardLabel: {
      '& label': {
        fontWeight: 'bold',
      },
    },
    descriptionLabel: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '28px',
      '&::before': {
        content: `'*'`,
        color: colors.beastRed,
        paddingRight: '5px',
        fontSize: '18px',
      },
    },
    formControl: {
      margin: 0,
    },
    group: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
    conversionCardDeleteButton: {
      backgroundColor: colors.beastRed,
      '&:hover': {
        backgroundColor: colors.beastRed,
      },
    },
  }),
);

function ConversionPicker({ selection }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isNewCard, setIsNewCard] = useState(false);
  const [mobiledocSummary, setMobiledocSummary] = useState(null);
  const initialPartnerButton = {
    partnerId: null,
    partnerName: '',
    id: null,
    freeReturns: false,
    freeShipping: false,
    partnerUrl: null,
    callToAction: 'Buy At',
    price: null,
  };
  const [conversionData, setConversionData] = useState({
    id: null,
    specialInfo: null,
    productCategory: null,
    promoItemName: null,
    image: null,
    expanded: false,
    partnerButtons: [{ ...initialPartnerButton, isPrimary: true }],
  });

  const classes = useStyles();
  const [conversionImageError, setConversionImageError] = useState(null);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const { id: storyId } = useContext(StoryContext);
  const {
    editor,
    conversionCardId,
    updateConversionPickerOpen,
    saveCardFunction,
    updateSaveCardFunction,
    updateConversionId,
  } = useContext(EditorContext);

  const { data: productCategoryData } = useQuery(productCategoryQuery, {
    onError: err => {
      errorMessageAction(err);
      setMessage('Error fetching list of campaigns');
    },
  });

  const { data: partnerListData } = useQuery(partnerListQuery, {
    onError: err => {
      errorMessageAction(err);
      setMessage('Error fetching list of partners');
    },
  });

  const [loadConversionCard] = useLazyQuery(conversionCardQuery, {
    onError: () => {
      setErrorMessage('Error fetching data for conversion card!');
    },
    onCompleted: data => {
      if (errorMessage) setErrorMessage('');
      if (!data) return;
      const { conversionCardById } = data;
      setMobiledocSummary(conversionCardById.mobiledocSummary);

      const partnerButtonsCTA = conversionCardById.partnerButtons.map(btn => {
        const { callToAction, price } = btn;
        const callToActionDefault = price ? 'Buy At' : 'Shop At';
        return {
          ...btn,
          callToAction: callToAction || callToActionDefault,
        };
      });
      conversionCardById.partnerButtons = [...partnerButtonsCTA];
      setConversionData(conversionCardById);
    },
  });

  const [addConversionCardImage] = useMutation(addConversionCardImageMutation, {
    onError: err => {
      const conversionErrMsg = getGraphqlErrorMessage(err);
      setConversionImageError(conversionErrMsg);
    },
    onCompleted: data => {
      setConversionData({ ...conversionData, image: data.image });
    },
  });

  const [deleteConversionCardImage] = useMutation(
    deleteConversionCardImageMutation,
    {
      onError: err => {
        const conversionErrMsg = getGraphqlErrorMessage(err);
        setConversionImageError(conversionErrMsg);
      },
      onCompleted: () => {
        setConversionData({ ...conversionData, image: null });
      },
    },
  );

  useEffect(() => {
    if (conversionCardId) {
      loadConversionCard({
        variables: {
          id: conversionCardId,
        },
      });
    } else {
      setIsNewCard(true);
    }
  }, [conversionCardId]);

  useEffect(() => {
    clearImageValidationError();
  }, [conversionData && conversionData.image && conversionData.image.id]);

  const createOrUpdateCard = () => {
    const {
      specialInfo,
      promoItemName,
      partnerButtons,
      expanded,
      image,
    } = conversionData;
    const buttonColumns = partnerButtons.map(btn => {
      const {
        id,
        price,
        freeShipping,
        freeReturns,
        partnerUrl,
        partnerId,
        callToAction,
        isPrimary,
      } = btn;
      return {
        id,
        price,
        free_shipping: freeShipping,
        free_returns: freeReturns,
        partner_url: partnerUrl,
        partner_id: partnerId,
        call_to_action: callToAction,
        is_primary: isPrimary,
      };
    });

    const variables = {
      conversionCard: {
        id: conversionCardId,
        article_id: storyId,
        special_info: specialInfo,
        expanded,
        promo_item_name: promoItemName,
        product_category_id: productCategory && productCategory[0].id,
        image_id: image && image.id,
        mobiledoc_summary: mobiledocSummary,
      },
      partnerButtons: buttonColumns,
    };

    if (isNewCard) {
      createConversionCard({ variables });
    } else {
      updateConversionCard({ variables });
    }
  };

  const setDataOnCreateOrUpdate = data => {
    const {
      conversionCardData,
      conversionCardData: { ConversionCard },
    } = data;

    if (ConversionCard) {
      const cardPayload = {
        promoItemId: Number(ConversionCard.id),
      };

      if (saveCardFunction) {
        // saveCardFunction is set in the card's edit function
        saveCardFunction(cardPayload);

        // reset save card function
        updateSaveCardFunction(null);
        updateConversionId(null);
      } else {
        editor.selectRange(selection);
        editor.insertCard(CONVERSION_CARD_NAME, cardPayload);
      }

      updateConversionPickerOpen(false);
    }

    if (errorMessage) setErrorMessage('');
    if (conversionCardData.ConversionCard) {
      setConversionData(conversionCardData.ConversionCard);
      setMobiledocSummary(conversionCardData.mobiledoc_summary);
      setErrors([]);
    } else {
      setErrors(conversionCardData.Validation.Errors);
    }
  };

  const [createConversionCard] = useMutation(createConversionCardMutation, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      if (!data && !data.conversionCardData) return;
      setDataOnCreateOrUpdate(data);
    },
  });

  const [updateConversionCard] = useMutation(updateConversionCardMutation, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      setDataOnCreateOrUpdate(data);
    },
  });

  const updatePartnerButton = (dataToUpdate, index) => {
    const buttonsToUpdate = [...conversionData.partnerButtons];
    const oldButtonData = { ...buttonsToUpdate[index] };
    buttonsToUpdate[index] = { ...oldButtonData, ...dataToUpdate };
    setConversionData({
      ...conversionData,
      partnerButtons: buttonsToUpdate,
    });
  };

  const removePartnerButton = () => {
    const buttonsToUpdate = [...conversionData.partnerButtons];
    buttonsToUpdate.pop();
    setConversionData({
      ...conversionData,
      partnerButtons: buttonsToUpdate,
    });
  };

  const addButton = () => {
    updatePartnerButton({ ...initialPartnerButton, isPrimary: false }, 1);
  };

  const removeButton = () => {
    removePartnerButton();
  };

  const clearImageValidationError = () => {
    const newErrors = errors.filter(error => error.fieldName !== 'image_id');
    setErrors(newErrors);
  };

  const getButtonErrorByFieldName = (fieldName, index) => {
    const isPrimary = index === 0;
    const err = errors.find(
      error => error.fieldName === fieldName && error.isPrimary === isPrimary,
    );
    return err && err.message;
  };

  const clearButtonErrorByFieldName = (fieldName, index) => {
    const isPrimary = index === 0;
    const errs = errors.filter(
      error =>
        !(error.fieldName === fieldName && error.isPrimary === isPrimary),
    );
    setErrors(errs);
  };

  const getItemErrorByFieldName = fieldName => {
    const err = errors.find(error => error.fieldName === fieldName);
    return err && err.message;
  };

  const clearErrorByFieldName = fieldName => {
    const errs = errors.filter(error => error.fieldName !== fieldName);
    setErrors(errs);
  };

  const resetCards = () => {
    updateSaveCardFunction(null);
    updateConversionId(null);
    updateConversionPickerOpen(false);
  };

  const {
    specialInfo,
    expanded,
    partnerButtons,
    promoItemName,
    productCategory,
    image,
  } = conversionData;

  return (
    <DragAndDrop>
      <section className="ConversionPicker">
        <Modal title="Conversion Card" open onClose={() => resetCards()}>
          <DialogContent>
            <Grid
              container
              className={classes.promoItem}
              spacing={2}
              justify="flex-start"
            >
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <RadioGroup
                    className={classes.group}
                    aria-label="Story Type"
                    name="type"
                    onChange={(e, cardType) =>
                      setConversionData({
                        ...conversionData,
                        expanded: cardType === CONVERSION_CARD_TYPES.EXPANDED,
                      })
                    }
                    value={
                      expanded
                        ? CONVERSION_CARD_TYPES.EXPANDED
                        : CONVERSION_CARD_TYPES.SIMPLE
                    }
                  >
                    <FormControlLabel
                      label="Simple"
                      control={<Radio />}
                      value={CONVERSION_CARD_TYPES.SIMPLE}
                    />
                    <FormControlLabel
                      label="Expanded"
                      control={<Radio />}
                      value={CONVERSION_CARD_TYPES.EXPANDED}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  getOptionLabel={x => x.categoryName}
                  options={
                    (productCategoryData &&
                      productCategoryData.productCategories) ||
                    []
                  }
                  renderInput={params => (
                    <TextField
                      className={classes.requiredLabel}
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={getItemErrorByFieldName('product_category_id')}
                      fullWidth
                      helperText={getItemErrorByFieldName(
                        'product_category_id',
                      )}
                      label="Product Category"
                    />
                  )}
                  onChange={(e, categoryData) => {
                    setConversionData({
                      ...conversionData,
                      productCategory: [categoryData],
                    });
                    clearErrorByFieldName('product_category_id');
                  }}
                  value={productCategory && productCategory[0]}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  classes={classes.requiredLabel}
                  label="Promo Item Name"
                  placeholder={PLACEHOLDERS.promoItemName}
                  updateFieldAction={(e, name) => {
                    setConversionData({
                      ...conversionData,
                      promoItemName: name,
                    });
                    clearErrorByFieldName('promo_item_name');
                  }}
                  value={promoItemName}
                  length={60}
                  small
                  focus
                  errorText={getItemErrorByFieldName('promo_item_name')}
                />
              </Grid>
              <Grid item xs={12}>
                <PromoImagePicker
                  image={image}
                  imageError={conversionImageError}
                  imageValidationError={getItemErrorByFieldName('image_id')}
                  addConversionCardImage={addConversionCardImage}
                  deleteConversionCardImage={deleteConversionCardImage}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  classes={classes.standardLabel}
                  label="Special Info"
                  placeholder={PLACEHOLDERS.specialInfo}
                  updateFieldAction={(e, info) =>
                    setConversionData({
                      ...conversionData,
                      specialInfo: info,
                    })
                  }
                  errorText={getItemErrorByFieldName('special_info')}
                  value={specialInfo}
                  length={40}
                  small
                />
              </Grid>

              {expanded === true && (
                <Grid item xs={12}>
                  <FormLabel className={classes.descriptionLabel}>
                    Product Description
                  </FormLabel>
                  <div>
                    <SlimMobiledoc
                      mobiledoc={mobiledocSummary || null}
                      placeholder="Here’s a little ditty about something itty bitty. The XS Landon is the perfect mini sidekick..."
                      errorMessage={getItemErrorByFieldName(
                        'mobiledoc_summary',
                      )}
                      updateDoc={md => {
                        setMobiledocSummary(md);
                        clearErrorByFieldName('mobiledoc_summary');
                      }}
                    />
                  </div>
                </Grid>
              )}
            </Grid>

            <h3 className={classes.partnerButtonTitle}>Buttons</h3>
            {partnerButtons.map((button, index) => (
              <Grid container spacing={2} justify="flex-start">
                <Grid item xs={12}>
                  <Autocomplete
                    getOptionLabel={x => x.partnerName}
                    options={
                      (partnerListData && partnerListData.partners) || []
                    }
                    renderInput={params => (
                      <TextField
                        className={classes.requiredLabel}
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={getButtonErrorByFieldName('partner_id', index)}
                        fullWidth
                        label={`${BUTTON_ORDER[index]} Partner`}
                        placeholder={PLACEHOLDERS.partner}
                        value={button.partnerName}
                      />
                    )}
                    onChange={(e, partner) => {
                      updatePartnerButton(
                        {
                          partnerName: partner?.partnerName,
                          partnerId: partner?.id,
                        },
                        index,
                      );
                      clearButtonErrorByFieldName('partner_id', index);
                    }}
                    value={{
                      partnerName: button.partnerName,
                      partnerId: button.partnerId,
                    }}
                  />
                  <FormHelperText error>
                    {getButtonErrorByFieldName('partner_id', index)}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    classes={classes.requiredLabel}
                    label="URL"
                    placeholder={PLACEHOLDERS.partnerUrl}
                    small
                    errorText={getButtonErrorByFieldName('partner_url', index)}
                    updateFieldAction={(e, url) => {
                      updatePartnerButton({ partnerUrl: url }, index);
                      clearButtonErrorByFieldName('partner_url', index);
                    }}
                    value={button.partnerUrl}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={CALL_TO_ACTIONS || []}
                    renderInput={params => (
                      <TextField
                        className={classes.requiredLabel}
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={getButtonErrorByFieldName(
                          'call_to_action',
                          index,
                        )}
                        fullWidth
                        label="Call to Action"
                        placeholder={PLACEHOLDERS.callToAction}
                        value={button.callToAction}
                      />
                    )}
                    onChange={(e, cta) => {
                      updatePartnerButton({ callToAction: cta }, index);
                      clearButtonErrorByFieldName('call_to_action', index);
                    }}
                    value={button.callToAction}
                  />
                  <FormHelperText error>
                    {getButtonErrorByFieldName('call_to_action', index)}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    classes={classes.standardLabel}
                    label="Price at Checkout (optional)"
                    placeholder={PLACEHOLDERS.priceAtCheckout}
                    withInset
                    updateFieldAction={(e, price) => {
                      updatePartnerButton({ price: price || null }, index);
                      clearButtonErrorByFieldName('price', index);
                    }}
                    value={button.price && String(button.price)}
                    errorText={getButtonErrorByFieldName('price', index)}
                    length={7}
                    small
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={button.freeShipping}
                        onChange={(e, checked) =>
                          updatePartnerButton({ freeShipping: checked }, index)
                        }
                      />
                    }
                    label={LABELS.FREE_SHIPPING}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={button.freeReturns}
                        onChange={(e, checked) =>
                          updatePartnerButton({ freeReturns: checked }, index)
                        }
                      />
                    }
                    label={LABELS.FREE_RETURNS}
                  />
                </Grid>

                {index + 1 === partnerButtons.length &&
                  index + 1 < BUTTON_LIMIT && (
                    <Grid item xs={12}>
                      <Button label="Add Retailer Button" onClick={addButton} />
                    </Grid>
                  )}

                {index + 1 === partnerButtons.length && index + 1 > 1 && (
                  <Grid item xs={12}>
                    <Button
                      className={classes.conversionCardDeleteButton}
                      label={`Delete ${BUTTON_ORDER[index]} Button`}
                      onClick={() => removeButton(index)}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
            <DialogActions>
              <Button label="Cancel" onClick={() => resetCards()} />
              <Button
                label={isNewCard ? 'Place' : 'Update'}
                onClick={createOrUpdateCard}
              />
            </DialogActions>
          </DialogContent>
        </Modal>
      </section>
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </DragAndDrop>
  );
}

export default ConversionPicker;
