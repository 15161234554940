import _ from 'lodash';
import React, { useEffect, useState, useReducer } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Button from '../../common/ButtonWrapper/ButtonWrapper';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: '70px',
    },
    addButtonContainer: {
      height: 100,
    },
    textField: {
      width: '100%',
      marginTop: '16px',
    },
    saveButton: {
      fontWeight: 'bold',
      margin: '5px',
    },
    addButton: {
      fontWeight: 'bold',
      margin: '50px',
    },
  })
);

const TagOption = option => <div id={option.id}>{option.name}</div>;

export default function TitleSearchInput({
  titles = [],
  tagId,
  onNew = _.noop,
  onChange = _.noop,
}) {

  const classes = useStyles();

  const [title, setTitle] = useState(null);
  const [titleInput, setTitleInput] = useState('');

  const menuStyle = {
    maxHeight: '250px',
    width: '100%',
    display: 'block',
  };

  const localOnChange = (e, value) => {
    setTitle(value);
    if (value) { 
      onChange(value)
    }
  };

  const onInputChange = (e, value) => {
    setTitleInput(value);
  }

  const localOnNew = () => {
    onNew();
  };

  useEffect(() => {
    const currentTitle = titles.find(t => _.toNumber(t.id) === _.toNumber(tagId))
    setTitle(currentTitle);
  }, [titles.length]);


  const showAddButton = titleInput && !_.includes(titles.map(t => t.name), titleInput);

  return (
    <div>

    <div className={classes.addButtonContainer}>
      {
        showAddButton && 
          
        <Button
          className={classes.addButton}
          label="+ Add New Title"
          onClick={localOnNew}
        />
      }

    </div>

      <Autocomplete 
        freeSolo
        menustyle={menuStyle}
        getOptionLabel={tag => tag.name}
        getOptionSelected={(option, value) => option?.id === value?.id}
        options={titles}
        onChange={localOnChange}
        value={title}
        inputValue={titleInput}
        onInputChange={onInputChange}
        //disableClearable
        renderOption={TagOption}
        renderInput={params => (
          <TextField
            className={classes.textField}
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            //error={!!errorMessage}
            //helperText={errorMessage}
            placeholder={`Search ${titles.length} Titles`}
          />
        )}
      />

    </div>
  );
}
