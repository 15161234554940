export const ROUTE_NAME = {
  BODY: 'body',
  IMAGES: 'images',
  MAIN_INFO: 'main-info',
  METADATA: 'metadata',
  OBSESSED_CLASSIFICATION: 'classification',
};

export const PERMISSIONS = {
  ADMIN: 'admin',
  ADOPS: 'adops',
  BUSINESS: 'business',
  OPSSUPPORT: 'opssupport',
};
