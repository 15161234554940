/*
 *  - reducer for state
 *  - site selector
 *  - search by keyword
 *  - search by article
 *  - search by slug/url
 *  - numbered pages
 *
 *
 */
  
// react + redux
import React, { useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { errorMessageAction } from '../../redux/actions/messages';

// util
import moment from 'moment';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import dateFormat from 'dateformat';

// material-ui
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '@material-ui/core/Container';
import CopyToClipboard from 'react-copy-to-clipboard';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FileCopy from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles, createStyles } from '@material-ui/core/styles';

// data fetching
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client'

// 1st party
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import TypeIndicator from '../../common/TypeIndicator/TypeIndicator';
import Navbar from '../../common/Navbar/Navbar';
import { colors, fonts } from '../../styles/global/variables';

// Obsessed Admin
import allStories from './queries/allStories.gql';
import allStoriesSearch from './queries/allStoriesSearch.gql';
import StoryResultCard from  './StoryResultCard';

AllStories.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: colors.lightGrey,
      height: '100vh',
      overflow: 'scroll',
      marginLeft: drawerWidth,
      display: 'flex',
    },
    resultsContainer: {
      flexGrow: 1,
    },
    thumbnail: {
      width: 151,
      height: 151,
    },
    appBar: {
      backgroundColor: 'black',
      color: 'white',
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    slugLink: {
      fontFamily: 'arial',
      fontSize: '13px',
      textDecoration: 'none',
      color: colors.black,
    },
    lastSavedAt: {
      fontFamily: 'arial',
      color: colors.darkGrey,
    },
    fileCopy: {
      color: colors.darkGrey,
      cursor: 'pointer',
    },
    searchField: {
      width: '100%',
      marginTop: '16px',
    },
    dateField: {
      'min-width': '140px',
    },
    searchFieldGroup: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    pagingDisclaimer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '20px',
    },
    paging: {
      color: colors.darkGrey,
      backgroundColor: colors.white,
      margin: '5px',
      '&:hover': {
        backgroundColor: colors.whiteDown,
      },
    },
  }),
);

const allStoriesQuery = gql`${allStories}`;
const allStoriesSearchQuery = gql`${allStoriesSearch}`;

const formatDate = date => {
  if (!date) return null;

  return moment(date)
    .add(1, 'days')
    .format('YYYY.MM.DD');
};

export default function AllStories({ onEnter, permissions }) {
  const history = useHistory();

  const [previousCursorHistory, setPreviousCursorHistory] = React.useState([]);
  const [filterByHeadline, setFilterByHeadLine] = React.useState('');
  const [filterByDate, setFilterByDate] = React.useState(null);
  const [variablesToUse, setVariablesToUse] = React.useState({});
  const [queryToUse, setQueryToUse] = React.useState(allStoriesQuery);
  const { data, refetch, error } = useQuery(queryToUse, {
    variables: variablesToUse,
  });

  useEffect(() => {
    onEnter(history, permissions);
  }, []);


  function updateQuery({ searchTerm, date, cursor }) {
    const formattedDate = date ? formatDate(date) : null;
    setQueryToUse(searchTerm ? allStoriesSearchQuery : allStoriesQuery);
    setVariablesToUse(
      searchTerm
        ? {
            query: searchTerm,
          }
        : {
            after: cursor,
            beforeDate: formattedDate,
          },
    );
    setFilterByHeadLine(searchTerm);
    if (formattedDate) {
      setFilterByDate(date);
    }
    refetch();
  }

  function dispatchError(err) {
    errorMessageAction(err, err.message);
  }

  function handleNavigateHome() {
    history.push('/');
  }

  function handlePaginate(reverse) {
    const { edges } = data.stories;
    const lastArticleCursor = edges[edges.length - 1].cursor;
    if (reverse) {
      previousCursorHistory.pop();
      setPreviousCursorHistory(previousCursorHistory);
      updateQuery({
        cursor: previousCursorHistory[previousCursorHistory.length - 1],
        date: filterByDate,
      });
    } else {
      setPreviousCursorHistory([...previousCursorHistory, lastArticleCursor]);
      updateQuery({ cursor: lastArticleCursor, date: filterByDate });
    }
  }

  function clearAllFilters() {
    setPreviousCursorHistory([]);
    setFilterByDate(null);
    updateQuery({ searchTerm: filterByHeadline, date: null });
  }

  if (error) {
    dispatchError(error);
  }

  const classes = useStyles();

  return (
    <div className={classes.root} maxWidth={false}>

        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <Typography>  
              Obsessed Admin
            </Typography>  
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          anchor="left"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerContainer}>
            <Toolbar />
            <List>
              <ListItem>
                <TextField
                  className={classes.searchField}
                  name="FilterByHeadline"
                  label="Search"
                  placeholder="Headline or Author"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterByHeadline}
                  onChange={e => {
                    updateQuery({ searchTerm: e.target.value });
                  }}
                />
              </ListItem>
              <ListItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.dateField}
                    margin="normal"
                    id="FilterUpdatedDate"
                    label="Filter by Date"
                    format="MM/dd/yyyy"
                    value={filterByDate}
                    onChange={date => updateQuery({ searchTerm: '', date })}
                    KeyboardButtonProps={{
                      'aria-label': 'search date',
                    }}
                  />

                  {(filterByHeadline || filterByDate) && (
                    <IconButton onClick={clearAllFilters}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </MuiPickersUtilsProvider>
              </ListItem>
            </List>
          </div>

        </Drawer>

          <Container className={classes.resultsContainer}>
            <Toolbar />
            <Toolbar />

            <Typography variant="h5">
              Results
            </Typography>

            {data &&
              data.stories &&
              data.stories.edges.map(storyEdge => (
                <StoryResultCard story={storyEdge} />
            ))}

            <Paper>
              <Button
                className={classes.paging}
                disabled={!!filterByHeadline || !previousCursorHistory.length}
                variant="contained"
                onClick={() => handlePaginate(true)}
                label="Prev"
              />
              <Button
                className={classes.paging}
                disabled={!!filterByHeadline}
                variant="contained"
                onClick={() => handlePaginate(false)}
                label="Next"
              />
            </Paper>
          </Container>


      <Snackbar
        key
        open={error}
        message={error && error.message}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
    </div>
  );
}
