mutation DeleteStoryObsessedTag($articleId: ID!, $obsessedTagId: ID!) {
  story: deleteStoryObsessedTag(articleId: $articleId, obsessedTagId: $obsessedTagId) {
    obsessed_tags {
      id
      name
      type
      slug
    }
  }
}
