import isEqual from 'lodash/isEqual';

const AUTHOR_ERRORS = [
  {
    message: 'Duplicate Authors Cannot Be saved!',
    errorIdentifiers: {
      code: '23505', // Unique violation
      column: null,
      constraint: 'author_slug_key',
      table: 'author',
    },
  },
  {
    message: 'Author names must not be left blank!',
    errorIdentifiers: {
      code: '23502', // Not Null violation
      column: 'slug',
      table: 'author',
      constraint: null,
    },
  },
];

export default function AuthorErrorParser(error) {
  const gqlErrors = error.graphQLErrors[0];
  const {
    extensions: {
      exception: { code, column = null, table, constraint = null },
    },
  } = gqlErrors;

  const formattedError = {
    code,
    column,
    table,
    constraint,
  };

  const matchedError = AUTHOR_ERRORS.find(authorError => {
    return isEqual(formattedError, authorError.errorIdentifiers) && authorError;
  });

  if (matchedError) return matchedError.message;
  return 'Failed To Save!';
}
