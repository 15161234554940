import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FileCopy from '@material-ui/icons/FileCopy';
import indigo from '@material-ui/core/colors/indigo';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LABEL, PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import { Context as ValidationContext } from '../../../../../context/ValidationContext';
import SeoHeadlineQueryGQL from './graphql/seoHeadline.gql';
import CheckForDuplicateSeoHeadlineGQL from './graphql/checkForDuplicateSeoHeadline.gql';
import SeoHeadlineMutationGQL from './graphql/seoHeadlineMutation.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';

const seoHeadlineQuery = gql`
  ${SeoHeadlineQueryGQL}
`;

const checkForDuplicateSeoHeadlineQuery = gql`
  ${CheckForDuplicateSeoHeadlineGQL}
`;

const seoHeadlineMutation = gql`
  ${SeoHeadlineMutationGQL}
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    fileCopy: {
      padding: '4px',
    },
    group: {
      'align-items': 'center',
      cursor: 'pointer',
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
    slug: {
      color: indigo[500],
      'line-height': '21px',
    },
  })
);

SeoHeadlineSection.propTypes = {
  onCopy: PropTypes.func.isRequired,
};

function SeoHeadlineSection({ onCopy }) {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [seoHeadline, setSeoHeadline] = useState('');
  const [savedSlug, setSavedSlug] = useState(null);
  const [slugPreview, setSlugPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { errors, getErrorByFieldName } = useContext(ValidationContext);

  const [loadSeoHeadline] = useLazyQuery(seoHeadlineQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      if (!data.story) return;
      const {
        story: { seoHeadline: seoHeadlineData, slug: slugData },
      } = data;

      if (seoHeadlineData) setSeoHeadline(seoHeadlineData);
      if (slugData) {
        setSavedSlug(slugData);
        setSlugPreview(slugData);
      }
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [loadDuplicateSlugCheck] = useLazyQuery(
    checkForDuplicateSeoHeadlineQuery,
    {
      onCompleted: (data) => {
        if (errorMessage) setErrorMessage('');

        if (!data.matchSeoHeadline) return;
        const {
          matchSeoHeadline: { isDuplicate, slug },
        } = data;

        setSlugPreview(slug);

        if (isDuplicate) {
          setErrorMessage(
            `Error: This slug already exists in our database and each slug must be unique. You can update the slug by editing the SEO Headline field.`
          );
        } else if (errorMessage) {
          setErrorMessage('');
        }
      },
      onError: (err) => {
        setErrorMessage(getGraphqlErrorMessage(err));
      },
    }
  );

  const [updateSeoHeadline] = useMutation(seoHeadlineMutation, {
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (getErrorByFieldName('slug')) {
      setErrorMessage(getErrorByFieldName('slug').message);
    } else if (getErrorByFieldName('seo_headline')) {
      setErrorMessage(getErrorByFieldName('seo_headline').message);
    }
  }, [errors]);

  useEffect(() => {
    if (storyContext.id) {
      loadSeoHeadline({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) => {
    const value = e && e.target && e.target.value;
    setSeoHeadline(value);
    if (!savedSlug) {
      loadDuplicateSlugCheck({
        variables: {
          seoHeadline: value,
        },
      });
    }
    updateSeoHeadline({
      variables: {
        id: storyContext.id,
        story: {
          seo_headline: value,
        },
      },
    });
  };

  let helperMessage = `${seoHeadline.length}/110`;
  let hasError = false;

  if (errorMessage) {
    hasError = true;
    helperMessage = `${seoHeadline.length}/110 ${errorMessage}`;
  }

  return (
    <Grid item xs={12} md={8}>
      <TextField
        label={LABEL.SEO_HEADLINE}
        placeholder={PLACEHOLDER.SEO_HEADLINE}
        fullWidth
        onChange={onChange}
        value={seoHeadline}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={onChange}
        error={hasError}
        helperText={helperMessage}
        onCopy={onCopy}
      />
      <div className={classes.group}>
        <p className={classes.slug}>{slugPreview}</p>
        <CopyToClipboard text={savedSlug} onCopy={onCopy}>
          <FileCopy className={classes.fileCopy} color='primary' />
        </CopyToClipboard>
      </div>
    </Grid>
  );
}

export default SeoHeadlineSection;
