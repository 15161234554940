mutation UpdateListWithScheduleWindowMutation(
  $storyIds: [Int]!
  $startTime: DateTime
  $endTime: DateTime
) {
  updateListWithScheduleWindow(
    storyIds: $storyIds
    name: MANUALSCOUTED
    startTime: $startTime
    endTime: $endTime
  ) {
    name
  }
}
