import { isPlatformSupported, patchAll } from '@elastic/apm-rum-core';
var alreadyBootstrap = false;
var enabled = false;
export default function bootstrap() {
  if (alreadyBootstrap) {
    return enabled;
  }

  alreadyBootstrap = true;

  if (isPlatformSupported()) {
    patchAll();
    enabled = true;
  } else if (typeof window !== 'undefined') {
    console.log('[Elastic APM] platform is not supported!');
  }

  return enabled;
}