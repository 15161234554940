export const SUCCESS_MESSAGE = 'publishing-toolkit/messages/SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'publishing-toolkit/messages/ERROR_MESSAGE';
export const DISMISS_MESSAGE = 'publishing-toolkit/messages/DISMISS_MESSAGE';
export const DISMISS_FORM_FIELD_ERROR =
  'publishing-toolkit/messages/DISMISS_FORM_FIELD_ERROR';
export const DISMISS_ALL_ERRORS =
  'publishing-toolkit/messages/DISMISS_ALL_ERRORS';
export const UPDATE_ERRORS = 'publishing-toolkit/messages/UPDATE_ERRORS';

export const SAVED_MESSAGE_DATA = {
  message: 'Successfully saved.',
};

export const ERROR_MESSAGE_DATA = {
  message: 'Save unsuccessful. Please check for errors.',
};

export function updateErrorsAction(errors) {
  return {
    type: UPDATE_ERRORS,
    errors,
  };
}

export function successMessageAction(customMessage) {
  return {
    type: SUCCESS_MESSAGE,
    data: { message: customMessage || SAVED_MESSAGE_DATA.message },
  };
}

export function errorMessageAction(data, customMessage) {
  global.apm.captureError(
    new Error(
      `PTK error: ${customMessage ||
        data.message ||
        (data.errors && data.errors[0].message)}`,
      data,
    ),
  );

  const errorData = {
    ...data,
    ...ERROR_MESSAGE_DATA,
  };
  if (customMessage || data.customMessage) {
    errorData.message = customMessage || data.customMessage;
  }
  return {
    type: ERROR_MESSAGE,
    data: errorData,
  };
}

export function dismissMessageAction() {
  return {
    type: DISMISS_MESSAGE,
    data: { message: '' },
  };
}

export function dismissFormFieldErrorsAction(id) {
  return {
    type: DISMISS_FORM_FIELD_ERROR,
    data: id,
  };
}

export function dismissAllErrorsAction() {
  return {
    type: DISMISS_ALL_ERRORS,
  };
}
