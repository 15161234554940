import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { StoryContext } from '../../../context/StoryContext';
import ObsessedSection from './ObsessedSection';

const useStyles = makeStyles(theme =>
  createStyles({
    gridItem: {
      margin: 'auto',
    },
    metadataGrid: {
      paddingTop: '24px',
    },
    appBar: theme.mixins.toolbar,

    bottomSpacing: {
      marginBottom: '150px',
    },
  }),
);

export default function Metadata() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  return (
    <Container className={classes.bottomSpacing}>
      <Grid className={classes.metadataGrid}>
        <Grid item className={classes.gridItem} xs={12} sm={6} md={8}>
          <div className={classes.appBar} />
          <ObsessedSection />
        </Grid>
      </Grid>
    </Container>
  );
}
