import React, { useEffect, useReducer } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import getImageUrl from '../../helpers/images';
import { siteLink } from '../../helpers/site';
import { colors, fonts } from '../../styles/global/variables';

const formatDate = date => {
  if (!date) return null;

  return moment(date)
    .add(1, 'days')
    .format('YYYY MMM DD');
};

import './StoryResultCard.module.scss'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const thumbnailWidth = 151;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'flex',
      width: '100%',
      margin: '12px 0',
    },
    header: {
      marginTop: 0,
    },
    details: {
      display: 'flex',
      flexDirection: 'column', 
      flex: '1 1 auto',
    },
    content: {
      flex: '0 1 auto',
    },
    slugLink: {
      fontFamily: fonts.drukTextWeb,
      letterSpacing: '-0.59px',
      fontSize: '20px',
      textDecoration: 'none',
      color: colors.black,
    },
    result: {
      margin: '1em',
      display: 'flex',
    },
    resultDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    thumbnail: {
      flex: '0 0 151px',
      minWidth: '151px',
      backgroundColor: '#ddd',
    },

    tags: { },

    tag: {
      margin: theme.spacing(0.5),
      fontFamily: fonts.drukTextWeb,
      display: 'inline-block',
      fontSize: '11px',
      borderRadius: '12px',
      boxShadow: '1px 1px 1px black',
    },

    tagType: {
      fontFamily: fonts.drukTextWeb,
      backgroundColor: colors.obsessed.pink,
      borderBottomLeftRadius: '12px',
      borderTopLeftRadius: '12px',
      color: colors.white,
      display: 'inline-block',
      padding: '0px 4px 0 6px',
    },

    tagName: {
      fontFamily: fonts.drukTextWeb,
      backgroundColor: colors.obsessed.yellow,
      borderBottomRightRadius: '12px',
      borderTopRightRadius: '12px',
      color: 'black',
      display: 'inline-block',
      padding: '0px 6px 0px 4px',
      whiteSpace: 'nowrap',
    },

  }),
);

function ObsessedTag ({ tag: { id, name, slug, type } }) {

  const classes = useStyles();

  return (
    <span className={classes.tag}>
      <span className={classes.tagType}>
        {type}
      </span>
      <span className={classes.tagName}>
        {name}
      </span>
    </span>
  )
}

export default function ({ story }) {

  const classes = useStyles();
  const tags = story?.node?.obsessedTags || [];

  console.log('story', story.node?.obsessedTags)

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>

          <Typography variant="subtitle2">
            {formatDate(story.node.updatedAt)}
          </Typography>
          <Typography variant="h4" className={classes.header}>
            <Link
              to={siteLink(story.node)}
              className={classes.slugLink}
            >
              {story.node.longHeadline}
            </Link>
          </Typography>

          <div className={classes.tags}>
            <Typography variant="subtitle2">
              {tags.map(tag => <ObsessedTag tag={tag} />)}
            </Typography>
          </div>

        </CardContent>
      </div>
      <CardMedia 
        className={classes.thumbnail}
        image={getImageUrl(story.node.mainImage, { width: thumbnailWidth })}
      />
    </Card>
  );
}
