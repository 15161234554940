import { useCallback } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useArticleSelector(
  setScreamer,
  setAutocompleteValue,
  setAutocompleteOptions,
) {
  return useCallback(
    (event, value) => {
      setAutocompleteOptions([value]);
      setAutocompleteValue(value);
      setScreamer({
        articleId: value.node.id,
        articleHeadline: value.node.longHeadline,
        articleSlug: value.node.slug,
      });
    },
    [setScreamer, setAutocompleteValue],
  );
}
