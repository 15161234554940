import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const KEY = {
  ESCAPE: 27,
  ENTER: 13,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
};

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onEnter: PropTypes.func,
    onLeft: PropTypes.func,
    onRight: PropTypes.func,
    onUp: PropTypes.func,
    onDown: PropTypes.func,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    global.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    global.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(event) {
    if (event.defaultPrevented) {
      return;
    }

    switch (event.keyCode) {
      case KEY.ENTER:
        if (this.props.onEnter) {
          this.props.onEnter();
        }
        break;
      case KEY.ESCAPE:
        if (this.props.onClose) {
          this.props.onClose();
          event.preventDefault();
        }
        break;
      case KEY.LEFT:
        if (this.props.onLeft) {
          this.props.onLeft();
        }
        break;
      case KEY.RIGHT:
        if (this.props.onRight) {
          this.props.onRight();
        }
        break;
      case KEY.UP:
        if (this.props.onUp) {
          this.props.onUp();
        }
        break;
      case KEY.DOWN:
        if (this.props.onDown) {
          this.props.onDown();
        }
        break;
      default:
        break;
    }
  }

  render() {
    let modal = null;

    if (this.props.open) {
      modal = (
        <div >
          <Dialog
            maxWidth={'sm'}
            fullWidth
            open={this.props.open}
            onClose={this.props.onClose}
          >
            {this.props.title && (
              <DialogTitle id="modal-dialog-title">
                {this.props.title}
              </DialogTitle>
            )}
            <div >{this.props.children}</div>
          </Dialog>
        </div>
      );
    }

    return modal;
  }
}
