import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formLabel: { marginBottom: 12 },
  selectedArticle: {
    marginTop: 12,
    padding: 24,
  },
}));

Step3.propTypes = {
  disabled: PropTypes.bool.isRequired,
  existingArticle: PropTypes.shape({
    canonicalSlug: PropTypes.string,
    extensions: PropTypes.object,
    longHeadline: PropTypes.string,
    message: PropTypes.string,
  }),
  isFetching: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

Step3.displayName = 'ArticleSlugRedirectStep3';

export default function Step3({
  disabled,
  existingArticle,
  isFetching,
  onBlur,
  onChange,
  value,
}) {
  const classes = useStyles();

  return (
    <div className={classes.fieldContainer}>
      <h2 className={classes.formLabel}>Step 3:</h2>
      <TextField
        disabled={disabled}
        fullWidth
        label={
          !isFetching ? 'Enter the new slug' : 'Checking for existing slug...'
        }
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        variant="outlined"
      />
      {existingArticle && (
        <Paper className={classes.selectedArticle} variant="outlined">
          <h3 style={{ color: 'red' }}>ERROR: This slug is already taken</h3>
          {existingArticle.message && (
            <p>{`Message: ${existingArticle.message}`}</p>
          )}
          <p>
            Current URL:{' '}
            <a
              href={`${process.env.WWW_DOMAIN}/${
                existingArticle.canonicalSlug ||
                existingArticle.extensions.exception.data.current_slug
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {`${process.env.WWW_DOMAIN}/${
                existingArticle.canonicalSlug ||
                existingArticle.extensions.exception.data.current_slug
              }`}
            </a>
          </p>
        </Paper>
      )}
    </div>
  );
}
