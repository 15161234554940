import React, { useReducer, useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'mobiledoc-kit';
import { useLazyQuery, useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import mobiledocQuery from '../queries/mobiledoc.gql';
import mobiledocMutation from '../mutations/mobiledoc.gql';
import BlockQuoteCard, {
  BLOCK_QUOTE_CARD_NAME,
} from '../features/article/body/components/BlockQuoteCard/BlockQuoteCard';
import ConversionCard, {
  CONVERSION_CARD_NAME,
} from '../features/article/body/components/ConversionCard/ConversionCard';
import ImageCard, {
  IMAGE_CARD_NAME,
} from '../features/article/body/components/ImageCard/ImageCard';
import PullQuoteCard, {
  PULL_QUOTE_CARD_NAME,
} from '../features/article/body/components/PullQuoteCard/PullQuoteCard';
import FancyLinksCard, {
  FANCY_LINKS_CARD_NAME,
} from '../features/article/body/components/FancyLinksCard/FancyLinksCard';
import SectionBreakCard, {
  SECTION_BREAK_CARD_NAME,
} from '../features/article/body/components/SectionBreakCard/SectionBreakCard';
import VideoCard, {
  VIDEO_CARD_NAME,
} from '../features/article/body/components/VideoCard/VideoCard';
import PdfCard, {
  PDF_CARD_NAME,
} from '../features/article/body/components/PdfCard/PdfCard';
import SocialCard, {
  SOCIAL_CARD_NAME,
} from '../features/article/body/components/SocialCard/SocialCard';
import TrackingCard, {
  TRACKING_CARD_NAME,
} from '../features/article/body/components/TrackingCard/TrackingCard';
import SoundcloudCard, {
  SOUNDCLOUD_CARD_NAME,
} from '../features/article/body/components/SoundcloudCard/SoundcloudCard';
import ActionButtonCard, {
  ACTION_BUTTON_CARD_NAME,
} from '../features/article/body/components/ActionButtonCard/ActionButtonCard';
import NewsletterCard, {
  NEWSLETTER_CARD_NAME,
} from '../features/article/body/components/NewsletterCard/NewsletterCard';
import HimalayaCard, {
  HIMALAYA_CARD_NAME,
} from '../features/article/body/components/HimalayaCard/HimalayaCard';
import SnippetCard, {
  SNIPPET_CARD_NAME,
} from '../features/article/body/components/SnippetCard/SnippetCard';
import LegacyOembedCard, {
  LEGACY_OEMBED_CARD_NAME,
} from '../features/article/body/components/LegacyOembedCard/LegacyOembedCard';
import { EMPTY_MOBILEDOC } from '../helpers/mobiledoc-helpers';
import renderComponentAsCard from '../helpers/cardRenderer';
import renderComponentAsAtom from '../helpers/atomRenderer';
import UnknownCard from '../features/article/body/components/UnknownCard/UnknownCard';
import UnknownAtom from '../features/article/body/components/UnknownAtom/UnknownAtom';
import { StoryContext } from './StoryContext';

export const MOBILEDOC_VERSION = '0.3.0';

export const Context = React.createContext({});

const UPDATE_EDITOR = 'UPDATE_EDITOR';
const UPDATE_IMAGE_PICKER_OPEN = 'UPDATE_IMAGE_PICKER_OPEN';
const UPDATE_QUOTE_PICKER_OPEN = 'UPDATE_QUOTE_PICKER_OPEN';
const UPDATE_QUOTE_CARD_ID = 'UPDATE_QUOTE_CARD_ID';
const UPDATE_SAVE_CARD_FUNCTION = 'UPDATE_SAVE_CARD_FUNCTION';
const UPDATE_CONVERSION_PICKER_OPEN = 'UPDATE_CONVERSION_PICKER_OPEN';
const UPDATE_CONVERSION_CARD_ID = 'UPDATE_CONVERSION_CARD_ID';
const UPDATE_FANCY_LINKS_PICKER_OPEN = 'UPDATE_FANCY_LINKS_PICKER_OPEN';
const UPDATE_FANCY_LINKS_ID = 'UPDATE_FANCY_LINKS_ID';
const UPDATE_SECTION_BREAK_PICKER_OPEN = 'UPDATE_SECTION_BREAK_PICKER_OPEN';
const UPDATE_SECTION_BREAK_ID = 'UPDATE_SECTION_BREAK_ID';
const UPDATE_EMBED_PICKER_OPEN = 'UPDATE_EMBED_PICKER_OPEN';
const UPDATE_VIDEO_CARD_ID = 'UPDATE_VIDEO_CARD_ID';
const UPDATE_PDF_CARD_ID = 'UPDATE_PDF_CARD_ID';
const RESET_EMBED_CARD_IDS = 'RESET_EMBED_CARD_IDS';
const UPDATE_SOCIAL_CARD_ID = 'UPDATE_SOCIAL_CARD_ID';
const UPDATE_TRACKING_PIXEL_ID = 'UPDATE_TRACKING_PIXEL_ID';
const UPDATE_SOUND_CLOUD_ID = 'UPDATE_SOUND_CLOUD_ID';
const UPDATE_ACTION_BUTTON_ID = 'UPDATE_ACTION_BUTTON_ID';
const UPDATE_NEWSLETTER_CARD_ID = 'UPDATE_NEWSLETTER_CARD_ID';
const UPDATE_HIMALAYA_ID = 'UPDATE_HIMALAYA_ID';
const UPDATE_SNIPPET_ID = 'UPDATE_SNIPPET_ID';

const DEFAULT_STATE = {
  editor: null,
  imagePickerOpen: false,
  quotePickerOpen: false,
  conversionPickerOpen: false,
  conversionCardId: null,
  fancyLinksPickerOpen: false,
  sectionBreakPickerOpen: false,
  embedPickerOpen: false,
  quoteCardId: null,
  fancyLinksId: null,
  sectionBreakId: null,
  videoCardId: null,
  pdfCardId: null,
  socialCardId: null,
  soundcloudId: null,
  trackingPixelId: null,
  actionButtonId: null,
  newsletterCardId: null,
  himalayaId: null,
  snippetId: null,
  saveCardFunction: null,
};

const useLocalContext = data => {
  const refContext = useRef(data);
  refContext.current = data;
  return refContext;
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_EDITOR:
      console.log('updating editor');
      return {
        ...state,
        editor: action.data,
      };
    case UPDATE_IMAGE_PICKER_OPEN:
      return {
        ...state,
        imagePickerOpen: action.data,
      };
    case UPDATE_QUOTE_PICKER_OPEN:
      return {
        ...state,
        quotePickerOpen: action.data,
      };
    case UPDATE_CONVERSION_PICKER_OPEN:
      return {
        ...state,
        conversionPickerOpen: action.data,
      };
    case UPDATE_CONVERSION_CARD_ID:
      return {
        ...state,
        conversionCardId: action.data,
      };
    case UPDATE_QUOTE_CARD_ID:
      return {
        ...state,
        quoteCardId: action.data,
      };
    case UPDATE_SAVE_CARD_FUNCTION:
      return {
        ...state,
        saveCardFunction: action.data,
      };
    case UPDATE_FANCY_LINKS_PICKER_OPEN:
      return {
        ...state,
        fancyLinksPickerOpen: action.data,
      };
    case UPDATE_FANCY_LINKS_ID:
      return {
        ...state,
        fancyLinksId: action.data,
      };
    case UPDATE_SECTION_BREAK_PICKER_OPEN:
      return {
        ...state,
        sectionBreakPickerOpen: action.data,
      };
    case UPDATE_SECTION_BREAK_ID:
      return {
        ...state,
        sectionBreakId: action.data,
      };
    case UPDATE_EMBED_PICKER_OPEN:
      return {
        ...state,
        embedPickerOpen: action.data,
      };
    case UPDATE_VIDEO_CARD_ID:
      return {
        ...state,
        videoCardId: action.data,
      };
    case UPDATE_PDF_CARD_ID:
      return {
        ...state,
        pdfCardId: action.data,
      };
    case UPDATE_SOCIAL_CARD_ID:
      return {
        ...state,
        socialCardId: action.data,
      };
    case UPDATE_TRACKING_PIXEL_ID:
      return {
        ...state,
        trackingPixelId: action.data,
      };
    case UPDATE_SOUND_CLOUD_ID:
      return {
        ...state,
        soundcloudId: action.data,
      };
    case UPDATE_ACTION_BUTTON_ID:
      return {
        ...state,
        actionButtonId: action.data,
      };
    case UPDATE_NEWSLETTER_CARD_ID:
      return {
        ...state,
        newsletterCardId: action.data,
      };
    case UPDATE_HIMALAYA_ID:
      return {
        ...state,
        himalayaId: action.data,
      };
    case UPDATE_SNIPPET_ID:
      return {
        ...state,
        snippetId: action.data,
      };
    case RESET_EMBED_CARD_IDS:
      return {
        ...state,
        quoteCardId: null,
        videoCardId: null,
        pdfCardId: null,
        socialCardId: null,
        trackingPixelId: null,
        soundcloudId: null,
        actionButtonId: null,
        newsletterCardId: null,
        himalayaId: null,
      };
    default:
      throw new Error('Error updating editor context');
  }
}

const query = gql`
  ${mobiledocQuery}
`;

const mutation = gql`
  ${mobiledocMutation}
`;

function EditorContext({ children }) {
  const wrapper = useRef(null);
  const { id } = useContext(StoryContext);
  const [state, updateState] = useReducer(reducer, DEFAULT_STATE);
  const [updateMobiledoc] = useMutation(mutation);
  const refContext = useLocalContext({ id });

  const [loadMobiledoc, { loading: mobiledocLoading }] = useLazyQuery(query, {
    onError: err => {
      console.log('EditorContext:loadMobiledoc:err', err);
    },
    onCompleted: data => {
      const {
        storyData: { mobiledoc },
      } = data;

      if (state.editor) state.editor.destroy();
      createEditor(mobiledoc, { placeholder: 'Write your masterpiece' });
    },
  });

  const createEditor = useCallback((doc = EMPTY_MOBILEDOC, options) => {
    const editorOptions = {
      cards: [
        renderComponentAsCard(ImageCard, IMAGE_CARD_NAME),
        renderComponentAsCard(PullQuoteCard, PULL_QUOTE_CARD_NAME, {
          updateQuotePickerOpen,
          updateQuoteId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(BlockQuoteCard, BLOCK_QUOTE_CARD_NAME, {
          updateQuotePickerOpen,
          updateQuoteId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(ConversionCard, CONVERSION_CARD_NAME, {
          updateConversionPickerOpen,
          updateConversionId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(FancyLinksCard, FANCY_LINKS_CARD_NAME, {
          updateFancyLinksPickerOpen,
          updateFancyLinksId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(SectionBreakCard, SECTION_BREAK_CARD_NAME, {
          updateSectionBreakPickerOpen,
          updateSectionBreakId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(VideoCard, VIDEO_CARD_NAME, {
          updateEmbedPickerOpen,
          updateVideoCardId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(PdfCard, PDF_CARD_NAME, {
          updateEmbedPickerOpen,
          updatePdfCardId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(SocialCard, SOCIAL_CARD_NAME, {
          updateEmbedPickerOpen,
          updateSocialCardId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(TrackingCard, TRACKING_CARD_NAME, {
          updateEmbedPickerOpen,
          updateTrackingPixelId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(SoundcloudCard, SOUNDCLOUD_CARD_NAME, {
          updateEmbedPickerOpen,
          updateSoundcloudId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(ActionButtonCard, ACTION_BUTTON_CARD_NAME, {
          updateEmbedPickerOpen,
          updateActionButtonId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(NewsletterCard, NEWSLETTER_CARD_NAME, {
          updateEmbedPickerOpen,
          updateNewsletterCardId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(HimalayaCard, HIMALAYA_CARD_NAME, {
          updateEmbedPickerOpen,
          updateHimalayaId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(SnippetCard, SNIPPET_CARD_NAME, {
          updateEmbedPickerOpen,
          updateSnippetId,
          updateSaveCardFunction,
        }),
        renderComponentAsCard(LegacyOembedCard, LEGACY_OEMBED_CARD_NAME),
      ],
      unknownCardHandler: renderComponentAsCard(UnknownCard).render,
      unknownAtomHandler: renderComponentAsAtom(UnknownAtom).render,
    };

    const mobiledocEditor = new Editor({
      ...editorOptions,
      mobiledoc: doc,
      ...options,
    });

    if (wrapper.current) {
      mobiledocEditor.render(wrapper.current);
    }

    mobiledocEditor.postDidChange(() => {
      const serializedDoc = mobiledocEditor.serialize(MOBILEDOC_VERSION);

      updateMobiledoc({
        variables: {
          id: refContext.current.id,
          story: {
            mobiledoc: serializedDoc,
          },
        },
        context: {
          // Requests get debounced together if they share the same debounceKey.
          debounceKey: '1',
        },
      });
    });

    updateState({
      type: UPDATE_EDITOR,
      data: mobiledocEditor,
    });
  });

  const updateImagePickerOpen = isImagePickerOpen => {
    updateState({
      type: UPDATE_IMAGE_PICKER_OPEN,
      data: isImagePickerOpen,
    });
  };

  const updateQuotePickerOpen = isQuotePickerOpen => {
    updateState({
      type: UPDATE_QUOTE_PICKER_OPEN,
      data: isQuotePickerOpen,
    });
  };

  const updateConversionPickerOpen = isConversionPickerOpen => {
    updateState({
      type: UPDATE_CONVERSION_PICKER_OPEN,
      data: isConversionPickerOpen,
    });
  };

  const updateQuoteId = quoteCardId => {
    updateState({
      type: UPDATE_QUOTE_CARD_ID,
      data: quoteCardId,
    });
  };

  const updateConversionId = conversionCardId => {
    updateState({
      type: UPDATE_CONVERSION_CARD_ID,
      data: conversionCardId,
    });
  };

  const updateSaveCardFunction = saveCardFunction => {
    updateState({
      type: UPDATE_SAVE_CARD_FUNCTION,
      data: saveCardFunction,
    });
  };

  const updateFancyLinksPickerOpen = isFancyLinksPickerOpen => {
    updateState({
      type: UPDATE_FANCY_LINKS_PICKER_OPEN,
      data: isFancyLinksPickerOpen,
    });
  };

  const updateFancyLinksId = fancyLinksId => {
    updateState({
      type: UPDATE_FANCY_LINKS_ID,
      data: fancyLinksId,
    });
  };

  const updateSectionBreakPickerOpen = isSectionBreakPickerOpen => {
    updateState({
      type: UPDATE_SECTION_BREAK_PICKER_OPEN,
      data: isSectionBreakPickerOpen,
    });
  };

  const updateSectionBreakId = sectionBreakId => {
    updateState({
      type: UPDATE_SECTION_BREAK_ID,
      data: sectionBreakId,
    });
  };

  const updateEmbedPickerOpen = isEmbedPickerOpen => {
    updateState({
      type: UPDATE_EMBED_PICKER_OPEN,
      data: isEmbedPickerOpen,
    });
  };

  const updateVideoCardId = videoCardId => {
    updateState({
      type: UPDATE_VIDEO_CARD_ID,
      data: videoCardId,
    });
  };

  const updatePdfCardId = pdfCardId => {
    updateState({
      type: UPDATE_PDF_CARD_ID,
      data: pdfCardId,
    });
  };

  const resetEmbedCardIds = () => {
    updateState({
      type: RESET_EMBED_CARD_IDS,
    });
  };

  const updateSocialCardId = socialCardId => {
    updateState({
      type: UPDATE_SOCIAL_CARD_ID,
      data: socialCardId,
    });
  };

  const updateTrackingPixelId = trackingPixelId => {
    updateState({
      type: UPDATE_TRACKING_PIXEL_ID,
      data: trackingPixelId,
    });
  };

  const updateSoundcloudId = soundcloudId => {
    updateState({
      type: UPDATE_SOUND_CLOUD_ID,
      data: soundcloudId,
    });
  };

  const updateActionButtonId = actionButtonId => {
    updateState({
      type: UPDATE_ACTION_BUTTON_ID,
      data: actionButtonId,
    });
  };

  const updateNewsletterCardId = newsletterCardId => {
    updateState({
      type: UPDATE_NEWSLETTER_CARD_ID,
      data: newsletterCardId,
    });
  };

  const updateHimalayaId = himalayaId => {
    updateState({
      type: UPDATE_HIMALAYA_ID,
      data: himalayaId,
    });
  };

  const updateSnippetId = snippetId => {
    updateState({
      type: UPDATE_SNIPPET_ID,
      data: snippetId,
    });
  };

  const value = {
    ...state,
    wrapper,
    loadMobiledoc,
    createEditor,
    updateImagePickerOpen,
    updateQuotePickerOpen,
    updateQuoteId,
    updateSaveCardFunction,
    updateConversionPickerOpen,
    updateConversionId,
    updateFancyLinksPickerOpen,
    updateFancyLinksId,
    updateSectionBreakPickerOpen,
    updateSectionBreakId,
    updateEmbedPickerOpen,
    updateVideoCardId,
    updatePdfCardId,
    resetEmbedCardIds,
    updateSocialCardId,
    updateTrackingPixelId,
    updateSoundcloudId,
    updateActionButtonId,
    updateNewsletterCardId,
    updateHimalayaId,
    updateSnippetId,
    mobiledocLoading,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

EditorContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default EditorContext;
