query conversionQuery($id: ID!) {
  conversionCardById(id: $id) {
    id
    expanded
    image {
      id
      mobiledocCaption: mobiledoc_caption
      crops
      publicId: public_id
      version
    }
    productCategory: product_category {
      categoryName: category_name
      id
    }
    promoItemName: promo_item_name
    mobiledocSummary: mobiledoc_summary
    specialInfo: special_info
    partnerButtons: partner_buttons {
      id
      freeReturns: free_returns
      freeShipping: free_shipping
      isPrimary: is_primary
      partnerName: partner_name
      partnerId: partner_id
      partnerUrl: partner_url
      callToAction: call_to_action
      price
    }
  }
}
