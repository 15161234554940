import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Snackbar from '@material-ui/core/Snackbar';
import { gql } from '@apollo/client'
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { StoryContext } from '../../../context/StoryContext';
import ConfirmDialog from '../../../common/ConfirmDialog/ConfirmDialog';
import publishStoryGQL from './mutations/publishStory.gql';
import bumpStoryGQL from './mutations/bumpStory.gql';
import scheduleStoryGQL from './mutations/scheduleStory.gql';
import { errorMessageAction } from '../../../redux/actions/messages';
import { Context as ValidationContext } from '../../../context/ValidationContext';
import {
  Context as EditorContext,
  MOBILEDOC_VERSION,
} from '../../../context/EditorContext';

PublishModal.propTypes = {
  confirmDialogOpen: PropTypes.bool,
  onPublishLoading: PropTypes.func,
  onClosePublishModal: PropTypes.func,
  manualBump: PropTypes.bool,
};

export default function PublishModal(props) {
  const history = useHistory();
  const urlPath = history.location.pathname;
  const isBodyRoute = urlPath.match(`/body`);

  const storyContext = useContext(StoryContext);
  const {
    publicationDate,
    unlockDate,
    unlockPending,
    hasPublishedVersion,
    slug: storyContextSlug,
  } = storyContext;
  const { editor } = useContext(EditorContext);
  const { updateValidationContext } = useContext(ValidationContext);
  const [message, setMessage] = useState(null);

  const publishStoryMutation = gql`
    ${publishStoryGQL}
  `;

  const bumpStoryMutation = gql`
    ${bumpStoryGQL}
  `;

  const scheduleStoryMutation = gql`
    ${scheduleStoryGQL}
  `;
  const {
    confirmDialogOpen,
    onPublishLoading,
    onClosePublishModal,
    manualBump,
  } = props;

  const redirectToUrl = slug => {
    let newUrlPath;
    if (urlPath.match(`/new/`)) {
      newUrlPath = urlPath.replace('/new/', `/${slug}/`);
    } else if (urlPath.match(`/${storyContextSlug}/`)) {
      newUrlPath = urlPath.replace(`/${storyContextSlug}/`, `/${slug}/`);
    } else {
      newUrlPath = `/${slug}/main-info`;
    }
    history.push(newUrlPath);
  };

  const shouldSchedule = moment(publicationDate).isAfter(moment());
  const formattedPublishDate = moment(storyContext.publicationDate).format(
    'MM/DD/YY h:mma',
  );

  const dateToUnlock = moment(unlockDate).format('MM/DD/YY h:mma');
  let confirmDialogTitle;
  let confirmDialogDescription;
  if (hasPublishedVersion && manualBump) {
    confirmDialogTitle =
      'This article will be bumped immediately to the top of the vertical and all feeds.';
    confirmDialogDescription =
      "If this is correct, and you're ready to and bump this post, select yes.";
  } else if (hasPublishedVersion) {
    confirmDialogTitle = 'This article has already been published.';
    confirmDialogDescription =
      'Republishing it will update the live article with any saved changes made. Do you want to republish this article?';
  } else if (!hasPublishedVersion && unlockPending && shouldSchedule) {
    confirmDialogTitle = `This article is scheduled to publish on ${formattedPublishDate} and unlock on ${dateToUnlock}`;
    confirmDialogDescription =
      "If this is correct, and you're ready to schedule and bump this post, select yes.";
  } else if (!hasPublishedVersion && shouldSchedule) {
    confirmDialogTitle = 'This article has not been published yet.';
    confirmDialogDescription = `Are you sure you want to schedule this article to publish at ${formattedPublishDate}?`;
  } else if (!hasPublishedVersion && unlockPending) {
    confirmDialogTitle = `This article is scheduled to unlock on ${dateToUnlock}`;
    confirmDialogDescription =
      "If this is correct, and you're ready to publish this post, select yes.";
  } else {
    confirmDialogTitle = 'This article has not been published yet.';
    confirmDialogDescription = 'Would you like to publish this article?';
  }

  function handleOnClose() {
    onClosePublishModal();
  }

  function handleOnConfirm() {
    const { id } = storyContext;
    let serializedDoc = null;
    if (isBodyRoute) {
      serializedDoc = editor && editor.serialize(MOBILEDOC_VERSION);
    }

    if (manualBump) {
      bumpStory({ variables: { id, story: { mobiledoc: serializedDoc } } });
    } else if (shouldSchedule && !hasPublishedVersion) {
      scheduleStory({ variables: { id, story: { mobiledoc: serializedDoc } } });
    } else {
      publishStory({ variables: { id, story: { mobiledoc: serializedDoc } } });
    }

    onClosePublishModal();
  }

  const [bumpStory, { loading: bumpLoading }] = useMutation(bumpStoryMutation, {
    onError: err => {
      errorMessageAction(err);
      setMessage(err);
    },
    onCompleted: data => {
      if (data && data.bumpStory && data.bumpStory.story) {
        const { id, updatedAt, seoHeadline, slug } = data.bumpStory.story;
        storyContext.updateId(id);
        setMessage('Story Published And Bumped!');
        storyContext.updateHasPublishedVersion(true);
        storyContext.updateUpdatedAt(updatedAt);
        storyContext.updateSeoHeadline(seoHeadline);
        redirectToUrl(slug);
      } else if (data.bumpStory.Validation.Errors.length) {
        updateValidationContext(data.bumpStory.Validation.Errors);
        setMessage(data.bumpStory.Validation.Errors[0].message);
      } else {
        setMessage('Failed to bump article an unknown reason');
      }
    },
  });

  const [scheduleStory, { loading: scheduleLoading }] = useMutation(
    scheduleStoryMutation,
    {
      onError: err => {
        errorMessageAction(err);
        setMessage(err);
      },
      onCompleted: data => {
        if (data && data.scheduleStory && data.scheduleStory.story) {
          const { id, updatedAt, seoHeadline, slug } = data.scheduleStory.story;
          storyContext.updateId(id);
          storyContext.updateUpdatedAt(updatedAt);
          storyContext.updateScheduled(true);
          setMessage('Story Scheduled for Publish!');
          storyContext.updateSeoHeadline(seoHeadline);
          redirectToUrl(slug);
        } else if (data.scheduleStory.Validation.Errors.length) {
          updateValidationContext(data.scheduleStory.Validation.Errors);
          setMessage(data.scheduleStory.Validation.Errors[0].message);
        } else {
          setMessage('Failed to schedule for an unknown reason');
        }
      },
    },
  );

  const [publishStory, { loading: publishLoading }] = useMutation(
    publishStoryMutation,
    {
      onError: err => {
        errorMessageAction(err);
        setMessage(err);
      },
      onCompleted: data => {
        if (data && data.publishStory && data.publishStory.story) {
          const { id, slug, updatedAt, seoHeadline } = data.publishStory.story;
          storyContext.updateId(id);
          storyContext.updateSlug(slug);
          storyContext.updateUpdatedAt(updatedAt);
          setMessage('Story Published!');
          storyContext.updateHasPublishedVersion(true);
          storyContext.updateSeoHeadline(seoHeadline);
          redirectToUrl(slug);
        } else if (data.publishStory.Validation.Errors.length) {
          updateValidationContext(data.publishStory.Validation.Errors);
          setMessage(data.publishStory.Validation.Errors[0].message);
        } else {
          setMessage('Failed to publish for an unknown reason');
        }
      },
    },
  );

  useEffect(() => {
    onPublishLoading(publishLoading || scheduleLoading || bumpLoading);
  }, [publishLoading]);

  return (
    <>
      <ConfirmDialog
        body={confirmDialogDescription}
        inFlight={publishLoading}
        onClose={handleOnClose}
        onConfirm={handleOnConfirm}
        open={confirmDialogOpen}
        title={confirmDialogTitle}
      />
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
