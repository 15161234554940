import React, { useContext, useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { LABEL } from '../../../labels';
import { StoryContext } from '../../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../../helpers/graphqlError';
import unlockPendingAndDateQueryGQL from '../graphql/unlockPendingAndDateQuery.gql';
import unlockPendingMutationGQL from '../graphql/unlockPendingMutation.gql';
import unlockDateMutationGQL from '../graphql/unlockDateMutation.gql';
import { Context as ValidationContext } from '../../../../../../context/ValidationContext';

const unlockPendingAndDateQuery = gql`
  ${unlockPendingAndDateQueryGQL}
`;

const unlockPendingMutation = gql`
  ${unlockPendingMutationGQL}
`;

const unlockDateMutation = gql`
  ${unlockDateMutationGQL}
`;

function UnlockPendingSection() {
  const storyContext = useContext(StoryContext);
  const { unlockPending, unlockDate } = storyContext;
  const [unlockPendingError, setUnlockPendingError] = useState('');
  const [unlockDateError, setUnlockDateError] = useState('');
  const { errors, getErrorByFieldName } = useContext(ValidationContext);
  const [loadUnlockPendingAndDate] = useLazyQuery(unlockPendingAndDateQuery, {
    onCompleted: (data) => {
      if (unlockPendingError) setUnlockPendingError('');
      storyContext.updateUnlockPending(data.story.metadata.unlockPending);
      storyContext.updateUnlockDate(data.story.unlockDate);
    },
    onError: (err) => {
      const errMsg = getGraphqlErrorMessage(err);
      setUnlockPendingError(errMsg);
      setUnlockDateError(errMsg);
    },
  });

  const [updateUnlockPending] = useMutation(unlockPendingMutation, {
    onCompleted: (data) => {
      if (unlockPendingError) setUnlockPendingError('');
      storyContext.updateUnlockPending(data.metadata.unlockPending);
    },
    onError: (err) => {
      setUnlockPendingError(getGraphqlErrorMessage(err));
    },
  });

  const [updateUnlockDate] = useMutation(unlockDateMutation, {
    onCompleted: (data) => {
      if (unlockDateError) setUnlockDateError('');
      storyContext.updateUnlockDate(data.story.unlockDate);
    },
    onError: (err) => {
      setUnlockDateError(getGraphqlErrorMessage(err));
    },
  });
  useEffect(() => {
    if (getErrorByFieldName('unlock_pending')) {
      setUnlockPendingError(getErrorByFieldName('unlock_pending').message);
    }
  }, [errors]);
  useEffect(() => {
    if (storyContext.id) {
      loadUnlockPendingAndDate({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = () => {
    updateUnlockPending({
      variables: {
        articleId: storyContext.id,
        metadata: {
          unlock_pending: !unlockPending,
        },
      },
    });
  };

  const onUnlockDateChange = (date) => {
    const dateStr = date && moment(date).isValid() && date.toISOString();
    if (!dateStr) return;

    updateUnlockDate({
      variables: {
        id: storyContext.id,
        story: {
          unlock_date: dateStr,
        },
      },
    });
    setUnlockPendingError('');
  };

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={unlockPending} onChange={onChange} />}
        label={<Typography>{LABEL.UNLOCK_PENDING_CHECKBOX}</Typography>}
      />
      {unlockPendingError && (
        <FormHelperText error>{unlockPendingError}</FormHelperText>
      )}
      {unlockPending && (
        <Grid container justify='flex-start'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin='normal'
              id='unlock-date-picker-dialog'
              label='Date'
              format='MM/dd/yyyy'
              value={unlockDate}
              onChange={onUnlockDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={new Date()}
              errortext={unlockDateError}
            />
            <KeyboardTimePicker
              margin='normal'
              id='unlock-time-picker'
              label='Time (ET)'
              value={unlockDate}
              onChange={onUnlockDateChange}
              fullwidth='true'
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              errortext={unlockDateError}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
    </>
  );
}

export default UnlockPendingSection;
