// react + redux
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/order
import { errorMessageAction } from '../../redux/actions/messages';

// util
import moment from 'moment';
import _ from 'lodash';

import { makeStyles, createStyles } from '@material-ui/core/styles';

// material-ui
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

// Data Fetching & Queries
import { gql, useQuery, useMutation } from '@apollo/client';
import tagById from './queries/tagById.gql';

import tagUpdate from './queries/tagUpdate.gql';
import tagCreate from './queries/tagCreate.gql';
import tagsByType from './queries/tagsByType.gql';

// 1st party
import Navbar from '../../common/Navbar/Navbar';
import TagInfo from './TagInfo';
import TitleSearchInput from './TitleSearchInput';
import { colors } from '../../styles/global/variables';

ObsessedTagAdmin.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

ObsessedTagAdmin.defaultProps = {
  onEnter: () => {},
  permissions: [],
};

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: colors.greenDown,
      color: colors.white,
      fontWeight: 'bold',
      margin: '5px',
    },
    container: {
      textAlign: 'center',
    },
    input: {
      margin: '100px 0',
    },
    root: {
      backgroundColor: colors.lightGrey,
      height: '100vh',
      overflow: 'scroll',
      paddingTop: 200,
    },
    section: {
      marginBottom: '70px',
    },
    slugLink: {
      color: colors.black,
      fontFamily: 'arial',
      fontSize: '13px',
      textDecoration: 'none',
    },
    tagHeader: {
      color: 'black',
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '1em 0',
      padding: '6px',
    },
    tagList: {
      flexGrow: 1,
    },
  })
);

const fetchTagQuery = gql`
  ${tagById}
`;
const saveTagQuery = gql`
  ${tagUpdate}
`;
const createTagQuery = gql`
  ${tagCreate}
`;
const tagsByTypeQuery = gql`
  ${tagsByType}
`;

const formatDate = (date) => {
  if (!date) return null;

  return moment(date).add(1, 'days').format('YYYY.MM.DD hh:mm:ss');
};

export default function ObsessedTagAdmin({ onEnter, permissions }) {
  const history = useHistory();
  const match = useRouteMatch();

  const { tagId } = match.params;
  const isNew = tagId === 'new';
  const isSearch = tagId === 'search';

  const defaultFormValues = {
    children: [],
    id: tagId,
    name: '',
    slug: '',
    type: 'title',
  };

  const [tag, setTag] = useState({});
  const [message, setMessage] = useState();

  const [titles, setTitles] = useState([]);
  const [genres, setGenres] = useState([]);
  const [sources, setSources] = useState([]);

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [fieldErrors, setFieldErrors] = useState([]);

  const [lastSave, setLastSave] = useState();

  useQuery(tagsByTypeQuery, {
    onCompleted: ({ tags }) => {
      if (tags) {
        setTitles(tags);
      }
    },
    variables: {
      limit: 1000,
      type: 'title',
    },
  });

  useQuery(tagsByTypeQuery, {
    onCompleted: ({ tags }) => {
      if (tags) {
        setGenres(tags);
      }
    },
    variables: {
      limit: 1000,
      type: 'genre',
    },
  });

  useQuery(tagsByTypeQuery, {
    onCompleted: ({ tags }) => {
      if (tags) {
        setSources(tags);
      }
    },
    variables: {
      limit: 1000,
      type: 'source',
    },
  });

  const { error } = useQuery(fetchTagQuery, {
    onCompleted: ({ tag: selectedTag }) => {
      if (selectedTag) {
        setTag(selectedTag);
        setFormValues(selectedTag);
      }
    },
    skip: isNew || isSearch,
    variables: { tagId },
  });

  const [saveTag] = useMutation(saveTagQuery, {
    onCompleted: ({ tag: selectedTag }) => {
      if (selectedTag) {
        setTag(selectedTag);
        setLastSave();
        setMessage(`Title Saved at   ${formatDate(new Date())}`);
        setFieldErrors([]);
      }
    },
    onError: (err) => {
      const errors = err?.graphQLErrors[0]?.extensions?.fieldErrors;
      setFieldErrors(errors || []);
    },
  });

  const [createTag] = useMutation(createTagQuery, {
    onCompleted: ({ tag: selectedTag }) => {
      if (selectedTag) {
        setTag(selectedTag);
        history.push(`/obsessed-tag/${selectedTag.id}`);
        setFieldErrors([]);
      }
    },
    onError: (err) => {
      const errors = err?.graphQLErrors[0]?.extensions?.fieldErrors;
      setFieldErrors(errors || []);
    },
  });

  const resetFormForCreate = () => {
    setTag({ tagId: 'new' });
    setFormValues({ ...defaultFormValues, id: 'new' });
    setFieldErrors([]);
  };

  const updateFormValues = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const onTitleSelect = (selectedTag) => {
    setTag(selectedTag);
    history.push(`/obsessed-tag/${selectedTag.id}`);
  };

  const onNew = () => {
    resetFormForCreate();
    history.push(`/obsessed-tag/new`);
  };

  const handleSave = () => {
    if (isNew) {
      createTag({
        variables: {
          ..._.omit(formValues, 'children'),
          childIds: formValues.children.map((t) => t.id),
        },
      });
    } else {
      saveTag({
        variables: {
          ..._.omit(formValues, 'children'),
          childIds: formValues.children.map((t) => t.id),
          tagId,
        },
      });
    }
  };

  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  function dispatchError(err) {
    errorMessageAction(err, err.message);
  }

  if (error) {
    dispatchError(error);
  }

  if (isNew && tag.id) resetFormForCreate();

  const classes = useStyles();
  const showTagInfo = isNew || !_.isEmpty(_.omit(tag, 'id'));
  const showSearch = isSearch;

  return (
    <div className={classes.root}>
      <nav>
        <Navbar
          title='Obsessed Title Editor'
          handleNavigateHome={() => history.push('/')}
        />
      </nav>
      <Container maxWidth='md' className={classes.container}>
        {showSearch && (
          <div className={classes.input}>
            <TitleSearchInput
              classes={{
                input: classes.inputInput,
                root: classes.inputRoot,
              }}
              titles={titles}
              tagId={tagId}
              onChange={onTitleSelect}
              onNew={onNew}
            />
          </div>
        )}

        {!_.isEmpty(tag) && (
          <Typography className={classes.tagHeader} variant='h5'>
            {tag.name}
          </Typography>
        )}

        {showTagInfo && (
          <TagInfo
            sources={sources}
            genres={genres}
            formValues={formValues}
            onChange={updateFormValues}
            onSave={handleSave}
            lastSave={lastSave}
            fieldErrors={fieldErrors}
          />
        )}

        <Snackbar
          key
          open={message}
          message={message}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        />
      </Container>
    </div>
  );
}
