import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { gql, useMutation } from '@apollo/client';
import capitalize from 'lodash/capitalize';
import { HomepageContext, UPDATE_SECTION_TITLE } from './HomepageContext';
import ProductCallout from './ProductCallout';
import SectionCard from './SectionCard';
import updateHomepageSectionTitle from '../../queries/homepage/updateHomepageSectionTitle.gql';

const UPDATE_SECTION_TITLE_MUTATION = gql`
  ${updateHomepageSectionTitle}
`;

Section.propTypes = {
  isTitleEditable: PropTypes.bool,
  section: PropTypes.oneOf([
    'section1',
    'section2',
    'section3',
    'section4',
    'section5',
    'section6',
    'section7',
    'section8',
  ]).isRequired,
  includeProductCallout: PropTypes.bool,
  styles: PropTypes.object,
};

export default function Section({
  isTitleEditable,
  section,
  includeProductCallout,
  styles,
}) {
  const { state, dispatch } = useContext(HomepageContext);
  const [updateSectionTitle] = useMutation(UPDATE_SECTION_TITLE_MUTATION, {
    variables: {
      sectionId: state[section].id,
      title: state[section].title.toLowerCase(),
    },
  });
  const handleInputChange = useCallback(
    event => {
      dispatch({
        type: UPDATE_SECTION_TITLE,
        payload: {
          section,
          title: event.target.value,
        },
      });
    },
    [dispatch, section],
  );
  const handleInputBlur = useCallback(() => updateSectionTitle(), []);

  const { stories } = state[section];

  const selectedStoryCards = useMemo(() => {
    return stories.map((story, i) => (
      <SectionCard
        key={story.id}
        section={section}
        slot={i + 1}
        styles={styles}
      />
    ));
  }, [section, stories]);

  return (
    <section className={`${styles.additionalClass} ${styles.section}`}>
      {isTitleEditable ? (
        <TextField
          className={styles.title}
          inputProps={{ onBlur: handleInputBlur }}
          label="Section Title"
          onChange={handleInputChange}
          value={state[section].title
            .split(' ')
            .map(capitalize)
            .join(' ')}
        />
      ) : (
        <Typography align="center" className={styles.title}>
          {state[section].title
            .split(' ')
            .map(capitalize)
            .join(' ')}
        </Typography>
      )}
      {selectedStoryCards}
      {includeProductCallout && (
        <ProductCallout
          className={styles['product-callout']}
          section={section}
        />
      )}
    </section>
  );
}
