import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client'
import getScreamer from '../../../queries/screamer/getScreamer.gql';

const getScreamerQuery = gql`
  ${getScreamer}
`;

// eslint-disable-next-line import/prefer-default-export
export function useScreamerState() {
  const { data, loading } = useQuery(getScreamerQuery);
  const [saveAllowed, setSaveAllowed] = useState(false);
  const [loadingScreamerState, setLoadingScreamerState] = useState(loading);
  const [screamer, setScreamer] = useState({
    active: false,
    articleId: '',
    articleHeadline: '',
    articlePubDate: '',
    articleSlug: '',
    bannerHeadline: '',
    id: '',
  });
  const setState = useCallback(
    newState => {
      setScreamer(prevState => {
        if (prevState.articleId || newState.articleId) {
          setSaveAllowed(true);
        }
        return { ...prevState, ...newState };
      });
    },
    [screamer, setScreamer, setSaveAllowed],
  );

  useEffect(() => {
    if (loading === false && data && data.screamer) {
      setScreamer(data.screamer);
    }

    setLoadingScreamerState(loading);
  }, [loading, data]);

  return [
    { loadingScreamerState, saveAllowed, screamer },
    setState,
    setSaveAllowed,
  ];
}
