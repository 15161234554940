import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import readItAtQueryGQL from './graphql/readItAtQuery.gql';
import readItAtMutationGQL from './graphql/readItAtMutation.gql';

const readItAtQuery = gql`
  ${readItAtQueryGQL}
`;

const readItAtMutation = gql`
  ${readItAtMutationGQL}
`;

function ReadItAtSection() {
  const storyContext = useContext(StoryContext);
  const [readItAtName, setReadItAtName] = useState('');
  const [readItAtUrl, setReadItAtUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loadReadItAt] = useLazyQuery(readItAtQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const readItAtData = data && data.story && data.story.readItAt;
      if (readItAtData && readItAtData.name) setReadItAtName(readItAtData.name);
      if (readItAtData && readItAtData.url) setReadItAtUrl(readItAtData.url);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateReadItAt] = useMutation(readItAtMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadReadItAt({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e, field) => {
    if (field === 'NAME') {
      setReadItAtName(e.target.value);
      updateReadItAt({
        variables: {
          id: storyContext.id,
          story: {
            read_it_at: {
              name: e.target.value,
              url: readItAtUrl,
            },
          },
        },
      });
    } else if (field === 'URL') {
      setReadItAtUrl(e.target.value);
      updateReadItAt({
        variables: {
          id: storyContext.id,
          story: {
            read_it_at: {
              name: readItAtName,
              url: e.target.value,
            },
          },
        },
      });
    }
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <TextField
          label='Read It At Source'
          placeholder={PLACEHOLDER.READ_IT_AT_NAME}
          value={readItAtName}
          fullWidth
          onChange={(e) => onChange(e, 'NAME')}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          label='Read It At URL'
          placeholder={PLACEHOLDER.READ_IT_AT_URL}
          value={readItAtUrl}
          fullWidth
          onChange={(e) => onChange(e, 'URL')}
          InputLabelProps={{
            shrink: true,
          }}
          onBlur={onChange}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </Grid>
    </>
  );
}

export default ReadItAtSection;
