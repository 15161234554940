query mediaQuery($versionId: String!) {
  story: storyById(versionId: $versionId) {
    media {
      image {
        id
        isMainImage: main_image
      }
      video {
        id
      }
    }
    images {
      id
      isMainImage: main_image
    }
  }
}
