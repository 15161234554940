import PropTypes from 'prop-types';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CodeIcon from '@material-ui/icons/Code';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinkIcon from '@material-ui/icons/Link';
import { colors } from '../../../../../styles/global/variables';

export const VIDEO_CARD_NAME = 'pt-video-card';

const useStyles = makeStyles({
  card: {
    maxWidth: '500px',
    width: '90%',
    margin: '0 auto',
  },
  icon: {
    height: '100px',
    width: '100px',
    opacity: '0.8',
    margin: '0 auto 10px',
    display: 'block',
  },
  cardHeader: {
    'padding-bottom': 0,
    'text-transform': 'capitalize',
  },
  link: {
    color: colors.blue,
  },
});

export default function VideoCard(props) {
  const classes = useStyles();
  const {
    payload: { id, content, videoChecked },
    actions: {
      updateEmbedPickerOpen,
      updateVideoCardId,
      updateSaveCardFunction,
    },
    edit,
    save,
  } = props;

  function onEdit() {
    updateVideoCardId(id);
    updateEmbedPickerOpen(true);
    updateSaveCardFunction(save);

    edit();
  }

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton aria-label="embed">
            <EditIcon onClick={onEdit} />
          </IconButton>
        }
        title={`Video - ${videoChecked}`}
      />
      <CardContent>
        <CodeIcon className={classes.icon} />
      </CardContent>

      <List dense>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <a
                className={classes.link}
                href={content}
                target="_blank"
                rel="noopener noreferrer"
              >
                {content}
              </a>
            }
          />
        </ListItem>
      </List>
    </Card>
  );
}

VideoCard.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.string,
    videoChecked: PropTypes.string,
    class: PropTypes.string,
  }),
  actions: PropTypes.shape({
    updateEmbedPickerOpen: PropTypes.func,
    updateVideoCardId: PropTypes.func,
    updateSaveCardFunction: PropTypes.func,
  }),
  save: PropTypes.func,
  edit: PropTypes.func,
};
