mutation uploadAdstxt(
  $base64Data: String!
) {
  uploadAdstxt(base64Data: $base64Data) {
    id
    base64_data
    updated_at
    created_at
  }
}
