import React, { useEffect, useState, useReducer } from 'react';
import gql from 'graphql-tag';

import { colors, fonts } from '../../styles/global/variables';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/client'

import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Button from '../../common/ButtonWrapper/ButtonWrapper';

import tagByIdQuery from './queries/tagById.gql';

const tagById = gql`
  ${tagByIdQuery}
`;

const useStyles = makeStyles(theme =>
  createStyles({
    fieldLabel: {
      fontWeight: 'bold',
      margin: '0 1em',
      padding: '6px 0',
    },
    tagChip: {
      margin: '6px 6px',
    },
    tagTitle: {
      fontWeight: 'bold',
      color: '#666',
      margin: '0 1em',
      padding: '1em 0',
      lineHeight: 1,
    },
    root: {
      marginBottom: '70px',
    },
    chipContainer: {
      flexWrap: 'wrap',
    },
    textField: {
      width: '100%',
      marginTop: '16px',
    },
    saveButton: {
      fontWeight: 'bold',
      margin: '5px',
      backgroundColor: 'red',
    },
    typeOption: {
      padding: '16px 32px',
    },
    actions: {
      justifyContent: 'center',
    },
  })
);

export default function TitleCard ({
  tag,
  onDeleteTag,
}) {
  const classes = useStyles();

  const [genres, setGenres] = useState([]);
  const [sources, setSources] = useState([]);

  const { genresData } = useQuery(tagById, {
    variables: { tagId: tag.id },
    onCompleted: ({ tag }) => {
      setGenres(tag.children.filter(t => t.type === 'genre'));
      setSources(tag.children.filter(t => t.type === 'source'));
    },
  })

  return (
    <Paper className={classes.root}>
      <List dense className={classes.chipContainer}>

        <ListItem>
          <Typography className={classes.fieldLabel} variant="h6">
            Title:
            <span className={classes.tagTitle}>{tag.name}</span>
          </Typography>
        </ListItem>

        <Divider />

        <ListItem>
          <Typography className={classes.fieldLabel} variant="h6">
            Genre:
          </Typography>
          {
            genres.map(tag => 
              <Chip 
                className={classes.tagChip}
                label={tag.name} 
                key={tag.id}/>
            )
          }

        </ListItem>

        <Divider />

        <ListItem>
          <Typography className={classes.fieldLabel} variant="h6">
            Where To Watch:
          </Typography>
          {
            sources.map(tag => 
              <Chip 
                className={classes.tagChip}
                label={tag.name} 
                key={tag.id}/>
            )
          }

        </ListItem>

        <Divider />

        <ListItem className={classes.actions}>
          <Button
            className={classes.saveButton}
            onClick={() => onDeleteTag(tag.id)}
            label='Remove Title'
          />
        </ListItem>
      </List>
    </Paper>
  );
}
