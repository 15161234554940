export const OPEN_CONFIRM_DIALOG = 'publishing-toolkit/ui/OPEN_CONFIRM_DIALOG';
export const CLOSE_CONFIRM_DIALOG =
  'publishing-toolkit/ui/CLOSE_CONFIRM_DIALOG';

export function openConfirmDialogAction() {
  return {
    type: OPEN_CONFIRM_DIALOG,
  };
}

export function closeConfirmDialogAction() {
  return {
    type: CLOSE_CONFIRM_DIALOG,
  };
}
