import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ToolbarGroup from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import blue from '@material-ui/core/colors/blue';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client'
import { useQuery, useMutation } from '@apollo/client';
import DragAndDrop from '../../utils/dnd-context';
import Navbar from '../../common/Navbar/Navbar';
import CheatPane from './CheatPane';
import CheatList from '../../queries/list.gql';
import ListMutation from '../../mutations/list.gql';
import { errorMessageAction } from '../../redux/actions/messages';
import Button from '../../common/ButtonWrapper/ButtonWrapper';

CheatSheet.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme =>
  createStyles({
    cheatLabel: {
      color: blue[500],
      margin: '0 8px',
      width: '30px',
      textAlign: 'left',
    },
    cheatContainer: {
      margin: '15px 0',
    },
    cheatInputBox: {
      flex: 1,
    },
    cheatDate: {
      fontSize: '13px',
    },
    toolbar: theme.mixins.toolbar,
    cheat_list: {
      maxWidth: '800px',
    },
    reorderContainer: {
      marginTop: '15px',
      marginBottom: '15px',
    },
    shadowPadding: {
      padding: '8px',
      '& .shadowBox': {
        boxShadow: '#02141F 6px 6px 0',
        border: '1px solid #ddd',
      },
    },
    reorderBtn: {
      maxWidth: '800px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
);

const cheatSheetQuery = gql`
  ${CheatList}
`;
const cheatMutation = gql`
  ${ListMutation}
`;

export default function CheatSheet({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  const [reorderMode, setReordermode] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedCheats, setSelectedCheats] = useState([]);

  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  useQuery(cheatSheetQuery, {
    onError: err => {
      errorMessageAction(err);
      setMessage('Error fetching existing cheat');
    },
    onCompleted: data => {
      const { stories } = data.list;
      setSelectedCheats(stories);
    },
    variables: {
      name: 'CHEATSHEET',
    },
  });

  const [updateCheatSheet] = useMutation(cheatMutation, {
    onError: err => {
      errorMessageAction(err);
      setMessage('Failed To Save');
    },
    onCompleted: data => {
      setMessage('Successfully Saved');
      const { stories } = data.updateList;
      setSelectedCheats(stories);
    },
  });

  function handleNavigateHome() {
    history.push('/');
  }

  function swapPanes() {
    setReordermode(!reorderMode);
  }

  function moveCheat(dragIndex, hoverIndex) {
    const newCheats = [...selectedCheats];

    const temp = newCheats[dragIndex];
    newCheats[dragIndex] = newCheats[hoverIndex];
    newCheats[hoverIndex] = temp;

    setSelectedCheats(newCheats);
  }

  function onUpdateCheatList(cheat, index) {
    const newCheats = [...selectedCheats];
    newCheats[index] = cheat;

    setSelectedCheats(newCheats);
  }

  function onSavePublish() {
    const cheatIds = selectedCheats.map(cheat => {
      return Number(cheat.id);
    });
    updateCheatSheet({
      variables: {
        name: 'CHEATSHEET',
        storyIds: cheatIds,
      },
    });
  }

  return (
    <>
      <DragAndDrop>
        <div>
          <nav>
            <Navbar title="Cheat Sheet" handleNavigateHome={handleNavigateHome}>
              <ToolbarGroup>
                <Button
                  label="Save & Publish"
                  primary="true"
                  onClick={onSavePublish}
                />
              </ToolbarGroup>
            </Navbar>
          </nav>
          <div className={classes.toolbar} />
          <CheatPane
            reorderMode={reorderMode}
            cheats={selectedCheats}
            moveCheat={moveCheat}
            classes={classes}
            swapPanes={swapPanes}
            onUpdateCheatList={onUpdateCheatList}
          />
        </div>
      </DragAndDrop>

      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
