import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { StoryContext } from '../../../../../context/StoryContext';
import { MediaContext } from '../../../../../context/MediaContext/MediaContext';
import STORY_TYPE from '../../../../../utils/story-type';
import ARTICLE_SUBTYPE from '../../../../../utils/article-subtype';
import DragAndDrop from '../../../../../utils/dnd-context';
import AccentColor from '../AccentColor/AccentColor';
import storyForMediaQueryGQL from './graphql/storyForMediaQuery.gql';
import StoryHero from '../StoryHero/StoryHero';
import ImageModal from '../ImageModal/ImageModal';
import VideoModal from '../VideoModal/VideoModal';
import UploadBox from '../UploadBox/UploadBox';
import ImageBox from '../ImageBox/ImageBox';
import { createCloudinaryUploadWidgetCallback } from '../../../../../helpers/imageHelper';
import { useDeepEffect } from '../../../../../helpers/customHooks';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import { logError } from '../../../../../helpers/logError';
import { handleError } from '../../../../../helpers/global-error-handler';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: theme.mixins.toolbar,
    container: {
      margin: 'auto',
      maxWidth: '540px',
    },
    imageUploadBox: {
      marginTop: '9px',
    },
  })
);

const storyForMediaQuery = gql`
  ${storyForMediaQueryGQL}
`;

function Images() {
  const [story, setStory] = useState({});
  const [images, setImages] = useState([]); // Non-hero images
  const [errorMessage, setErrorMessage] = useState('');

  const { id, type, slug } = useContext(StoryContext);
  const {
    imageIds,
    videoModalOpen,
    imageModalOpen,
    addImage,
    updateCurrentImageId,
    updateImageModalOpen,
  } = useContext(MediaContext);
  const classes = useStyles();

  const { loadMobiledoc } = useContext(EditorContext);

  const [loadStoryForMedia] = useLazyQuery(storyForMediaQuery, {
    onCompleted: (data) => {
      const storyData = data && data.story;
      const imagesData = data && data.images;

      if (storyData) setStory(storyData);
      if (imagesData) setImages(imagesData);
    },
    onError: (error) => {
      // Log error to error monitoring service
      logError(error, {
        afterCapture: handleError,
      });

      // Provide error message to user
      setErrorMessage(
        `Oops! Please report this message to the #web-support channel in Slack: GraphqlError - loadStoryForMedia ${error}`
      );
    },
    variables: {
      versionId: id,
    },
  });

  useDeepEffect(() => {
    if (id) {
      loadStoryForMedia({
        variables: {
          imageIds,
          versionId: id,
        },
      });
    }
  }, [id, imageIds]);

  useEffect(() => {
    if (id) {
      loadMobiledoc({ variables: { versionId: id } });
    }
  }, []);

  const onEditImage = (image) => {
    updateCurrentImageId(image.id);
    updateImageModalOpen(true);
  };

  const renderImages = () => {
    let imagesContent;

    if (slug) {
      imagesContent = (
        <Grid container spacing={3}>
          {renderImageBoxes()}
          <Grid item xs={6} className={classes.imageUploadBox}>
            <UploadBox
              onImageUploaded={createCloudinaryUploadWidgetCallback({
                onError: ({ message }) => setErrorMessage(message),
                onSuccess: ({ validatedImage }) => {
                  addImage({
                    variables: {
                      articleId: id,
                      image: validatedImage,
                      isMainImage: false,
                    },
                  });
                  setErrorMessage('');
                },
              })}
            />
          </Grid>
        </Grid>
      );
    } else {
      imagesContent = (
        <div>
          <h2>
            Please add a headline and save this article <br />
            before uploading images
          </h2>
        </div>
      );
    }

    return imagesContent;
  };

  const renderImageBoxes = () =>
    images.map((image, index) => (
      <Grid item xs={6} className={classes.imageMargin} key={image.id}>
        <ImageBox
          index={type === STORY_TYPE.GALLERY ? index : null}
          image={image}
          showImageDisplayType={
            story.metadata.subtype === ARTICLE_SUBTYPE.FEATURE
          }
          onEdit={onEditImage}
          useDragAndDrop={type === STORY_TYPE.GALLERY}
        />
      </Grid>
    ));
  return (
    <DragAndDrop>
      <div className={classes.appBar} />
      <div className={classes.container}>
        <div>
          {slug && (
            <h2>{type === STORY_TYPE.GALLERY ? 'Gallery' : 'Story'} Hero</h2>
          )}
          {story.metadata &&
            story.metadata.subtype === ARTICLE_SUBTYPE.FEATURE && (
              <AccentColor />
            )}
        </div>

        {slug && <StoryHero />}

        {slug && (
          <h2>{type === STORY_TYPE.GALLERY ? 'Slides' : 'Story Images'}</h2>
        )}

        {errorMessage?.length ? (
          <Alert severity='error'>{errorMessage}</Alert>
        ) : null}

        <div>{renderImages()}</div>

        {imageModalOpen && (
          <ImageModal
            inVideoModal={videoModalOpen}
            showImageDisplayType={
              story.metadata &&
              story.metadata.subtype === ARTICLE_SUBTYPE.FEATURE
            }
          />
        )}
        {videoModalOpen && <VideoModal />}
      </div>
    </DragAndDrop>
  );
}

export default Images;
