import React from 'react';
import PropTypes from 'prop-types';
import { NavigationIndex } from '../../common/NavigationIndex/NavigationIndex';

BizToolsIndex.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

export function BizToolsIndex({ onEnter, permissions }) {
  return (
    <NavigationIndex
      onEnter={onEnter}
      permissions={permissions}
      title="The Daily Beast Business Tools"
      listItems={[
        { route: '/biztools/refresh', displayText: 'Ad Ops Refresh Tools' },
        {
          route: '/biztools/brandspartners',
          displayText: 'Brands and Partners',
        },
        { route: '/biztools/adstxt', displayText: 'Upload ads.txt File' },
        {
          route: '/biztools/categorization-data',
          displayText: 'Categorization Data',
        },
        {
          route: '/biztools/scouted',
          displayText: 'Scouted',
        },
      ]}
    />
  );
}
