import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import createPageDraftMutation from '../../queries/homepage/createPageDraft.gql';

const HomepageContext = createContext();

export const SET_HOMEPAGE = 'SET_HOMEPAGE';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_PRODUCT_CALLOUT = 'SET_PRODUCT_CALLOUT';
export const UPDATE_CARD = 'UPDATE_CARD';
export const UPDATE_SECTION_TITLE = 'UPDATE_SECTION_TITLE';
export const UPDATE_SECTION_SUBTITLE = 'UPDATE_SECTION_SUBTITLE';

const initialState = {
  id: '',
  snackbar: {
    message: null,
    type: null,
  },
  section1: {
    id: '',
    imageHeights: ['225', '126', '126', '103', '103', '103', '103'],
    stories: [
      createDefaultStory('1'),
      createDefaultStory('2'),
      createDefaultStory('3'),
      createDefaultStory('4', false),
      createDefaultStory('5', false),
      createDefaultStory('6', false),
      createDefaultStory('7', false),
    ],
    title: '',
    subtitle: '',
  },
  section2: {
    id: '',
    imageHeights: ['225', '225', '225', '225'],
    stories: [
      createDefaultStory('1'),
      createDefaultStory('2'),
      createDefaultStory('3'),
      createDefaultStory('4'),
    ],
    title: 'Obsessional',
    subtitle: '',
  },
  section3: {
    id: '',
    imageHeights: ['225', '126', '126', '126'],
    productCallout: { error: '', lists: [], type: '' },
    stories: [
      createDefaultStory('1', false),
      createDefaultStory('2', false),
      createDefaultStory('3', false),
      createDefaultStory('4', false),
    ],
    title: '',
    subtitle: '',
  },
  section4: {
    id: '',
    imageHeights: ['225', '225', '225', '225', '225'],
    stories: [
      createDefaultStory('1'),
      createDefaultStory('2'),
      createDefaultStory('3'),
      createDefaultStory('4'),
      createDefaultStory('5'),
    ],
    title: '',
    subtitle: '',
  },
  section5: {
    id: '',
    imageHeights: ['126', '126', '126', '126', '126', '126'],
    productCallout: { error: '', lists: [], type: '' },
    stories: [
      createDefaultStory('1', false),
      createDefaultStory('2', false),
      createDefaultStory('3', false),
      createDefaultStory('4', false),
      createDefaultStory('5', false),
      createDefaultStory('6', false),
    ],
    title: '',
    subtitle: '',
  },
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case UPDATE_CARD:
      return updateCard(state, payload);
    case UPDATE_SECTION_TITLE:
      return updateSectionTitle(state, payload);
    case UPDATE_SECTION_SUBTITLE:
      return updateSectionSubtitle(state, payload);
    case SET_SNACKBAR:
      return setSnackbar(state, payload);
    case SET_HOMEPAGE:
      return setHomepage(state, payload);
    case SET_PRODUCT_CALLOUT:
      return setProductCallout(state, payload);
    default:
      return initialState;
  }
};

const CREATE_PAGE_DRAFT_MUTATION = gql`
  ${createPageDraftMutation}
`;

HomepageContextProvider.propTypes = { children: PropTypes.element.isRequired };

function HomepageContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  const [createDraft] = useMutation(CREATE_PAGE_DRAFT_MUTATION, {
    fetchPolicy: 'no-cache',
    variables: { pageName: 'obsessed-landing' },
  });

  useEffect(() => {
    createDraft().then(({ data: { createDraft: payload } }) =>
      dispatch({ type: SET_HOMEPAGE, payload }),
    );
  }, [createDraft]);

  return (
    <HomepageContext.Provider value={value}>
      {children}
    </HomepageContext.Provider>
  );
}

const HomepageContextConsumer = HomepageContext.Consumer;

export { HomepageContext, HomepageContextProvider, HomepageContextConsumer };

function createDefaultStory(id, withImage = true) {
  const story = { error: '', id, longHeadline: '', withImage };

  if (withImage) {
    story.mainImage = {
      url: 'https://via.placeholder.com/429x225?text=Placeholder+Image',
    };
  }

  return story;
}

function updateCard(state, payload) {
  const { section, slot, story } = payload;
  const newState = { ...state };

  newState[section].stories[slot - 1] = {
    ...newState[section].stories[slot - 1],
    ...story,
  };

  return newState;
}

function updateSectionTitle(state, payload) {
  return {
    ...state,
    [payload.section]: { ...state[payload.section], title: payload.title },
  };
}

function updateSectionSubtitle(state, payload) {
  return {
    ...state,
    [payload.section]: {
      ...state[payload.section],
      subtitle: payload.subtitle,
    },
  };
}

function setSnackbar(state, payload) {
  return {
    ...state,
    snackbar: payload,
  };
}

function setHomepage(state, payload) {
  const newState = { ...state, id: payload.id };

  payload.sections.forEach(
    ({ id, position, productCallout, stories, title, subtitle }) => {
      const stateSection = newState[`section${position}`];

      stateSection.title = title;
      stateSection.subtitle = subtitle;
      stateSection.id = id;
      stateSection.productCallout = {
        ...stateSection.productCallout,
        ...productCallout,
      };
      stories.forEach((story, idx) => {
        stateSection.stories[idx] = {
          ...stateSection.stories[idx],
          ...story,
        };
      });
    },
  );

  return newState;
}

function setProductCallout(state, { section, ...productCallout }) {
  return {
    ...state,
    [section]: {
      ...state[section],
      productCallout: {
        ...state[section].productCallout,
        ...productCallout,
      },
    },
  };
}
