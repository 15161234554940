mutation SaveStoryMutation($id: ID!, $story: StoryInput!) {
  saveStory(id: $id, story: $story) {
    story {
      ... on Article {
        type
        id
        slug
        updatedAt: updated_at
        seoHeadline: seo_headline
        scheduled
      }
      ... on Cheat {
        type
        id
        slug
        updatedAt: updated_at
        seoHeadline: seo_headline
        scheduled
      }
      ... on Gallery {
        type
        id
        slug
        updatedAt: updated_at
        seoHeadline: seo_headline
        scheduled
      }
    }

    Validation {
      ... on SaveErrors {
        actionType
        Errors {
          message
          fieldName
        }
      }
    }
  }
}
