query imagesQuery($imageIds: [ID]) {
  images(ids: $imageIds) {
    id
    altText: alt_text
    publicId: public_id
    url
    version
    displayType: display_type
    mobiledocCaption: mobiledoc_caption
    caption
    createdAt: created_at
    updatedAt: updated_at
    credit
    crops
    fileName: file_name
    rights
    title
  }
}
