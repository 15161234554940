import React from 'react';
import PropTypes from 'prop-types';
import { NavigationIndex } from '../../common/NavigationIndex/NavigationIndex';


MainIndex.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array
};

export function MainIndex({ onEnter, permissions }) {
  return (
    <NavigationIndex
      onEnter={onEnter}
      permissions={permissions}
      title="The Daily Beast Publishing Toolkit"
      listItems={[
        { route: '/new', displayText: 'Create New Story'},
        { route: '/cheatsheet', displayText: 'Cheat Sheet'},
        { route: '/homepage', displayText: 'Homepage'},
        { route: '/progressive-scroll', displayText: 'Progressive Scroll'},
        { route: '/all-stories', displayText: 'All Stories'},
        { route: '/authors', displayText: 'Authors'},
        { route: '/screamer', displayText: 'Screamer'},
        { route: '/category', displayText: 'Category Pages'},
        { route: '/', displayText: ''},
        { route: '/obsessed/new', displayText: 'Obsessed New Story'},
        { route: '/obsessed/homepage', displayText: 'Obsessed Homepage'},
        { route: '/obsessed-tag/search', displayText: 'Obsessed Title Editor'},
        { route: '/obsessed-dynamic-navigation-tool', displayText: 'Obsessed Dynamic Navigation'},
        { route: '/obsessed-admin', displayText: 'Obsessed Admin'},
      ]}
    />
  );
}
