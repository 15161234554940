import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import getNavItemsQuery from '../../../queries/navigation/getNavItems.gql';

const GET_NAV_ITEMS_QUERY = gql`
  ${getNavItemsQuery}
`;

export default function useNavState(siteId) {
  const { data, loading } = useQuery(GET_NAV_ITEMS_QUERY, {
    variables: {
      siteId,
    },
  });
  const [navItems, setNavItems] = useState(() => {
    const arr = [];
    for (let i = 1; i <= 11; i += 1) {
      arr[i] = { id: i.toString(), name: '', path: '' };
    }
    return arr;
  });

  useEffect(() => {
    if (loading === false && data && data.navigation) {
      setNavItems(data.navigation);
    }
  }, [loading, data]);

  return [navItems, setNavItems];
}
