import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';

Input.propTypes = {
  index: PropTypes.number,
  charCounter: PropTypes.bool,
  errorText: PropTypes.string,
  focus: PropTypes.bool,
  keyToUpdate: PropTypes.string,
  label: PropTypes.string,
  length: PropTypes.number,
  minLength: PropTypes.number,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onComponentMount: PropTypes.func,
  placeholder: PropTypes.string,
  updateFieldAction: PropTypes.func,
  value: PropTypes.string,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
};

export default function Input(props) {
  const {
    value,
    length,
    minLength,
    onChange,
    onComponentMount,
    focus,
    keyToUpdate,
    updateFieldAction,
    index,
    errorText,
    charCounter,
    classes,
    label,
    placeholder,
    multiline,
    name,
    disabled,
  } = props;

  const fieldValue = value || '';
  const currentLength = fieldValue.length;

  const field = useRef(null);
  const [count, setCount] = useState(currentLength);
  const [tooLong, setTooLong] = useState(currentLength > length);
  const [tooShort, setTooShort] = useState(currentLength < minLength);

  useEffect(() => {
    if (onComponentMount) {
      onComponentMount(props);
    }
    if (field && field.current && focus) {
      global.setTimeout(() => {
        field.current.focus();
      }, 10);
    }

    setCount(fieldValue.length);
    setTooLong(fieldValue.length > length);
    setTooShort(fieldValue.length < minLength);
  }, [value]);

  const handleChange = event => {
    const eventFieldValue = event.target.value;

    updateField(eventFieldValue);
    updateCounter(eventFieldValue);
  };

  const updateField = eventFieldValue => {
    if (onChange) {
      onChange(eventFieldValue);
    } else {
      updateFieldAction(keyToUpdate, eventFieldValue, index);
    }
  };

  const updateCounter = targetFieldValue => {
    const counterLength = targetFieldValue.length;

    setCount(counterLength);
    setTooLong(counterLength > length);
    setTooShort(counterLength < minLength);
  };

  const counterValue = `${count}/${length}`;
  let errorMessage = null;
  if (errorText) {
    errorMessage = errorText;
  } else if (tooLong) {
    errorMessage = `${count}/${length} Warning: You've gone over the limit`;
  } else if (tooShort) {
    errorMessage = `${count}/${length} Warning: value too short`;
  }

  const errExists = !!errorMessage;
  let helperText;
  if (errExists) {
    helperText = errorMessage;
  } else {
    helperText = length ? counterValue : charCounter && count;
  }

  return (
    <TextField
      className={classes}
      error={errExists}
      fullWidth
      helperText={helperText}
      label={label}
      placeholder={placeholder}
      id={keyToUpdate}
      multiline={multiline}
      name={name}
      onChange={handleChange}
      ref={field}
      InputLabelProps={{
        shrink: true,
      }}
      rows={multiline ? 3 : 1}
      value={value || ''}
      disabled={disabled}
    />
  );
}
