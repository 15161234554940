mutation DeleteStoryMutation($id: ID!) {
  deleteStory(id: $id) {
    story {
      ... on Article {
        id
      }
      ... on Cheat {
        id
      }
      ... on Gallery {
        id
      }
    }
  }
}
