mutation UpdateScreamer($id: ID!, $screamer: ScreamerInput) {
  updateScreamer(id: $id, screamer: $screamer) {
    id
    active
    articleHeadline: article_headline
    articleId: article_id
    articlePubDate: article_pub_date
    articleSlug: article_slug
    bannerHeadline: banner_headline
  }
}
