mutation CreateStoryDraftMutation($slug: String, $siteId: Int) {
  createStoryDraft(slug: $slug, siteId: $siteId) {
    id
    type
    publicationDate: publication_date
    shortHeadline: short_headline
    slug
    scheduled
    status
    seoHeadline: seo_headline
    updatedAt: updated_at
    unlockDate: unlock_date
    site {
      id
      name
    }
    metadata {
      id
      unlockPending: unlock_pending
      subtype
    }
    obsessed_tags {
      id
      name
      type
      slug
    }
  }
}
