import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Snackbar,
  FormControl,
  Typography,
  Box,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client';

import AuthorErrors from '../../errors/AuthorErrors';
import {
  openConfirmDialogAction,
  closeConfirmDialogAction,
} from '../../redux/actions/ui';
import Navbar from '../../common/Navbar/Navbar';
import ConfirmDialog from '../../common/ConfirmDialog/ConfirmDialog';
import Input from '../../common/Input/Input';
import SlimMobiledoc from '../../common/SlimMobiledoc/SlimMobiledoc';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import { RIGHTS, ALL_RIGHTS } from '../../helpers/rights';
import getImageUrl from '../../helpers/images';
import authorQuery from '../../queries/author.gql';
import createAuthorMutation from '../../mutations/createAuthor.gql';
import updateAuthorMutation from '../../mutations/updateAuthor.gql';
import { errorMessageAction } from '../../redux/actions/messages';
import { colors } from '../../styles/global/variables';

Author.propTypes = {
  actions: PropTypes.object,
  message: PropTypes.string,
  errors: PropTypes.array,
  confirmDialogOpen: PropTypes.bool,
  onEnter: PropTypes.string,
  permissions: PropTypes.array,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: theme.mixins.toolbar,
    formControl: {
      display: 'flex',
    },
    main: {
      maxWidth: '600px',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      marginRight: 'auto',
      marginLeft: 'auto',
      background: 'white',
      padding: theme.spacing(4),
    },
    wrapper: {
      backgroundColor: '#f3f4f7',
      overflow: 'auto',
    },
    image_presentation: {
      width: '100px',
    },
    mobileDocStyles: {
      '& [class^="MobiledocWrapper"]': {
        width: '100%',
        minHeight: '100px',
        border: '1px solid #ddd',
        marginTop: '15px',
        padding: '0 10px',
      },
      '& .__mobiledoc-editor.__has-no-content:after': {
        top: '17px',
      },
    },
  })
);

function mapStateToProps({ messages, ui }) {
  return {
    confirmDialogOpen: ui.confirmDialogOpen,
    message: messages.message,
    errors: messages.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        openConfirmDialogAction,
        closeConfirmDialogAction,
      },
      dispatch
    ),
  };
}

export const AUTHOR_TYPE = {
  STAFF: 'staff',
  FREELANCE: 'freelance',
};

const AUTHOR_PLACEHOLDER_IMAGE =
  'https://img.thedailybeast.com/image/upload/v1497991738/author_image_placeholder_qeiguk.png';

const LABEL = {
  TITLE: 'Create Author',
  UPDATE_TITLE: 'Update Author',
  NAME: 'Author Name',
  BIO: 'Quick Bio',
  JOB_TITLE: 'Job Title',
  EMAIL: 'Email',
  TWITTER: 'Twitter',
  PHOTO_BUTTON_SUBMIT: 'Upload',
  PHOTO_BUTTON_DELETE: 'Delete',
  BUTTON_SUBMIT: 'Create',
  BUTTON_UPDATE: 'Update',
  CONFIRM_DIALOG: 'Are you sure you want to delete this image?',
};

const PLACEHOLDER = {
  NAME: 'First and Last Name',
  BIO: 'Keep it between 200-500 characters',
  EMAIL: 'Email',
  JOB_TITLE: 'Senior Word Writer',
  TWITTER: 'Twitter Handle',
};

const getAuthorBySlugQuery = gql`
  ${authorQuery}
`;
const updateAuthorBySlugMutation = gql`
  ${updateAuthorMutation}
`;
const createNewAuthorMutation = gql`
  ${createAuthorMutation}
`;

function Author(props) {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [mobiledocBio, setMobiledocBio] = useState(null);
  const [authorData, setAuthorData] = useState({
    type: AUTHOR_TYPE.FREELANCE,
    name: null,
  });
  const [checkboxes, setCheckboxes] = useState({
    all: { disabled: false, checked: false },
    editorial: { disabled: false, checked: true },
    aggregation: { disabled: false, checked: true },
    translation: { disabled: false, checked: false },
    video: { disabled: false, checked: false },
    audio: { disabled: false, checked: false },
    merchandising: { disabled: false, checked: false },
    promotion: { disabled: false, checked: false },
  });
  const [message, setMessage] = useState(null);
  const slug = routeMatch.params.authorSlug;
  const [loadAuthor] = useLazyQuery(getAuthorBySlugQuery, {
    onError: (err) => {
      errorMessageAction(err);
      setMessage('Error fetching author');
    },
    onCompleted: (data) => {
      const { authorData: resAuthorData } = data;
      updateCheckboxState(resAuthorData.rights);
      setAuthorData(resAuthorData);
      setMobiledocBio(resAuthorData.mobiledocBio);
    },
    variables: {
      slug,
    },
  });

  const mutationToUse = slug
    ? updateAuthorBySlugMutation
    : createNewAuthorMutation;

  const [updateAuthor] = useMutation(mutationToUse, {
    onError: (err) => {
      errorMessageAction(err);
      setMessage(AuthorErrors(err));
    },
    onCompleted: (data) => {
      setMessage('Successfully Saved');
      setAuthorData(data.authorData);
      if (!slug && data.authorData.slug) {
        history.push(`/author/${data.authorData.slug}`);
      }
    },
  });

  useEffect(() => {
    props.onEnter(history, props.permissions);
    if (slug) loadAuthor();
  }, []);

  function onSave(updateAuthorMode) {
    let rights = [];
    if (checkboxes.all.checked) {
      rights = ALL_RIGHTS;
    } else {
      Object.keys(checkboxes).forEach((key) => {
        if (checkboxes[key].checked) {
          rights.push(RIGHTS[key.toUpperCase()]);
        }
      });
    }

    const authorInputTypeData = cleanseData({
      ...authorData,
      rights,
      mobiledocBio,
    });

    const idVariable = updateAuthorMode ? { id: authorData.id } : {};
    updateAuthor({
      variables: {
        ...idVariable,
        authorInputData: authorInputTypeData,
      },
    });
  }

  function handleOnConfirm() {
    setAuthorData({ ...authorData, image: null });
    props.actions.closeConfirmDialogAction();
  }

  function handleNavigateHome() {
    history.push('/');
  }

  function displayError(fieldId) {
    for (let i = 0; i < props.errors.length; i += 1) {
      if (props.errors[i].path === fieldId) {
        return props.errors[i].message;
      }
    }
    return null;
  }

  function handlePhotoButtonClick() {
    if (!authorData.image) {
      const opts = {
        theme: 'minimal',
        show_powered_by: false,
        sources: ['local'],
        upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
        max_file_size: '20000000',
        client_allowed_formats: ['png', 'jpg', 'gif', 'jpeg'],
      };

      const widget = global.cloudinary.createUploadWidget(
        opts,
        (error, result) => {
          if (result && result?.event === 'success') {
            const response = result.info;
            const imageUrl = getImageUrl(
              {
                publicId: response.public_id,
                version: response.version,
              },
              {
                flags: 'lossy',
                quality: 'auto',
              }
            );
            setAuthorData({ ...authorData, image: imageUrl });
          }

          if (error) {
            props.actions.errorMessageAction(error, error.message);
          }
        }
      );

      widget.open();
    } else {
      props.actions.openConfirmDialogAction();
    }
  }

  function handleCheck(evt) {
    const { name } = evt.target;
    const {
      all,
      editorial,
      aggregation,
      translation,
      video,
      audio,
      merchandising,
      promotion,
    } = checkboxes;

    if (name === 'all') {
      setCheckboxes({
        all: { checked: !all.checked, disabled: false },
        editorial: { checked: true, disabled: !editorial.disabled },
        aggregation: { checked: true, disabled: !aggregation.disabled },
        translation: { checked: true, disabled: !translation.disabled },
        video: { checked: true, disabled: !video.disabled },
        audio: { checked: true, disabled: !audio.disabled },
        merchandising: { checked: true, disabled: !merchandising.disabled },
        promotion: { checked: true, disabled: !promotion.disabled },
      });
    } else {
      setCheckboxes({
        ...checkboxes,
        [name]: {
          ...checkboxes[name],
          checked: !checkboxes[name].checked,
        },
      });
    }
  }

  function handleTypeToggle(type) {
    setAuthorData({ ...authorData, type });
  }

  function updateCheckboxState(rights) {
    if (rights.length === 7) {
      setCheckboxes({
        all: { checked: true, disabled: false },
        editorial: { checked: true, disabled: true },
        aggregation: { checked: true, disabled: true },
        translation: { checked: true, disabled: true },
        video: { checked: true, disabled: true },
        audio: { checked: true, disabled: true },
        merchandising: { checked: true, disabled: true },
        promotion: { checked: true, disabled: true },
      });
    } else {
      const newCheckboxes = {
        editorial: { checked: false, disabled: false },
        aggregation: { checked: false, disabled: false },
      };
      rights.forEach((right) => {
        const rightsKey = Object.keys(RIGHTS).find(
          (key) => RIGHTS[key] === right
        );
        if (rightsKey) {
          newCheckboxes[rightsKey.toLowerCase()] = {
            checked: true,
            disabled: false,
          };
        }
      });

      setCheckboxes({
        ...checkboxes,
        ...newCheckboxes,
      });
    }
  }

  function cleanseData(authorInputData) {
    const { jobTitle, email, twitter, bio, id, __typename } = authorInputData;
    const newAuthorInputData = { ...authorInputData };

    if (jobTitle === '') {
      newAuthorInputData.jobTitle = null;
    }
    if (email === '') {
      newAuthorInputData.email = null;
    }
    if (twitter && twitter[0] === '@') {
      newAuthorInputData.twitter = twitter.slice(1);
    }
    if (bio) {
      newAuthorInputData.bio = null;
    }
    if (id) {
      delete newAuthorInputData.id;
    }
    if (__typename) {
      delete newAuthorInputData.__typename;
    }
    return newAuthorInputData;
  }

  const updateAuthorMode = !!slug;
  const { image, name, jobTitle, type, email, twitter } = authorData;
  const imageUrl =
    image === '' || !image
      ? AUTHOR_PLACEHOLDER_IMAGE
      : image.replace('http://', 'https://');
  const pageTitle = updateAuthorMode ? LABEL.UPDATE_TITLE : LABEL.TITLE;
  const {
    all,
    editorial,
    aggregation,
    translation,
    video,
    audio,
    merchandising,
    promotion,
  } = checkboxes;

  return (
    <div className={classes.wrapper}>
      <div className={classes.appBar} />
      <nav>
        <Navbar title={pageTitle} handleNavigateHome={handleNavigateHome} />
      </nav>
      <main className={classes.main}>
        <Typography variant='h4' component='h4' gutterBottom>
          {pageTitle}
        </Typography>
        <div>
          <Box my={1}>
            <RadioGroup row aria-label='staff_type' name='staff_type'>
              <FormControlLabel
                value={AUTHOR_TYPE.STAFF}
                control={<Radio />}
                label={AUTHOR_TYPE.STAFF}
                checked={type === AUTHOR_TYPE.STAFF}
                onChange={() => handleTypeToggle(AUTHOR_TYPE.STAFF)}
              />
              <FormControlLabel
                value={AUTHOR_TYPE.FREELANCE}
                control={<Radio />}
                label={AUTHOR_TYPE.FREELANCE}
                checked={type === AUTHOR_TYPE.FREELANCE}
                onChange={() => handleTypeToggle(AUTHOR_TYPE.FREELANCE)}
              />
            </RadioGroup>
          </Box>
          <Box my={4}>
            <Input
              label={LABEL.NAME}
              placeholder={PLACEHOLDER.NAME}
              updateFieldAction={(e, newName) => {
                setAuthorData({ ...authorData, name: newName });
              }}
              value={name}
              errorText={displayError('authorName')}
              focus={!slug}
            />
          </Box>
          <Box my={4}>
            <Input
              label={LABEL.JOB_TITLE}
              placeholder={PLACEHOLDER.JOB_TITLE}
              updateFieldAction={(e, newJobTitle) => {
                setAuthorData({ ...authorData, jobTitle: newJobTitle });
              }}
              value={jobTitle}
              length={35}
            />
          </Box>
          <Box my={4}>
            <Input
              label={LABEL.EMAIL}
              placeholder={PLACEHOLDER.EMAIL}
              updateFieldAction={(e, newEmail) => {
                setAuthorData({ ...authorData, email: newEmail });
              }}
              value={email}
              errorText={displayError('email')}
            />
          </Box>
          <Box>
            <h3>Photo</h3>
            <Box>
              {imageUrl === AUTHOR_PLACEHOLDER_IMAGE || imageUrl === '' ? (
                <BackupIcon
                  color='disabled'
                  style={{
                    fontSize: '160px',
                    width: '110px',
                    textAlign: 'center',
                    padding: '30px',
                    border: `1px solid ${colors.lighterGrey}`,
                  }}
                />
              ) : (
                <img
                  src={imageUrl}
                  role='presentation'
                  className={classes.image_presentation}
                />
              )}
            </Box>
            <Button
              label={
                authorData.image
                  ? LABEL.PHOTO_BUTTON_DELETE
                  : LABEL.PHOTO_BUTTON_SUBMIT
              }
              onClick={handlePhotoButtonClick}
            />
          </Box>
          <Box my={4}>
            <Input
              label={LABEL.TWITTER}
              placeholder={PLACEHOLDER.TWITTER}
              updateFieldAction={(e, newTwitter) => {
                setAuthorData({ ...authorData, twitter: newTwitter });
              }}
              value={twitter}
            />
          </Box>
        </div>
        <h3>{LABEL.BIO}</h3>

        <Box className={classes.mobileDocStyles}>
          <SlimMobiledoc
            mobiledoc={slug ? authorData.mobiledocBio : null}
            placeholder={PLACEHOLDER.BIO}
            updateDoc={(newMobiledocBio) => {
              return setMobiledocBio(newMobiledocBio);
            }}
          />
        </Box>
        <div>
          <Box mt={3} mb={1}>
            <h3>Syndication Rights</h3>
          </Box>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormControlLabel
              label={
                <>
                  <Box fontStyle='bold' display='inline'>
                    All:
                  </Box>
                  Select All Rights
                </>
              }
              control={
                <Checkbox
                  checked={all.checked}
                  disabled={all.disabled}
                  onChange={handleCheck}
                  name='all'
                />
              }
            />

            <Box mt={3} mb={1}>
              <h3>Individual Rights</h3>
            </Box>

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Editorial:
                  </Box>{' '}
                  Editorial reuse in print/digital media
                </>
              }
              control={
                <Checkbox
                  checked={editorial.checked}
                  disabled={editorial.disabled}
                  onChange={handleCheck}
                  name='editorial'
                />
              }
            />

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Aggregation:
                  </Box>{' '}
                  Aggregation
                </>
              }
              control={
                <Checkbox
                  checked={aggregation.checked}
                  disabled={aggregation.disabled}
                  onChange={handleCheck}
                  name='aggregation'
                />
              }
            />

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Translation:
                  </Box>{' '}
                  Translation for foreign distribution
                </>
              }
              control={
                <Checkbox
                  checked={translation.checked}
                  disabled={translation.disabled}
                  onChange={handleCheck}
                  name='translation'
                />
              }
            />

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Video:
                  </Box>{' '}
                  Film/Television/Dramatic
                </>
              }
              control={
                <Checkbox
                  checked={video.checked}
                  disabled={video.disabled}
                  onChange={handleCheck}
                  name='video'
                />
              }
            />

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Audio:
                  </Box>{' '}
                  Audio
                </>
              }
              control={
                <Checkbox
                  checked={audio.checked}
                  disabled={audio.disabled}
                  onChange={handleCheck}
                  name='audio'
                />
              }
            />

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Merchandising:
                  </Box>{' '}
                  Merchandising
                </>
              }
              control={
                <Checkbox
                  checked={merchandising.checked}
                  disabled={merchandising.disabled}
                  onChange={handleCheck}
                  name='merchandising'
                />
              }
            />

            <FormControlLabel
              label={
                <>
                  <Box fontWeight='fontWeightBold' display='inline'>
                    Promotion:
                  </Box>{' '}
                  Promotion
                </>
              }
              control={
                <Checkbox
                  checked={promotion.checked}
                  disabled={promotion.disabled}
                  onChange={handleCheck}
                  name='promotion'
                />
              }
            />
          </FormControl>
        </div>
        <Box my={4}>
          <Button
            label={updateAuthorMode ? LABEL.BUTTON_UPDATE : LABEL.BUTTON_SUBMIT}
            onClick={() => onSave(updateAuthorMode)}
          />
        </Box>
      </main>
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
      />
      <ConfirmDialog
        open={props.confirmDialogOpen}
        onConfirm={handleOnConfirm}
        onClose={props.actions.closeConfirmDialogAction}
        title={LABEL.CONFIRM_DIALOG}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Author);
