mutation createBrand(
  $partnerName: String!
) {
  createPartner(partnerName: $partnerName) {
    id
    partner_name
    updated_at
    created_at
  }
}
