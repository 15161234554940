mutation ResetCrossPromotionMutation ($articleId: ID!) {
  crossPromotion: resetCrossPromotion(articleId: $articleId) {
    vertical {
      id
      name
    }
    subvertical {
      id
      name
    }
    franchise {
      id
      name
    }
  }
}
