query AnalyticsPageObsessedStoryData($slot: Int!) {
  page(name: OBSESSED_LANDING) {
    sectionTopBox: section(atPosition: 1) {
      id
      story(atPosition: $slot) {
        id
        vertical {
          name
        }
        subvertical {
          name
        }
        authors {
          id
          name
        }
        longHeadline: long_headline
      }
    }
    sectionSeeSkip: section(atPosition: 3) {
      id
      story(atPosition: $slot) {
        id
        vertical {
          name
        }
        subvertical {
          name
        }
        authors {
          id
          name
        }
        longHeadline: long_headline
      }
    }
  }
}
