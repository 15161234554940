query metadataPartnerContent($versionId: String!) {
  storyData: storyById(versionId: $versionId) {
    id
    metadata {
	  sponsored
	  branded
	  exclude_from_feeds
    }
  }
}
