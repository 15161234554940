query ObsesssedTagById ($tagId: ID!) {
  tag: obsessedTagById(tagId: $tagId) {
    id
    name
    type
    slug
    children {
      id
      name
      type
      slug
    }
  }
}

