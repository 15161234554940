import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client'
import searchAuthor from './currentAuthor.gql';

export const Context = React.createContext();

const CURRENT_AUTHOR_QUERY = gql`
  ${searchAuthor}
`;

const initialState = {
  isAuthenticated: false,
  currentUser: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { isAuthenticated: true, currentUser: action.data };
    default:
      throw new Error('Unknown auth action');
  }
}

function AuthContext({ children }) {
  const [state, updateAuth] = useReducer(reducer, initialState);
  const [registerUser] = useLazyQuery(CURRENT_AUTHOR_QUERY, {
    onCompleted(data) {
      updateAuth({ type: 'LOGIN_SUCCESS', data: data.searchAuthorByEmail });
    },
  });

  const defaultState = {
    ...state,
    registerUser,
  };

  return <Context.Provider value={defaultState}>{children}</Context.Provider>;
}

AuthContext.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthContext;
