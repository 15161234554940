/* eslint-disable react/require-default-props */
import React, { useEffect, useCallback, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import debounce from 'lodash/debounce';
import { useApolloClient } from '@apollo/client';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import SlugRedirectModal, { MESSAGE } from './SlugRedirectModal';
import {
  useModalHandlers,
  useStep2Handlers,
  useStep3Handlers,
} from './customHooks';
import { ACTIONS, initialState, reducer } from './state';

const useStyles = makeStyles((theme) => ({
  appBar: theme.mixins.toolbar,
  divider: {
    margin: '48px 24px',
  },
  formLabel: {
    marginBottom: 12,
  },
  header: {
    marginBottom: 48,
    marginTop: 38,
  },
  submitButton: {
    marginTop: 36,
  },
}));

ArticleSlugRedirect.displayName = 'ArticleSlugRedirect';

ArticleSlugRedirect.propTypes = {
  onEnter: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.array,
};

export default function ArticleSlugRedirect({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  const [{ canPublish, modal, snackbar, step1, step2, step3 }, dispatch] =
    useReducer(reducer, initialState);

  const client = useApolloClient();
  const {
    onChangeHandler: step2OnChangeHandler,
    onInputChangeHandler: step2OnInputChangeHandler,
  } = useStep2Handlers({
    client,
    dispatch,
  });
  const {
    onBlurHandler: step3OnBlurHandler,
    onChangeHandler: step3OnChangeHandler,
  } = useStep3Handlers({
    client,
    dispatch,
  });

  const {
    onCancelHandler: modalOnCancelHandler,
    onConfirmHandler: modalOnConfirmHandler,
  } = useModalHandlers({
    dispatch,
    step1,
    step2,
    step3,
  });

  const handleSnackbarClose = useCallback(() =>
    dispatch({ payload: initialState.snackbar, type: ACTIONS.SET_SNACKBAR })
  );

  return (
    <Container>
      <h1 className={classes.header}>Article Slug Redirect</h1>
      <div className={classes.container}>
        {/* ******* STEP 1 ******* */}
        <Step1
          onChange={(event, newValue) => {
            dispatch({
              payload: { step: 1, value: newValue },
              type: ACTIONS.SET_VALUE,
            });
            dispatch({
              payload: {
                message:
                  newValue.option === 1 ? MESSAGE.option1 : MESSAGE.option2,
              },
              type: ACTIONS.SET_MODAL,
            });
          }}
          value={step1.value}
        />
        {/* ******* END STEP 1 ******* */}

        <Divider className={classes.divider} variant='middle' />

        {/* ******* STEP 2 ******* */}
        <Step2
          step1Option={step1.value?.option}
          isFetching={step2.isFetching}
          onChange={step2OnChangeHandler}
          onInputChange={debounce(step2OnInputChangeHandler, 500)}
          options={step2.options}
          placeholder={step2.placeholder}
          value={step2.value}
        />
        {/* ******* END STEP 2 ******* */}

        <Divider className={classes.divider} variant='middle' />

        {/* ******* STEP 3 ******* */}
        <Step3
          disabled={!step2.value}
          existingArticle={step3.existingArticle}
          isFetching={step3.isFetching}
          onBlur={step3OnBlurHandler}
          onChange={step3OnChangeHandler}
          value={step3.value}
        />
        {/* ******* END STEP 3 ******* */}
      </div>
      <Button
        className={classes.submitButton}
        color='primary'
        disabled={!canPublish}
        onClick={() =>
          dispatch({ payload: { open: true }, type: ACTIONS.SET_MODAL })
        }
        variant='contained'
      >
        Publish Changes
      </Button>
      <SlugRedirectModal
        additionalSlug={step3.value}
        message={modal.message}
        onCancel={modalOnCancelHandler}
        onConfirm={modalOnConfirmHandler}
        open={modal.open}
        workingSlug={step2.value?.canonicalSlug}
      />
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={snackbar.open}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
}
