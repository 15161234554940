import React, { useState } from 'react';
import { Grid, Box, Divider, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import blue from '@material-ui/core/colors/blue';
import Cookies from 'js-cookie';
import { logError } from '../../helpers/logError';
import { handleError } from '../../helpers/global-error-handler';

LoginPage.propTypes = {
  onEnter: PropTypes.func,
};
const loginRequest = {
  scopes: ['user.read', process.env.AZURE_API_SCOPE],
};

const useStyles = makeStyles(() => ({
  background: {
    backgroundColor: '#f3f4f7',
    minHeight: '100vh',
    overflow: 'scroll',
  },
  box: {
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '80px',
    background: 'white',
    boxShadow: '0px 0px 5px 5px #ddd',
  },
  logoutText: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heading: {
    color: blue[500],
  },
  link: {
    cursor: 'pointer',
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const [userAccountInvalid, setUserAccountInvalid] = useState(false);

  async function login() {
    try {
      await global.PublicClientApplication.loginRedirect(loginRequest);
    } catch (error) {
      setUserAccountInvalid(true);
      logError(error, {
        afterCapture: handleError,
      });
      console.error(error);
    }
  }

  function logout() {
    // If two microsoft accounts are logged in, sometimes the interaction status cookie will get stuck true
    Cookies.remove(`msal.${process.env.AZURE_CLIENT_ID}.interaction.status`);
    global.PublicClientApplication.logoutRedirect().catch((error) => {
      logError(error, {
        afterCapture: handleError,
      });
    });
  }

  return (
    <div className={classes.background}>
      <Grid container direction='column' className={classes.box}>
        <Grid item>
          <Box p={3} py={4}>
            <h2 className={classes.heading}>
              The Daily Beast Publishing Toolkit
            </h2>
            {userAccountInvalid && (
              <FormHelperText error>
                Your account could not be retrieved. You may try to log out of
                it by{' '}
                <a href className={classes.logoutText} onClick={logout}>
                  clicking here.
                </a>
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item className={classes.link}>
          <Box onClick={login} p={3} py={2}>
            Login
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
