import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { v4 as uuid } from 'uuid';
import Modal from '../../../../../common/Modal/Modal';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import Input from '../../../../../common/Input/Input';
import SlimMobiledoc from '../../../../../common/SlimMobiledoc/SlimMobiledoc';
import { PULL_QUOTE_CARD_NAME } from '../PullQuoteCard/PullQuoteCard';
import { BLOCK_QUOTE_CARD_NAME } from '../BlockQuoteCard/BlockQuoteCard';

const QUOTE_TYPES = {
  PULL_QUOTE: 'pull-quote',
  BLOCK_QUOTE: 'block-quote',
};

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      margin: 0,
    },
    group: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
    mobiledocQuote: {
      'margin-top': '20px',
      'margin-bottom': '5px',
    },
    quoteSpacing: {
      marginTop: '20px',
    },
  }),
);

function QuotePicker(props) {
  const { selection } = props;
  const classes = useStyles();
  const {
    editor,
    updateQuotePickerOpen,
    saveCardFunction,
    quoteCardId,
    updateSaveCardFunction,
    updateQuoteId,
    resetEmbedCardIds,
  } = useContext(EditorContext);

  const [selectedQuoteType, setSelectedQuoteType] = useState(
    QUOTE_TYPES.PULL_QUOTE,
  );
  const [quote, setQuote] = useState('');
  const [pullQuoteCredit, setPullQuoteCredit] = useState('');
  const [blockQuoteCredit, setBlockQuoteCredit] = useState('');
  const [mobiledoc, setMobiledoc] = useState(null);

  useEffect(() => {
    editor.post.walkAllLeafSections(section => {
      if (
        section.type === 'card-section' &&
        section.payload.id === quoteCardId
      ) {
        if (section.name === PULL_QUOTE_CARD_NAME) {
          setSelectedQuoteType(QUOTE_TYPES.PULL_QUOTE);
          setQuote(section.payload.content.quote);
          setPullQuoteCredit(section.payload.content.credit);
        } else {
          setSelectedQuoteType(QUOTE_TYPES.BLOCK_QUOTE);
          setBlockQuoteCredit(section.payload.content.credit);
          setMobiledoc(section.payload.content.mobiledoc);
        }
      }
    });
  }, []);

  const handleSubmit = () => {
    let content;
    if (selectedQuoteType === QUOTE_TYPES.PULL_QUOTE) {
      content = {
        credit: pullQuoteCredit,
        quote,
      };
    } else {
      content = {
        credit: blockQuoteCredit,
        mobiledoc,
      };
    }

    const cardPayload = {
      id: uuid(),
      content,
    };

    const cardName =
      selectedQuoteType === QUOTE_TYPES.PULL_QUOTE
        ? PULL_QUOTE_CARD_NAME
        : BLOCK_QUOTE_CARD_NAME;

    if (saveCardFunction) {
      // saveCardFunction is set in the card's edit function
      saveCardFunction(cardPayload);

      // reset save card function
      updateSaveCardFunction(null);
      updateQuoteId(null);
    } else {
      editor.selectRange(selection);
      editor.insertCard(cardName, cardPayload);
    }

    updateQuotePickerOpen(false);
  };

  const onClose = () => {
    updateQuotePickerOpen(false);
    updateSaveCardFunction(null);
    resetEmbedCardIds();
  };

  const onQuoteTypeChange = event => {
    const newQuoteType = event.target.value;

    if (newQuoteType === QUOTE_TYPES.BLOCK_QUOTE) {
      setSelectedQuoteType(QUOTE_TYPES.BLOCK_QUOTE);
    } else {
      setSelectedQuoteType(QUOTE_TYPES.PULL_QUOTE);
    }
  };

  const renderQuoteEditor = () => {
    let quoteEditor;
    if (selectedQuoteType === QUOTE_TYPES.PULL_QUOTE) {
      quoteEditor = (
        <div>
          <Input
            label="Credit"
            placeholder="Rudyard Kipling"
            onChange={credit => setPullQuoteCredit(credit)}
            value={pullQuoteCredit}
          />
          <Input
            label="Quote"
            placeholder="He wrapped himself in quotations - as a beggar would enfold himself in the purple of Emperors."
            onChange={quoteValue => setQuote(quoteValue)}
            value={quote}
            multiline
            classes={classes.quoteSpacing}
          />
        </div>
      );
    } else {
      quoteEditor = (
        <div>
          <Input
            label="Credit"
            placeholder="Rudyard Kipling"
            onChange={credit => setBlockQuoteCredit(credit)}
            value={blockQuoteCredit}
          />
          <div className={classes.mobiledocQuote}>Quote</div>
          <SlimMobiledoc
            mobiledoc={mobiledoc}
            placeholder="He wrapped himself in quotations - as a beggar would enfold himself in the purple of Emperors."
            updateDoc={mobiledocValue => setMobiledoc(mobiledocValue)}
          />
        </div>
      );
    }

    return quoteEditor;
  };

  return (
    <Modal open title="Quote Type">
      <DialogContent>
        <form>
          <FormControl
            fullWidth
            className={classes.formControl}
            component="fieldset"
            disabled={!!quoteCardId}
          >
            <FormLabel component="legend">Insert a quote</FormLabel>
            <RadioGroup
              className={classes.group}
              aria-label="Quote Type"
              name="Quote Type"
              onChange={onQuoteTypeChange}
              value={selectedQuoteType}
            >
              <FormControlLabel
                label="Pull Quote"
                control={<Radio />}
                value={QUOTE_TYPES.PULL_QUOTE}
              />
              <FormControlLabel
                label="Block Quote"
                control={<Radio />}
                value={QUOTE_TYPES.BLOCK_QUOTE}
              />
            </RadioGroup>
            {renderQuoteEditor()}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" onClick={onClose} />
        <Button
          label={quoteCardId ? 'Update' : 'Place'}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Modal>
  );
}

QuotePicker.propTypes = {
  selection: PropTypes.object,
};

export default QuotePicker;
