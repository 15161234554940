import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import merge from 'lodash/merge';

import { HomepageContextProvider } from './HomepageContext';
import HomepageNav from './HomepageNav';
import HomepageSnackbar from './HomepageSnackbar';
import Section from './Section';
import * as section1styles from './Section1.module.scss';
import * as section2styles from './Section2.module.scss';
import * as section3styles from './Section3.module.scss';
import * as sectionObsessedStyles from './SectionObsessed.module.scss';
import * as section4styles from './Section4.module.scss';
import * as section5styles from './Section5.module.scss';
import * as section6styles from './Section6.module.scss';
import * as section7styles from './Section7.module.scss';

export function makeSharedLayoutStyles(theme, customStyles = {}) {
  return merge(
    {
      main: {
        display: 'grid',
        gridTemplateAreas: `
        'header'
        'section1'
        'break1'
        'cheatSheet'
        'break2'
        'section2'
        'break3'
        'section3'
        'break4'
        'obsessed'
        'break5'
        'section4'
        'break6'
        'section5'
        'break7'
        'section6'
        'break8'
        'section7'
        `,
        margin: '6.5rem auto 0',
        maxWidth: 475,
        padding: '0 0.8rem 0.8rem',
      },
      header: {
        gridArea: 'header',
      },
      section1: {
        gridArea: 'section1',
      },
      break1: {
        alignSelf: 'center',
        gridArea: 'break1',
      },
      section2: {
        alignSelf: 'center',
        gridArea: 'section2',
      },
      break2: {
        alignSelf: 'center',
        gridArea: 'break2',
      },
      section3: {
        alignSelf: 'center',
        gridArea: 'section3',
      },
      break3: {
        alignSelf: 'center',
        gridArea: 'break3',
      },
      obsessed: { gridArea: 'obsessed' },
      obsessedPlaceholder: {
        alignItems: 'center',
        background: '#d1d5d5',
        borderRadius: '0.4rem',
        color: 'white',
        display: 'grid',
        fontSize: '2.4rem',
        fontWeight: 'bold',
        gridArea: 'obsessed',
        height: '30rem',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '100%',
      },
      section4: {
        gridArea: 'section4',
      },
      break4: {
        alignSelf: 'center',
        gridArea: 'break4',
      },
      section5: {
        gridArea: 'section5',
      },
      break5: {
        alignSelf: 'center',
        gridArea: 'break5',
      },
      section6: {
        gridArea: 'section6',
      },
      break6: {
        alignSelf: 'center',
        gridArea: 'break6',
      },
      section7: {
        gridArea: 'section7',
      },
      break7: {
        alignSelf: 'center',
        gridArea: 'break7',
      },
      break8: {
        alignSelf: 'center',
        gridArea: 'break8',
      },
      sectionBreak: {
        height: '4rem',
        margin: `${theme.spacing(2) * 0.1}rem 0`,
        position: 'relative',
        width: '100%',
        '& > div': {
          background: '#d1d5d5',
          height: '100%',
          left: '50%',
          position: 'absolute',
          top: 0,
          transform: `translateX(-50%)`,
          width: '100vw',
        },
      },
      title: {
        fontSize: '2rem',
        marginBottom: theme.spacing(2),
      },
      cheatSheet: {
        alignItems: 'center',
        background: '#d1d5d5',
        borderRadius: '0.4rem',
        color: 'white',
        display: 'flex',
        fontSize: '2.4rem',
        fontWeight: 'bold',
        gridArea: 'cheatSheet',
        height: '60rem',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        main: {
          gap: '0.8rem',
          gridTemplateAreas: `
          'header header'
          'section1 section1'
          'break1 break1'
          'section2 cheatSheet'
          'break2 break2'
          'section3 section3'
          'break3 break3'
          'obsessed obsessed'
          'break4 break4'
          'section4 section4'
          'break5 break5'
          'section5 section5'
          'break6 break6'
          'section6 section6'
          'break7 break7'
          'section7 section7'
          `,
          gridTemplateColumns: '1fr 1fr',
          maxWidth: 700,
        },
        obsessed: {
          height: '100%',
        },
        cheatSheet: {
          height: '100%',
        },
        break8: {
          display: 'none',
        },
      },
      [theme.breakpoints.up('md')]: {
        main: {
          gridTemplateAreas: `
          'header header'
          'section1 section1'
          'break1 break1'
          'section2 cheatSheet'
          'break2 cheatSheet'
          'section3 cheatSheet'
          'break3 break3'
          'obsessed obsessed'
          'break4 break4'
          'section4 section4'
          'break5 break5'
          'section5 section5'
          'break6 break6'
          'section6 section6'
          'break7 break7'
          'section7 section7'
          'break8 break8'
          `,
          gridTemplateColumns: '4fr 1fr',
          maxWidth: 1100,
        },
        title: {
          fontSize: '3rem',
          marginBottom: theme.spacing(3),
        },
        break2: {
          '& > div': {
            borderRadius: '0.4rem',
            position: 'relative',
            width: '100%',
          },
        },
        break8: {
          display: 'block',
        },
      },
    },
    customStyles,
  );
}

const useStyles = makeStyles(theme => makeSharedLayoutStyles(theme));

Homepage.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

export default function Homepage({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  return (
    <HomepageContextProvider>
      <main className={classes.main}>
        <HomepageNav />
        <div className={classes.header}>
          <Typography align="center" className={classes.title} variant="h1">
            Homepage
          </Typography>
        </div>
        <Section
          section="section1"
          styles={{ additionalClass: classes.section1, ...section1styles }}
          title="Topbox"
        />
        <div className={`${classes.break1} ${classes.sectionBreak}`}>
          <div />
        </div>
        <div className={classes.cheatSheet}>Cheat Sheet</div>
        <div className={`${classes.break2} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          isTitleEditable
          section="section2"
          styles={{ additionalClass: classes.section2, ...section2styles }}
        />
        <div className={`${classes.break3} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          includeProductCallout
          section="section3"
          styles={{ additionalClass: classes.section3, ...section3styles }}
        />
        <div className={`${classes.break4} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          section="section8"
          styles={{
            additionalClass: classes.obsessed,
            ...sectionObsessedStyles,
          }}
        />
        <div className={`${classes.break5} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          section="section4"
          styles={{ additionalClass: classes.section4, ...section4styles }}
        />
        <div className={`${classes.break6} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          includeProductCallout
          section="section5"
          styles={{ additionalClass: classes.section5, ...section5styles }}
        />
        <div className={`${classes.break7} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          isTitleEditable
          section="section6"
          styles={{ additionalClass: classes.section6, ...section6styles }}
        />
        <div className={`${classes.break8} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          section="section7"
          styles={{ additionalClass: classes.section7, ...section7styles }}
        />
        <HomepageSnackbar />
      </main>
    </HomepageContextProvider>
  );
}
