import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  name: {
    marginLeft: theme.spacing(1),
  },
}));

export default ({ image, name }) => {
  const classes = useStyles();

  return (
    <>
      <Avatar alt="name" src={image} />
      <p className={classes.name}>{name}</p>
    </>
  );
};
