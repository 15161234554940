import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { colors } from '../../styles/global/variables';
import { errorMessageAction } from '../../redux/actions/messages';
import allCheatsSearch from '../../queries/autocompleteCheatSearch.gql';

CheatAutoComplete.propTypes = {
  cheat: PropTypes.object,
  index: PropTypes.number,
  onUpdateCheatList: PropTypes.func,
};

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      display: 'inline-flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    authorWithDate: {
      color: colors.blue,
      justifySelf: 'flex-end',
      flexWrap: 'nowrap',
    },
    headline: {
      maxWidth: '70%',
    },
  }),
);

function formatDate(date) {
  return moment(date).format('MMMM Do YYYY, h:mm:ss a');
}

const allCheatsSearchQuery = gql`
  ${allCheatsSearch}
`;

export default function CheatAutoComplete({ cheat, index, onUpdateCheatList }) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [selectedCheat, setSelectedCheat] = useState({});
  const [querySearchTerm, setQuerySearchTerm] = useState(null);

  const {
    data: allCheatsSearchData,
    refetch: refetchAllCheatsDataSource,
  } = useQuery(allCheatsSearchQuery, {
    onError: err => {
      errorMessageAction(err);
      setError('Error fetching list of cheats');
    },
    variables: { query: querySearchTerm },
  });

  function updateQuery(searchTerm) {
    setQuerySearchTerm(searchTerm);
    refetchAllCheatsDataSource();
  }

  return (
    <Autocomplete
      getOptionLabel={option => option?.longHeadline || null}
      value={Object.keys(selectedCheat).length !== 0 ? selectedCheat : cheat}
      filterOptions={x => x}
      options={allCheatsSearchData ? allCheatsSearchData.suggestions.edges : []}
      renderOption={({ node: { authors, longHeadline, publicationDate } }) => {
        const displayedAuthor = authors && authors[0] && authors[0].name;
        return (
          <div className={classes.listItem}>
            <div className={classes.headline}>{longHeadline}</div>
            <div className={classes.authorWithDate}>
              {displayedAuthor} | {formatDate(publicationDate)}
            </div>
          </div>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          helperText={error}
        />
      )}
      onChange={(e, { node: currentCheat }) => {
        setSelectedCheat(currentCheat);
        onUpdateCheatList(currentCheat, index);
      }}
      onInputChange={(e, inputString) => {
        if (inputString) updateQuery(inputString || '');
      }}
    />
  );
}
