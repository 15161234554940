import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import classnames from 'classnames';
import dateFormat from 'dateformat';

export const MENU_ITEM_TYPE = {
  PLAIN_TEXT: 'plainText',
  WITH_AVATAR: 'withAvatar',
  WITH_PUB_DATE: 'withPubDate',
  WITH_AUTHORS_AND_PUB_DATE: 'withAuthorsAndPubDate',
};

export function getPublicationDate(date) {
  return dateFormat(date, "mm.dd.yy H:MM TT 'ET'");
}

export default class AutoCompleteMenuItem extends Component {
  static propTypes = {
    menuItemType: PropTypes.oneOf([
      MENU_ITEM_TYPE.PLAIN_TEXT,
      MENU_ITEM_TYPE.WITH_AVATAR,
      MENU_ITEM_TYPE.WITH_PUB_DATE,
      MENU_ITEM_TYPE.WITH_AUTHORS_AND_PUB_DATE,
    ]).isRequired,
    text: PropTypes.string,
    data: PropTypes.object,
    defaultAvatarImage: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
  };

  static muiName = MenuItem.muiName;

  render() {
    const {
      menuItemType,
      text,
      data,
      defaultAvatarImage,
      onSelect,
    } = this.props;

    let menuItem = null;

    const className = classnames('AutoCompleteMenuItem', {
      'AutoCompleteMenuItem--with-plain-text':
        menuItemType === MENU_ITEM_TYPE.PLAIN_TEXT,
      'AutoCompleteMenuItem--with-avatar':
        menuItemType === MENU_ITEM_TYPE.WITH_AVATAR,
      'AutoCompleteMenuItem--with-pub-date':
        menuItemType === MENU_ITEM_TYPE.WITH_PUB_DATE,
      'AutoCompleteMenuItem--with-authors-and-pub-date':
        menuItemType === MENU_ITEM_TYPE.WITH_AUTHORS_AND_PUB_DATE,
    });

    if (menuItemType === MENU_ITEM_TYPE.PLAIN_TEXT) {
      menuItem = (
        <MenuItem
          className={className}
          primaryText={text}
          onClick={() => {
            onSelect({ text });
          }}
        />
      );
    } else if (menuItemType === MENU_ITEM_TYPE.WITH_AVATAR) {
      const { name, image } = data;
      const avatarSrc = image || defaultAvatarImage;

      menuItem = (
        <MenuItem
          className={className}
          primaryText={name}
          leftIcon={<Avatar src={avatarSrc} />}
          onClick={() => {
            onSelect({ text: name });
          }}
        />
      );
    } else if (menuItemType === MENU_ITEM_TYPE.WITH_PUB_DATE) {
      const { long_headline: headline, publication_date } = data;

      menuItem = (
        <MenuItem
          className={className}
          primaryText={
            <div>
              <div>{headline}</div>
              <div>{getPublicationDate(publication_date)}</div>
            </div>
          }
          onClick={() => {
            onSelect({ text: headline });
          }}
        />
      );
    } else if (menuItemType === MENU_ITEM_TYPE.WITH_AUTHORS_AND_PUB_DATE) {
      const {
        long_headline: headline,
        Authors: authors,
        publication_date,
      } = data;
      const author = authors.map(a => a.name).join(', ');

      menuItem = (
        <MenuItem
          className={className}
          primaryText={
            <div>
              <div style={{ lineHeight: '23px' }}>{headline}</div>
              <div
                style={{
                  fontSize: '10px',
                  color: 'blue',
                  lineHeight: '10px',
                }}
              >
                {author} {getPublicationDate(publication_date)}
              </div>
            </div>
          }
          onClick={() => {
            onSelect({ text: headline });
          }}
        />
      );
    }

    return menuItem;
  }
}
