import React, { useState, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DeleteStoryMutationGQL from './graphql/deleteStoryMutation.gql';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { colors } from '../../../../../styles/global/variables';

const deleteStoryMutation = gql`
  ${DeleteStoryMutationGQL}
`;

const deleteConfirmationMessage = 'Story Deleted';

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      '&:hover': {
        backgroundColor: colors.beastRed,
      },
      backgroundColor: colors.beastRed,
      marginB: '25px',
      marginTop: '25px',
    },
  })
);

function DeleteStorySection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const history = useHistory();

  const [deleteStory, { loading }] = useMutation(deleteStoryMutation, {
    onCompleted: () => {
      setErrorMessage(deleteConfirmationMessage);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const handleDeleteClick = () => {
    deleteStory({
      variables: {
        id: storyContext.id,
      },
    });
    setDeleteDialogOpen(false);
  };

  const deleteDialog = (
    <Dialog
      open
      title='Delete'
      modal={false}
      onRequestClose={() => setDeleteDialogOpen(false)}
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          This Story will be permanently deleted from PTK and removed if
          published.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button label='Nevermind' onClick={() => setDeleteDialogOpen(false)} />
        <Button label="I'm sure" onClick={handleDeleteClick} />
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {deleteDialogOpen && deleteDialog}
      <Grid item xs={12} md={8}>
        <Button
          className={classes.deleteButton}
          label='Delete this story'
          disabled={loading || !storyContext.seoHeadline}
          onClick={() => setDeleteDialogOpen(true)}
        />
        <Snackbar
          open={!!errorMessage}
          message={errorMessage}
          autoHideDuration={1000}
          onClose={() => {
            if (errorMessage === deleteConfirmationMessage) history.push('/');
          }}
        />
      </Grid>
    </>
  );
}

export default DeleteStorySection;
