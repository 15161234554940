import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { HomepageContext, UPDATE_CARD, SET_SNACKBAR } from './HomepageContext';
import articlesForAutocompleteQuery from '../../queries/articlesForAutocomplete.gql';
import updatePlacementMutation from '../../queries/homepage/updatePlacement.gql';
import { SNACKBAR_TYPE } from './HomepageSnackbar';
import getImageUrl from '../../helpers/images';

const AUTOCOMPLETE_QUERY = gql`
  ${articlesForAutocompleteQuery}
`;
const UPDATE_PLACEMENT_MUTATION = gql`
  ${updatePlacementMutation}
`;
const useStyles = makeStyles(() => ({ byline: { fontSize: 12 } }));

SectionCard.propTypes = {
  section: PropTypes.string.isRequired,
  slot: PropTypes.number.isRequired,
  styles: PropTypes.object,
};

Option.propTypes = {
  option: PropTypes.shape({
    node: PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired }),
      ),
      longHeadline: PropTypes.string.isRequired,
      publicationDate: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function Option({ option }) {
  const classes = useStyles();

  return (
    <div>
      <Typography>{option.node.longHeadline}</Typography>
      <Typography className={classes.byline} color="secondary" variant="body1">
        {`${option.node?.authors?.map?.(a => a.name).join(', ')} - ${dateFormat(
          option.node.publicationDate,
          "mm.dd.yy H:MM TT 'ET'",
        )}`}
      </Typography>
    </div>
  );
}

function filterOptions(options) {
  return options;
}

function getOptionLabel(option) {
  return option && option.node && option.node.longHeadline;
}

function getOptionSelected(option, value) {
  return option.node.longHeadline === value.node.longHeadline;
}

function renderOption(option) {
  return <Option option={option} />;
}

function useOptions(section, slot) {
  const [options, setOptions] = useState([
    {
      node: {
        error: '',
        id: '1',
        longHeadline: '',
        withImage: true,
        mainImage: {
          url: 'https://via.placeholder.com/429x225?text=Placeholder+Image',
        },
      },
    },
  ]);
  const [loading, setLoading] = useState(false);
  const { dispatch, state } = useContext(HomepageContext);
  const story = state[section].stories[slot - 1];
  const client = useApolloClient();

  const getOptions = useCallback(
    (event, value) => {
      if (story.error) {
        dispatch({
          type: UPDATE_CARD,
          payload: { section, slot, story: { error: '' } },
        });
      }
      setLoading(true);

      return client
        .query({
          query: AUTOCOMPLETE_QUERY,
          fetchPolicy: 'no-cache',
          variables: {
            query: value,
            types: ['ARTICLE', 'GALLERY'],
          },
        })
        .then(response => {
          setOptions(response.data.suggestions.edges);
          setLoading(false);
        })
        .catch(err => {
          setOptions([]);
          setLoading(false);
          global.apm.captureError(err);
          dispatch({
            type: UPDATE_CARD,
            payload: {
              section,
              slot,
              story: { error: 'This article does not exist' },
            },
          });
          dispatch({
            type: SET_SNACKBAR,
            payload: {
              type: SNACKBAR_TYPE.ERROR,
              message: `\`src/layouts/Homepage/SectionCard.js\`: ${err.toString()}`,
            },
          });
        });
    },
    [section, slot, story.error],
  );

  return [options, getOptions, loading];
}

function useCardSelector(section, slot) {
  const [updatePlacement] = useMutation(UPDATE_PLACEMENT_MUTATION);
  const { dispatch, state } = useContext(HomepageContext);
  const { id: sectionId, stories } = state[section];

  return useCallback(
    (event, value) => {
      if (!value?.node?.id) {
        return Promise.resolve().then(() => {
          dispatch({
            type: UPDATE_CARD,
            payload: {
              section,
              slot,
              story: { ...stories[slot - 1] },
            },
          });
        });
      }
      return updatePlacement({
        variables: {
          sectionId,
          position: slot,
          storyId: value.node.id,
        },
      })
        .then(() =>
          dispatch({
            type: UPDATE_CARD,
            payload: {
              section,
              slot,
              story: value.node,
            },
          }),
        )
        .catch(global.apm.captureError);
    },
    [section, sectionId, slot],
  );
}

export default function SectionCard({ section, slot, styles }) {
  const { state } = useContext(HomepageContext);
  const { imageHeights, stories } = state[section];
  const story = stories[slot - 1];

  const [optionsOpen, setOptionsOpen] = useState(false);
  const onOptionsClose = useCallback(() => setOptionsOpen(false), []);
  const onOptionsOpen = useCallback(() => setOptionsOpen(true), []);
  const [options, getOptions, loading] = useOptions(section, slot);
  const selectCard = useCardSelector(section, slot);

  return (
    <>
      <Card className={styles[`slot${slot}`]} key={story.id}>
        {story.withImage && (
          <CardMedia
            className={styles.image}
            component="img"
            height={imageHeights[slot - 1]}
            image={
              story.longHeadline
                ? getImageUrl(story.mainImage, { crop: 'scale', width: 600 })
                : story.mainImage.url
            }
          />
        )}
        <CardContent className={styles.input}>
          <Autocomplete
            defaultValue={{ node: story }}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            loading={loading}
            onChange={selectCard}
            onInputChange={getOptions}
            onClose={onOptionsClose}
            onOpen={onOptionsOpen}
            open={optionsOpen}
            options={options}
            renderOption={renderOption}
            renderInput={params => (
              <TextField
                {...params}
                error={!!story.error}
                helperText={story.error}
                label={`Story ${slot}`}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        params.InputProps.endAdornment
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
        </CardContent>
      </Card>
    </>
  );
}
