import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import PageControls from './PageControls/PageControls';
import ExclusionsList from './ExclusionsList/ExclusionsList';
import BizToolsBasePage from '../BizToolsBasePage';

AdRefresh.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(() => ({
  headingGap: {
    marginTop: '38px',
  },
  mainHeaderContainer: {
    display: 'inline-block',
  },
  mainRefreshControlHeader: {
    display: 'inline-block',
    textTransform: 'uppercase',
  },
}));

export default function AdRefresh({ onEnter, permissions }) {
  const classes = useStyles();

  return (
    <BizToolsBasePage
      title="Ad Ops Refresh Tools"
      onEnter={onEnter}
      permissions={permissions}
    >
      <PageControls />
      <h3 className={classes.headingGap}>Refresh Exclusions</h3>
      <ExclusionsList />
    </BizToolsBasePage>
  );
}
