import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { LABEL, PLACEHOLDER } from '../../labels';
import rubricQueryGQL from './graphql/rubricQuery.gql';
import rubricMutationGQL from './graphql/rubricMutation.gql';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';

const rubricQuery = gql`
  ${rubricQueryGQL}
`;

const rubricMutation = gql`
  ${rubricMutationGQL}
`;

function RubricSection() {
  const storyContext = useContext(StoryContext);
  const [rubric, setRubric] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loadRubric] = useLazyQuery(rubricQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const rubricData = data && data.story && data.story.rubric;
      if (rubricData) setRubric(rubricData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateRubric] = useMutation(rubricMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadRubric({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) => {
    const value = e && e.target && e.target.value;
    setRubric(value);

    updateRubric({
      variables: {
        id: storyContext.id,
        story: {
          rubric: value,
        },
      },
    });
  };

  let helperMessage = `${rubric.length}/140`;
  let hasError = false;

  if (errorMessage) {
    helperMessage = `${rubric.length}/140 ${errorMessage}`;
    hasError = true;
  }

  return (
    <Grid item xs={12} md={8}>
      <TextField
        label={LABEL.RUBRIC}
        error={hasError}
        helperText={helperMessage}
        fullWidth
        placeholder={PLACEHOLDER.RUBRIC}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        value={rubric}
        onBlur={onChange}
      />
    </Grid>
  );
}

export default React.memo(RubricSection);
