import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client'

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client'
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopy from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Snackbar from '@material-ui/core/Snackbar';
import FormHelperText from '@material-ui/core/FormHelperText';
import { StoryContext } from '../../../../../context/StoryContext';
import previewSlug from '../../queries/previewSlug.gql';
import generatePreviewSlug from '../../mutations/generatePreviewSlug.gql';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { colors } from '../../../../../styles/global/variables';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const previewSlugQuery = gql`
  ${previewSlug}
`;

const previewSlugMutation = gql`
  ${generatePreviewSlug}
`;

const useStyles = makeStyles(() =>
  createStyles({
    previewSlug: {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
      borderBottom: `1px solid ${colors.mediumGrey}`,
    },
    fileCopyIcon: {
      padding: '4px',
      opacity: '0.7',
    },
    generateSlugButton: {
      margin: '8px 0 8px',
    },
  }),
);

export default function PreviewSlug() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  const [message, setMessage] = useState(null);
  const [warning, setWarning] = useState(null);
  const [previewSlugHash, setPreviewSlugHash] = useState(null);
  const [open, setOpen] = React.useState(false);
  const storyContext = useContext(StoryContext);

  useEffect(() => {
    setWarning(null);
  }, [storyContext.id]);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [generatePreviewSlugMutation] = useMutation(previewSlugMutation, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error generating story preview slug.');
    },
    onCompleted: data => {
      const { metadata } = data;
      setPreviewSlugHash(metadata.preview_slug);
    },
  });

  const [loadPreviewSlug] = useLazyQuery(previewSlugQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error loading story preview slug.');
    },
    onCompleted: data => {
      const {
        storyData: { metadata },
      } = data;
      if (metadata) {
        setPreviewSlugHash(metadata.preview_slug);
      }
    },
  });

  useEffect(() => {
    if (id) {
      loadPreviewSlug({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  function generateSlug() {
    setWarning('Changes must be saved before preview links are available!');
    generatePreviewSlugMutation({
      variables: {
        id,
      },
    });
  }

  return (
    <>
      <h3>Preview Article Slug</h3>
      <CopyToClipboard
        text={`${process.env.WWW_DOMAIN}/preview/${previewSlugHash}`}
      >
        <div className={classes.previewSlug}>
          {previewSlugHash}
          {previewSlugHash && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Preview link has been copied to clipboard!"
                >
                  <FileCopy
                    className={classes.fileCopyIcon}
                    onClick={handleTooltipOpen}
                  />
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </CopyToClipboard>
      <FormHelperText error>{warning}</FormHelperText>
      <Button
        className={classes.generateSlugButton}
        size="small"
        color="primary"
        label="Generate Preview URL"
        onClick={generateSlug}
        disabled={!!previewSlugHash}
      />
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
