import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import Pusher from 'pusher-js';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import { gql, useMutation } from '@apollo/client';
import { endsWith } from 'lodash';
import PublishModal from './PublishModal/PublishModal';
import {
  openConfirmDialogAction,
  closeConfirmDialogAction,
} from '../../redux/actions/ui';
import {
  dismissMessageAction,
  errorMessageAction,
  dismissAllErrorsAction,
} from '../../redux/actions/messages';
import createStoryDraftGQL from '../../mutations/createStoryDraft.gql';
import Navbar from '../../common/Navbar/Navbar';
import LastSavedAt from '../../common/LastSavedAt/LastSavedAt';
import SvgSprite from '../../common/SvgSprite/SvgSprite';
import ArticleNavbarButtonGroup from './ArticleNavbarButtonGroup/ArticleNavbarButtonGroup';
import ArticleActiveUsers from './ArticleActiveUsers/ArticleActiveUsers';
import StoryContextProvider from '../../context/StoryContext';
import ValidationContext from '../../context/ValidationContext';
import EditorContext from '../../context/EditorContext';
import MediaContextProvider from '../../context/MediaContext/MediaContext';
import { Sidebar } from '../../common/Sidebar/Sidebar';
import { logError } from '../../helpers/logError';
import { handleError } from '../../helpers/global-error-handler';

Story.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object,
  confirmDialogOpen: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  onEnter: PropTypes.func,
  width: PropTypes.string,
};

const components = {
  lg: true,
  md: true,
  xl: true,
};

const styles = (theme) => ({
  appBar: theme.mixins.toolbar,
  publishActions: {
    'align-items': 'center',
    display: 'flex',
  },
});

const createStoryDraftMutation = gql`
  ${createStoryDraftGQL}
`;

function Story(props) {
  const {
    actions,
    match,
    onEnter,
    history,
    width,
    classes,
    location,
    children,
    confirmDialogOpen,
    permissions,
  } = props;
  let activeArticle = null;
  const userId = 'tempUderId';
  const [pusher, setPusher] = useState(null);
  const [socket, setSocket] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(components[width]);
  const [manualBump, setManualBump] = useState(false);
  const [message, setMessage] = useState(null);
  const [story, setStory] = useState({});
  const [publishLoading, setpublishLoading] = useState(false);
  const isMounted = useRef(true);

  const [createStoryDraft, { loading: draftMutationLoading }] = useMutation(
    createStoryDraftMutation,
    {
      onCompleted: (data) => {
        if (isMounted.current && data && data.createStoryDraft) {
          setStory(data.createStoryDraft);
        }
      },
      onError: () => {
        if (isMounted.current) {
          setMessage('Error creating new article version');
        }
      },
    }
  );

  useEffect(() => {
    onEnter(history, permissions);

    Pusher.logToConsole = true;
    (async function setupPusher() {
      let requestObject;

      try {
        const currentAccounts = global.PublicClientApplication.getAllAccounts();

        if (!currentAccounts?.length) {
          global.PublicClientApplication.loginRedirect().catch((error) => {
            logError(error, {
              afterCapture: handleError,
            });
          });
          return;
        }

        const currentAccount = currentAccounts[0];
        requestObject = {
          account: currentAccount,
          scopes: [process.env.AZURE_API_SCOPE],
        };

        const accessTokenData =
          await global.PublicClientApplication.acquireTokenSilent(
            requestObject
          );

        setPusher(
          new Pusher(process.env.PUSHER_KEY, {
            auth: {
              headers: {
                Authorization: `Bearer ${accessTokenData.accessToken}`,
              },
            },
            authEndpoint: `${process.env.API_HOST}/pusher/auth`,
            cluster: 'us2',
            forceTLS: true,
          })
        );
      } catch (error) {
        const args = {
          requestObject,
        };

        logError(error, {
          afterCapture: handleError(error, args),
        });
      }
    })();

    const { slug, type, year, month, day } = match.params;
    const path = location.pathname;
    const siteId = path.startsWith('/obsessed') ? 2 : 1;
    if (slug && type) {
      createStoryDraft({
        variables: { siteId, slug: `${type}/${year}/${month}/${day}/${slug}` },
      });

      // TODO: new draft mutation for gallery
    } else if (slug) {
      createStoryDraft({ variables: { siteId, slug } });
    } else {
      createStoryDraft({ variables: { siteId, slug: null } });
    }

    function handleBeforeUnload(event) {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      disconnectSocket();
      return undefined;
    }

    global.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      isMounted.current = false;
      global.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const newStory = endsWith(match?.path, '/new');
    if (story.id && userId && !activeArticle && !newStory) {
      connectSocket();
    }

    return () => {
      isMounted.current = false;
      disconnectSocket();
    };
  }, [story.id, userId, activeArticle, socket]);

  const onOpenPublishModal = () => {
    actions.openConfirmDialogAction();
  };

  const onPublishLoading = (status) => {
    setpublishLoading(status);
  };

  function connectSocket() {
    if (pusher) {
      let socketInst;
      if (!socketInst) {
        socketInst = pusher.subscribe(`presence-${story.slug}`);
      }

      socketInst.bind('pusher:subscription_succeeded', (members) => {
        const activeMembers = [];
        members.each((m) => {
          if (m.id !== members.me.id) {
            activeMembers.push(m);
          }
        });

        setActiveUsers(activeMembers);
      });

      socketInst.bind('pusher:subscription_error', (err) => {
        setActiveUsers([]);
        console.log('Pusher Error', err);
      });

      socketInst.bind('pusher:member_added', onMemberBeginEditingArticle);
      socketInst.bind('pusher:member_removed', onMemberEndEditingArticle);

      setSocket(socketInst);
      activeArticle = true;
    }
  }

  function disconnectSocket() {
    if (socket) {
      socket.unsubscribe(`presence-${story.slug}`);
      setSocket(null);
      activeArticle = false;
    }
  }

  const handleNavigateHome = () => {
    actions.dismissAllErrorsAction();
    history.push('/');
  };

  const onManualBump = () => {
    setManualBump(true);
  };

  const onClosePublishModal = () => {
    setManualBump(false);
    actions.closeConfirmDialogAction();
  };

  function onMemberBeginEditingArticle(member) {
    // remove the member if they alreay exist (from multiple browser tabs!)
    let activeMembers = activeUsers.filter((m) => m.id !== member.id);

    // add them back in once
    activeMembers = activeMembers.concat([member]);
    setActiveUsers(activeMembers);
  }

  function onMemberEndEditingArticle(member) {
    const activeMembers = activeUsers.filter((m) => m.id !== member.id);
    setActiveUsers(activeMembers);
  }

  if (draftMutationLoading || !story.id) return null;

  return (
    <StoryContextProvider
      value={{
        hasPublishedVersion: false,
        id: story.id,
        obsessed_tags: story.obsessed_tags,
        publicationDate: story.publicationDate,
        scheduled: story.scheduled,
        seoHeadline: story.seoHeadline,
        site: story.site,
        slug: story.slug,
        status: story.status,
        subtype: story.metadata && story.metadata.subtype,
        type: story.type,
        unlockDate: story.metadata && story.metadata.unlockDate,
        unlockPending: story.metadata && story.metadata.unlockPending,
        updatedAt: story.updatedAt,
      }}
    >
      <EditorContext>
        <ValidationContext>
          <MediaContextProvider>
            <SvgSprite />
            <Navbar handleNavigateHome={handleNavigateHome} inStory>
              <div className={classes.publishActions}>
                <Hidden mdDown>
                  <LastSavedAt />
                </Hidden>
                <ArticleActiveUsers activeUsers={activeUsers} />
                <ArticleNavbarButtonGroup
                  openPublishModal={onOpenPublishModal}
                  draftOrPublishLoading={publishLoading || draftMutationLoading}
                  articleId={story.id}
                  onSidebarToggle={() => setSidebarOpen(!sidebarOpen)}
                  onManualBump={onManualBump}
                  selectedStoryType={story.type}
                  slug={story.slug}
                />
              </div>
            </Navbar>

            <Sidebar
              open={sidebarOpen}
              extraTopMargin={!story.hasPublishedVersion}
            />

            <div className={classes.appBar} />

            <main>
              <div>{children}</div>
            </main>
            <Snackbar
              open={!!message}
              message={message}
              onClose={() => {
                setMessage(null);
              }}
              autoHideDuration={5000}
            />
            <PublishModal
              onPublishLoading={onPublishLoading}
              story={story}
              manualBump={manualBump}
              onClosePublishModal={onClosePublishModal}
              confirmDialogOpen={confirmDialogOpen}
            />
          </MediaContextProvider>
        </ValidationContext>
      </EditorContext>
    </StoryContextProvider>
  );
}

function mapStateToProps(state) {
  const { messages, ui, user } = state;
  return {
    confirmDialogOpen: ui.confirmDialogOpen,
    messages,
    userId: user.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeConfirmDialogAction,
        dismissAllErrorsAction,
        dismissMessageAction,
        errorMessageAction,
        openConfirmDialogAction,
      },
      dispatch
    ),
  };
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
  withRouter
)(Story);
