import React, { useState, useEffect, useContext } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Modal from '../../../../../common/Modal/Modal';
import UploadBox from '../UploadBox/UploadBox';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import ImageBox from '../ImageBox/ImageBox';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import { createCloudinaryUploadWidgetCallback } from '../../../../../helpers/imageHelper';
import videoQueryGQL from './graphql/videoQuery.gql';
import updateVideoMutationGQL from './graphql/updateVideoMutation.gql';
import imageQueryGQL from './graphql/imageQuery.gql';
import { StoryContext } from '../../../../../context/StoryContext';
import { MediaContext } from '../../../../../context/MediaContext/MediaContext';
import Input from '../../../../../common/Input/Input';
import { logError } from '../../../../../helpers/logError';
import { handleError } from '../../../../../helpers/global-error-handler';

export const VIDEO_TYPE = {
  JW: 'jw',
  YOUTUBE: 'youtube',
};

export const LABEL = {
  CAPTION: 'Video Caption',
  CLOSE: 'Close',
  CREDIT: 'Video Credit',
  JW: 'JW',
  TITLE: 'Video Title',
  YOUTUBE: 'Youtube',
};

export const PLACEHOLDER = {
  CAPTION:
    'An astouding number of court battles—from Trump University suits to libel cases—will accompany Trump even as he moves into the White House',
  CREDIT: "Photographer's name",
  TITLE: 'Apprentice Staffer Claims Gary Busey ate worms, Donald Laughed',
  VIDEO_CODE: 'Paste your code here...',
  VIDEO_URL: 'Paste your URL here...',
};

export const EMPTY_CREDIT_ERROR = 'Video credit can’t be empty';

const useStyles = makeStyles(() =>
  createStyles({
    modalSpacing: {
      padding: '15px',
    },
    uploadDialog: {
      width: 'calc(100% - 20px)',
    },
  })
);

const videoQuery = gql`
  ${videoQueryGQL}
`;

const imageQuery = gql`
  ${imageQueryGQL}
`;

const updateVideoMutation = gql`
  ${updateVideoMutationGQL}
`;

function VideoModal() {
  const [mixedContent, setMixedContent] = useState('');
  const [type, setType] = useState(VIDEO_TYPE.YOUTUBE);
  const [title, setTitle] = useState('');
  const [caption, setCaption] = useState('');
  const [credit, setCredit] = useState('');
  const [creditErrorMessage, setCreditErrorMessage] =
    React.useState(EMPTY_CREDIT_ERROR);
  const [image, setImage] = useState(null);

  const {
    addMainImage,
    addVideo,
    updateImageModalOpen,
    updateVideoModalOpen,
    storyHero: { imageId, videoId, errorMessage },
    updateCurrentImageId,
    updateStoryHeroError,
  } = useContext(MediaContext);
  const { id } = useContext(StoryContext);
  const classes = useStyles();

  const [loadVideo] = useLazyQuery(videoQuery, {
    onCompleted: (data) => {
      if (errorMessage) updateStoryHeroError(null);

      const videoData = data && data.video;
      if (videoData) {
        setMixedContent(videoData.mixedContent);
        setType(videoData.type);
        setTitle(videoData.title);
        setCaption(videoData.caption);
        setCredit(videoData.credit);
        setCreditErrorMessage(videoData.credit ? '' : EMPTY_CREDIT_ERROR);
      }
    },
    onError: (err) => {
      // Log error to error monitoring service
      logError(error, {
        afterCapture: handleError,
      });

      // Provide error message to user
      const error = getGraphqlErrorMessage(err);
      updateStoryHeroError(error);
    },
  });

  const [loadImage] = useLazyQuery(imageQuery, {
    onCompleted: (data) => {
      if (errorMessage) updateStoryHeroError(null);

      const imageData = data && data.image;
      if (imageData) setImage(imageData);
    },
    onError: (err) => {
      // Log error to error monitoring service
      logError(error, {
        afterCapture: handleError,
      });

      // Provide error message to user
      const error = getGraphqlErrorMessage(err);
      updateStoryHeroError(error);
    },
  });

  const [updateVideo] = useMutation(updateVideoMutation, {
    onCompleted: (data) => {
      if (errorMessage) updateStoryHeroError(null);

      const videoData = data && data.video;
      if (videoData) {
        setMixedContent(videoData.mixedContent);
        setType(videoData.type);
        setTitle(videoData.title);
        setCaption(videoData.caption);
        setCredit(videoData.credit);
      }
    },
    onError: (err) => {
      // Log error to error monitoring service
      logError(error, {
        afterCapture: handleError,
      });

      // Provide error message to user
      const error = getGraphqlErrorMessage(err);
      updateStoryHeroError(error);
    },
  });

  useEffect(() => {
    if (videoId) {
      loadVideo({
        variables: {
          videoId,
        },
      });
    }
    if (imageId) {
      loadImage({
        variables: {
          imageId,
        },
      });
    }

    if (!imageId) {
      setImage(null);
    }
  }, [imageId, videoId]);

  const onClose = () => {
    const mixedContentHasLength = mixedContent && mixedContent.length;
    const creditHasLength = credit && credit.length;
    const allFieldsMissing =
      !mixedContentHasLength && !creditHasLength && !image;
    const missingFields = !mixedContentHasLength || !creditHasLength || !image;
    if (allFieldsMissing) {
      updateStoryHeroError(null);
    } else if (videoId) {
      if (missingFields) {
        updateStoryHeroError('Error: Please check required video fields');
      }
      updateVideo({
        variables: {
          id: videoId,
          video: {
            caption,
            credit,
            mixed_content: mixedContent,
            title,
            type,
          },
        },
      });
    } else {
      addVideo({
        variables: {
          articleId: id,
          video: {
            caption,
            credit,
            mixed_content: mixedContent,
            title,
            type,
          },
        },
      });
    }

    updateVideoModalOpen(false);
  };

  const onEditImage = () => {
    if (image) {
      updateCurrentImageId(image.id);
      updateImageModalOpen(true);
    }
  };

  return (
    <section>
      <Modal open onClose={onClose}>
        <Grid container className={classes.modalSpacing} direction='column'>
          <Grid item>
            <h2>Video Upload</h2>
          </Grid>
          <Grid item>
            <Input
              value={mixedContent}
              multiline={type === VIDEO_TYPE.YOUTUBE}
              placeholder={
                type === VIDEO_TYPE.YOUTUBE
                  ? PLACEHOLDER.VIDEO_URL
                  : PLACEHOLDER.VIDEO_CODE
              }
              onChange={setMixedContent}
            />
          </Grid>
          <Grid item>
            <h3>Type</h3>
            <FormControl>
              <RadioGroup
                name='Video'
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel
                  value={VIDEO_TYPE.YOUTUBE}
                  label={LABEL.YOUTUBE}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={VIDEO_TYPE.JW}
                  label={LABEL.JW}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
            {mixedContent && (
              <div>
                <Box mt={5}>
                  <Input
                    value={title}
                    label={LABEL.TITLE}
                    placeholder={PLACEHOLDER.TITLE}
                    onChange={setTitle}
                    charCounter
                    length={255}
                  />
                </Box>
                <Box mt={5}>
                  <Input
                    value={caption}
                    label={LABEL.CAPTION}
                    placeholder={PLACEHOLDER.CAPTION}
                    multiline
                    onChange={setCaption}
                  />{' '}
                </Box>
                <Box mt={5}>
                  <Input
                    value={credit}
                    label={LABEL.CREDIT}
                    placeholder={PLACEHOLDER.CREDIT}
                    onChange={(fieldValue) => {
                      const err =
                        fieldValue && fieldValue.length
                          ? ''
                          : EMPTY_CREDIT_ERROR;
                      setCreditErrorMessage(err);
                      setCredit(fieldValue);
                    }}
                    charCounter
                    length={255}
                    errorText={creditErrorMessage}
                  />
                </Box>
              </div>
            )}
          </Grid>

          <Grid item>
            <h3>Thumbnail</h3>
          </Grid>

          <Grid item>
            {image && (
              <ImageBox
                image={image}
                imageWidth={476}
                imageHeight={268}
                onEdit={onEditImage}
              />
            )}
          </Grid>
          <Grid item>
            {!image && (
              <UploadBox
                onImageUploaded={createCloudinaryUploadWidgetCallback({
                  onError: ({ message }) => updateStoryHeroError(message),
                  onSuccess: ({ validatedImage }) => {
                    addMainImage({
                      variables: {
                        articleId: id,
                        image: validatedImage,
                        isMainImage: true,
                      },
                    });
                    updateStoryHeroError('');
                  },
                })}
                className={classes.uploadDialog}
              />
            )}
          </Grid>
        </Grid>

        <div>
          <Button label={LABEL.CLOSE} primary onClick={onClose} />
        </div>
      </Modal>
    </section>
  );
}

export default VideoModal;
