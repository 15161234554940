import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import AnalyticsCheatsheet from './AnalyticsCheatsheet';
import AnalyticsSection from './AnalyticsSection';
import * as section1styles from './Section1.module.scss';
import * as section2styles from './Section2.module.scss';
import * as section3styles from './Section3.module.scss';
import * as sectionObsessedStyles from './SectionObsessed.module.scss';
import * as section4styles from './Section4.module.scss';
import * as section5styles from './Section5.module.scss';
import * as section6styles from './Section6.module.scss';
import * as section7styles from './Section7.module.scss';
import * as style from './HomepageAnalytics.module.scss';
import HomepageAnalyticsQuery from '../../queries/homepage/fetchHomepageAnalytics.gql';
import { makeSharedLayoutStyles } from './HomePage';

const ANALYTICS_DATA = {
  obsessed: {
    section: 9,
  },
};

const analyticsQuery = gql`
  ${HomepageAnalyticsQuery}
`;

const useStyles = makeStyles(theme =>
  makeSharedLayoutStyles(theme, {
    main: {
      gridTemplateAreas: `
      'error'
      'key'
      'section1'
      'break1'
      'cheatSheet'
      'break2'
      'section2'
      'break3'
      'section3'
      'break4'
      'obsessed'
      'break5'
      'section4'
      'break6'
      'section5'
      'break7'
      'section6'
      'break8'
      'section7'
    `,
    },
    cheatSheet: {
      alignItems: 'initial',
      background: 'none',
      color: 'black',
      display: 'grid',
      flexDirection: 'column',
      gap: '0.4rem',
      gridArea: 'cheatSheet',
      height: 'auto',
      justifyContent: 'initial',
      width: '100%',
      '& *': {
        textAlign: 'left',
      },
      '& > p': { textAlign: 'center' },
    },
    error: { gridArea: 'error' },
    key: { gridArea: 'key' },
    [theme.breakpoints.up('sm')]: {
      main: {
        gap: 0,
        gridTemplateAreas: `
          'error error'
          'key key'
          'section1 section1'
          'break1 break1'
          'section2 cheatSheet'
          'break2 break2'
          'section3 section3'
          'break3 break3'
          'obsessed obsessed'
          'break4 break4'
          'section4 section4'
          'break5 break5'
          'section5 section5'
          'break6 break6'
          'section6 section6'
          'break7 break7'
          'section7 section7'
          'break8 break8'
        `,
        gridTemplateColumns: '1fr 1fr',
      },
      section2: {
        paddingRight: '0.8rem',
      },
      cheatSheet: {
        borderLeft: '1px solid #d1d5d5',
        borderRadius: 0,
        paddingLeft: '0.8rem',
      },
    },
    [theme.breakpoints.up('md')]: {
      main: {
        gridTemplateAreas: `
          'error error'
          'key key'
          'section1 section1'
          'break1 break1'
          'section2 cheatSheet'
          'break2 cheatSheet'
          'section3 cheatSheet'
          'break3 break3'
          'obsessed obsessed'
          'break4 break4'
          'section4 section4'
          'break5 break5'
          'section5 section5'
          'break6 break6'
          'section6 section6'
          'break7 break7'
          'section7 section7'
          'break8 break8'
        `,
        gridTemplateColumns: '4fr 1fr',
      },
      break2: { paddingRight: '0.8rem' },
      section3: { paddingRight: '0.8rem' },
    },
  }),
);

HomepageAnalytics.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

export default function HomepageAnalytics({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
    loadAnalyticsQuery();
  }, []);

  const [analyticsData, setAnalyticsData] = useState();
  const [error, setError] = useState(null);
  const [loadAnalyticsQuery] = useLazyQuery(analyticsQuery, {
    errorPolicy: 'all',
    onError(err) {
      setError(err.toString());
    },
    onCompleted(data) {
      if (data?.getPageAnalytics?.length > 0) {
        setAnalyticsData(data.getPageAnalytics);
      } else {
        setError('Missing error report.');
      }
    },
  });

  return (
    <main className={classes.main}>
      {error && (
        <div className={`${classes.error} ${style.errorWrapper}`}>
          <ExpansionPanel>
            <ExpansionPanelSummary
              className={style.errorInner}
              expandIcon={<ExpandMoreIcon />}
            >
              Error getting data from Google Analytics. Please contact web
              support.
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <pre>{error}</pre>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
      <div className={`${classes.key} ${style.keyWrapper}`}>
        <Typography variant="h3">Homepage Analytics</Typography>
        <Paper className={style.keyInner}>
          <Typography className={style.keyText}>
            <strong>Big %</strong> - % of all homepage clicks in the last 30{' '}
            <em>minutes</em> to that particular slot
          </Typography>
          <Typography className={style.keyText}>
            <strong>Small %</strong> - % of all homepage clicks in the last 30{' '}
            <em>days</em> to that particular slot
          </Typography>
          <Typography className={style.keyText}>
            <strong>Clicks</strong> - Number of clicks to that slot over the
            past 30 <em>minutes</em>
          </Typography>
          <br />
          <Typography className={style.keyText}>
            All data is slot-specific and does not change when articles are
            switched in and out
          </Typography>
        </Paper>
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 1)}
        nStories={7}
        section={1}
        styles={{ additionalClasses: classes.section1, ...section1styles }}
      />
      <div className={`${classes.break1} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsCheatsheet
        analyticsData={analyticsData?.filter(stats => stats.section === 8)}
        className={classes.cheatSheet}
      />
      <div className={`${classes.break2} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 2)}
        nStories={7}
        section={2}
        styles={{ additionalClasses: classes.section2, ...section2styles }}
      />
      <div className={`${classes.break3} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 3)}
        includeProductCallout
        nStories={5}
        section={3}
        styles={{ additionalClasses: classes.section3, ...section3styles }}
      />
      <div className={`${classes.break4} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 9)}
        nStories={6}
        section={8}
        styles={{
          additionalClasses: classes.obsessed,
          ...sectionObsessedStyles,
        }}
      />
      <div className={`${classes.break5} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 4)}
        nStories={6}
        section={4}
        styles={{ additionalClasses: classes.section4, ...section4styles }}
      />
      <div className={`${classes.break6} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 5)}
        includeProductCallout
        nStories={5}
        section={5}
        styles={{ additionalClasses: classes.section5, ...section5styles }}
      />
      <div className={`${classes.break7} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 6)}
        isTitleEditable
        nStories={4}
        section={6}
        styles={{ additionalClasses: classes.section6, ...section6styles }}
      />
      <div className={`${classes.break8} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 7)}
        nStories={6}
        section={7}
        styles={{ additionalClasses: classes.section7, ...section7styles }}
      />
    </main>
  );
}
