query EditorsQuery($versionId: String!) {
  story: storyById(versionId: $versionId) {
    editors {
      id
      name
      slug
      email
    }
  }
}
