import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ItemTypes = {
  CHEAT: 'cheat',
};

/* istanbul ignore next */
const cheatSource = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

const styles = {
  opacity: '.5',
};

/* istanbul ignore next */
const cheatTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect(); // eslint-disable-line react/no-find-dom-node
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveCheat(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex; // eslint-disable-line no-param-reassign
  },
};

class CheatSheetDraggableCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isDragging: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    moveCheat: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    publicationDate: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  render() {
    const {
      classes,
      connectDragSource,
      connectDropTarget,
      index,
      isDragging,
      label,
      publicationDate,
      title,
    } = this.props;

    return connectDropTarget(
      <div style={isDragging ? styles : {}}>
        <Grid
          alignItems="center"
          className={classes.reorderContainer}
          container
        >
          <Grid item>
            <h3 className={classes.cheatLabel}>{label}</h3>
          </Grid>
          <Grid className={classes.cheatInputBox} item>
            {connectDragSource(
              <div className={classes.shadowPadding}>
                <Box p={1} className="shadowBox">
                  <Typography variant="subtitle1">{title}</Typography>
                  <Grid align="right" className={classes.cheatDate} item>
                    {publicationDate}
                  </Grid>
                </Box>
              </div>,
            )}
          </Grid>
        </Grid>
      </div>,
    );
  }
}

export default compose(
  DropTarget(ItemTypes.CHEAT, cheatTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
  })),
  DragSource(ItemTypes.CHEAT, cheatSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
)(CheatSheetDraggableCard);
