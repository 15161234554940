import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import Button from '../../../../common/ButtonWrapper/ButtonWrapper';
import getGraphqlErrorMessage from '../../../../helpers/graphqlError';

import fetchAdRefreshControls from './queries/adRefreshControl/fetchAdRefreshControls.gql';
import upateShouldRefresh from './queries/adRefreshControl/updateShouldRefresh.gql';
import updateAdRefreshInterval from './queries/adRefreshControl/updateAdRefreshInterval.gql';
import updateRefreshCap from './queries/adRefreshControl/updateRefreshCap.gql';
import isNumber from '../isNumber';

const useStyles = makeStyles(theme => ({
  appBar: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #333',
    padding: '30px',
    marginTop: '50px',
  },
  wrapper: { flex: '50%' },
  siteSection: { flex: '100%' },
  item: { margin: '15px 17px' },
  saveContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  refreshCap: { marginTop: '15px' },
  refreshControlsBySectionContainer: {
    display: 'inline-block',
  },
  refreshControlsBySection: {
    display: 'inline-block',
    textTransform: 'uppercase',
  },
}));

const onLoadPageTypes = ['STANDARD', 'FEATURE', 'GALLERY'];
const errorPageTypes = ['CHEAT', 'STANDARD', 'FEATURE', 'GALLERY'];

export default function PageControlsSections() {
  const classes = useStyles();

  const [message, setMessage] = useState(null);

  const [pageTypes, setPageTypes] = useState([]);

  const [adRefreshBySection, setAdRefreshBySection] = useState({});

  const [shouldRefresh, setShouldRefresh] = useState(
    Object.assign(
      {},
      ...onLoadPageTypes.map(p => ({
        [p]: {
          id: `default-${onLoadPageTypes.indexOf(p) + 1}`,
          shouldRefresh: false,
        },
      })),
    ),
  );

  const [interval, setInterval] = useState(
    Object.assign(
      {},
      ...onLoadPageTypes.map(p => ({
        [p]: {
          id: `default-${onLoadPageTypes.indexOf(p) + 1}`,
          interval: '20',
        },
      })),
    ),
  );

  const [refreshCap, setRefreshCap] = useState(
    Object.assign(
      {},
      ...onLoadPageTypes.map(p => ({
        [p]: {
          id: `default-${onLoadPageTypes.indexOf(p) + 1}`,
          refreshCap: 5,
        },
      })),
    ),
  );

  const [site, setSite] = useState('thedailybeast');

  const [verticalList, setVerticalList] = useState([]);

  const [selectedVertical, setSelectedVertical] = useState({});

  const [changedAdRefreshControls, setChangedAdRefreshControls] = useState({
    interval: [],
    refreshCap: [],
    shouldRefresh: [],
  });

  const [intervalErrorText, setIntervalErrorText] = useState(
    Object.assign(...errorPageTypes.map(p => ({ [p]: null }))),
  );

  const [refreshCapErrorText, setRefreshCapErrorText] = useState(
    Object.assign(...onLoadPageTypes.map(p => ({ [p]: null }))),
  );

  useQuery(
    gql`
      ${fetchAdRefreshControls}
    `,
    {
      onCompleted: data => {
        const organizedAdRefreshControls = organizeAdRefreshControls(data);
        setAdRefreshBySection(organizedAdRefreshControls);
      },
    },
  );

  const syncForm = (field, value, verticalName, siteName, template) => {
    const updatedAdRefresh = { ...adRefreshBySection };
    if (siteName === 'thedailybeast' && template !== 'cheat') {
      updatedAdRefresh.thedailybeast[verticalName][field][template][
        field
      ] = value;
    } else if (template === 'cheat') {
      updatedAdRefresh.thedailybeast.general[field].cheat[field] = value;
    } else if (siteName === 'obsessed') {
      updatedAdRefresh.obsessed.general[field][template][field] = value;
    }

    setAdRefreshBySection(updatedAdRefresh);
  };

  const handleAdRefreshMutation = adRefreshControl => {
    if (adRefreshControl) {
      setMessage('Saved!');
    } else {
      setMessage('Failed to save for an unknown reason');
    }
  };

  const [runShouldRefreshMutation] = useMutation(
    gql`
      ${upateShouldRefresh}
    `,
    {
      onError: err => {
        setMessage(getGraphqlErrorMessage(err));
      },
      onCompleted: data => {
        handleAdRefreshMutation(data?.adRefreshControl);
      },
    },
  );

  const [runAdRefreshIntervalMutation] = useMutation(
    gql`
      ${updateAdRefreshInterval}
    `,
    {
      onError: err => {
        setMessage(getGraphqlErrorMessage(err));
      },
      onCompleted: data => {
        handleAdRefreshMutation(data?.adRefreshControl);
      },
    },
  );

  const [runRefreshCapMutation] = useMutation(
    gql`
      ${updateRefreshCap}
    `,
    {
      onError: err => {
        setMessage(getGraphqlErrorMessage(err));
      },
      onCompleted: data => {
        handleAdRefreshMutation(data?.adRefreshControl);
      },
    },
  );

  const organizeAdRefreshControls = data => {
    const listOfVerticals = [];
    const organizedAdRefreshControls = data.adRefreshControls.reduce(
      (acc, obj) => {
        const {
          id,
          interval: dataInterval,
          pageType,
          refreshCap: dataRefreshCap,
          shouldRefresh: dataShouldRefresh,
          siteName,
          templateType,
          verticalId,
          verticalName,
        } = obj;

        if (!acc[siteName]) {
          acc[siteName] = {};
        }

        if (verticalName) {
          if (!acc[siteName][verticalName]) {
            acc[siteName][verticalName] = {};
            acc[siteName][verticalName].interval = {};
            acc[siteName][verticalName].shouldRefresh = {};
            acc[siteName][verticalName].refreshCap = {};
          }

          acc[siteName][verticalName].interval[templateType || pageType] = {
            id,
            interval: dataInterval,
          };

          acc[siteName][verticalName].shouldRefresh[
            templateType || pageType
          ] = {
            id,
            shouldRefresh: dataShouldRefresh,
          };

          acc[siteName][verticalName].refreshCap[templateType || pageType] = {
            id,
            refreshCap: dataRefreshCap,
          };

          // populate vertical dropdown
          const itemExists = listOfVerticals.some(
            item => item.id === verticalId,
          );
          if (!itemExists) {
            listOfVerticals.push({ id: verticalId, name: verticalName });
          }
          // populate cheat sheat
        } else if (pageType && !templateType) {
          if (pageType !== 'crossword') {
            if (!acc[siteName].general) {
              acc[siteName].general = {};
              acc[siteName].general.interval = {};
              acc[siteName].general.shouldRefresh = {};
              acc[siteName].general.refreshCap = {};
            }

            acc[siteName].general.interval[pageType] = {
              id,
              interval: dataInterval,
            };

            acc[siteName].general.shouldRefresh[pageType] = {
              id,
              shouldRefresh: dataShouldRefresh,
            };

            acc[siteName].general.refreshCap[pageType] = {
              id,
              refreshCap: dataRefreshCap,
            };
          }
          // populate obsessed
        } else if (pageType && templateType) {
          if (!acc[siteName].general) {
            acc[siteName].general = {};
            acc[siteName].general.interval = {};
            acc[siteName].general.shouldRefresh = {};
            acc[siteName].general.refreshCap = {};
          }

          acc[siteName].general.interval[templateType] = {
            id,
            interval: dataInterval,
          };

          acc[siteName].general.shouldRefresh[templateType] = {
            id,
            shouldRefresh: dataShouldRefresh,
          };

          acc[siteName].general.refreshCap[templateType] = {
            id,
            refreshCap: dataRefreshCap,
          };
        }

        return acc;
      },
      {},
    );
    setVerticalList(
      listOfVerticals.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    );
    return organizedAdRefreshControls;
  };

  // Sync Form changes
  const syncChanges = (id, input, field) => {
    let newChange = {};
    const newShouldRefresh = [...changedAdRefreshControls[field]];
    const changeIndex = newShouldRefresh.findIndex(item => item.id === id);

    if (changeIndex === -1) {
      newShouldRefresh.push({ id, [field]: input });
    } else {
      newShouldRefresh[changeIndex][field] = input;
    }

    if (field === 'shouldRefresh') {
      newChange = {
        interval: [...changedAdRefreshControls.interval],
        refreshCap: [...changedAdRefreshControls.refreshCap],
        [field]: [...newShouldRefresh],
      };
    } else if (field === 'interval') {
      newChange = {
        shouldRefresh: [...changedAdRefreshControls.shouldRefresh],
        refreshCap: [...changedAdRefreshControls.refreshCap],
        [field]: [...newShouldRefresh],
      };
    } else if (field === 'refreshCap') {
      newChange = {
        interval: [...changedAdRefreshControls.interval],
        shouldRefresh: [...changedAdRefreshControls.shouldRefresh],
        [field]: [...newShouldRefresh],
      };
    }
    setChangedAdRefreshControls(newChange);
  };

  function handleSwitchInput(id, label, input) {
    setShouldRefresh(prevState => ({
      ...prevState,
      [label]: {
        id,
        shouldRefresh: input,
      },
    }));
    syncChanges(id, input, 'shouldRefresh');
    syncForm('shouldRefresh', input, selectedVertical?.name, site, label);
  }

  function handleNumberInput(min, field, setError, setValue, id, label, input) {
    setValue(prevState => ({
      ...prevState,
      [label]: {
        id,
        [field]: input,
      },
    }));

    const parsedInput = parseInt(input, 10);
    if (isNumber(parsedInput)) {
      setValue(prevState => ({
        ...prevState,
        [label]: {
          id,
          [field]: parsedInput,
        },
      }));
      if (parsedInput < min) {
        setError(prevState => ({
          ...prevState,
          [label]: `Minimum of ${min}`,
        }));
      } else if (parsedInput > 1000) {
        setError(prevState => ({
          ...prevState,
          [label]: 'Maximum of 1000',
        }));
      } else {
        setError(prevState => ({ ...prevState, [label]: null }));
        syncChanges(id, input, field);
        syncForm(field, input, selectedVertical?.name, site, label);
      }
    } else {
      setError(prevState => ({
        ...prevState,
        [label]: 'Must be a number',
      }));
    }
  }

  const extractPageTypes = obj => {
    const result = [];
    const subObjects = ['interval', 'shouldRefresh', 'refreshCap'];
    subObjects.forEach(subObject => {
      if (obj[subObject]) {
        Object.keys(obj[subObject]).forEach(key => {
          if (!result.includes(key)) {
            result.push(key);
          }
        });
      }
    });
    return result.sort();
  };

  const handleSiteChange = newSite => {
    if (newSite !== site) {
      setSite(newSite);
    }

    if (newSite === 'obsessed') {
      updateForm(adRefreshBySection.obsessed.general);
    } else if (newSite === 'thedailybeast' && selectedVertical?.name) {
      addCheat(selectedVertical.name);
      updateForm(adRefreshBySection.thedailybeast[selectedVertical.name]);
    }
  };

  const handleVerticalChange = (e, selectedComponent) => {
    const {
      props: { verticaltype },
    } = selectedComponent;

    if (verticaltype.id !== selectedVertical.id) {
      setSelectedVertical(verticaltype);
      resetErrors();
      addCheat(verticaltype.name);
      updateForm(adRefreshBySection[site][verticaltype.name]);
    }
  };

  const addCheat = verticalName => {
    const result = adRefreshBySection.thedailybeast[verticalName];
    const intervalCheat =
      adRefreshBySection.thedailybeast.general.interval.cheat;
    const shouldRefreshCheat =
      adRefreshBySection.thedailybeast.general.shouldRefresh.cheat;
    const refreshCapCheat =
      adRefreshBySection.thedailybeast.general.refreshCap.cheat;

    result.interval = {
      cheat: intervalCheat,
      ...result.interval,
    };

    result.shouldRefresh = {
      cheat: shouldRefreshCheat,
      ...result.shouldRefresh,
    };

    result.refreshCap = {
      cheat: refreshCapCheat,
      ...result.refreshCap,
    };
  };

  const updateForm = result => {
    const newPageTypes = extractPageTypes(result);
    setPageTypes(newPageTypes);
    setShouldRefresh(result.shouldRefresh);
    setInterval(result.interval);
    setRefreshCap(result.refreshCap);
  };

  const disableButton = () => {
    const noChangesExist = Object.keys(changedAdRefreshControls).every(key => {
      return (
        changedAdRefreshControls[key] && !changedAdRefreshControls[key].length
      );
    });
    const intervalErrorsExist = Object.keys(intervalErrorText).some(
      key => intervalErrorText[key] !== null,
    );
    const refreshCapErrorsExist = Object.keys(refreshCapErrorText).some(
      key => refreshCapErrorText[key] !== null,
    );

    const errorCheck = intervalErrorsExist || refreshCapErrorsExist;

    return noChangesExist || errorCheck;
  };

  const submitForm = () => {
    changedAdRefreshControls.interval.forEach(i => {
      const { id, interval: intervalChange } = i;
      runAdRefreshIntervalMutation({
        variables: { id, interval: intervalChange },
      });
    });

    changedAdRefreshControls.shouldRefresh.forEach(i => {
      const { id, shouldRefresh: shouldRefreshChange } = i;
      runShouldRefreshMutation({
        variables: { id, shouldRefresh: shouldRefreshChange },
      });
    });

    changedAdRefreshControls.refreshCap.forEach(i => {
      const { id, refreshCap: refreshCapChange } = i;
      runRefreshCapMutation({
        variables: { id, refreshCap: refreshCapChange },
      });
    });

    resetForm();
  };

  const resetErrors = () => {
    const originalErrorState = Object.assign(
      ...onLoadPageTypes.map(p => ({ [p]: null })),
    );
    setIntervalErrorText(originalErrorState);
    setRefreshCapErrorText(originalErrorState);
  };

  const resetForm = () => {
    const form = {
      interval: [],
      refreshCap: [],
      shouldRefresh: [],
    };
    setChangedAdRefreshControls(form);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h3 className={classes.refreshControlsBySection}>
          Refresh Intervals By Section
        </h3>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.saveContainer}>
          <Button
            color="primary"
            label="Save"
            className={classes.saveButton}
            onClick={submitForm}
            disabled={disableButton()}
          >
            Save
          </Button>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.item}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="site-selection"
              value={site}
              onChange={e => handleSiteChange(e.target.value)}
              row
            >
              <FormControlLabel
                value="obsessed"
                control={<Radio />}
                label="Obsessed"
              />
              <FormControlLabel
                value="thedailybeast"
                control={<Radio />}
                label="Daily Beast"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.siteSection}>
        <div className={classes.item}>
          <FormControl fullWidth>
            <Select
              id="select-vertical"
              label="Vertical"
              disabled={site === 'obsessed'}
              onChange={handleVerticalChange}
            >
              {verticalList.map(vertical => (
                <MenuItem
                  value={vertical.id}
                  key={vertical.id}
                  verticaltype={vertical}
                >
                  {vertical.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {pageTypes.map(label => (
        <div className={classes.wrapper} key={label}>
          <div key={label} className={classes.item}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={shouldRefresh[label].shouldRefresh}
                    onChange={e =>
                      handleSwitchInput(
                        shouldRefresh[label].id,
                        label,
                        e.target.checked,
                      )
                    }
                  />
                }
                disabled={site === 'thedailybeast' && !selectedVertical?.name}
                label={label.toUpperCase()}
              />
            </FormControl>
            <TextField
              InputLabelProps={{ shrink: true }}
              error={!!intervalErrorText[label]}
              fullWidth
              helperText={intervalErrorText[label]}
              disabled={site === 'thedailybeast' && !selectedVertical?.name}
              label="Refresh Interval (seconds)"
              onChange={event =>
                handleNumberInput(
                  15,
                  'interval',
                  setIntervalErrorText,
                  setInterval,
                  interval[label].id,
                  label,
                  event.target.value,
                )
              }
              value={interval[label].interval}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.refreshCap}
              error={!!refreshCapErrorText[label]}
              fullWidth
              helperText={refreshCapErrorText[label]}
              disabled={site === 'thedailybeast' && !selectedVertical?.name}
              label="Refresh Cap"
              onChange={event =>
                handleNumberInput(
                  1,
                  'refreshCap',
                  setRefreshCapErrorText,
                  setRefreshCap,
                  refreshCap[label].id,
                  label,
                  event.target.value,
                )
              }
              value={refreshCap[label].refreshCap}
            />
          </div>
        </div>
      ))}
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
      />
    </div>
  );
}
