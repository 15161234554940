query StorySuggestions(
  $query: String
  $types: [StoryType] = [ARTICLE, CHEAT, GALLERY]
) {
  suggestions(query: $query, types: $types, first: 5) {
    edges {
      node {
        ... on Article {
          ...StoryContent
        }

        ... on Cheat {
          ...StoryContent
        }

        ... on Gallery {
          ...StoryContent
        }
      }
    }
  }
}

fragment StoryContent on Story {
  id
  longHeadline: long_headline
  slug
}