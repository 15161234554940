import React from 'react';
import ReactDOM from 'react-dom';

const atomRenderer = (component, actions) => ({ env, payload, value }) => {
  const targetNode = document.createElement('span');
  const { onTeardown } = env;
  const props = {
    ...env,
    payload,
    actions,
    value,
  };

  const element = React.createElement(component, props);
  ReactDOM.render(element, targetNode);

  onTeardown(() => ReactDOM.unmountComponentAtNode(targetNode));

  return targetNode;
};

export default function (component, name, actions) {
  return {
    name,
    type: 'dom',
    render: atomRenderer(component, actions),
  };
}
