import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import { Transformation } from 'cloudinary-react';
import { Grid, Divider } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import Alert from '@material-ui/lab/Alert';
import { colors } from '../../../../../styles/global/variables';
import CloudinaryImage from '../../../../../common/CloudinaryImage/CloudinaryImage';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { StoryContext } from '../../../../../context/StoryContext';
import ARTICLE_SUBTYPE from '../../../../../utils/article-subtype';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import { MediaContext } from '../../../../../context/MediaContext/MediaContext';
import ImageModal from '../ImageModal/ImageModal';
import { createCloudinaryUploadWidgetCallback } from '../../../../../helpers/imageHelper';

PromoImagePicker.propTypes = {
  addConversionCardImage: PropTypes.func.isRequired,
  deleteConversionCardImage: PropTypes.func.isRequired,
  image: PropTypes.shape({
    altText: PropTypes.string,
    id: PropTypes.number,
    mainImage: PropTypes.bool,
    publicId: PropTypes.string,
    url: PropTypes.string,
    version: PropTypes.string,
  }),
  imageError: PropTypes.string,
  imageValidationError: PropTypes.object,
};
const MIN_IMAGE_WIDTH = 3000;

function PromoImagePicker({
  image,
  imageError,
  imageValidationError,
  addConversionCardImage,
  deleteConversionCardImage,
}) {
  const { imageModalOpen, updateImageModalOpen, updateCurrentImageId } =
    useContext(MediaContext);

  const { id, subtype } = useContext(StoryContext);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(imageError);

  const className = classnames('ConversionCard', {
    'ConversionCardImage--with-error': errorMessage,
  });

  const onEditImage = () => {
    updateCurrentImageId(image.id);
    updateImageModalOpen(true);
  };

  const onDeleteImage = () => {
    const { id: imageId } = image;
    deleteConversionCardImage({
      variables: {
        id: imageId,
      },
    });
    setDeleteDialogOpen(false);
  };

  const openImageUploadDiaglog = () => {
    const minImageWidth =
      subtype === ARTICLE_SUBTYPE.FEATURE ? MIN_IMAGE_WIDTH : 0;

    const options = {
      client_allowed_formats: ['png', 'jpg', 'gif', 'jpeg'],
      image_metadata: true,
      max_file_size: '20000000',
      min_image_width: minImageWidth,
      multiple: false,
      show_powered_by: false,
      sources: ['local'],
      theme: 'minimal',
      upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
    };

    const widget = global.cloudinary.createUploadWidget(
      options,
      createCloudinaryUploadWidgetCallback({
        onError: ({ message }) => setErrorMessage(message),
        onSuccess: ({ validatedImage }) => {
          addConversionCardImage({
            variables: {
              articleId: id,
              image: validatedImage,
              isMainImage: false,
            },
          });
          setErrorMessage('');
        },
      })
    );
    widget.open();
  };

  let content = null;

  if (image) {
    const { publicId, altText } = image;
    content = (
      <CloudinaryImage publicId={publicId} alt={altText}>
        <Transformation width='540' height='304' crop='limit' />
      </CloudinaryImage>
    );
  } else {
    content = (
      <BackupIcon
        color='disabled'
        style={{
          height: '200px',
          opacity: 0.25,
          padding: '30px',
          width: '200px',
        }}
      />
    );
  }

  let errorNode = null;
  if (imageValidationError || errorMessage) {
    errorNode = (
      <Alert severity='error'>{imageValidationError || errorMessage}</Alert>
    );
  }

  const renderLeftButton = () => {
    let leftButtonClick;
    let leftButtonLabel;
    let leftButtonColor;
    if (image) {
      leftButtonClick = onEditImage;
      leftButtonLabel = 'Edit';
      leftButtonColor = 'default';
    } else {
      leftButtonClick = openImageUploadDiaglog;
      leftButtonLabel = 'Upload Image';
    }

    return (
      <Button
        fullWidth
        className='btn'
        label={leftButtonLabel}
        onClick={leftButtonClick}
        style={{ flex: 1 }}
        color={leftButtonColor}
      />
    );
  };

  const renderDeleteDialog = () => (
    <DeleteDialog
      open={deleteDialogOpen}
      text='Are you sure you want to delete this image?'
      onDelete={onDeleteImage}
      onClose={() => setDeleteDialogOpen(false)}
    />
  );
  return (
    <>
      <div className={className}>
        <div>
          <Grid
            style={{
              alignItems: 'center',
              border: image ? 'none' : `1px solid ${colors.lighterGrey}`,
              height: '300px',
              marginBottom: '20px',
            }}
            container
            justify='center'
          >
            <Grid item>{content}</Grid>
          </Grid>
        </div>

        {errorNode}

        <Grid
          style={{
            marginBottom: '20px',
            marginTop: '10px',
          }}
          container
          spacing={3}
        >
          <Grid item style={{ flex: 1 }}>
            {renderLeftButton()}
          </Grid>
          <Grid item style={{ flex: 1 }}>
            {image && (
              <Button
                fullWidth
                className='btn'
                style={{ flex: 1 }}
                label='Remove'
                color='secondary'
                onClick={() => setDeleteDialogOpen(true)}
              />
            )}
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '5px' }} />
      </div>
      {imageModalOpen && <ImageModal inConversionCard />}
      {renderDeleteDialog()}
    </>
  );
}

export default PromoImagePicker;
