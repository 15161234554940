query authorBySlug(
  $slug: String!
) {
  authorData: author(slug: $slug) {
  	id
	name
    email
    type
    slug
    bio
    mobiledocBio: mobiledoc_bio
    twitter
    facebook
    image
    jobTitle: job_title
    rights
  }
}