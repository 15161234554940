import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ToolbarGroup from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Button from '../../common/ButtonWrapper/ButtonWrapper';
import Navbar from '../../common/Navbar/Navbar';
import SavingSnackbar, {
  SAVE_STATE,
} from '../../common/SavingSnackbar/SavingSnackbar';
import { useHandleNavigateHome } from '../../hooks/useHandleNavigateHome';
import useNavState from './customHooks/useNavState';
import useSaveNav from './customHooks/useSaveNav';

const MAX_CHARACTER_COUNT = 95;

function removeBlankNavItems(navItems) {
  return navItems.filter(item => item.name);
}

const blankNavItems = startId => _.range(11, 1).map(i => ({ id: startId + i, name: '', path: ''}));

function fillBlankNavItems(navItems) {
  const maxId = _.max(navItems.map(i => _.toNumber(i.id)));
  const newArray = [...navItems, ...blankNavItems(maxId)].slice(0, 11);

  return newArray;
}

function removeIdProperty(navItems) {
  return navItems.map(({ name, path }) => ({ name, path }));
}

DynamicNavigationTool.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme => {
  return {
    container: {
      width: 600,
      padding: theme.spacing(3),
    },
    listItem: {
      display: 'grid',
      gap: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      gridTemplateAreas: `"number name"
        "number path"`,
      gridTemplateColumns: 'max-content 1fr',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    listItemNumber: {
      gridArea: 'number',
    },
    listItemName: {
      gridArea: 'name',
    },
    listItemPath: {
      gridArea: 'path',
    },
    listItemPathInputAdornment: {
      color: theme.palette.grey[600],
      fontSize: 11,
    },
    loading: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: theme.spacing(6),
    },
    main: {
      backgroundColor: theme.palette.grey['100'],
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: `${theme.spacing(9)}px ${theme.spacing(2)}px`,
    },
    title: { ...theme.typography.body1 },
  };
});

export default function DynamicNavigationTool({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  const handleNavigateHome = useHandleNavigateHome();
  const [saveState, setSaveState] = useState(null);
  const [navItems, setNavItems] = useNavState("1");

  useEffect(() => {
    if (navItems.length < 11) {
      setNavItems(fillBlankNavItems(navItems));
    }
  }, [navItems]);

  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  const characterCount = useMemo(() => {
    return !navItems
      ? 0
      : navItems.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.name.trim().length;
        }, 0);
  }, [navItems]);

  const saveAllowed = useMemo(() => {
    return (
      characterCount <= MAX_CHARACTER_COUNT &&
      saveState !== SAVE_STATE.SAVING &&
      !!navItems &&
      navItems.every(
        curVal =>
          (!curVal.name && !curVal.path) || (curVal.name && curVal.path),
      )
    );
  }, [characterCount, navItems, saveState]);

  const [updateNavItems] = useSaveNav();

  const handleSaveClick = useCallback(() => {
    setSaveState(SAVE_STATE.SAVING);
    updateNavItems({
      variables: {
        siteId: "1",
        navInputs: removeIdProperty(removeBlankNavItems(navItems)),
      },
    })
      .then(result => {
        if (result) {
          setSaveState(SAVE_STATE.SUCCESS);
          setNavItems(fillBlankNavItems(result.data.updateNavItems));
        }
      })
      .catch(error => {
        global.apm.captureError(error);
        setSaveState(SAVE_STATE.ERROR);
        setNavItems(navItems);
      });
  }, [navItems]);

  const handleInputChange = useCallback(
    (index, property) => event => {
      event.persist();
      setNavItems(oldData => {
        const newData = [...oldData];
        newData[index][property] = event.target.value;
        return newData;
      });
      setSaveState();
    },
    [],
  );

  return (
    <>
      <nav>
        <Navbar
          title="Dynamic Navigation Tool"
          handleNavigateHome={handleNavigateHome}
        >
          <ToolbarGroup>
            <Button
              disabled={!saveAllowed}
              label="Save"
              onClick={handleSaveClick}
            />
          </ToolbarGroup>
        </Navbar>
      </nav>
      <main className={classes.main}>
        <Paper className={classes.container}>
          <Typography
            align="right"
            className={classes.title}
            color={characterCount > MAX_CHARACTER_COUNT ? 'error' : 'initial'}
          >
            Total Character Count: {characterCount}/{MAX_CHARACTER_COUNT}
          </Typography>
          {!navItems ? (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          ) : (
            <List component="ol">
              {navItems.map((listItem, i) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    divider={i !== 10}
                    key={listItem.id}
                  >
                    <Box className={classes.listItemNumber}>
                      {i + 1}.
                    </Box>
                    <TextField
                      className={classes.listItemName}
                      disabled={saveState === SAVE_STATE.SAVING}
                      error={!!listItem.path && !listItem.name}
                      helperText={
                        listItem.path &&
                        !listItem.name &&
                        "The 'Name' field cannot be empty if the 'Path' field is not empty."
                      }
                      label="Name"
                      onChange={handleInputChange(i, 'name')}
                      value={listItem.name}
                      variant="outlined"
                    />
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            className={classes.listItemPathInputAdornment}
                            disablePointerEvents
                            disableTypography
                            position="start"
                          >
                            {process.env.WWW_DOMAIN}
                          </InputAdornment>
                        ),
                      }}
                      className={classes.listItemPath}
                      disabled={saveState === SAVE_STATE.SAVING}
                      error={!!listItem.name && !listItem.path}
                      helperText={
                        listItem.name &&
                        !listItem.path &&
                        "The 'Path' field cannot be empty if the 'Name' field is not empty."
                      }
                      label="Path"
                      onChange={handleInputChange(i, 'path')}
                      value={listItem.path}
                      variant="outlined"
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </Paper>
        <SavingSnackbar saveState={saveState} />
      </main>
    </>
  );
}
