mutation CreateConversionCardMutation(
	$conversionCard: ConversionCardInput!
	$partnerButtons: [PartnerButtonInput]!
) {
	conversionCardData: createConversionCard(
		conversionCard: $conversionCard
		partnerButtons: $partnerButtons
	) {
		ConversionCard {
			id
			expanded
			image {
				mobiledocCaption: mobiledoc_caption
				crops
				publicId: public_id
				version
				id
			}
			productCategory: product_category {
				categoryName: category_name
				id
			}
			promoItemName: promo_item_name
			mobiledocSummary: mobiledoc_summary
			specialInfo: special_info
			partnerButtons: partner_buttons {
				id
				freeReturns: free_returns
				freeShipping: free_shipping
				isPrimary: is_primary
				partnerName: partner_name
				partnerUrl: partner_url
				callToAction: call_to_action
				price
			}
		}
		Validation {
			actionType
			Errors {
        isPrimary
				message
				fieldName
			}
		}
	}
}
