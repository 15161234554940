{
  screamer {
    id
    active
    articleId: article_id
    articleSlug: article_slug
    articleHeadline: article_headline
    articleShortHeadline: article_short_headline
    articlePubDate: article_pub_date
    bannerHeadline: banner_headline
  }
}
