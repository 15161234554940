import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import _ from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';

import { StoryContext } from '../StoryContext';
import mediaQueryGQL from './graphql/mediaQuery.gql';
import addMainImageMutationGQL from './graphql/addMainImageMutation.gql';
import deleteImageMutationGQL from './graphql/deleteImageMutation.gql';
import addVideoMutationGQL from './graphql/addVideoMutation.gql';
import deleteVideoAndImageMutationGQL from './graphql/deleteVideoAndImageMutation.gql';
import addImageMutationGQL from './graphql/addImageMutation.gql';
import getGraphqlErrorMessage from '../../helpers/graphqlError';

const mediaQuery = gql`
  ${mediaQueryGQL}
`;

const addMainImageMutation = gql`
  ${addMainImageMutationGQL}
`;

const addImageMutation = gql`
  ${addImageMutationGQL}
`;

const deleteImageMutation = gql`
  ${deleteImageMutationGQL}
`;

const addVideoMutation = gql`
  ${addVideoMutationGQL}
`;

const deleteVideoAndImageMutation = gql`
  ${deleteVideoAndImageMutationGQL}
`;

export const MediaContext = React.createContext();

const DEFAULT_STATE = {
  storyHero: {
    imageId: null,
    videoId: null,
    errorMessage: null,
  },
  imageIds: [],
  currentImageId: null,
  imageModalOpen: false,
  videoModalOpen: false,
};

const UPDATE_IMAGE_MEDIA = 'UPDATE_IMAGE_MEDIA';
const UPDATE_VIDEO_MEDIA = 'UPDATE_VIDEO_MEDIA';
const UPDATE_HERO_IMAGE = 'UPDATE_HERO_IMAGE';
const UPDATE_HERO_VIDEO = 'UPDATE_HERO_VIDEO';
const UPDATE_HERO_VIDEO_AND_IMAGE = 'UPDATE_HERO_VIDEO_AND_IMAGE';
const UPDATE_IMAGE_IDS = 'UPDATE_IMAGE_IDS';
const UPDATE_CURRENT_IMAGE_ID = 'UPDATE_CURRENT_IMAGE_ID';
const UPDATE_IMAGE_MODAL_OPEN = 'UPDATE_IMAGE_MODAL_OPEN';
const UPDATE_VIDEO_MODAL_OPEN = 'UPDATE_VIDEO_MODAL_OPEN';
const UPDATE_STORY_HERO_ERROR = 'UPDATE_STORY_HERO_ERROR';

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_IMAGE_MEDIA:
      return {
        ...state,
        storyHero: {
          imageId: action.data.imageId,
          videoId: state.storyHero.videoId,
        },
        imageIds: action.data.imageIds,
      };
    case UPDATE_VIDEO_MEDIA:
      return {
        ...state,
        storyHero: {
          imageId: action.data.imageId,
          videoId: action.data.videoId,
        },
        imageIds: action.data.imageIds,
      };
    case UPDATE_HERO_IMAGE:
      return {
        ...state,
        storyHero: {
          imageId: action.data,
          videoId: state.storyHero.videoId,
          errorMessage: state.storyHero.errorMessage,
        },
      };
    case UPDATE_HERO_VIDEO:
      return {
        ...state,
        storyHero: {
          imageId: state.storyHero.imageId,
          videoId: action.data,
          errorMessage: null,
        },
      };
    case UPDATE_HERO_VIDEO_AND_IMAGE:
      return {
        ...state,
        storyHero: {
          imageId: action.data.imageId,
          videoId: action.data.videoId,
          errorMessage: null,
        },
      };
    case UPDATE_IMAGE_IDS:
      return {
        ...state,
        imageIds: action.data,
      };
    case UPDATE_CURRENT_IMAGE_ID:
      return {
        ...state,
        currentImageId: action.data,
      };
    case UPDATE_IMAGE_MODAL_OPEN:
      return {
        ...state,
        imageModalOpen: action.data,
      };
    case UPDATE_VIDEO_MODAL_OPEN:
      return {
        ...state,
        videoModalOpen: action.data,
      };
    case UPDATE_STORY_HERO_ERROR:
      return {
        ...state,
        storyHero: {
          imageId: state.storyHero.imageId,
          videoId: state.storyHero.videoId,
          errorMessage: action.data,
        },
      };
    default:
      throw new Error('Error updating image context');
  }
}

function MediaContextProvider({ children }) {
  const [state, updateState] = useReducer(reducer, DEFAULT_STATE);
  const { id } = useContext(StoryContext);

  const [loadMedia] = useLazyQuery(mediaQuery, {
    onError: (err) => {
      console.log('MediaContext:loadMedia:err', err);
      const error = getGraphqlErrorMessage(err);
      updateState({ type: UPDATE_STORY_HERO_ERROR, data: error });
    },
    onCompleted: (data) => {
      console.log('MediaContext:loadMedia:data', data);
      const imageId =
        data &&
        data.story &&
        data.story.media &&
        data.story.media.image &&
        data.story.media.image.isMainImage &&
        data.story.media.image.id;

      const videoId =
        data &&
        data.story &&
        data.story.media &&
        data.story.media.video &&
        data.story.media.video.id;

      const imageIdsData = data && data.story && data.story.images;
      const imageIds = [];

      if (imageIdsData.length > 0) {
        imageIdsData.forEach((image) => {
          if (imageId !== image.id && !image.isMainImage) {
            imageIds.push(image.id);
          }
        });
      }

      if (videoId && imageId) {
        updateState({
          type: UPDATE_VIDEO_MEDIA,
          data: { imageId, videoId, imageIds },
        });
      } else if (imageId) {
        updateState({ type: UPDATE_IMAGE_MEDIA, data: { imageId, imageIds } });
      } else {
        updateState({ type: UPDATE_IMAGE_IDS, data: imageIds });
      }
    },
  });

  /*
   * Variables: articleId!, isMainImage, image!
   */
  const [addMainImage] = useMutation(addMainImageMutation, {
    onError: (err) => {
      console.log('MediaContext:addMainImage:err', err);
      const errorMessage = getGraphqlErrorMessage(err);
      updateState({ type: UPDATE_STORY_HERO_ERROR, data: errorMessage });
    },
    onCompleted: (data) => {
      console.log('MediaContext:addMainImage:data', data);
      updateState({ type: UPDATE_HERO_IMAGE, data: data.image.id });
    },
  });

  /*
   * Variables: articleId!, image!
   */
  const [addImage] = useMutation(addImageMutation, {
    onError: (err) => {
      console.log('MediaContext:addImage:err', err);
    },
    onCompleted: (data) => {
      console.log('MediaContext:addImage:data', data);

      const imageIdData = data && data.image && data.image.id;
      if (imageIdData) {
        const newImageIds = _.cloneDeep(state.imageIds);
        newImageIds.push(imageIdData);

        updateState({ type: UPDATE_IMAGE_IDS, data: newImageIds });
      }
    },
  });

  /*
   * Variables: articleId!, video!
   */
  const [addVideo] = useMutation(addVideoMutation, {
    onError: (err) => {
      console.log('MediaContext:addVideo:err', err);
      const errorMessage = getGraphqlErrorMessage(err);
      updateState({ type: UPDATE_STORY_HERO_ERROR, data: errorMessage });
    },
    onCompleted: (data) => {
      console.log('MediaContext:addVideo:data', data);

      updateState({ type: UPDATE_HERO_VIDEO, data: data.video.id });
    },
  });

  /*
   * Variables: id!
   */
  const [deleteHeroImage] = useMutation(deleteImageMutation, {
    onError: (err) => {
      console.log('MediaContext:deleteHeroImage:err', err);
      const errorMessage = getGraphqlErrorMessage(err);
      updateState({ type: UPDATE_STORY_HERO_ERROR, data: errorMessage });
    },
    onCompleted: (data) => {
      console.log('MediaContext:deleteHeroImage:data', data);

      updateState({ type: UPDATE_HERO_IMAGE, data: null });
    },
  });

  /*
   * Variables: id!
   */
  const [deleteImage] = useMutation(deleteImageMutation, {
    onError: (err) => {
      console.log('MediaContext:deleteImage:err', err);
    },
    onCompleted: (data) => {
      console.log('MediaContext:deleteImage:data', data);

      const imageIdData = data && data.image && data.image.id;
      if (imageIdData) {
        const newImageIds = state.imageIds.filter(
          (imageId) => imageId !== imageIdData
        );

        updateState({ type: UPDATE_IMAGE_IDS, data: newImageIds });
      }
    },
  });

  /*
   * Variables: imageId!, videoId!
   */
  const [deleteVideoAndImage] = useMutation(deleteVideoAndImageMutation, {
    onError: (err) => {
      console.log('MediaContext:deleteVideoAndImage:err', err);
      const errorMessage = getGraphqlErrorMessage(err);
      updateState({ type: UPDATE_STORY_HERO_ERROR, data: errorMessage });
    },
    onCompleted: (data) => {
      console.log('MediaContext:deleteVideoAndImage:data', data);

      updateState({
        type: UPDATE_HERO_VIDEO_AND_IMAGE,
        data: { imageId: null, videoId: null },
      });
    },
  });

  useEffect(() => {
    if (id) {
      loadMedia({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  const updateCurrentImageId = (imageId) => {
    updateState({ type: UPDATE_CURRENT_IMAGE_ID, data: imageId });
  };

  const updateImageModalOpen = (isImageModalOpen) => {
    updateState({ type: UPDATE_IMAGE_MODAL_OPEN, data: isImageModalOpen });
  };

  const updateVideoModalOpen = (isVideoModalOpen) => {
    updateState({ type: UPDATE_VIDEO_MODAL_OPEN, data: isVideoModalOpen });
  };

  const updateStoryHeroError = (errorMessage) => {
    updateState({ type: UPDATE_STORY_HERO_ERROR, data: errorMessage });
  };

  const value = {
    ...state,
    addMainImage,
    addImage,
    addVideo,
    deleteHeroImage,
    deleteImage,
    deleteVideoAndImage,
    updateCurrentImageId,
    updateImageModalOpen,
    updateVideoModalOpen,
    updateStoryHeroError,
  };

  return (
    <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
  );
}

MediaContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MediaContextProvider;
