query allStories(
	$beforeDate: String
	$after: String
	$types: [StoryType] = [ARTICLE, GALLERY, CHEAT]
	$status: [StatusType] = [SAVED, UNSAVED, PUBLISHED]
) {
	stories: storiesExtended(
		beforeDate: $beforeDate
		siteId: 2,
		after: $after
		types: $types
		first: 30
		orderBy: { direction: DESC, field: UPDATED_DATE }
		status: $status
	) {
		edges {
			cursor
			node {
				... on Article {
					type
					...StoryResult
				}

				... on Cheat {
					type
					...StoryResult
				}

				... on Gallery {
					type
					...StoryResult
				}
			}
		}
	}
}

fragment StoryResult on Story {
	longHeadline: long_headline
	updatedAt: updated_at
	site {
	  id
	  name
	}
	obsessedTags: obsessed_tags {
	  id
	  type
	  name
	  slug
	}
	mainImage: main_image {
	  id
	  title
	  credit
	  url
    crops 
    altText: alt_text
    publicId: public_id
    version
	}
	slug
	Authors: authors {
		name
	}
	Metadata: metadata {
		sponsored
		branded
	}
}
