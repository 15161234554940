 import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default function SvgSymbol({ name, className }) {
  return (
    <svg className={className}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
}
SvgSymbol.propTypes = propTypes;

export { SVG_SYMBOLS } from '../SvgSprite/SvgSprite';
