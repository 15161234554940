import PropTypes from 'prop-types';
import React from 'react';
import Select from '../../../../../../common/Select/Select';
import { UPDATE_NEWSLETTER_SELECTED } from '../EmbedPicker';

export const NEWSLETTER_TYPE = {
  SCOUTED: 'Scouted',
  OBSESSED: 'Obsessed',
  RIGHT_RICHTER: 'Right Richter',
  TRAVEL: 'Travel Digest',
  CONFIDER: 'Confider',
  ROYALIST: 'Royalist',
  SEESKIP: 'See/Skip',
};

export const NEWSLETTER_LIST = [
  { id: 1, name: NEWSLETTER_TYPE.SCOUTED },
  { id: 2, name: NEWSLETTER_TYPE.OBSESSED },
  { id: 3, name: NEWSLETTER_TYPE.RIGHT_RICHTER },
  { id: 4, name: NEWSLETTER_TYPE.TRAVEL },
  { id: 5, name: NEWSLETTER_TYPE.CONFIDER },
  { id: 6, name: NEWSLETTER_TYPE.ROYALIST },
  { id: 7, name: NEWSLETTER_TYPE.SEESKIP },
];

export default function NewsletterPickerForm(props) {
  const { selectedNewsletterType, onNewsletterTypeChange } = props;

  let newsletterTypeId = 1;
  const selectedNewsletter = NEWSLETTER_LIST.find(
    type => type.name === selectedNewsletterType,
  );
  if (selectedNewsletter) {
    newsletterTypeId = selectedNewsletter.id;
  }

  return (
    <div>
      <Select
        label="Type"
        data={[...NEWSLETTER_LIST]}
        keyToUpdate={UPDATE_NEWSLETTER_SELECTED}
        updateFieldAction={onNewsletterTypeChange}
        selectedId={newsletterTypeId}
      />
    </div>
  );
}

NewsletterPickerForm.propTypes = {
  selectedNewsletterType: PropTypes.string,
  onNewsletterTypeChange: PropTypes.func,
};
