import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { colors } from '../../../styles/global/variables';

export default class ArticleActiveUsers extends Component {
  static propTypes = {
    activeUsers: PropTypes.array,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.rootEl = null;

    this.state = {
      open: false,
    };

    this.renderActiveUsers = this.renderActiveUsers.bind(this);
    this.toggleActiveUsersList = this.toggleActiveUsersList.bind(this);
  }

  toggleActiveUsersList(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    this.setState({ open: !this.state.open });
  }

  renderActiveUsers() {
    return this.props.activeUsers.map(userName => (
      <ListItem>
        <ListItemText primary={userName.info.name} />
      </ListItem>
    ));
  }

  render() {
    const { activeUsers } = this.props;

    const { open } = this.state;

    let articleActiveUsers = null;

    if (activeUsers && activeUsers.length > 0) {
      articleActiveUsers = (
        <div
          ref={rootEl => {
            /* istanbul ignore next */
            this.rootEl = rootEl;
          }}
        >
          <div
            style={{
              width: '15px',
              height: '15px',
              margin: '0 10px',
              background: colors.beastRed,
              boxShadow: `0 0 3px 2px ${colors.beastRed}`,
              borderRadius: '50%',
              cursor: 'pointer',
              animation: 'article-active-users-indicator-pulse 1s infinite',
              transition: 'transform 0.3s',
            }}
            onClick={this.toggleActiveUsersList}
          />
          <Popover
            open={open}
            anchorEl={this.rootEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            onClose={this.toggleActiveUsersList}
          >
            <List aria-label="publishing-toolbar">
              <ListItem button>
                <ListSubheader>Currently being edited by:</ListSubheader>
              </ListItem>

              {this.renderActiveUsers()}
            </List>
          </Popover>
        </div>
      );
    }

    return articleActiveUsers;
  }
}
