export default function isValidUrl(str) {
  try {
    const url = new URL(str);
    const hostnameParts = url.hostname.split('.');

    const hasTLD =
      hostnameParts.length > 1 &&
      Boolean(hostnameParts[hostnameParts.length - 1]);

    const isHttp = url.protocol === 'http:';
    const isHttps = url.protocol === 'https:';

    return (isHttp || isHttps) && hasTLD;
  } catch (err) {
    return false;
  }
}
