import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '../ButtonWrapper/ButtonWrapper';
import { KEY } from '../InputDialog/InputDialog';

export default class ConfirmDialog extends Component {
  static propTypes = {
    body: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    inFlight: PropTypes.bool,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      global.document.addEventListener('keydown', this.handleKeyDown);
    } else {
      global.document.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  static defaultPropTypes = {
    open: false,
  };

  handleKeyDown(event) {
    switch (event.keyCode) {
      case KEY.ENTER:
        event.preventDefault();
        this.props.onConfirm();
        break;
      case KEY.ESCAPE:
        this.props.onClose();
        break;
      default:
        break;
    }
    return false;
  }

  render() {
    const confirmDialogActionButtons = [
      <Button
        key={1}
        label="Yes"
        onClick={this.props.onConfirm}
        disabled={this.props.inFlight}
      />,
      <Button key={2} label="No" onClick={this.props.onClose} />,
    ];

    return (
      <Dialog maxWidth="sm" modal={false} open={this.props.open}>
        <DialogTitle id="modal-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>{this.props.body}</DialogContent>
        <DialogActions>{confirmDialogActionButtons}</DialogActions>
      </Dialog>
    );
  }
}
