query SelectedVerticalQuery ($versionId: String!) {
  story: storyById(versionId: $versionId) {
    vertical {
      id,
      name,
      slug
      subverticals {
        id
        name
        slug
      }
    }
    subvertical {
      id,
      name,
      slug
    }
  }
}