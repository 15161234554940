import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import SelectField from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import fetch from '../../helpers/pt-fetch';

const styles = {
  inputLabel: {
    'font-size': '12px',
  },
};

class Select extends Component {
  static propTypes = {
    label: PropTypes.string,
    data: PropTypes.array,
    dataUrl: PropTypes.string,
    classes: PropTypes.object.isRequired,
    updateFieldAction: PropTypes.func.isRequired,
    keyToUpdate: PropTypes.string,
    selectedId: PropTypes.number,
    selectedFirst: PropTypes.bool,
    saveIdToStore: PropTypes.bool,
    onChange: PropTypes.func,
    nullable: PropTypes.bool,
    adjustLeftPosition: PropTypes.bool,
    errorText: PropTypes.string,
    dismissErrorsAction: PropTypes.func,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    let data = [];
    if (this.props.data) {
      data = this.props.data;
      if (this.props.nullable) {
        data.unshift({ id: null, name: '' });
      }
    }

    this.state = {
      selectedId: this.props.selectedId,
      data,
    };

    this.renderItems = this.renderItems.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    if (this.props.dataUrl) {
      this.fetchData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data });
    }
    if (nextProps.dataUrl !== this.props.dataUrl) {
      this.fetchData(nextProps.dataUrl);
    }
    if (nextProps.selectedId !== this.props.selectedId) {
      this.setState({ selectedId: nextProps.selectedId });
    }
  }

  onClick(event) {
    const selectWidth = event.currentTarget.offsetWidth;
    const selectOffsetLeft = event.currentTarget.offsetLeft;
    global.setTimeout(() => {
      const menuItem = global.document.querySelector('.Select__MenuItem');
      try {
        menuItem.parentElement.parentElement.parentElement.parentElement.style.width = `${selectWidth}px`;
        if (this.props.adjustLeftPosition) {
          menuItem.parentElement.parentElement.parentElement.parentElement.style.left = `${selectOffsetLeft}px`;
        }
      } catch (e) {
        // click on label, do nothing
      }
    }, 0);
  }

  fetchData(dataUrl = this.props.dataUrl) {
    return fetch(dataUrl, {
      headers: {
        'cache-control': 'no-cache',
      },
    })
      .then(res => res.json())
      .then(data => {
        if (this.props.nullable) {
          data.unshift({ id: null, name: '' });
        }
        let selectedId;
        if (this.props.selectedFirst && !this.props.selectedId) {
          selectedId = data[0].id;
        }

        this.setState({ data, selectedId });

        if (this.props.selectedFirst && selectedId) {
          // Update store with first in list ONLY IF no preselected value in props
          const value = this.props.saveIdToStore ? selectedId : null;
          const dataObject = this.state.data.find(
            item => item.id === selectedId,
          );
          this.props.updateFieldAction(
            this.props.keyToUpdate,
            value,
            dataObject,
          );
        }
      });
  }

  handleChange(event, selectComponent) {
    const {
      key,
      props: { value },
    } = selectComponent;

    this.setState({ selectedId: key, selectedName: value });

    const selectedKey = this.props.saveIdToStore ? key : null;
    const dataObject = this.state.data.find(
      item => item.id === event.target.value
    );

    this.props.updateFieldAction(
      this.props.keyToUpdate,
      selectedKey,
      dataObject,
    );

    if (this.props.onChange) {
      this.props.onChange(dataObject);
    }

    if (this.props.dismissErrorsAction && key) {
      this.props.dismissErrorsAction(this.props.keyToUpdate);
    }
  }

  renderItems() {
    return this.state.data.map((item, index) => {
      return (
        <MenuItem value={item.id} key={item.id}>
          {item.name}
        </MenuItem>
      );
    });
  }

  render() {
    const currentVertical = this.state.data.find(
      item => parseInt(item.id, 10) === parseInt(this.props.selectedId, 10),
    );

    const hintText = this.props.label
      ? `Click for ${this.props.label}`
      : undefined;

    return (
      <>
        <InputLabel className={this.props.classes.inputLabel} id="label">
          {this.props.label}
        </InputLabel>
        <SelectField
          id="select-field"
          fullWidth
          onClick={this.onClick}
          value={
            (currentVertical && currentVertical.id) ||
            this.state.selectedName ||
            ''
          }
          error={this.props.errorText}
          onChange={this.handleChange}
        >
          {this.renderItems()}
        </SelectField>
        {this.props.errrorText && (
          <FormHelperText>{this.props.errorText}</FormHelperText>
        )}
      </>
    );
  }
}
export default withStyles(styles)(Select);
