mutation AddVerticalMutation($articleId: ID!, $verticalId: ID!) {
  crossPromotion: addVertical(articleId: $articleId, verticalId: $verticalId) {
    vertical {
      id
      name
      subverticals {
        id
        name
      }
    }
    subvertical {
      id
      name
    }
  }
}