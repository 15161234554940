.section {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-areas:
    'title'
    'subtitle'
    'slot1'
    'slot2'
    'slot3'
    'slot4';
  grid-template-columns: 1fr;
  margin: 0 auto;
  width: 100%;
}

.title {
  grid-area: title;
}

.subtitle {
  grid-area: subtitle;
}

@for $i from 1 through 4 {
  .slot#{$i} {
    @if $i > 1 {
      .image {
        display: none;
      }
    }
    grid-area: slot#{$i};
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
}

@media screen and (min-width: 600px) {
  .section {
    border-right: 0.1rem solid #d1d5d5;
    padding-right: 1.6rem;
  }
}

@media screen and (min-width: 960px) {
  .section {
    grid-template-areas:
      'title title title'
      'subtitle subtitle subtitle'
      'slot1 slot1 slot1'
      'slot2 slot3 slot4';
    grid-template-columns: repeat(3, 1fr);
  }

  .slot1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @for $i from 2 through 4 {
    .slot#{$i} {
      .image {
        display: block;
      }
    }
  }
}
