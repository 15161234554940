import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';

import { HomepageContext, SET_PRODUCT_CALLOUT } from './HomepageContext';
import updateMembershipProductCalloutMutation from '../../queries/homepage/updateMembershipProductCallout.gql';
import updateNewsletterProductCalloutMutation from '../../queries/homepage/updateNewsletterProductCallout.gql';

const UPDATE_MEMBERSHIP_PRODUCT_CALLOUT = gql`
  ${updateMembershipProductCalloutMutation}
`;
const UPDATE_NEWSLETTER_PRODUCT_CALLOUT = gql`
  ${updateNewsletterProductCalloutMutation}
`;

const useStyles = makeStyles(() => ({
  code: {
    background: '#e8d7c7',
    borderRadius: '2px',
    color: '#0284cf',
    padding: '2px',
  },
  grid: {
    display: 'grid',
    gridTemplate: "'radioGroup view' auto / 1fr 3fr",
  },
  view: {
    alignSelf: 'center',
    gridArea: 'view',
  },
}));

ProductCallout.propTypes = {
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  section: PropTypes.string.isRequired,
};

export default function ProductCallout({ className, section }) {
  const { dispatch, state } = useContext(HomepageContext);
  const classes = useStyles();
  const [updateMembershipProductCallout] = useMutation(
    UPDATE_MEMBERSHIP_PRODUCT_CALLOUT
  );
  const [updateNewsletterProductCallout] = useMutation(
    UPDATE_NEWSLETTER_PRODUCT_CALLOUT
  );

  const onCalloutSelection = useCallback(
    (event) => {
      const mutation =
        event.target.value === 'membership'
          ? updateMembershipProductCallout
          : updateNewsletterProductCallout;
      dispatch({
        payload: {
          error:
            event.target.value !== 'membership'
              ? `A newsletter selection needs to be made in ${state[section].title} section.`
              : '',
          lists: [],
          section,
          type: event.target.value,
        },
        type: SET_PRODUCT_CALLOUT,
      });
      mutation({
        variables: {
          sectionId: state?.[section]?.id,
        },
      });
    },
    [section, state]
  );
  const onNewsletterSelection = useCallback(
    (event) => {
      updateNewsletterProductCallout({
        variables: {
          lists: [event.target.value],
          sectionId: state?.[section]?.id,
          text: state?.[section]?.productCallout?.text,
        },
      }).then(({ data }) =>
        dispatch({
          payload: {
            error: '',
            lists: data.updateNewsletterCallout.productCallout.lists,
            section,
          },
          type: SET_PRODUCT_CALLOUT,
        })
      );
    },

    [section, state]
  );

  return (
    <Card className={className}>
      <CardContent className={classes.grid}>
        <FormControl component='fieldset' required>
          <FormLabel component='legend'>Product Callout</FormLabel>
          <RadioGroup
            aria-label='gender'
            name='productCallout'
            value={state[section].productCallout?.type || ''}
            onChange={onCalloutSelection}
          >
            <FormControlLabel
              value='membership'
              control={<Radio />}
              label='Membership'
            />
            <FormControlLabel
              value='newsletter'
              control={<Radio />}
              label='Newsletter'
            />
          </RadioGroup>
        </FormControl>
        {state[section].productCallout?.type === 'membership' ? (
          <div className={`${classes.view} ${classes.form}`} />
        ) : (
          <div className={`${classes.view} ${classes.form}`}>
            <FormControl
              error={!!state[section].productCallout?.error}
              fullWidth
              required
            >
              <InputLabel id='product-callout-1-newsletter-selection'>
                Newsletter
              </InputLabel>
              <Select
                labelId='product-callout-1-newsletter-selection'
                value={state[section].productCallout?.lists?.[0] || ''}
                onChange={onNewsletterSelection}
              >
                <MenuItem value='scouted'>Scouted</MenuItem>
                <MenuItem value='obsessed'>Obsessed</MenuItem>
                <MenuItem value='beast-digest'>Beast Digest</MenuItem>
                <MenuItem value='cheat-sheet'>Cheat Sheet</MenuItem>
                <MenuItem value='politics'>Politics</MenuItem>
                <MenuItem value='entertainment'>Entertainment</MenuItem>
                <MenuItem value='confider'>Confider</MenuItem>
                <MenuItem value='pay-dirt'>Pay Dirt</MenuItem>
                <MenuItem value='royalist'>Royalist</MenuItem>
                <MenuItem value='see-skip'>See Skip</MenuItem>
                <MenuItem value='trail-mix'>Trail Mix</MenuItem>
              </Select>
              <FormHelperText>
                {state[section].productCallout.error}
              </FormHelperText>
            </FormControl>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
