export const UPDATE_USER = 'publishing-toolkit/user/UPDATE_USER';
export const DELETE_USER = 'publishing-toolkit/user/DELETE_USER';

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function deleteUser() {
  return {
    type: DELETE_USER,
  };
}
