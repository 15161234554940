import React, { useState, useReducer, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import EmbedToolbar, {
  EMBED_TOOLBAR_BUTTONS,
} from '../../../../../common/EmbedToolbar/EmbedToolbar';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import { StoryContext } from '../../../../../context/StoryContext';
import MobiledocToolbar, {
  TOOLBAR_BUTTONS,
} from '../../../../../common/MobiledocToolbar/MobiledocToolbar';
import { drawerWidth } from '../../../../../common/Sidebar/Sidebar';
import { mobiledocStyles } from '../../../../../styles/global/variables';
import { updateHyperlinkMarkup } from '../../../../../helpers/mobiledoc-helpers';
import ImagePicker from '../ImagePicker/ImagePicker';
import QuotePicker from '../QuotePicker/QuotePicker';
import ConversionPicker from '../ConversionPicker/ConversionPicker';
import FancyLinksPicker from '../FancyLinksPicker/FancyLinksPicker';
import SectionBreakPicker from '../SectionBreakPicker/SectionBreakPicker';
import EmbedPicker from '../EmbedPicker/EmbedPicker';
import isValidUrl from '../../../../../utils/is-valid-url';
import addDefaultProtocol from '../../../../../utils/add-default-protocol';

// Embed related UI
import InputDialog, {
  DIALOG_TYPE,
} from '../../../../../common/InputDialog/InputDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: theme.mixins.toolbar,
    mobiledocToolbar: {
      flexGrow: 1,
      maxWidth: '100%',
    },
    mobiledocEditor: {
      ...mobiledocStyles,
      marginTop: '110px',
      '&:focus, textarea:focus': {
        outline: 'none' /** For Safari, etc * */,
      },
      margin: '0px auto',
      maxWidth: '600px',
      position: 'relative',
      '&.__mobiledoc-editor.__has-no-content:after': {
        content: 'attr(data-placeholder)',
        color: '#afafae',
        cursor: 'text',
        position: 'absolute',
        top: 0,
      },
    },
    mobiledocWrapperWithDrawer: {
      'padding-right': `${drawerWidth}px`,
    },
  }),
);

function linkReducer(state, action) {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return { dialogOpen: true, href: action.href, error: false };
    case 'CLOSE_DIALOG':
      return { dialogOpen: false, error: false };
    case 'UPDATE_HREF':
      return { ...state, href: action.value };
    case 'UPDATE_ERROR':
      return { ...state, error: true };
    default:
      throw new Error('Unknown action passed to linkReducer');
  }
}

function Body() {
  const classes = useStyles();
  const [selection, setSelection] = useState(null);
  const [linkState, dispatch] = useReducer(linkReducer, {
    dialogOpen: false,
    href: null,
    error: false,
  });

  const {
    editor,
    wrapper,
    loadMobiledoc,
    imagePickerOpen,
    quotePickerOpen,
    conversionPickerOpen,
    updateConversionPickerOpen,
    updateImagePickerOpen,
    fancyLinksPickerOpen,
    sectionBreakPickerOpen,
    embedPickerOpen,
  } = useContext(EditorContext);
  const { id } = useContext(StoryContext);

  useEffect(() => {
    if (id) {
      loadMobiledoc({ variables: { versionId: id } });
    }
  }, [id]);

  // control the hyperlink dialog
  const onLinkClick = href => {
    setSelection(editor.range);
    dispatch({ type: 'OPEN_DIALOG', href });
  };

  const onBlur = () => {
    setSelection(editor.range);
  };

  const getAnchorText = () => {
    const anchorMarkups = editor.activeMarkups.filter(
      markup => markup.tagName === 'a',
    );

    let anchorHref = null;
    if (anchorMarkups.length === 1) {
      const currentAnchor = anchorMarkups[0];
      anchorHref = currentAnchor.attributes.href;
    }

    return anchorHref;
  };

  const mobiledocToolbarFormattingFeatures = [
    TOOLBAR_BUTTONS.BOLD,
    TOOLBAR_BUTTONS.ITALIC,
    TOOLBAR_BUTTONS.UNDERLINE,
    TOOLBAR_BUTTONS.LINE_THROUGH,
    TOOLBAR_BUTTONS.LINK,
    TOOLBAR_BUTTONS.UNORDERED_LIST,
    TOOLBAR_BUTTONS.ORDERED_LIST,
    TOOLBAR_BUTTONS.HEADINGS,
  ];

  const embedToolbarFormattingFeatures = [
    EMBED_TOOLBAR_BUTTONS.EMBED,
    EMBED_TOOLBAR_BUTTONS.FANCY_LINKS,
    EMBED_TOOLBAR_BUTTONS.IMAGE,
    EMBED_TOOLBAR_BUTTONS.SLIDESHOW,
    EMBED_TOOLBAR_BUTTONS.QUOTE,
    EMBED_TOOLBAR_BUTTONS.UNORDERED_LIST,
    EMBED_TOOLBAR_BUTTONS.ORDERED_LIST,
    EMBED_TOOLBAR_BUTTONS.SECTION_BREAK,
    EMBED_TOOLBAR_BUTTONS.CONVERSION,
  ];

  return (
    <>
      <div>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} className={classes.mobiledocToolbar}>
            <MobiledocToolbar
              withDraftBar
              inEditor
              orientation="horizontal"
              features={mobiledocToolbarFormattingFeatures}
              linkClickHandler={onLinkClick}
              editor={editor}
            />
            <Hidden xsDown>
              <EmbedToolbar features={embedToolbarFormattingFeatures} />
            </Hidden>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <div
              onBlur={onBlur}
              className={classes.mobiledocEditor}
              ref={wrapper}
            />
            <div style={{ marginBottom: '200px' }} />
          </Grid>
        </Grid>
      </div>
      {imagePickerOpen && (
        <ImagePicker onClose={() => updateImagePickerOpen(false)} />
      )}
      {quotePickerOpen && <QuotePicker selection={selection} />}
      {conversionPickerOpen && (
        <ConversionPicker
          onClose={() => updateConversionPickerOpen(false)}
          selection={selection}
        />
      )}
      {fancyLinksPickerOpen && <FancyLinksPicker selection={selection} />}
      {sectionBreakPickerOpen && <SectionBreakPicker selection={selection} />}
      {embedPickerOpen && <EmbedPicker selection={selection} />}
      {linkState.dialogOpen && (
        <InputDialog
          dialogType={DIALOG_TYPE.ADD}
          error={linkState.error}
          open
          title="Add Link"
          inputLabel="URL"
          inputPlaceholder="e.g. www.example.com"
          inputValue={getAnchorText()}
          onClose={() => {
            dispatch({ type: 'CLOSE_DIALOG' });
          }}
          onSave={() => {
            const linkHref = addDefaultProtocol(linkState.href);

            if (!isValidUrl(linkHref)) {
              dispatch({ type: 'UPDATE_ERROR' });
              return;
            }

            editor.selectRange(selection);

            editor.run(postEditor => {
              updateHyperlinkMarkup(editor, postEditor, linkHref);
            });

            dispatch({ type: 'CLOSE_DIALOG' });
          }}
          onInputChange={event => {
            dispatch({ type: 'UPDATE_HREF', value: event.target.value });
          }}
        />
      )}
    </>
  );
}

export default Body;
