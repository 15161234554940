import { useState } from 'react';
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag';

import AllObsessedTags from '../queries/allTags.gql';

const allTagsQuery = gql`${AllObsessedTags}`;

export default function useObsessedTags () {
  const [tags, setTags] = useState([]);

  const { loading, error, data, refetch, networkStatus } = 
    useQuery(allTagsQuery, {
      onCompleted: data => {
        console.log('set tags', data);
        setTags(data.obsessedTags);
      }
    });

  return {
    allTags:tags,
    loading,
    error,
    data,
    refetch,
    networkStatus,
  };
}
