import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import Snackbar from '@material-ui/core/Snackbar';
import categorizationData from '../../queries/categorizationData.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const THREAT_CONFIDENCE = {
  VERY_HIGH: 'Very High',
  HIGH: 'High',
  MODERATE: 'Moderate',
  LOW: 'Low',
  VERY_LOW: 'Very Low',
};

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      'margin-top': '10px',
      display: 'flex',
    },
    heading: {
      'margin-top': '30px',
      'margin-bottom': '10px',
      'font-weight': '600',
    },
    checkbox: {
      display: 'flex',
    },
    autoDataSection: {
      margin: '24px 0px 12px 0px',
    },
    autoDataContent: {
      'margin-left': '16px',
      'font-size': '0.875rem',
    },
    autoDataContentNone: {
      'margin-left': '16px',
      'font-style': 'italic',
      'font-size': '0.875rem',
    },
    item: {
      'padding-top': '0px',
      'padding-bottom': '0px',
      'list-style-type': 'circle',
    },
  }),
);

Categorization.propTypes = {
  renderHeader: PropTypes.bool,
  storyId: PropTypes.number,
};

const categorizationDataQuery = gql`
  ${categorizationData}
`;

export default function Categorization({ storyId, renderHeader }) {
  const classes = useStyles();

  const [isProcessed, setIsProcessed] = useState(false);
  const [autoKeywords, setAutoKeywords] = useState([]);
  const [autoIABCategories, setAutoIABCategories] = useState([]);
  const [autoThreats, setAutoThreats] = useState([]);
  const [autoBrandSafe, setAutoBrandSafe] = useState(null);
  const [autoSentiments, setAutoSentiments] = useState(null);
  const [message, setMessage] = useState(null);

  const [loadCategorizationData] = useLazyQuery(categorizationDataQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error loading categorization data.');
    },
    onCompleted: data => {
      const {
        storyData: {
          autoKeywords: autoKeywordsData,
          autoIABCategories: autoIABCategoriesData,
          autoBrandSafe: autoBrandSafeData,
          autoSentiments: autoSentimentsData,
          autoThreats: autoThreatsData,
        },
      } = data;

      const autoIABSet = new Set();
      autoIABCategoriesData.forEach(category => {
        autoIABSet.add(category.category);

        let currentCategory = category;
        let { parentIABCategory } = currentCategory;

        while (parentIABCategory) {
          currentCategory = parentIABCategory;
          parentIABCategory = currentCategory.parentIABCategory;

          autoIABSet.add(currentCategory.category);
        }
      });

      const brandSafe =
        autoBrandSafeData?.safe == null ? null : autoBrandSafeData?.safe;

      setAutoIABCategories(Array.from(autoIABSet));
      setAutoKeywords(autoKeywordsData.map(kw => kw.name));
      setAutoThreats(autoThreatsData);
      setAutoBrandSafe(brandSafe);
      setAutoSentiments(autoSentimentsData);

      if (brandSafe == null) {
        setIsProcessed(false);
      } else {
        setIsProcessed(true);
      }
    },
  });

  useEffect(() => {
    if (storyId) {
      loadCategorizationData({
        variables: {
          versionId: storyId,
        },
      });
    } else {
      setAutoKeywords([]);
      setAutoIABCategories([]);
      setAutoThreats([]);
      setAutoBrandSafe(null);
      setAutoSentiments(null);
    }
  }, [storyId]);

  const renderAutoThreats = () => {
    if (!autoThreats.length) {
      return (
        <Typography className={classes.autoDataContentNone}>
          None for this article
        </Typography>
      );
    }

    return (
      <List disablePadding>
        {autoThreats.map(threat => {
          return (
            <ListItem className={classes.item}>
              {`${threat.category}, ${THREAT_CONFIDENCE[threat.confidence]}`}
            </ListItem>
          );
        })}
      </List>
    );
  };

  if (!isProcessed) return null;

  return (
    <>
      {renderHeader && (
        <>
          <h3 className={classes.heading}>Automated Categorization Data</h3>
          <small>Can take up to several hours to populate</small>
        </>
      )}
      <h4 className={classes.autoDataSection}>IAB V2 Categories</h4>
      {autoIABCategories.length > 0 ? (
        <Typography className={classes.autoDataContent}>
          {autoIABCategories.join(', ')}
        </Typography>
      ) : (
        <Typography className={classes.autoDataContentNone}>
          None for this article
        </Typography>
      )}
      <h4 className={classes.autoDataSection}>Keywords</h4>
      {autoKeywords.length > 0 ? (
        <Typography className={classes.autoDataContent}>
          {autoKeywords.join(', ')}
        </Typography>
      ) : (
        <Typography className={classes.autoDataContentNone}>
          None for this article
        </Typography>
      )}
      <h4 className={classes.autoDataSection}>Brand Safety</h4>
      {autoBrandSafe !== null ? (
        <Typography className={classes.autoDataContent}>
          {autoBrandSafe ? 'Safe' : 'Unsafe'}
        </Typography>
      ) : (
        <Typography className={classes.autoDataContentNone}>
          None for this article
        </Typography>
      )}
      <h4 className={classes.autoDataSection}>Threats</h4>
      {renderAutoThreats()}
      <h4 className={classes.autoDataSection}>Sentiments</h4>
      {autoSentiments !== null ? (
        <List disablePadding>
          <ListItem className={classes.item}>
            {`positive: ${autoSentiments.positive}`}
          </ListItem>
          <ListItem className={classes.item}>
            {`neutral: ${autoSentiments.neutral}`}
          </ListItem>
          <ListItem className={classes.item}>
            {`negative: ${autoSentiments.negative}`}
          </ListItem>
        </List>
      ) : (
        <Typography className={classes.autoDataContentNone}>
          None for this article
        </Typography>
      )}
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
