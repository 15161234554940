import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import FileCopy from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import ClearIcon from '@material-ui/icons/Clear';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client'
import dateFormat from 'dateformat';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { errorMessageAction } from '../../redux/actions/messages';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import TypeIndicator from '../../common/TypeIndicator/TypeIndicator';
import Navbar from '../../common/Navbar/Navbar';
import allStories from '../../queries/allStories.gql';
import allStoriesSearch from '../../queries/allStoriesSearch.gql';
import { colors, fonts } from '../../styles/global/variables';
import { siteLink } from '../../helpers/site';

AllStories.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: theme.mixins.toolbar,
    slugLink: {
      fontFamily: fonts.helvetica,
      textDecoration: 'none',
      color: colors.darkGrey,
    },
    lastSavedAt: {
      fontFamily: fonts.helvetica,
      color: colors.darkGrey,
    },
    fileCopy: {
      color: colors.darkGrey,
      cursor: 'pointer',
    },
    searchField: {
      width: '100%',
      marginTop: '16px',
    },
    dateField: {
      'min-width': '140px',
    },
    searchFieldGroup: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    pagingGroup: {
      paddingBottom: '50px',
      display: 'flex',
      justifyContent: 'center',
    },
    pagingDisclaimer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '20px',
    },
    paging: {
      color: colors.darkGrey,
      backgroundColor: colors.white,
      margin: '5px',
      '&:hover': {
        backgroundColor: colors.whiteDown,
      },
    },
  }),
);

const allStoriesQuery = gql`
  ${allStories}
`;
const allStoriesSearchQuery = gql`
  ${allStoriesSearch}
`;

export default function AllStories({ onEnter, permissions }) {
  const history = useHistory();

  const [previousCursorHistory, setPreviousCursorHistory] = React.useState([]);
  const [filterByHeadline, setFilterByHeadLine] = React.useState('');
  const [filterByDate, setFilterByDate] = React.useState(null);
  const [variablesToUse, setVariablesToUse] = React.useState({});
  const [queryToUse, setQueryToUse] = React.useState(allStoriesQuery);
  const { data, refetch, error } = useQuery(queryToUse, {
    variables: variablesToUse,
  });

  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  function formatDate(date) {
    if (!date) return null;

    return moment(date)
      .add(1, 'days')
      .format('YYYY.MM.DD');
  }

  function updateQuery({ searchTerm, date, cursor }) {
    const formattedDate = date ? formatDate(date) : null;
    setQueryToUse(searchTerm ? allStoriesSearchQuery : allStoriesQuery);
    setVariablesToUse(
      searchTerm
        ? {
            query: searchTerm,
          }
        : {
            after: cursor,
            beforeDate: formattedDate,
          },
    );
    setFilterByHeadLine(searchTerm);
    if (formattedDate) {
      setFilterByDate(date);
    }
    refetch();
  }

  function dispatchError(err) {
    errorMessageAction(err, err.message);
  }

  function handleNavigateHome() {
    history.push('/');
  }

  function handlePaginate(reverse) {
    const { edges } = data.stories;
    const lastArticleCursor = edges[edges.length - 1].cursor;
    if (reverse) {
      previousCursorHistory.pop();
      setPreviousCursorHistory(previousCursorHistory);
      updateQuery({
        cursor: previousCursorHistory[previousCursorHistory.length - 1],
        date: filterByDate,
      });
    } else {
      setPreviousCursorHistory([...previousCursorHistory, lastArticleCursor]);
      updateQuery({ cursor: lastArticleCursor, date: filterByDate });
    }
  }

  function clearAllFilters() {
    setPreviousCursorHistory([]);
    setFilterByDate(null);
    updateQuery({ searchTerm: filterByHeadline, date: null });
  }

  if (error) {
    dispatchError(error);
  }

  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <div className={classes.appBar} />
      <nav>
        <Navbar title="All Stories" handleNavigateHome={handleNavigateHome} />
      </nav>
      <Grid container justify="flex-start">
        <Grid item xs={12}>
          <div className={classes.searchFieldGroup}>
            <TextField
              className={classes.searchField}
              name="FilterByHeadline"
              label="Search"
              placeholder="Headline or Author"
              InputLabelProps={{
                shrink: true,
              }}
              value={filterByHeadline}
              onChange={e => {
                updateQuery({ searchTerm: e.target.value });
              }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.dateField}
                margin="normal"
                id="FilterUpdatedDate"
                label="Filter by Date"
                format="MM/dd/yyyy"
                value={filterByDate}
                onChange={date => updateQuery({ searchTerm: '', date })}
                KeyboardButtonProps={{
                  'aria-label': 'search date',
                }}
              />

              {(filterByHeadline || filterByDate) && (
                <IconButton onClick={clearAllFilters}>
                  <ClearIcon />
                </IconButton>
              )}
            </MuiPickersUtilsProvider>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {data &&
                  data.stories &&
                  data.stories.edges.map(storyEdge => (
                    <TableRow hover key={storyEdge.cursor}>
                      <TableCell component="th">
                        <TypeIndicator story={storyEdge.node} />
                      </TableCell>
                      <TableCell component="th">
                        <Link
                          to={siteLink(storyEdge.node)}
                          className={classes.slugLink}
                        >
                          <Box fontWeight="fontWeightBold">
                            {storyEdge.node.longHeadline}
                          </Box>
                        </Link>
                      </TableCell>
                      <Hidden smDown>
                        <TableCell align="right">
                          <p className={classes.lastSavedText}>
                            last saved at{' '}
                            {dateFormat(
                              storyEdge.node.updatedAt,
                              "mm.dd.yy H:MM TT 'ET'",
                            )}
                          </p>
                        </TableCell>
                      </Hidden>

                      <TableCell align="right">
                        <CopyToClipboard
                          text={`${process.env.WWW_DOMAIN}/${storyEdge.node.slug}`}
                        >
                          <FileCopy className={classes.fileCopy} />
                        </CopyToClipboard>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.pagingDisclaimer}>
            {filterByHeadline &&
              'Next/Previous are only available when search terms are removed'}
          </div>
          <div className={classes.pagingGroup}>
            <Button
              className={classes.paging}
              disabled={!!filterByHeadline || !previousCursorHistory.length}
              variant="contained"
              onClick={() => handlePaginate(true)}
              label="Prev"
            />

            <Button
              className={classes.paging}
              disabled={!!filterByHeadline}
              variant="contained"
              onClick={() => handlePaginate(false)}
              label="Next"
            />
          </div>
        </Grid>
      </Grid>
      <Snackbar
        key
        open={error}
        message={error && error.message}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
    </Container>
  );
}
