export const colors = {
  white: '#ffffff',
  whiteDown: '#f3f4f7',
  blue: '#366eb5',
  green: '#4EB65F',
  greenDown: '#357a40',
  mobiledocText: '#02141F',
  red: '#ec4041',
  yellow: '#fffcba',
  neonYellow: '#ffff00',
  orange: '#ff9800',
  black: '#02141f',
  blueBlack: '#283741',
  bannerBg: '#faf785',
  beastRed: '#ea0606',
  darkerGrey: '#595b59',
  darkGrey: '#696b69',
  mediumGrey: '#afafae',
  lighterGrey: '#cacdd1',
  lightGrey: '#f3f4f7',
  materialWhite: '#fafafa',
  materialBlue: '#3d659e',

  obsessed: {
    yellow: '#feea00',
    pink: '#ff329c',
    lightBlue: '#03b9e4',
    deepBlue: '#322c70',
    sunriseOrange: '#f6861e',
    paleMint: '#00c277',
  },
};

export const fonts = {
  helveticaHeader: '"HelveticaNeue", "Helvetica", Arial, sans-serif',
  helvetica: 'Helvetica, Arial, sans-serif',
  'helvetica-bold': 'Helvetica-Bold, sans-serif',
  georgia: 'Georgia, Times, Times New Roman serif',
  'georgia-italic': 'Georgia-Italic',
  drukWeb: '"Druk Web Italic", ArialNarrow-bold, Arial, sans-serif',
  drukTextWeb: '"Druk Text Web", ArialNarrow-bold, Arial, sans-serif',
};

const heading = {
  color: colors.beastRed,
  fontWeight: '700',
  fontSize: '22px',
  lineHeight: '26px',
  letterSpacing: '-.36px',
};

const subHeading = {
  color: colors.blueBlack,
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '-.3px',
};

export const mobiledocStyles = {
  fontSize: '18px',
  lineHeight: '28px',
  'letter-spacing': 'normal',
  color: colors.mobiledocText,
  '& > p > a': { color: colors.blue },
  '& h2, & h3': {
    fontFamily: fonts.helveticaHeader,
  },
  '& > h2': heading,
  '& > h3': subHeading,
  heading,
  subHeading,
  'font-family': fonts.georgia,
};
