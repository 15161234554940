query imageQuery($id: ID!) {
  image(id: $id) {
    id
    url
    publicId: public_id
    altText: alt_text
    title
    credit
    owner
    rights
    crops
    file_name
    version
    imgDataFormat: img_data_format
    slideNumber: slide_number
    mobiledocCaption: mobiledoc_caption
    displayType: display_type
    mainImage: main_image
  }
}
