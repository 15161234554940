import MobileDetect from 'mobile-detect';

let md;

export default function isMobile() {
  if (!md) {
    md = new MobileDetect(global.navigator.userAgent);
  }
  return md.mobile();
}
