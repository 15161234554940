query HomepageSectionQuery($section: Int!) {
  page(name: HOME) {
    section(atPosition: $section) {
      id
      title
      position
      productCallout: product_callout {
        __typename
        ... on NewsletterProductCallout {
          type
          lists
        }
        ... on MembershipProductCallout {
          type
        }
      }
    }
  }
}
