export const RIGHTS = {
  EDITORIAL: 'Editorial reuse in a print/digital media',
  AGGREGATION: 'Aggregation',
  TRANSLATION: 'Translation for foreign distribution',
  VIDEO: 'Film/Television/Dramatic',
  AUDIO: 'Audio',
  MERCHANDISING: 'Merchandising',
  PROMOTION: 'Promotion',
};

export const ALL_RIGHTS = [
  RIGHTS.EDITORIAL,
  RIGHTS.AGGREGATION,
  RIGHTS.TRANSLATION,
  RIGHTS.VIDEO,
  RIGHTS.AUDIO,
  RIGHTS.MERCHANDISING,
  RIGHTS.PROMOTION,
];

export const NO_RIGHTS = [];

export function mergeRights(authors) {
  const mergedRightsSet = authors.reduce((rightsSet, currentAuthor) => {
    if (currentAuthor.rights) {
      currentAuthor.rights.forEach(right => {
        return rightsSet.add(right);
      });
    }

    return rightsSet;
  }, new Set());

  return Array.from(mergedRightsSet);
}

export default {
  RIGHTS,
  ALL_RIGHTS,
  NO_RIGHTS,
  mergeRights,
};
