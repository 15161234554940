import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import Button from '../../../../common/ButtonWrapper/ButtonWrapper';
import getGraphqlErrorMessage from '../../../../helpers/graphqlError';

import fetchAdRefreshControls from './queries/adRefreshControl/fetchAdRefreshControls.gql';
import upateShouldRefresh from './queries/adRefreshControl/updateShouldRefresh.gql';
import updateAdRefreshInterval from './queries/adRefreshControl/updateAdRefreshInterval.gql';
import updateRefreshCap from './queries/adRefreshControl/updateRefreshCap.gql';
import isNumber from '../isNumber';

const useStyles = makeStyles(theme => ({
  appBar: theme.mixins.toolbar,
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px solid #333',
    padding: '30px',
    marginTop: '50px',
  },
  wrapper: { flex: '50%' },
  item: { margin: '15px 17px' },
  saveContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  refreshCap: { marginTop: '15px' },
  mainHeaderContainer: {
    display: 'inline-block',
  },
  mainRefreshControlHeader: {
    display: 'inline-block',
    textTransform: 'uppercase',
  },
  mainPagesSub: {
    display: 'inline-block',
    fontWeight: '400',
    marginLeft: '5px',
    textTransform: 'uppercase',
  },
}));

const pageTypes = ['crossword'];

export default function PageControls() {
  const classes = useStyles();

  const [message, setMessage] = useState(null);

  const [adRefreshBySection, setAdRefreshBySection] = useState({});

  const [shouldRefresh, setShouldRefresh] = useState(
    Object.assign(
      {},
      ...pageTypes.map(p => ({
        [p]: {
          id: '2',
          shouldRefresh: true,
        },
      })),
    ),
  );

  const [interval, setInterval] = useState(
    Object.assign(
      {},
      ...pageTypes.map(p => ({
        [p]: {
          id: '2',
          interval: '',
        },
      })),
    ),
  );

  const [refreshCap, setRefreshCap] = useState(
    Object.assign(
      {},
      ...pageTypes.map(p => ({
        [p]: {
          id: '2',
          refreshCap: null,
        },
      })),
    ),
  );

  const [changedAdRefreshControls, setChangedAdRefreshControls] = useState({
    interval: [],
    refreshCap: [],
    shouldRefresh: [],
  });

  const [intervalErrorText, setIntervalErrorText] = useState(
    Object.assign(...pageTypes.map(p => ({ [p]: null }))),
  );

  const [refreshCapErrorText, setRefreshCapErrorText] = useState(
    Object.assign(...pageTypes.map(p => ({ [p]: null }))),
  );

  useQuery(
    gql`
      ${fetchAdRefreshControls}
    `,
    {
      onCompleted: data => {
        const organizedAdRefreshControls = organizeAdRefreshControls(data);
        setAdRefreshBySection(organizedAdRefreshControls);
      },
    },
  );

  const syncForm = (field, value) => {
    const updatedAdRefresh = { ...adRefreshBySection };
    updatedAdRefresh.thedailybeast.mainSection[field].crossword[field] = value;

    setAdRefreshBySection(updatedAdRefresh);
  };

  const handleAdRefreshMutation = adRefreshControl => {
    if (adRefreshControl) {
      setMessage('Saved!');
    } else {
      setMessage('Failed to save for an unknown reason');
    }
  };

  const [runShouldRefreshMutation] = useMutation(
    gql`
      ${upateShouldRefresh}
    `,
    {
      onError: err => {
        setMessage(getGraphqlErrorMessage(err));
      },
      onCompleted: data => {
        handleAdRefreshMutation(data?.adRefreshControl);
      },
    },
  );

  const [runAdRefreshIntervalMutation] = useMutation(
    gql`
      ${updateAdRefreshInterval}
    `,
    {
      onError: err => {
        setMessage(getGraphqlErrorMessage(err));
      },
      onCompleted: data => {
        handleAdRefreshMutation(data?.adRefreshControl);
      },
    },
  );

  const [runRefreshCapMutation] = useMutation(
    gql`
      ${updateRefreshCap}
    `,
    {
      onError: err => {
        setMessage(getGraphqlErrorMessage(err));
      },
      onCompleted: data => {
        handleAdRefreshMutation(data?.adRefreshControl);
      },
    },
  );

  const organizeAdRefreshControls = data => {
    const organizedAdRefreshControls = data.adRefreshControls.reduce(
      (acc, adRefreshControl) => {
        const {
          id,
          interval: dataInterval,
          pageType,
          refreshCap: dataRefreshCap,
          shouldRefresh: dataShouldRefresh,
        } = adRefreshControl;

        if (!acc.thedailybeast) {
          acc.thedailybeast = {};
        }

        if (pageType === 'crossword') {
          if (!acc.thedailybeast.mainSection) {
            acc.thedailybeast.mainSection = {};
            acc.thedailybeast.mainSection.interval = {};
            acc.thedailybeast.mainSection.shouldRefresh = {};
            acc.thedailybeast.mainSection.refreshCap = {};
          }

          acc.thedailybeast.mainSection.interval[pageType] = {
            id,
            interval: dataInterval,
          };

          acc.thedailybeast.mainSection.shouldRefresh[pageType] = {
            id,
            shouldRefresh: dataShouldRefresh,
          };

          acc.thedailybeast.mainSection.refreshCap[pageType] = {
            id,
            refreshCap: dataRefreshCap,
          };
        }

        return acc;
      },
      {},
    );

    const { mainSection } = organizedAdRefreshControls.thedailybeast;

    setRefreshCap(mainSection.refreshCap);
    setInterval(mainSection.interval);
    setShouldRefresh(mainSection.shouldRefresh);

    return organizedAdRefreshControls;
  };

  const syncChanges = (id, value, field) => {
    let newChange = {};
    const newShouldRefresh = [...changedAdRefreshControls[field]];
    const changeIndex = newShouldRefresh.findIndex(item => item.id === id);

    if (changeIndex === -1) {
      newShouldRefresh.push({ id, [field]: value });
    } else {
      newShouldRefresh[changeIndex][field] = value;
    }

    if (field === 'shouldRefresh') {
      newChange = {
        interval: [...changedAdRefreshControls.interval],
        refreshCap: [...changedAdRefreshControls.refreshCap],
        [field]: [...newShouldRefresh],
      };
    } else if (field === 'interval') {
      newChange = {
        shouldRefresh: [...changedAdRefreshControls.shouldRefresh],
        refreshCap: [...changedAdRefreshControls.refreshCap],
        [field]: [...newShouldRefresh],
      };
    } else if (field === 'refreshCap') {
      newChange = {
        interval: [...changedAdRefreshControls.interval],
        shouldRefresh: [...changedAdRefreshControls.shouldRefresh],
        [field]: [...newShouldRefresh],
      };
    }
    setChangedAdRefreshControls(newChange);
  };

  function handleSwitchInput(id, label, input) {
    setShouldRefresh(prevState => ({
      ...prevState,
      [label]: {
        id,
        shouldRefresh: input,
      },
    }));
    syncChanges(id, input, 'shouldRefresh');
    syncForm('shouldRefresh', input);
  }

  function handleNumberInput(min, field, setError, setValue, id, label, input) {
    setValue(prevState => ({
      ...prevState,
      [label]: {
        id,
        [field]: input,
      },
    }));

    const parsedInput = parseInt(input, 10);
    if (isNumber(parsedInput)) {
      setValue(prevState => ({
        ...prevState,
        [label]: {
          id,
          [field]: parsedInput,
        },
      }));
      if (parsedInput < min) {
        setError(prevState => ({
          ...prevState,
          [label]: `Minimum of ${min}`,
        }));
      } else if (parsedInput > 1000) {
        setError(prevState => ({
          ...prevState,
          [label]: 'Maximum of 1000',
        }));
      } else {
        setError(prevState => ({ ...prevState, [label]: null }));
        syncChanges(id, input, field);
        syncForm(field, input);
      }
    } else {
      setError(prevState => ({
        ...prevState,
        [label]: 'Must be a number',
      }));
    }
  }

  const disableButton = () => {
    const noChangesExist = Object.keys(changedAdRefreshControls).every(key => {
      return (
        changedAdRefreshControls[key] && !changedAdRefreshControls[key].length
      );
    });
    const intervalErrorsExist = Object.keys(intervalErrorText).some(
      key => intervalErrorText[key] !== null,
    );
    const refreshCapErrorsExist = Object.keys(refreshCapErrorText).some(
      key => refreshCapErrorText[key] !== null,
    );

    const errorCheck = intervalErrorsExist || refreshCapErrorsExist;

    return noChangesExist || errorCheck;
  };

  const submitForm = () => {
    changedAdRefreshControls.interval.forEach(i => {
      const { id, interval: intervalChange } = i;
      runAdRefreshIntervalMutation({
        variables: { id, interval: intervalChange },
      });
    });

    changedAdRefreshControls.shouldRefresh.forEach(i => {
      const { id, shouldRefresh: shouldRefreshChange } = i;
      runShouldRefreshMutation({
        variables: { id, shouldRefresh: shouldRefreshChange },
      });
    });

    changedAdRefreshControls.refreshCap.forEach(i => {
      const { id, refreshCap: refreshCapChange } = i;
      runRefreshCapMutation({
        variables: { id, refreshCap: refreshCapChange },
      });
    });

    resetForm();
  };

  const resetForm = () => {
    const form = {
      interval: [],
      refreshCap: [],
      shouldRefresh: [],
    };
    setChangedAdRefreshControls(form);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.mainHeaderContainer}>
          <h3 className={classes.mainRefreshControlHeader}>
            Refresh Intervals
          </h3>
          <h4 className={classes.mainPagesSub}>Main Pages</h4>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.saveContainer}>
          <Button
            color="primary"
            label="Save"
            className={classes.saveButton}
            onClick={submitForm}
            disabled={disableButton()}
          >
            Save
          </Button>
        </div>
      </div>
      {pageTypes.map(label => (
        <div className={classes.wrapper} key={label}>
          <div key={label} className={classes.item}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={shouldRefresh[label].shouldRefresh}
                    onChange={e =>
                      handleSwitchInput(
                        shouldRefresh[label].id,
                        label,
                        e.target.checked,
                      )
                    }
                  />
                }
                label={label.toUpperCase()}
              />
            </FormControl>
            <TextField
              InputLabelProps={{ shrink: true }}
              error={!!intervalErrorText[label]}
              fullWidth
              helperText={intervalErrorText[label]}
              label="Refresh Interval (seconds)"
              onChange={event =>
                handleNumberInput(
                  15,
                  'interval',
                  setIntervalErrorText,
                  setInterval,
                  interval[label].id,
                  label,
                  event.target.value,
                )
              }
              value={interval[label].interval}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.refreshCap}
              error={!!refreshCapErrorText[label]}
              fullWidth
              helperText={refreshCapErrorText[label]}
              label="Refresh Cap"
              onChange={event =>
                handleNumberInput(
                  1,
                  'refreshCap',
                  setRefreshCapErrorText,
                  setRefreshCap,
                  refreshCap[label].id,
                  label,
                  event.target.value,
                )
              }
              value={refreshCap[label].refreshCap}
            />
          </div>
        </div>
      ))}
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
      />
    </div>
  );
}
