import * as Sentry from '@sentry/react';

const noOp = () => {};

/**
 * Helper function to log error to Sentry
 * @param {Object} error - Error object or string.
 * @param {Object} options - Options for logging.
 * @param {Function} options.beforeCapture - A function that gets called before an error is sent to Sentry, allowing for extra tags or context to be added to the error.
 * @param {Function} options.afterCapture - A function that gets called after an error has been sent to Sentry.
 * @returns {void}
 */
export const logError = (error, options = {}) => {
  const { beforeCapture = noOp, afterCapture = noOp } = options;

  Sentry.withScope((scope) => {
    // This function allows you to enrich the error event before it is sent to Sentry.
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/
    beforeCapture(error, scope);

    if (typeof error === 'string') {
      Sentry.captureMessage(error);
    } else {
      Sentry.captureException(error);
    }

    afterCapture(error);
  });
};
