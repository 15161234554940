import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import gql from 'graphql-tag';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import getGraphqlErrorMessage from '../../../helpers/graphqlError';
import storySuggestionQueryGQL from './graphql/storySuggestionQuery.gql';

const storySuggestionQuery = gql`
  ${storySuggestionQueryGQL}
`;

ScoutedAutocomplete.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  cheat: PropTypes.shape({
    id: PropTypes.string,
    longHeadline: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  scoutedType: PropTypes.string.isRequired,
};

export default function ScoutedAutocomplete({
  className,
  disabled,
  cheat,
  index,
  scoutedType,
  onChange,
}) {
  const [options, setOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCheat, setSelectedCheat] = useState({});

  const [loadStories] = useLazyQuery(storySuggestionQuery, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      const stories = data && data.suggestions && data.suggestions.edges;
      setOptions(stories);
    },
  });

  const changeHandler = useCallback(
    (e, value) => {
      setSelectedCheat(value.node);
      onChange(value.node, index, scoutedType);
    },
    [onChange, index, scoutedType],
  );

  const inputChangeHandler = useCallback(
    (e, inputString) => {
      if (inputString) {
        loadStories({
          variables: {
            query: inputString,
          },
        });
      }
    },
    [loadStories],
  );

  return (
    <Autocomplete
      className={className}
      getOptionLabel={option => option?.longHeadline || ''}
      filterOptions={x => x}
      options={options}
      disabled={disabled}
      value={Object.keys(selectedCheat).length !== 0 ? selectedCheat : cheat}
      renderOption={option => (
        <div id={option.id}>{option.node.longHeadline}</div>
      )}
      renderInput={params => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errorMessage}
          helperText={errorMessage}
          label={`Story ${index + 1}`}
        />
      )}
      onChange={changeHandler}
      onInputChange={inputChangeHandler}
    />
  );
}
