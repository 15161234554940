import React, { useContext, useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { gql } from '@apollo/client'
import { useLazyQuery, useMutation } from '@apollo/client'

import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import accentColorQueryGQL from './graphql/accentColorQuery.gql';
import accentColorMutationGQL from './graphql/accentColorMutation.gql';
import deleteAccentColorMutationGQL from './graphql/deleteAccentColorMutation.gql';

const accentColorQuery = gql`
  ${accentColorQueryGQL}
`;

const accentColorMutation = gql`
  ${accentColorMutationGQL}
`;

const deleteAccentColorMutation = gql`
  ${deleteAccentColorMutationGQL}
`;

function AccentColor() {
  const { id } = useContext(StoryContext);
  const [accentColor, setAccentColor] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accentColorActive, setAccentColorActive] = useState(false);

  const [loadAccentColor] = useLazyQuery(accentColorQuery, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
      console.log('AccentColor:loadAccentColor:err', err);
    },
    onCompleted: data => {
      if (errorMessage) setErrorMessage(null);
      console.log('AccentColor:loadAccentColor:data', data);

      const accentColorData =
        data &&
        data.story &&
        data.story.metadata &&
        data.story.metadata.accentColor;

      if (accentColorData) {
        setAccentColor(accentColorData);
        setAccentColorActive(true);
      }
    },
  });

  const [updateAccentColor] = useMutation(accentColorMutation, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
      console.log('AccentColor:updateAccentColor:err', err);
    },
    onCompleted: data => {
      if (errorMessage) setErrorMessage(null);
      console.log('AccentColor:updateAccentColor:data', data);

      const accentColorData =
        data && data.metadata && data.metadata.accentColor;
      if (accentColorData) setAccentColor(accentColorData);
    },
  });

  const [deleteAccentColor] = useMutation(deleteAccentColorMutation, {
    onError: err => {
      setAccentColor(getGraphqlErrorMessage(err));
      console.log('AccentColor:deleteAccentColor:err', err);
    },
    onCompleted: data => {
      console.log('AccentColor:deleteAccentColor:data', DataTransfer);
      if (errorMessage) setErrorMessage(null);
      setAccentColor(data.metadata.accentColor);
    },
  });

  useEffect(() => {
    if (id) {
      loadAccentColor({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  const onAccentColorToggle = e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAccentColorActive(true);
      updateAccentColor({
        variables: {
          articleId: id,
          metadata: {
            accent_color: '000000',
          },
        },
      });
    } else {
      setAccentColorActive(false);
      deleteAccentColor({
        variables: {
          articleId: id,
          column: 'accent_color',
        },
      });
    }
  };

  const onChange = e => {
    const { value } = e.target;
    setAccentColor(value);

    updateAccentColor({
      variables: {
        articleId: id,
        metadata: {
          accent_color: value,
        },
      },
    });
  };

  const accentColorLength = accentColor && accentColor.length;
  let helperMessage = `${accentColorLength}/6`;
  let hasError = false;

  if (errorMessage) {
    hasError = true;
    helperMessage = `${accentColorLength}/6 ${errorMessage}`;
  }

  return (
    <>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={accentColorActive}
              onChange={onAccentColorToggle}
              value="accent_color"
            />
          }
          label="Accent Color"
        />
      </FormControl>
      {accentColorActive && (
        <TextField
          error={hasError}
          fullWidth
          helperText={helperMessage}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          value={accentColor}
          onBlur={onChange}
        />
      )}
    </>
  );
}

export default AccentColor;
