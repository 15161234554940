import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { gql } from '@apollo/client'
import Snackbar from '@material-ui/core/Snackbar';
import { StoryContext } from '../../../../../context/StoryContext';
import campaigns from '../../queries/campaigns.gql';
import storyCampaign from '../../queries/storyCampaign.gql';
import updateStoryCampaign from '../../mutations/updateStoryCampaign.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const storyCampaignQuery = gql`
  ${storyCampaign}
`;

const storyCampaignMutation = gql`
  ${updateStoryCampaign}
`;

const campaignsQuery = gql`
  ${campaigns}
`;

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      marginTop: '10px',
      marginBottom: '25px',
    },
  }),
);

export default function CampaignAutoComplete() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  const [message, setMessage] = useState(null);
  const [campaignData, setCampaignData] = useState({
    campaign_id: null,
    name: null,
    slug: null,
  });
  const [updateStoryCampaignMutation] = useMutation(storyCampaignMutation, {
    onError: err => {
      errorMessageAction(err);
      setMessage('Error auto-saving story campaign.');
    },
    onCompleted: data => {
      const {
        campaignData: { campaign },
      } = data;
      setCampaignData(campaign);
    },
  });

  const [loadStoryCampaign] = useLazyQuery(storyCampaignQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error fetching campaign for this story.');
    },
    onCompleted: data => {
      const {
        storyData: { campaign },
      } = data;
      if (campaign) {
        const { campaign_id: campaignId, name, slug } = campaign;
        setCampaignData({ campaign_id: campaignId, name, slug });
      }
    },
  });

  const { data: campaignsData } = useQuery(campaignsQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error fetching list of campaigns');
    },
  });

  useEffect(() => {
    if (id) {
      loadStoryCampaign({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  function onCampaignChange(currentId) {
    updateStoryCampaignMutation({
      variables: {
        id,
        storyInputData: {
          campaign_id: currentId,
        },
      },
    });
  }

  const campaignList = (campaignsData && campaignsData.campaigns) || [];
  return (
    <>
      <Autocomplete
        className={classes.select}
        getOptionLabel={x => x.name}
        value={campaignData}
        options={campaignList}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            label="Campaign"
          />
        )}
        onChange={(e, currentSelection) => {
          onCampaignChange(currentSelection.id);
        }}
      />
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
