import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import {
  InsertPhoto,
  Code,
  BorderHorizontal,
  FormatQuote,
  ShoppingCart,
} from '@material-ui/icons';
import SvgSymbol, { SVG_SYMBOLS } from '../SvgSymbol/SvgSymbol';
import { Context as EditorContext } from '../../context/EditorContext';

export const mobiledocToolbarSize = 55;

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: theme.mixins.toolbar,
    horizontalListWithDraftBar: {
      paddingTop: '43px',
      cursor: 'pointer',
    },
    fancylinksIcon: {
      height: mobiledocToolbarSize / 2,
      width: mobiledocToolbarSize / 2,
    },
  }),
);

export const EMBED_TOOLBAR_BUTTONS = {
  EMBED: 'embed',
  FANCY_LINKS: 'fancy-links',
  IMAGE: 'image',
  SLIDESHOW: 'slideshow',
  QUOTE: 'quote',
  UNORDERED_LIST: 'unodered-list',
  ORDERED_LIST: 'ordered-list',
  SECTION_BREAK: 'section-break',
  CONVERSION: 'conversion',
};

function EmbedToolbar(props) {
  const classes = useStyles();
  const {
    updateImagePickerOpen,
    updateQuotePickerOpen,
    updateFancyLinksPickerOpen,
    updateSectionBreakPickerOpen,
    updateEmbedPickerOpen,
    updateConversionPickerOpen,
  } = useContext(EditorContext);
  const { features = [] } = props;

  const toolbarItems = [];

  if (features.includes(EMBED_TOOLBAR_BUTTONS.EMBED)) {
    toolbarItems.push(
      <ListItem button onClick={() => updateEmbedPickerOpen(true)}>
        <Tooltip title="Add embed content">
          <Code />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(EMBED_TOOLBAR_BUTTONS.FANCY_LINKS)) {
    toolbarItems.push(
      <ListItem button onClick={() => updateFancyLinksPickerOpen(true)}>
        <Tooltip title="Add Fancy Link">
          <SvgSymbol
            className={classes.fancylinksIcon}
            name={SVG_SYMBOLS.FANCY_LINKS}
          />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(EMBED_TOOLBAR_BUTTONS.IMAGE)) {
    toolbarItems.push(
      <ListItem button onClick={() => updateImagePickerOpen(true)}>
        <Tooltip title="Add inline image">
          <InsertPhoto />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(EMBED_TOOLBAR_BUTTONS.QUOTE)) {
    toolbarItems.push(
      <ListItem button onClick={() => updateQuotePickerOpen(true)}>
        <Tooltip title="Add pull quote">
          <FormatQuote />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(EMBED_TOOLBAR_BUTTONS.SECTION_BREAK)) {
    toolbarItems.push(
      <ListItem button onClick={() => updateSectionBreakPickerOpen(true)}>
        <Tooltip title="Add a section break (horizontal line)">
          <BorderHorizontal />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(EMBED_TOOLBAR_BUTTONS.CONVERSION)) {
    toolbarItems.push(
      <ListItem onClick={() => updateConversionPickerOpen(true)}>
        <Tooltip title="Add commerce conversion">
          <ShoppingCart />
        </Tooltip>
      </ListItem>,
    );
  }

  return (
    <Drawer variant="permanent" anchor="left">
      <div className={classes.appBar} />
      <List className={classes.horizontalListWithDraftBar}>{toolbarItems}</List>
    </Drawer>
  );
}

EmbedToolbar.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
};

export default EmbedToolbar;
