import {
  OPEN_CONFIRM_DIALOG,
  CLOSE_CONFIRM_DIALOG,
} from '../actions/ui';

export const INITIAL_STATE = {
  confirmDialogOpen: false,
};

export default function uiReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case OPEN_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDialogOpen: true,
      };
    case CLOSE_CONFIRM_DIALOG:
      return {
        ...state,
        confirmDialogOpen: false,
      };
    default:
      return state;
  }
}
