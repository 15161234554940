import React, { Component } from 'react';
import { logError } from '../../helpers/logError';
import { handleError } from '../../helpers/global-error-handler';

export default class LogoutRedirectPage extends Component {
  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.state = {
      count: 5,
    };
  }

  componentDidMount() {
    this.timeoutId = global.setTimeout(
      /* istanbul ignore next */
      () => {
        global.PublicClientApplication.logoutRedirect().catch((error) => {
          logError(error, {
            afterCapture: handleError,
          });
        });
      },
      5000
    );
    this.intervalId = global.setInterval(
      /* istanbul ignore next */
      () => {
        this.countDown();
      },
      1000
    );
  }

  componentWillUnmount() {
    global.clearTimeout(this.timeoutId);
    global.clearInterval(this.intervalId);
  }

  countDown() {
    const count = this.state.count > 0 ? this.state.count - 1 : 0;
    this.setState({ count });
  }

  render() {
    return (
      <main>
        <h1>You logged in using wrong account</h1>
        <h2>You will be redirected in {this.state.count} seconds...</h2>
      </main>
    );
  }
}
