/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export const MESSAGE = {
  option1: {
    line1: 'Are you sure you want to change the canonical slug from:',
    line2: 'to:',
  },
  option2: {
    line1: 'Are you sure you want to update the existing slugs array of:',
    line2: 'to include:',
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    left: '50%',
    padding: theme.spacing(2, 4, 3),
    position: 'absolute',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    width: 400,
  },
  modalButton: {
    marginRight: 12,
  },
}));

SlugRedirectModal.propTypes = {
  additionalSlug: PropTypes.string,
  message: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  workingSlug: PropTypes.string,
};

SlugRedirectModal.displayName = 'SlugRedirectModal';

export default function SlugRedirectModal({
  additionalSlug,
  message,
  onCancel,
  onConfirm,
  open,
  workingSlug,
}) {
  const classes = useStyles();

  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <Typography paragraph>{message?.line1}</Typography>
        <Typography color='error' paragraph>
          {workingSlug}
        </Typography>
        <Typography paragraph>{message?.line2}</Typography>
        <Typography color='error' paragraph>
          {additionalSlug}
        </Typography>
        <Button
          className={classes.modalButton}
          color='primary'
          variant='contained'
          onClick={onConfirm}
        >
          Yes
        </Button>
        <Button
          className={classes.modalButton}
          color='secondary'
          onClick={onCancel}
          variant='contained'
        >
          No
        </Button>
      </div>
    </Modal>
  );
}
