import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

ButtonWrapper.propTypes = {
  disabled: PropTypes.bool,
  target: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

const useStyles = makeStyles({
  buttonWithPadding: {
    margin: '8px 8px 8px',
  },
  button: {
    'box-shadow': 'none',
  },
});

export default function ButtonWrapper({ ...params }) {
  const classes = useStyles();
  const buttonClasses = classnames({
    [classes.buttonWithPadding]: !params.className,
    [params.className]: params.className,
    [classes.button]: true,
  });
  return (
    <Button
      {...params}
      variant={params.variant || 'contained'}
      className={buttonClasses}
      color={params.color || 'primary'}
    >
      {params.label || ''}
    </Button>
  );
}
