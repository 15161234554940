mutation updateStoryCampaign($id: ID!, $storyInputData: StoryInput!) {
  campaignData: updateStory(id: $id, story: $storyInputData) {
    id
	  campaign {
	    id
	    name
	    slug
	  }
  }
}
