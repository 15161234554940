import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Navbar from '../../common/Navbar/Navbar';
import { Sidebar } from '../../common/Sidebar/Sidebar';

BizToolsBasePage.propTypes = {
  onEnter: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme => ({
  appBar: theme.mixins.toolbar,
}));

export default function BizToolsBasePage({
  onEnter,
  title,
  children,
  permissions,
}) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  return (
    <>
      <Navbar
        title={title}
        handleNavigateHome={() => history.push('/biztools')}
      />

      <Sidebar
        open
        listItems={[
          { slug: 'refresh', displayText: 'Ad Ops Refresh Tools' },
          { slug: 'brandspartners', displayText: 'Brands and Partners' },
          { slug: 'adstxt', displayText: 'Upload ads.txt File' },
          { slug: 'categorization-data', displayText: 'Categorization Data' },
          { slug: 'scouted', displayText: 'Scouted' },
        ]}
      />

      <Grid container justify="center">
        <Grid item xs={6}>
          <div className={classes.appBar} />
          <main>{children}</main>
        </Grid>
      </Grid>
    </>
  );
}
