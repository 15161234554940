mutation UpdateMembershipCallout(
  $buttonLink: String
  $buttonText: String
  $sectionId: ID!
  $text: String
) {
  updateMembershipCallout(
    sectionId: $sectionId
    callout: { text: $text, button_link: $buttonLink, button_text: $buttonText }
  ) {
    productCallout: product_callout {
      ... on MembershipProductCallout {
        type
      }
    }
  }
}
