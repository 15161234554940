import { z } from 'zod';
import IMAGE_DISPLAY_TYPE from '../utils/image-display-type';
import { logError } from './logError';
import { handleError } from './global-error-handler';

export const ValidImageSchema = z
  .object({
    credit: z.string(),
    crops: z
      .object({
        '1_1': z
          .object({
            height: z.number(),
            width: z.number(),
            x: z.number(),
            y: z.number(),
          })
          .required(),
        '16_9': z
          .object({
            height: z.number(),
            width: z.number(),
            x: z.number(),
            y: z.number(),
          })
          .required(),
        original: z
          .object({
            height: z.number(),
            width: z.number(),
            x: z.number(),
            y: z.number(),
          })
          .required(),
      })
      .required(),
    display_type: z.string(),
    img_data_format: z.string(),
    mobiledoc_caption: z.object({
      atoms: z.undefined().array(),
      cards: z.undefined().array(),
      markups: z.undefined().array(),
      sections: z.array(z.array(z.any())),
      version: z.literal('0.3.0'),
    }),
    public_id: z.string(),
    title: z.string(),
    url: z.string().url(),
    version: z.string(),
  })
  .partial({ mobiledoc_caption: true });

export const ValidCloudinaryResultSchema = z.object({
  event: z.literal('success'),
  info: z
    .object({
      eager: z.array(
        z.object({
          height: z.number(),
          transformation: z.string(),
          width: z.number(),
        })
      ),
      format: z.string(),
      height: z.number(),
      image_metadata: z.object({
        Artist: z.string().optional(),
        'Caption-Abstract': z.string().optional(),
        Creator: z.string().optional(),
        Credit: z.string().optional(),
        Description: z.string().optional(),
        Title: z.string().optional(),
      }),
      public_id: z.string(),
      secure_url: z.string().url(),
      version: z.number(),
      width: z.number(),
    })
    .required(),
});

// https://cloudinary.com/documentation/image_upload_api_reference

/**
 *
 * @param {*} result - The result/response from calling Cloudinary's upload widget.
 * @returns {Object} - The image object to pass.
 *
 * @example
 * const makeGQLVariables = imageHelper(result)
 * makeGQLVariables(articleId, isMainImage)
 * // returns { articleId, image, isMainImage }
 */
export function createImageObject(result) {
  const response = result.info;

  const imageTitle = response.image_metadata.Title || '';
  const title = imageTitle.substr(0, 255);
  const imageMetadataCaption =
    response.image_metadata.Description ||
    response.image_metadata['Caption-Abstract'];
  const mobiledocCaption = imageMetadataCaption
    ? {
        atoms: [],
        cards: [],
        markups: [],
        sections: [
          [1, 'p', [[0, [], 0, JSON.stringify(imageMetadataCaption)]]],
        ],
        version: '0.3.0',
      }
    : undefined;
  const creditData =
    response.image_metadata.Artist ||
    response.image_metadata.Creator ||
    response.image_metadata.Credit ||
    '';
  const imageCrops = {
    '1_1': response.eager.find((crop) => crop.transformation === 't_1_1_crop'),
    '16_9': response.eager.find(
      (crop) => crop.transformation === 't_16_9_crop'
    ),
  };
  const cropsData = {
    '1_1': {
      height: imageCrops['1_1'].height,
      width: imageCrops['1_1'].width,
      x: 0,
      y: 0,
    },
    '16_9': {
      height: imageCrops['16_9'].height,
      width: imageCrops['16_9'].width,
      x: 0,
      y: 0,
    },
    original: {
      height: response.height,
      width: response.width,
      x: 0,
      y: 0,
    },
  };

  return {
    credit: creditData,
    crops: cropsData,
    display_type: IMAGE_DISPLAY_TYPE.STANDARD_WIDTH,
    img_data_format: response.format,
    mobiledoc_caption: mobiledocCaption,
    public_id: response.public_id,
    title,
    url: response.secure_url,
    version: response.version.toString(),
  };
}

/**
 * Creates a validation function that validates an object. You have options to
 * deal with the success or failure of the validation. You can either pass in a
 * function that gets called if the result is valid or invalid, or you can just
 * use the returned object to determine if the result is valid or invalid.
 * @param {Object} schema - The schema to use to validate the object.
 * @param {string} objectLabel - The name of the object to validate.
 * @param {Object} options - Options for the factory function
 * @param {Object} options.logErrorOptions - Options for logging the error.
 *
 * @returns {Function} validateFunction - A function that validates an object.
 */
export function createValidateFunction(schema, objectLabel, options = {}) {
  /**
   * A function that validates an object
   * @param {Object} objectToValidate - The object to validate.
   * @param {Object} options - Options for the validation
   * @param {Function} options.onError - A function that gets called if the result is invalid.
   * @param {Function} options.onSuccess - A function that gets called if the result is valid.
   * @returns {Object} validationResult.error - The Zod error if the result is invalid.
   * @returns {boolean} validationResult.success - Whether or not the result is valid.
   * @returns {Object} validationResult[ `validated${objectLabel}` ] - The validated object.
   */
  return function validateFunction(
    objectToValidate,
    { onSuccess = () => {}, onError = () => {} } = {}
  ) {
    if (!objectToValidate) {
      const err = new Error(
        `Validate${objectLabel} Error: ${objectLabel} was not provided.`
      );
      onError(err);

      return {
        error: err,
        success: false,
        validatedObject: undefined,
      };
    }

    const {
      data: validatedObject,
      error,
      success,
    } = schema.safeParse(objectToValidate);

    if (!success) {
      logError(error, options.logErrorOptions); // Log error to error monitoring service
      onError(error);
    } else {
      onSuccess(validatedObject);
    }
    return { error, success, [`validated${objectLabel}`]: validatedObject };
  };
}

/**
 * Creates a callback function to use with Cloudinary's upload widget.
 * @param {Object} options - Options for the callback function.
 * @param {Function} options.handleResult - A function that gets called on all Cloudinary events. Custom handling of other events can be done here.
 * @param {Function} options.onError - A function that gets called if there is an error.
 * @param {Function} options.onSuccess - A function that gets called if there is no error.
 * @returns {Function} callback - A callback function to use with Cloudinary's upload widget.
 */
export function createCloudinaryUploadWidgetCallback({
  handleResult = () => {},
  onError = () => {},
  onSuccess = () => {},
} = {}) {
  /**
   * A callback function to use with Cloudinary's upload widget.
   * @param {Object} error - The error object.
   * @param {Object} result - The result object.
   * @returns {void}
   */
  return function cloudinaryUploadWidgetCallback(error, result) {
    if (error) {
      // Log error to error monitoring service
      logError(error, {
        afterCapture: handleError,
        beforeCapture: (e, scope) => {
          // Since the error provided by Cloudinary is not an instance of Error
          // or String, we lose the stack trace so we need to provide extra
          // context to help with debugging.
          scope.setContext('Custom Context', {
            callingFunction:
              'src/helpers/imageHelper.js ~ cloudinaryUploadWidgetCallback',
          });
          scope.setTag('Vendor', 'Cloudinary');
        },
      });

      // Provide error message
      onError({
        message: `Oops! Please report this message to the #web-support channel in Slack: Cloudinary failed to create upload widget. ${JSON.stringify(
          error
        )}`,
      });
      return;
    }

    // We mainly care about the success event but other events can happen that
    // can be handled via the handleResult option
    // https://cloudinary.com/documentation/upload_widget_reference#events
    handleResult(result);
    if (result?.event !== 'success') return;

    const validateCloudinaryResult = createValidateFunction(
      ValidCloudinaryResultSchema,
      'CloudinaryResult',
      {
        logErrorOptions: {
          beforeCapture(e, scope) {
            scope.setTag('Vendor', 'Cloudinary');
          },
        },
      }
    );

    const {
      error: cloudinaryResultError,
      success: isValidCloudinaryResult,
      validatedCloudinaryResult,
    } = validateCloudinaryResult(result);

    if (!isValidCloudinaryResult) {
      onError({
        message: `Oops! Please report this message to the #web-support channel in Slack: Cloudinary did not provide a valid result. ${cloudinaryResultError}`,
      });
      return;
    }

    const image = createImageObject(validatedCloudinaryResult);

    const validateImageObject = createValidateFunction(
      ValidImageSchema,
      'Image'
    );

    const {
      error: imageError,
      success: isValidImage,
      validatedImage,
    } = validateImageObject(image);

    if (!isValidImage) {
      onError({
        message: `Oops! Please report this message to the #web-support channel in Slack: Unable to create a valid image. ${imageError}`,
      });
    } else {
      onSuccess({
        validatedImage,
      });
    }
  };
}
