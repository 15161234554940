mutation updateRefreshCap($id: ID!, $refreshCap: Int!) {
  adRefreshControl: setRefreshCap(id: $id, refreshCap: $refreshCap) {
    id
    interval
    pageType: page_type
    refreshCap: refresh_cap
    shouldRefresh: should_refresh
    siteId: site_id
    siteName: site_name
    templateType: template_type
    verticalId: vertical_id
    verticalSlug: vertical_slug
    verticalName: vertical_name
  }
}
