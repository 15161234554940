import PropTypes from 'prop-types';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';

export const NEWSLETTER_CARD_NAME = 'pt-newsletter-card';

const useStyles = makeStyles({
  card: {
    maxWidth: '500px',
    width: '90%',
    margin: '0 auto',
  },
  icon: {
    height: '130px',
    width: '130px',
    opacity: '0.8',
    margin: '0 auto 10px',
    display: 'block',
  },
  cardHeader: {
    'padding-bottom': 0,
  },
});

export default function NewsletterCard(props) {
  const classes = useStyles();
  const {
    payload: { id, newsletterSelected },
    actions: {
      updateEmbedPickerOpen,
      updateNewsletterCardId,
      updateSaveCardFunction,
    },
    save,
    edit,
  } = props;

  const onEdit = () => {
    updateNewsletterCardId(id);
    updateEmbedPickerOpen(true);
    updateSaveCardFunction(save);

    edit();
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton aria-label="embed">
            <EditIcon onClick={onEdit} />
          </IconButton>
        }
        title="Newsletter"
      />
      <CardContent>
        <CodeIcon className={classes.icon} />
        <div>{newsletterSelected}</div>
      </CardContent>
    </Card>
  );
}

NewsletterCard.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    newsletterSelected: PropTypes.string,
  }),
  actions: PropTypes.shape({
    updateEmbedPickerOpen: PropTypes.func,
    updateNewsletterCardId: PropTypes.func,
    updateSaveCardFunction: PropTypes.func,
  }),
  save: PropTypes.func,
  edit: PropTypes.func,
};
