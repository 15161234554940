import React, { useState, useContext, useEffect } from 'react';
import { Transformation } from 'cloudinary-react';
import { Grid, Divider } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Alert from '@material-ui/lab/Alert';
import { gql, useLazyQuery } from '@apollo/client';
import { colors } from '../../../../../styles/global/variables';
import CloudinaryImage from '../../../../../common/CloudinaryImage/CloudinaryImage';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import { createCloudinaryUploadWidgetCallback } from '../../../../../helpers/imageHelper';
import { StoryContext } from '../../../../../context/StoryContext';
import heroImageAndVideoQueryGQL from './graphql/heroImageAndVideoQuery.gql';
import heroImageQueryGQL from './graphql/heroImageQuery.gql';
import ARTICLE_SUBTYPE from '../../../../../utils/article-subtype';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import { MediaContext } from '../../../../../context/MediaContext/MediaContext';
import { logError } from '../../../../../helpers/logError';
import { handleError } from '../../../../../helpers/global-error-handler';

const MIN_IMAGE_WIDTH = 3000;

const heroImageAndVideoQuery = gql`
  ${heroImageAndVideoQueryGQL}
`;

const heroImageQuery = gql`
  ${heroImageQueryGQL}
`;

function StoryHero() {
  const {
    storyHero: { imageId, videoId, errorMessage },
    addMainImage,
    deleteHeroImage,
    deleteVideoAndImage,
    updateImageModalOpen,
    updateCurrentImageId,
    updateVideoModalOpen,
    updateStoryHeroError,
  } = useContext(MediaContext);
  const [isLeftButtonDisabled, setIsLeftButtonDisabled] = useState(false);

  const { id, subtype } = useContext(StoryContext);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const isGallery = false;

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [loadHeroImageAndVideo] = useLazyQuery(heroImageAndVideoQuery, {
    onCompleted: (data) => {
      updateStoryHeroError(null);

      const imageData = data && data.image;
      const videoData = data && data.video;
      if (imageData) setImage(imageData);
      if (videoData) setVideo(videoData);

      // Provide error message to user
      const err =
        imageData && (!imageData.credit || !imageData.credit.length)
          ? ''
          : 'Error: Please check required image fields';
      updateStoryHeroError(err);
    },
    onError: (err) => {
      // Log error to error monitoring service
      logError(err, {
        afterCapture: handleError,
      });

      // Provide error message to user
      const error = getGraphqlErrorMessage(err);
      updateStoryHeroError(error);
    },
  });

  const [loadHeroImage] = useLazyQuery(heroImageQuery, {
    onCompleted: (data) => {
      const imageData = data && data.image;
      if (imageData) setImage(imageData);
    },
    onError: (err) => {
      // Log error to error monitoring service
      logError(err, {
        afterCapture: handleError,
      });

      // Provide error message to user
      const error = getGraphqlErrorMessage(error);
      updateStoryHeroError(err);
    },
  });

  useEffect(() => {
    if (imageId && videoId) {
      loadHeroImageAndVideo({
        variables: {
          imageId,
          videoId,
        },
      });
    } else if (imageId) {
      loadHeroImage({
        variables: {
          imageId,
        },
      });
    } else {
      setImage(null);
    }
  }, [imageId, videoId]);

  const onEditVideo = () => {
    updateVideoModalOpen(true);
  };

  const onEditImage = () => {
    updateCurrentImageId(imageId);
    updateImageModalOpen(true);
  };

  const onDeleteImage = () => {
    deleteHeroImage({
      variables: {
        id: imageId,
      },
    });
    setDeleteDialogOpen(false);
    updateStoryHeroError(null);
  };

  const onDeleteVideo = () => {
    deleteVideoAndImage({
      variables: {
        imageId,
        videoId,
      },
    });
    setDeleteDialogOpen(false);
  };

  const openImageUploadDiaglog = () => {
    const minImageWidth =
      subtype === ARTICLE_SUBTYPE.FEATURE ? MIN_IMAGE_WIDTH : 0;

    const options = {
      clientAllowedFormats: ['png', 'jpg', 'gif', 'jpeg'],
      image_metadata: true,
      maxFileSize: 20000000,
      maxFiles: 1,
      minImageWidth,
      multiple: false,
      showPoweredBy: false,
      sources: ['local'],
      theme: 'minimal',
      uploadPreset: process.env.CLOUDINARY_UPLOAD_PRESET,
    };

    const widget = global.cloudinary.createUploadWidget(
      options,
      createCloudinaryUploadWidgetCallback({
        handleResult: (result) => {
          if (result?.event !== 'close') return;
          // Re-enable the left button when the widget is closed
          setIsLeftButtonDisabled(false);
        },
        onError: ({ message }) => updateStoryHeroError(message),
        onSuccess: ({ validatedImage }) => {
          addMainImage({
            variables: {
              articleId: id,
              image: validatedImage,
              isMainImage: true,
            },
          });
          updateStoryHeroError('');
        },
      })
    );
    widget.open();
  };

  const openVideoUploadDialog = () => {
    updateVideoModalOpen(true);
  };

  let content = null;

  if (image && video) {
    content = (
      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
          }}
        >
          <PlayCircleOutlineIcon
            style={{
              height: '200px',
              opacity: 0.75,
              padding: '30px',
              width: '200px',
            }}
          />
        </div>
        <CloudinaryImage publicId={image.publicId} alt={image.altText}>
          <Transformation width='540' height='304' crop='limit' />
        </CloudinaryImage>
      </div>
    );
  } else if (image) {
    content = (
      <CloudinaryImage publicId={image.publicId} alt={image.altText}>
        <Transformation width='540' height='304' crop='limit' />
      </CloudinaryImage>
    );
  } else {
    content = (
      <BackupIcon
        color='disabled'
        style={{
          height: '200px',
          opacity: 0.25,
          padding: '30px',
          width: '200px',
        }}
      />
    );
  }

  let errorNode = null;
  if (errorMessage) {
    errorNode = <Alert severity='error'>{errorMessage}</Alert>;
  }

  const renderLeftButton = () => {
    let leftButtonClick;
    let leftButtonLabel;
    let leftButtonColor;
    if (video) {
      leftButtonClick = onEditVideo;
      leftButtonLabel = 'Edit';
      leftButtonColor = 'default';
    } else if (image) {
      leftButtonClick = onEditImage;
      leftButtonLabel = 'Edit';
      leftButtonColor = 'default';
    } else if (!isGallery) {
      leftButtonClick = (e) => {
        setIsLeftButtonDisabled(true);
        openImageUploadDiaglog(e);
      };
      leftButtonLabel = 'Upload Image';
    }

    return (
      <Button
        className='btn'
        color={leftButtonColor}
        disabled={isLeftButtonDisabled}
        fullWidth
        label={leftButtonLabel}
        onClick={leftButtonClick}
        style={{ flex: 1 }}
      />
    );
  };

  const renderRightButton = () => {
    let rightButtonClick;
    let rightButtonLabel;
    let rightButtonColor;
    if (video) {
      rightButtonClick = () => setDeleteDialogOpen(true);
      rightButtonLabel = 'Remove';
      rightButtonColor = 'secondary';
    } else if (image) {
      rightButtonClick = () => setDeleteDialogOpen(true);
      rightButtonLabel = 'Remove';
      rightButtonColor = 'secondary';
    } else if (!isGallery) {
      rightButtonClick = openVideoUploadDialog;
      rightButtonLabel = 'Video';
    }

    return (
      <Button
        fullWidth
        className='btn'
        style={{ flex: 1 }}
        label={rightButtonLabel}
        color={rightButtonColor}
        onClick={rightButtonClick}
      />
    );
  };

  const renderDeleteDialog = () => {
    const deleteText = videoId
      ? 'Are you sure you want to delete this video?'
      : 'Are you sure you want to delete this image?';
    const onDeleteMedia = videoId ? onDeleteVideo : onDeleteImage;

    return (
      <DeleteDialog
        open={deleteDialogOpen}
        text={deleteText}
        onDelete={onDeleteMedia}
        onClose={() => setDeleteDialogOpen(false)}
      />
    );
  };

  return (
    <>
      <div className='StoryHero'>
        <div>
          <Grid
            style={{
              alignItems: 'center',
              border: image ? 'none' : `1px solid ${colors.lighterGrey}`,
              height: '300px',
              marginBottom: '20px',
            }}
            container
            justify='center'
          >
            <Grid item>{content}</Grid>
          </Grid>
        </div>

        {errorNode}

        <Grid
          style={{
            marginBottom: '20px',
            marginTop: '10px',
          }}
          container
          spacing={3}
        >
          <Grid item style={{ flex: 1 }}>
            {renderLeftButton()}
          </Grid>
          <Grid item style={{ flex: 1 }}>
            {renderRightButton()}
          </Grid>
        </Grid>
        <Divider light style={{ marginTop: '5px' }} />
      </div>
      {renderDeleteDialog()}
    </>
  );
}

export default StoryHero;
