import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import shortid from 'shortid';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import LinkIcon from '@material-ui/icons/Link';
import CardContent from '@material-ui/core/CardContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import { colors } from '../../../../../styles/global/variables';
import SvgSymbol, {
  SVG_SYMBOLS,
} from '../../../../../common/SvgSymbol/SvgSymbol';

export const FANCY_LINKS_CARD_NAME = 'pt-fancy-links-card';

const useStyles = makeStyles({
  card: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  svgIcon: {
    height: '120px',
    width: '120px',
    opacity: '0.6',
    margin: '0 auto 10px',
    display: 'block',
  },
  cardHeader: {
    'padding-bottom': 0,
  },
  link: {
    color: colors.blue,
  },
});

export default function FancyLinksCard(props) {
  const classes = useStyles();
  const {
    payload: { id, links },
    actions: {
      updateFancyLinksId,
      updateSaveCardFunction,
      updateFancyLinksPickerOpen,
    },
    save,
    edit,
  } = props;

  const onEdit = () => {
    updateFancyLinksId(id);
    updateSaveCardFunction(save);
    updateFancyLinksPickerOpen(true);

    edit();
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        className={classes.cardheader}
        action={
          <IconButton onClick={onEdit} aria-label="embed">
            <EditIcon />
          </IconButton>
        }
        title="Fancylinks"
      />
      <CardContent>
        <SvgSymbol className={classes.svgIcon} name={SVG_SYMBOLS.FANCY_LINKS} />
      </CardContent>

      <List dense>
        {links.map(link => (
          <Fragment key={shortid.generate()}>
            <Divider />
            <ListItem button>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <a
                    className={classes.link}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link}
                  </a>
                }
              />
            </ListItem>
          </Fragment>
        ))}
      </List>
    </Card>
  );
}

FancyLinksCard.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.string),
    linksData: PropTypes.arrayOf(PropTypes.object),
  }),
  actions: PropTypes.shape({
    updateSaveCardFunction: PropTypes.func,
    updateFancyLinksId: PropTypes.func,
    updateFancyLinksPickerOpen: PropTypes.func,
  }),
  save: PropTypes.func,
  edit: PropTypes.func,
};
