import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import STORY_TYPE from '../utils/story-type';
import { ROUTE_NAME } from '../app/constants';
import { SITE, Site as SiteInfo } from '../helpers/site';
import { redirectObsessedStory } from '../helpers/obsessed';

export const StoryContext = React.createContext();

const UPDATE_TYPE = 'UPDATE_TYPE';
const UPDATE_SITE = 'UPDATE_SITE';
const UPDATE_OBSESSED_TAGS = 'UPDATE_OBSESSED_TAGS';
const UPDATE_ID = 'UPDATE_ID';
const UPDATE_SUBTYPE = 'UPDATE_SUBTYPE';
const UPDATE_PUBLICATION_DATE = 'UPDATE_PUBLICATION_DATE';
const UPDATE_HAS_PUBLISHED_VERSION = 'UPDATE_HAS_PUBLISHED_VERSION';
const UPDATE_UNLOCK_PENDING = 'UPDATE_UNLOCK_PENDING';
const UPDATE_UNLOCK_DATE = 'UPDATE_UNLOCK_DATE';
const UPDATE_STATUS = 'UPDATE_STATUS';
const UPDATE_SLUG = 'UPDATE_SLUG';
const UPDATE_SEO_HEADLINE = 'UPDATE_SEO_HEADLINE';
const UPDATE_UPDATED_AT = 'UPDATE_UPDATED_AT';
const UPDATE_SCHEDULED = 'UPDATE_SCHEDULED';

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_SITE:
      return { ...state, site: action.data };
    case UPDATE_OBSESSED_TAGS:
      return { ...state, obsessed_tags: action.data };
    case UPDATE_TYPE:
      return { ...state, type: action.data };
    case UPDATE_ID:
      return { ...state, id: action.data };
    case UPDATE_PUBLICATION_DATE:
      return { ...state, publicationDate: action.data };
    case UPDATE_HAS_PUBLISHED_VERSION:
      return { ...state, hasPublishedVersion: action.data };
    case UPDATE_UNLOCK_PENDING:
      return { ...state, unlockPending: action.data };
    case UPDATE_UNLOCK_DATE:
      return { ...state, unlockDate: action.data };
    case UPDATE_STATUS:
      return { ...state, status: action.data };
    case UPDATE_SUBTYPE:
      return { ...state, subtype: action.data };
    case UPDATE_SLUG:
      return { ...state, slug: action.data };
    case UPDATE_SEO_HEADLINE:
      return { ...state, seoHeadline: action.data };
    case UPDATE_UPDATED_AT:
      return { ...state, updatedAt: action.data };
    case UPDATE_SCHEDULED:
      return { ...state, scheduled: action.data };
    default:
      throw new Error('Error updating story');
  }
}

function StoryContextProvider({ value, children }) {
  const [state, updateState] = useReducer(reducer, value);

  const {
    site: { id = SITE.THE_DAILY_BEAST },
  } = state;

  const updateStoryType = (storyType) => {
    updateState({ data: storyType, type: UPDATE_TYPE });
  };
  const updateStorySite = (site) => {
    updateState({ data: site, type: UPDATE_SITE });
  };
  const updateStoryObsessedTags = (tags) => {
    updateState({ data: site, type: UPDATE_OBSESSED_TAGS });
  };
  const updateId = (id) => {
    updateState({ data: id, type: UPDATE_ID });
  };
  const updatePublicationDate = (publicationDate) => {
    updateState({ data: publicationDate, type: UPDATE_PUBLICATION_DATE });
  };
  const updateHasPublishedVersion = (hasPublishedVersion) => {
    updateState({
      data: hasPublishedVersion,
      type: UPDATE_HAS_PUBLISHED_VERSION,
    });
  };
  const updateUnlockPending = (unlockPending) => {
    updateState({ data: unlockPending, type: UPDATE_UNLOCK_PENDING });
  };
  const updateUnlockDate = (unlockDate) => {
    updateState({ data: unlockDate, type: UPDATE_UNLOCK_DATE });
  };
  const updateSubtype = (subtype) => {
    updateState({ data: subtype, type: UPDATE_SUBTYPE });
  };
  const updateStatus = (status) => {
    updateState({ data: status, type: UPDATE_STATUS });
  };
  const updateSlug = (slug) => {
    updateState({ data: slug, type: UPDATE_SLUG });
  };
  const updateSeoHeadline = (seoHeadline) => {
    updateState({ data: seoHeadline, type: UPDATE_SEO_HEADLINE });
  };
  const updateUpdatedAt = (updatedAt) => {
    updateState({ data: updatedAt, type: UPDATE_UPDATED_AT });
  };
  const updateScheduled = (scheduled) => {
    updateState({ data: scheduled, type: UPDATE_SCHEDULED });
  };

  const getListItems = () => {
    const isObsessed = state.site?.id === SITE.OBSESSED;
    const isGallery = state.type === STORY_TYPE.GALLERY;

    const maybeBody = isGallery
      ? []
      : [{ displayText: 'Body', slug: ROUTE_NAME.BODY }];

    const maybeObsessed = isObsessed
      ? [
          {
            displayText: 'Classification',
            slug: ROUTE_NAME.OBSESSED_CLASSIFICATION,
          },
        ]
      : [];

    return [
      { displayText: 'MainInfo', slug: ROUTE_NAME.MAIN_INFO },
      ...maybeBody,
      ...maybeObsessed,
      {
        displayText: STORY_TYPE.GALLERY ? 'Images' : 'Media',
        slug: ROUTE_NAME.IMAGES,
      },
      { displayText: 'Metadata', slug: ROUTE_NAME.METADATA },
    ];
  };

  if (redirectObsessedStory(id)) {
    const { pathname } = window.location;

    return (
      <Redirect
        exact
        from={`${pathname}`}
        to={`/${SiteInfo.obsessed.slugPrefix}${pathname}`}
      />
    );
  }

  return (
    <StoryContext.Provider
      value={{
        ...state,
        getListItems,
        updateHasPublishedVersion,
        updateId,
        updatePublicationDate,
        updateScheduled,
        updateSeoHeadline,
        updateSlug,
        updateStatus,
        updateStoryObsessedTags,
        updateStorySite,
        updateStoryType,
        updateSubtype,
        updateUnlockDate,
        updateUnlockPending,
        updateUpdatedAt,
      }}
    >
      {children}
    </StoryContext.Provider>
  );
}

StoryContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.shape({
    hasPublishedVersion: PropTypes.bool,
    id: PropTypes.string,
    publicationDate: PropTypes.string,
    scheduled: PropTypes.bool,
    seoHeadline: PropTypes.string,
    slug: PropTypes.string,
    status: PropTypes.string,
    storyType: PropTypes.string,
    subtype: PropTypes.string,
    unlockDate: PropTypes.string,
    unlockPending: PropTypes.bool,
    updatedAt: PropTypes.string,
  }).isRequired,
};

export default StoryContextProvider;
