export const UPDATE_CATEGORY = 'publishing-toolkit/category/UPDATE_CATEGORY';
export const UPDATE_CATEGORY_FIELD = 'publishing-toolkit/category/UPDATE_CATEGORY_FIELD';
export const UPDATE_CATEGORY_FEATURED_ARTICLE = 'publishing-toolkit/category/UPDATE_CATEGORY_FEATURED_ARTICLE';
export const DELETE_CATEGORY_FEATURED_ARTICLE = 'publishing-toolkit/category/DELETE_CATEGORY_FEATURED_ARTICLE';

export function updateCategoryData(data) {
  return {
    type: UPDATE_CATEGORY,
    data,
  };
}

export function updateCategoryField(key, value) {
  return {
    type: UPDATE_CATEGORY_FIELD,
    key,
    value,
  };
}

export function updateCategoryFeaturedArticle(key, value, data) {
  return {
    type: UPDATE_CATEGORY_FEATURED_ARTICLE,
    key,
    value,
    data,
  };
}

export function deleteCategoryFeaturedArticle() {
  return {
    type: DELETE_CATEGORY_FEATURED_ARTICLE,
  };
}
