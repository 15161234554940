mutation CreatePageDraft($pageName: String!) {
  createDraft(pageName: $pageName) {
    id
    name
    status
    sections {
      id
      position
      productCallout: product_callout {
        ... on MembershipProductCallout {
          type
        }

        ... on NewsletterProductCallout {
          lists
          type
        }
      }
      stories {
        id
        longHeadline: long_headline
        mainImage: main_image {
          publicId: public_id
          version
        }
      }
      title
      subtitle
    }
  }
}
