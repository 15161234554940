import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import updateNavItemsMutation from '../../../mutations/navigation/updateNavItems.gql';

const UPDATE_NAV_ITEMS_MUTATION = gql`
  ${updateNavItemsMutation}
`;

export default function useSaveNav () {
  return useMutation(UPDATE_NAV_ITEMS_MUTATION);
}
