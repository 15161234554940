const PRODUCT_NAME = [
  { id: 1, name: 'standard' },
  { id: 2, name: 'royalist' },
  { id: 3, name: 'sneakpeek' },
  { id: 4, name: 'opinion' },
  { id: 5, name: '2020' },
  { id: 6, name: 'analysis' },
];

export default PRODUCT_NAME;
