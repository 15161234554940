import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import merge from 'lodash/merge';

import { HomepageContextProvider } from './HomepageContext';
import HomepageNav from './HomepageNav';
import HomepageSnackbar from './HomepageSnackbar';
import Section from './Section';
import section1styles from './Section1.module.scss';
import section2styles from './Section2.module.scss';
import section3styles from './Section3.module.scss';
import section4styles from './Section4.module.scss';
import section5styles from './Section5.module.scss';

export function makeSharedLayoutStyles(theme, customStyles = {}) {
  return merge(
    {
      main: {
        display: 'grid',
        gridTemplateAreas: `
        'header'
        'section1'
        'break1'
        'section2'
        'break2'
        'section3'
        'break3'
        'section4'
        'break4'
        'section5'
        `,
        margin: '6.5rem auto 0',
        maxWidth: 475,
        padding: '0 0.8rem 0.8rem',
      },
      header: { gridArea: 'header' },
      section1: { gridArea: 'section1' },
      break1: { gridArea: 'break1' },
      section2: { gridArea: 'section2' },
      break2: { gridArea: 'break2' },
      section3: { gridArea: 'section3' },
      break3: { gridArea: 'break3' },
      section4: { gridArea: 'section4' },
      break4: { gridArea: 'break4' },
      section5: { gridArea: 'section5' },
      sectionBreak: {
        height: '4rem',
        margin: `${theme.spacing(2) * 0.1}rem 0`,
        position: 'relative',
        width: '100%',
        '& > div': {
          background: '#d1d5d5',
          height: '100%',
          left: '50%',
          position: 'absolute',
          top: 0,
          transform: `translateX(-50%)`,
          width: '100vw',
        },
      },
      title: {
        fontSize: '2rem',
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        main: {
          gridTemplateAreas: `
          'header header'
          'section1 section1'
          'break1 break1'
          'section2 section3'
          'break2 break2'
          'section4 section4'
          'break3 break3'
          'section5 section5'
          'break4 break4'
        `,
          gridTemplateColumns: '1fr 1fr',
          maxWidth: 700,
          padding: '0 1.6rem 1.6rem',
        },
      },
      [theme.breakpoints.up('md')]: {
        main: {
          gridTemplateAreas: `
          'header header header header'
          'section1 section1 section1 section1'
          'break1 break1 break1 break1'
          'section2 section2 section2 section3'
          'break2 break2 break2 break2'
          'section4 section4 section4 section4'
          'break3 break3 break3 break3'
          'section5 section5 section5 section5'
          'break4 break4 break4 break4'
        `,
          gridTemplateColumns: 'repeat(4, 1fr)',
          maxWidth: 1100,
        },
        title: {
          fontSize: '3rem',
          marginBottom: theme.spacing(3),
        },
      },
    },
    customStyles,
  );
}

const useStyles = makeStyles(theme => makeSharedLayoutStyles(theme));

Homepage.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

export default function Homepage({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  return (
    <HomepageContextProvider>
      <HomepageNav />
      <main className={classes.main}>
        <div className={classes.header}>
          <Typography align="center" className={classes.title} variant="h1">
            Obsessed Landing Page
          </Typography>
        </div>
        <Section
          section="section1"
          styles={{ additionalClass: classes.section1, ...section1styles }}
          title="Topbox"
        />
        <div className={`${classes.break1} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          isSubtitleEditable
          section="section2"
          styles={{ additionalClass: classes.section2, ...section2styles }}
          title="Obsessional"
        />
        <div className={`${classes.break2} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          section="section3"
          styles={{ additionalClass: classes.section3, ...section3styles }}
        />
        <div className={`${classes.break3} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          isTitleEditable
          isSubtitleEditable
          section="section4"
          styles={{ additionalClass: classes.section4, ...section4styles }}
        />
        <div className={`${classes.break4} ${classes.sectionBreak}`}>
          <div />
        </div>
        <Section
          section="section5"
          styles={{ additionalClass: classes.section5, ...section5styles }}
        />
        <HomepageSnackbar />
      </main>
    </HomepageContextProvider>
  );
}
