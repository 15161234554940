query HomepageAnalyticsQuery {
  getPageAnalytics(siteId: "1") {
    name
    realTimePercentOfTotalClicks
    thirtyDayPercentOfTotalClicks
    section
    slot
    realTimeTotalClicks
  }
}
