query VerticalsQuery{
  verticals {
    id
    slug
    name
    subverticals {
      id
      slug
      name
    }
  }
}