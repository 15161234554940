import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FormHelperText } from '@material-ui/core';
import { LABEL } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import sensitiveFlagsQueryGQL from './graphql/sensitiveFlagsQuery.gql';
import sensitiveFlagMutationGQL from './graphql/sensitiveFlagMutation.gql';

const SENSITIVE_CONTENT_FLAG = {
  NSFW: 'explicit',
  TRAGEDY: 'tragedy',
  VIOLENCE: 'violence',
};

const useStyles = makeStyles(() =>
  createStyles({
    sensitiveContentArea: {
      'padding-top': '10px',
    },
    sensitiveContentLabel: {
      'padding-top': '25px',
    },
  })
);

const sensitiveFlagsQuery = gql`
  ${sensitiveFlagsQueryGQL}
`;

const sensitiveFlagMutation = gql`
  ${sensitiveFlagMutationGQL}
`;

function SensitiveContentFlagsSection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [violence, setViolence] = useState(false);
  const [explicit, setExplicit] = useState(false);
  const [tragedy, setTragedy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [loadSensitiveFlags] = useLazyQuery(sensitiveFlagsQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const metadata = data && data.story && data.story.metadata;
      setViolence(metadata.violence);
      setExplicit(metadata.explicit);
      setTragedy(metadata.tragedy);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateSensitiveFlag] = useMutation(sensitiveFlagMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const sensitiveFlagsData = data && data.sensitiveFlagsData;
      setViolence(sensitiveFlagsData.violence);
      setExplicit(sensitiveFlagsData.explicit);
      setTragedy(sensitiveFlagsData.tragedy);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadSensitiveFlags({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (flag, e) => {
    updateSensitiveFlag({
      variables: {
        articleId: storyContext.id,
        metadata: {
          [flag]: e.target.checked,
        },
      },
    });
  };

  return (
    <Grid item xs={12} md={8}>
      <FormControl component='fieldset'>
        <FormLabel className={classes.sensitiveContentLabel}>
          Sensitive Content Flags
        </FormLabel>

        <FormGroup>
          <FormControlLabel
            className={classes.sensitiveContentArea}
            control={
              <Switch
                checked={violence}
                onChange={(e) => onChange(SENSITIVE_CONTENT_FLAG.VIOLENCE, e)}
                value={SENSITIVE_CONTENT_FLAG.VIOLENCE}
              />
            }
            label={LABEL.VIOLENCE}
          />

          <small>
            Content with graphic depictions of violence, harassment, or weapon
            use.
          </small>
          <FormControlLabel
            className={classes.sensitiveContentArea}
            control={
              <Switch
                checked={explicit}
                onChange={(e) => onChange(SENSITIVE_CONTENT_FLAG.NSFW, e)}
                value={SENSITIVE_CONTENT_FLAG.NSFW}
              />
            }
            label={LABEL.NSFW}
          />
          <small>
            Content or image containing pornography or sexually explicit
            situations. Headline or hero image includes obscenities or illegal
            substance use.
          </small>
          <FormControlLabel
            className={classes.sensitiveContentArea}
            control={
              <Switch
                checked={tragedy}
                onChange={(e) => onChange(SENSITIVE_CONTENT_FLAG.TRAGEDY, e)}
                value={SENSITIVE_CONTENT_FLAG.TRAGEDY}
              />
            }
            label={LABEL.TRAGEDY}
          />
          <small>
            Content concerning natural disasters, mass casualty events,
            terrorist attacks, murder, or suicide.
          </small>
        </FormGroup>
      </FormControl>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Grid>
  );
}

export default SensitiveContentFlagsSection;
