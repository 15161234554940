mutation DeleteAutoDataMutation($articleId: ID!) {
  autoThreats: deleteArticleAutoThreats(articleId: $articleId) {
    id
    category
    confidence
  }
  autoKeywords: deleteArticleAutoKeywords(articleId: $articleId) {
    id
    name
  }
  autoIABCategory: deleteArticleAutoIABCategory(articleId: $articleId) {
    id
    category
    score
  }
  autoSentiment: deleteArticleAutoSentiment(articleId: $articleId) {
    positive
    negative
    neutral
  }
  autoBrandSafe: deleteArticleAutoBrandSafe(articleId: $articleId) {
    id
    safe
  }
}