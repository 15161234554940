import { get } from 'lodash';

export const SITE = {
  THE_DAILY_BEAST: '1',
  OBSESSED: '2',
};

export const Site = {
  tdb: {
    id: '1',
    slugPrefix: '',
    name: `The Daily Beast`,
  },
  obsessed: {
    id: '2',
    slugPrefix: 'obsessed',
    name: `The Daily Beast's Obsessed`,
  },
};

export const siteForId = (id) => Object.values(Site).find((s) => s.id === id);

export const siteLink = (story) => {
  const site = siteForId(story?.site?.id);
  if (!site) {
    return story.slug;
  }

  return `${site.slugPrefix}/${story.slug}`;
};
