import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { LABEL, PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import ShortHeadlineQueryGQL from './graphql/shortHeadlineQuery.gql';
import ShortHeadlineMutationGQL from './graphql/shortHeadlineMutation.gql';
import { Context as ValidationContext } from '../../../../../context/ValidationContext';

const shortHeadlineQuery = gql`
  ${ShortHeadlineQueryGQL}
`;

const shortHeadlineMutation = gql`
  ${ShortHeadlineMutationGQL}
`;

function HomepageHeadline() {
  const storyContext = useContext(StoryContext);
  const [shortHeadline, setShortHeadline] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { errors, getErrorByFieldName } = useContext(ValidationContext);

  const [loadShortHeadline] = useLazyQuery(shortHeadlineQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const shortHeadlineData = data && data.story && data.story.shortHeadline;
      if (shortHeadlineData) setShortHeadline(data.story.shortHeadline);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateShortHeadline] = useMutation(shortHeadlineMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (getErrorByFieldName('short_headline')) {
      setErrorMessage(getErrorByFieldName('short_headline').message);
    }
  }, [errors]);

  useEffect(() => {
    if (storyContext.id) {
      loadShortHeadline({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) => {
    const value = e && e.target && e.target.value;
    setShortHeadline(value);
    updateShortHeadline({
      variables: {
        id: storyContext.id,
        story: {
          short_headline: value,
        },
      },
    });
  };

  let helperMessage = `${shortHeadline.length}/60`;
  let hasError = false;

  if (errorMessage) {
    hasError = true;
    helperMessage = `${shortHeadline.length}/60 ${errorMessage}`;
  }

  return (
    <Grid item xs={12} md={8}>
      <TextField
        label={LABEL.SHORT_HEADLINE}
        placeholder={PLACEHOLDER.SHORT_HEADLINE}
        fullWidth
        onChange={onChange}
        value={shortHeadline}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={onChange}
        error={hasError}
        helperText={helperMessage}
      />
    </Grid>
  );
}

export default HomepageHeadline;
