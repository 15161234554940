query videoQuery($videoId: ID!) {
  video(id: $videoId) {
    id
    mixedContent: mixed_content
    type
    title
    caption
    credit
  }
}
