/* eslint-disable import/no-import-module-exports */
import 'core-js/stable';
import './initializeErrorHandling';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as Msal from '@azure/msal-browser';
import ToolkitProvider from './app/ToolkitProvider';
import { v0ThemeSettings, v4ThemeSettings } from './app/theme';
import { logError } from './helpers/logError';
import { handleError } from './helpers/global-error-handler';

const theme = createTheme(v4ThemeSettings); // v1.0
const themeV0 = getMuiTheme(v0ThemeSettings); // v0.18.0
// Used by snippet for feature switch style functionality
global.window.settings = { ENABLE_SNIPPET: false };

const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.AZURE_TENANT_ID}`,
    clientId: process.env.AZURE_CLIENT_ID,
    navigateToLoginRequestUrl: false,
    redirectUri: process.env.AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    claimsBasedCachingEnabled: true,
    storeAuthStateInCookie: true,
  },
  system: {
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    logLevel: Msal.LogLevel.Verbose,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case Msal.LogLevel.Error:
            console.error(message);
            return;
          case Msal.LogLevel.Info:
            console.info(message);
            return;
          case Msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case Msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.error(`Unknown MSAL Error`);
        }
      },
    },
    windowHashTimeout: 60000,
  },
};

async function initializeMsal() {
  const PublicClientApplication = new Msal.PublicClientApplication(msalConfig);
  await PublicClientApplication.initialize();
  return PublicClientApplication;
}

async function renderApp() {
  try {
    const msalInstance = await initializeMsal();
    global.PublicClientApplication = msalInstance;

    if (window.self === window.top) {
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <V0MuiThemeProvider muiTheme={themeV0}>
            <ToolkitProvider />
          </V0MuiThemeProvider>
        </ThemeProvider>,
        document.getElementById('root')
      );
    } else {
      logError('window.self !== window.top', {
        afterCapture: handleError,
      });
    }
  } catch (error) {
    logError(error, {
      afterCapture: handleError,
    });
  }
}

renderApp();

if (module.hot) {
  module.hot.accept();
}
