.red-dot {
  animation: article-active-users-indicator-pulse 1s infinite;
  background: #ea0606;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 3px 2px #ea0606;
  cursor: pointer;
  height: 15px;
  margin: 0 10px;
  transition: transform 0.3s;
  width: 15px;
}
