import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { ApolloProvider, ApolloLink, ApolloClient } from '@apollo/client';

import { InMemoryCache } from '@apollo/client/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import DebounceLink from 'apollo-link-debounce';
import createRootReducer from './reducers';
import { Router } from './Router';
import AuthContext from '../context/auth/AuthContext';
import ptFetch from '../helpers/pt-fetch';

const DEFAULT_DEBOUNCE_TIMEOUT = 3000;

const cache = new InMemoryCache({
  addTypename: false,
});

const batchLink = new BatchHttpLink({
  batchInterval: 100,
  batchMax: 10,
  fetch: ptFetch,
  uri: `${process.env.PTS_HOST}/graphql`,
});

const link = ApolloLink.from([
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  batchLink,
]);

const client = new ApolloClient({
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  },
  link,
});

export default class ToolkitProvider extends Component {
  constructor(props) {
    super(props);

    this.store = null;
  }

  render() {
    const { data: initialState } = this.props;
    // TODO: envify?
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const history = createBrowserHistory();

    this.store = createStore(
      createRootReducer(history), // root reducer with router state
      initialState,
      composeEnhancers(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          reduxThunk
        )
      )
    );

    global.store = this.store;
    return (
      <ApolloProvider client={client}>
        <Provider store={this.store}>
          <AuthContext>
            <ConnectedRouter history={history}>
              <Router store={this.store} client={client} />
            </ConnectedRouter>
          </AuthContext>
        </Provider>
      </ApolloProvider>
    );
  }
}

ToolkitProvider.propTypes = {
  data: PropTypes.object,
};
