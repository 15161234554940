.section {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-areas:
    'title'
    'slot1'
    'slot2'
    'slot3'
    'slot4';
  grid-template-columns: 1fr;
  margin: 0 auto;
  width: 100%;
}

.title {
  grid-area: title;
}

@for $i from 1 through 5 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

@media screen and (min-width: 600px) {
  .section {
    grid-template-areas:
      '. title title .'
      'slot1 slot2 slot3 slot3'
      'slot1 slot2 slot4 slot4';
    grid-template-columns: repeat(4, 1fr);
  }

  .slot3,
  .slot4 {
    display: grid;
    grid-gap: 1.2rem;
    grid-template: 'image input' auto / 1fr 1fr;
  }

  .image {
    grid-area: image;
  }

  .input {
    grid-area: input;
  }
}
