query ObsessedAnalyticsQuery {
  getPageAnalytics(siteId: "2") {
    name
    realTimePercentOfTotalClicks
    thirtyDayPercentOfTotalClicks
    section
    slot
    realTimeTotalClicks
  }
}
