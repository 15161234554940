import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { drawerWidth } from '../../../../../common/Sidebar/Sidebar';
import { Site } from '../../../../../helpers/site';
import {
  dismissFormFieldErrorsAction,
  successMessageAction,
} from '../../../../../redux/actions/messages';
import STORY_TYPE from '../../../../../utils/story-type';
import { StoryContext } from '../../../../../context/StoryContext';
import AudienceSection from '../AudienceSection/AudienceSection';
import StoryTypeSection from '../StoryTypeSection/StoryTypeSection';
import RubricSection from '../RubricSection/RubricSection';
import SeoHeadlineSection from '../SeoHeadlineSection/SeoHeadlineSection';
import ArticleHeadlineSection from '../ArticleHeadlineSection/ArticleHeadlineSection';
import SocialHeadlineSection from '../SocialHeadlineSection/SocialHeadlineSection';
import HomepageHeadlineSection from '../HomepageHeadlineSection/HomepageHeadlineSection';
import DekSection from '../DekSection/DekSection';
import VerticalSection from '../VerticalSection/VerticalSection';
import FranchiseSection from '../FranchiseSection/FranchiseSection';
import AuthorSection from '../AuthorSection/AuthorSection';
import EditorSection from '../EditorSection/EditorSection';
import KeywordSection from '../KeywordSection/KeywordSection';
import CrossPromotionSection from '../CrossPromotionSection/CrossPromotionSection';
import SensitiveContentFlagsSection from '../SensitiveContentFlagsSection/SensitiveContentFlagsSection';
import ReadItAtSection from '../ReadItAtSection/ReadItAtSection';
import PublicationDateSection from '../PublicationDateSection/PublicationDateSection';
import DeleteStorySection from '../DeleteStorySection/DeleteStorySection';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  readItAtArea: {
    'padding-top': '20px',
  },
  rootGrid: {
    'padding-top': '25px',
  },
  rootGridWithDrawerOffset: {
    'padding-right': `${drawerWidth}px`,
  },
  sensitiveContentArea: {
    'padding-top': '10px',
  },
  sensitiveContentLabel: {
    'padding-top': '25px',
  },
  group: {
    display: 'inline-flex',
    flexDirection: 'row',
    margin: `${theme.spacing}px 0`,
  },
  appBar: theme.mixins.toolbar,
  deleteButton: {
    'margin-top': '25px',
    'margin-bottom': '25px',
  },
  dontScaleFont: {
    '& label': {
      fontSize: '16px!important',
    },
  },
});

MainInfo.propTypes = {
  actions: PropTypes.object,
  width: PropTypes.number,
  classes: PropTypes.object.isRequired,
  messages: PropTypes.shape({
    errors: PropTypes.array,
  }),
};

const mediaSizes = {
  sm: true,
  md: true,
  lg: true,
  xl: true,
};

function MainInfo(props) {
  const storyContext = useContext(StoryContext);
  const { classes, width } = props;

  const rootGridClassWithDrawerOffset = classnames({
    [classes.rootGridWithDrawerOffset]: mediaSizes[width],
    rootGrid: true,
  });

  const handleCopySlug = () => {
    props.actions.successMessageAction('Slug successfully copied.');
  };

  if (storyContext.type === STORY_TYPE.GALLERY) {
    return (
      <Container className={rootGridClassWithDrawerOffset}>
        <div className={classes.appBar} />
        <Grid
          spacing={4}
          className={classes.rootGrid}
          container
          justify="center"
        >
          Gallery Editing is no longer supported
        </Grid>
      </Container>
    );
  }

  return (
    <Container className={rootGridClassWithDrawerOffset}>
      <div className={classes.appBar} />
      <form>
        <Grid
          spacing={4}
          className={classes.rootGrid}
          container
          justify="center"
        >
          <AudienceSection />
          <StoryTypeSection />
          <RubricSection />
          <SeoHeadlineSection onCopy={handleCopySlug} />
          <ArticleHeadlineSection />
          <SocialHeadlineSection />
          <HomepageHeadlineSection />
          <DekSection />
          {storyContext.site.id !== Site.obsessed.id && <VerticalSection />}
          {storyContext.site.id !== Site.obsessed.id && <FranchiseSection />}
          <AuthorSection />
          <EditorSection />
          {storyContext.site.id !== Site.obsessed.id && <KeywordSection />}
          <CrossPromotionSection />
          <SensitiveContentFlagsSection />
          {storyContext.type === STORY_TYPE.CHEAT && <ReadItAtSection />}
          <PublicationDateSection />
          <DeleteStorySection />
        </Grid>
      </form>
    </Container>
  );
}

function mapStateToProps({ messages, ui }) {
  return {
    deleteArticleModalOpen: ui.deleteArticleModalOpen,
    messages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        dismissFormFieldErrorsAction,
        successMessageAction,
      },
      dispatch,
    ),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withWidth(),
  withRouter,
)(MainInfo);
