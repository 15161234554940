import React from 'react';
import * as Sentry from '@sentry/react';

const noOp = () => {};

function FallbackDefault({ error }) {
  return (
    <>
      <p style={{ color: 'red' }}>
        An error has occurred. In order to aid the engineering team in debugging
        this error, please notify us in the Slack #web-support channel with the
        following error message:
      </p>
      <p>
        <code
          style={{
            background: 'tomato',
            borderRadius: 2,
            color: 'white',
            margin: '4px 0',
            padding: 4,
          }}
        >
          {error.toString()}
        </code>
      </p>
    </>
  );
}

/**
 * Error Boundary. Utilizes Sentry to capture uncaught errors.
 * https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary
 * @constructor
 * @param {Object} props - Component properties.
 * @param {Function} props.beforeCapture - A function that gets called before an error is sent to Sentry, allowing for extra tags or context to be added to the error.
 * @param {React.ReactNode} props.children - The children to render.
 * @param {React.ReactNode} props.fallback - The fallback component to render.
 * @param {Function} props.onError - A function that gets called when the Error Boundary encounters an error. onError is useful if you want to propagate the error into a state management library like Redux, or if you want to check any side effects that could have occurred due to the error.
 * @param {Function} props.onMount - A function that gets called on ErrorBoundary componentDidMount().
 * @param {Function} props.onUnmount - A function that gets called on ErrorBoundary componentWillUnmount().
 * @param {boolean} props.showDialog - If a Sentry User Feedback Widget should be rendered when the Error Boundary catches an error.
 */
export function ErrorBoundary({
  beforeCapture = noOp,
  children,
  fallback = FallbackDefault,
  onError = noOp,
  onMount = noOp,
  onUnmount = noOp,
  showDialog,
}) {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={fallback}
      onError={onError}
      onMount={onMount}
      onUnmount={onUnmount}
      showDialog={showDialog}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

/**
 * Error Boundary higher-order component. Utilizes Sentry to capture uncaught errors.
 * https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary
 *
 * @param {React.Component} Component - The component to wrap.
 * @param {Object} options - Options for the Error Boundary.
 * @param {Function} options.beforeCapture - A function that gets called before an error is sent to Sentry, allowing for extra tags or context to be added to the error.
 * @param {React.ReactNode} options.fallback - The fallback component to render.
 * @param {Function} options.onError - A function that gets called when the Error Boundary encounters an error. onError is useful if you want to propagate the error into a state management library like Redux, or if you want to check any side effects that could have occurred due to the error.
 * @param {Function} options.onMount - A function that gets called on ErrorBoundary componentDidMount().
 * @param {Function} options.onUnmount - A function that gets called on ErrorBoundary componentWillUnmount().
 * @param {boolean} options.showDialog - If a Sentry User Feedback Widget should be rendered when the Error Boundary catches an error.
 */

export function withErrorBoundary(Component, options) {
  return Sentry.withErrorBoundary(Component, {
    fallback: FallbackDefault,
    ...options,
  });
}
