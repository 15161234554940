import {
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  DISMISS_MESSAGE,
  DISMISS_FORM_FIELD_ERROR,
  DISMISS_ALL_ERRORS,
  UPDATE_ERRORS,
} from '../actions/messages';

export const INITIAL_STATE = {
  message: '',
  errors: [],
  name: '',
};

function dismissFormFieldErrorReducer(state, id) {
  const errors = state.errors.filter(error => {
    return error.path !== id;
  });
  return {
    ...state,
    errors,
  };
}

export default function messagesReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SUCCESS_MESSAGE:
    case ERROR_MESSAGE:
    case DISMISS_MESSAGE:
      return {
        ...state, ...action.data,
      };
    case DISMISS_FORM_FIELD_ERROR:
      return dismissFormFieldErrorReducer(state, action.data);
    case DISMISS_ALL_ERRORS:
      return {
        ...state,
        message: '',
        errors: [],
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
}
