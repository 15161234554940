import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import AnalyticsSection from './AnalyticsSection';
import section1styles from './Section1.module.scss';
import section2styles from './Section2.module.scss';
import section3styles from './Section3.module.scss';
import section4styles from './Section4.module.scss';
import section5styles from './Section5.module.scss';
import style from './ObsessedAnalytics.module.scss';
import ObsessedAnalyticsQuery from '../../queries/obsessed/fetchObsessedAnalytics.gql';
import { makeSharedLayoutStyles } from './ObsessedLandingPage';

const useStyles = makeStyles(theme =>
  makeSharedLayoutStyles(theme, {
    main: {
      gridTemplateAreas: `
      'error'
      'key'
      'section1'
      'break1'
      'section2'
      'break2'
      'section3'
      'break3'
      'section4'
      'break4'
      'section5'
    `,
    },
    error: { gridArea: 'error' },
    key: { gridArea: 'key' },
    [theme.breakpoints.up('sm')]: {
      main: {
        gridTemplateAreas: `
          'error error'
          'key key'
          'section1 section1'
          'break1 break1'
          'section2 section3'
          'break2 break2'
          'section4 section4'
          'break3 break3'
          'section5 section5'
          'break4 break4'
        `,
      },
    },
    [theme.breakpoints.up('md')]: {
      main: {
        gridTemplateAreas: `
          'error error error error'
          'key key key key'
          'section1 section1 section1 section1'
          'break1 break1 break1 break1'
          'section2 section2 section2 section3'
          'break2 break2 break2 break2'
          'section4 section4 section4 section4'
          'break3 break3 break3 break3'
          'section5 section5 section5 section5'
          'break4 break4 break4 break4'
        `,
      },
    },
  }),
);
const analyticsQuery = gql`
  ${ObsessedAnalyticsQuery}
`;

ObsessedAnalytics.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

export default function ObsessedAnalytics({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  const [analyticsData, setAnalyticsData] = useState();
  const [error, setError] = useState(null);

  useQuery(analyticsQuery, {
    errorPolicy: 'all',
    onError(err) {
      setError(err.toString());
    },
    onCompleted(data) {
      if (data?.getPageAnalytics?.length > 0) {
        setAnalyticsData(data.getPageAnalytics);
      } else {
        setError('Missing error report.');
      }
    },
  });

  return (
    <main className={classes.main}>
      {error && (
        <div className={`${classes.error} ${style.errorWrapper}`}>
          <Accordion>
            <AccordionSummary
              className={style.errorInner}
              expandIcon={<ExpandMoreIcon />}
            >
              Error getting data from Google Analytics. Please contact web
              support.
            </AccordionSummary>
            <AccordionDetails>
              <pre>{error}</pre>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      <div className={`${classes.key} ${style.keyWrapper}`}>
        <Typography variant="h3">Obsessed Homepage Analytics</Typography>
        <Paper className={style.keyInner}>
          <Typography className={style.keyText}>
            <strong>Big %</strong> - % of all homepage clicks in the last 30{' '}
            <em>minutes</em> to that particular slot
          </Typography>
          <Typography className={style.keyText}>
            <strong>Small %</strong> - % of all homepage clicks in the last 30{' '}
            <em>days</em> to that particular slot
          </Typography>
          <Typography className={style.keyText}>
            <strong>Clicks</strong> - Number of clicks to that slot over the
            past 30 <em>minutes</em>
          </Typography>
          <br />
          <Typography className={style.keyText}>
            All data is slot-specific and does not change when articles are
            switched in and out
          </Typography>
        </Paper>
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 1)}
        nStories={7}
        section={1}
        styles={{ additionalClasses: classes.section1, ...section1styles }}
      />
      <div className={`${classes.break1} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 2)}
        nStories={4}
        section={2}
        styles={{ additionalClasses: classes.section2, ...section2styles }}
      />
      <div className={`${classes.break2} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 3)}
        nStories={4}
        section={3}
        styles={{ additionalClasses: classes.section3, ...section3styles }}
      />
      <div className={`${classes.break3} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 4)}
        nStories={5}
        section={4}
        styles={{ additionalClasses: classes.section4, ...section4styles }}
      />
      <div className={`${classes.break4} ${classes.sectionBreak}`}>
        <div />
      </div>
      <AnalyticsSection
        analyticsData={analyticsData?.filter(stats => stats.section === 5)}
        nStories={6}
        section={5}
        styles={{ additionalClasses: classes.section5, ...section5styles }}
      />
    </main>
  );
}
