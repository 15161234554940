.section {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-areas:
    'title'
    'subtitle'
    'slot1'
    'slot2'
    'slot3'
    'slot4'
    'slot5'
    'slot6';
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);
  margin: 0 auto;
  width: 100%;
}

.title {
  grid-area: title;
}

.subtitle {
  grid-area: subtitle;
}

@for $i from 1 through 6 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

@media screen and (min-width: 600px) {
  .section {
    grid-template-areas:
      '. title title .'
      '. subtitle subtitle .'
      'slot2 slot1 slot1 slot4'
      'slot3 slot1 slot1 slot5';
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, auto);
  }
}
