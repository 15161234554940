.section {
  display: grid;
  gap: 0.8rem;
  grid-template-areas:
    'title'
    'slot1'
    'slot2'
    'slot3'
    'slot4'
    'slot5'
    'slot6'
    'slot7';
}

.title {
  grid-area: title;
}

@for $i from 1 through 6 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
}

@media screen and (min-width: 600px) {
  .section {
    grid-auto-flow: unset;
    grid-auto-rows: unset;
    grid-template-areas:
      'title title title title'
      'slot1 slot1 slot4 slot4'
      'slot1 slot1 slot5 slot5'
      'slot2 slot3 slot6 slot6';
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 960px) {
  .section {
    grid-template-areas:
      'title title title title'
      'slot1 slot1 slot2 slot4'
      'slot1 slot1 slot2 slot4'
      'slot1 slot1 slot2 slot5'
      'slot1 slot1 slot3 slot5'
      'slot1 slot1 slot3 slot6'
      'slot1 slot1 slot3 slot6';
  }
}
