import React, { useState, useEffect, useContext } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import SelectField from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LABEL } from '../../../labels';
import PRODUCT_NAME_TYPES from '../../../../../../utils/product-name-type';
import { StoryContext } from '../../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../../helpers/graphqlError';
import productNameQueryGQL from '../graphql/productNameQuery.gql';
import productNameMutationGQL from '../graphql/productNameMutation.gql';
import { Context as ValidationContext } from '../../../../../../context/ValidationContext';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      'font-size': '12px',
    },
  })
);

const productNameQuery = gql`
  ${productNameQueryGQL}
`;

const productNameMutation = gql`
  ${productNameMutationGQL}
`;

function ProductNameSection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const { errors, getErrorByFieldName } = useContext(ValidationContext);

  const [loadProductName] = useLazyQuery(productNameQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const productNameData =
        data &&
        data.story &&
        data.story.metadata &&
        data.story.metadata.productName;
      if (productNameData) setSelectedProductName(productNameData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateProductName] = useMutation(productNameMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const productNameData =
        data && data.metadata && data.metadata.productName;
      if (productNameData) setSelectedProductName(productNameData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });
  useEffect(() => {
    if (getErrorByFieldName('product_name')) {
      setErrorMessage(getErrorByFieldName('product_name').message);
    }
  }, [errors]);
  useEffect(() => {
    if (storyContext.id) {
      loadProductName({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onClick = (event) => {
    const selectWidth = event.currentTarget.offsetWidth;
    const selectOffsetLeft = event.currentTarget.offsetLeft;
    global.setTimeout(() => {
      const menuItem = global.document.querySelector('.Select__MenuItem');
      try {
        menuItem.parentElement.parentElement.parentElement.parentElement.style.width = `${selectWidth}px`;
        menuItem.parentElement.parentElement.parentElement.parentElement.style.left = `${selectOffsetLeft}px`;
      } catch (e) {
        // click on label, do nothing
      }
    }, 0);
  };

  const onChange = (e, selectComponent) => {
    const {
      props: { value },
    } = selectComponent;

    updateProductName({
      variables: {
        articleId: storyContext.id,
        metadata: {
          product_name: value,
        },
      },
    });
  };

  return (
    <>
      <InputLabel className={classes.inputLabel} id='label'>
        {LABEL.PRODUCT_NAME}
      </InputLabel>
      <SelectField
        id='select-field'
        fullWidth
        onClick={onClick}
        value={selectedProductName}
        error={!!errorMessage}
        onChange={onChange}
      >
        {PRODUCT_NAME_TYPES.map((productName) => (
          <MenuItem value={productName.name} key={productName.name}>
            {productName.name}
          </MenuItem>
        ))}
      </SelectField>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
}

export default ProductNameSection;
