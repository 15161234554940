query categorizationData($versionId: String!) {
  storyData: storyById(versionId: $versionId) {
    id
    autoKeywords: auto_keywords {
      id
      name
    }
    autoIABCategories: auto_iab_categories {
      id
      category
      parentIABCategory {
        id
        category
        parentIABCategory {
          id
          category
          parentIABCategory {
            id
            category
          }
        }
      }
    }
    autoBrandSafe: auto_brand_safe {
      safe
    }
    autoSentiments: auto_sentiment {
      neutral
      positive
      negative
    }
    autoThreats: auto_threats {
      category
      confidence
    }
  }
}
