import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LABEL } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import publicationDateQueryGQL from './graphql/publicationDateQuery.gql';
import publicationDateMutationGQL from './graphql/publicationDateMutation.gql';

const publicationDateQuery = gql`
  ${publicationDateQueryGQL}
`;

const publicationDateMutation = gql`
  ${publicationDateMutationGQL}
`;

function PublicationDateSection() {
  const storyContext = useContext(StoryContext);
  const { publicationDate } = storyContext;
  const [errorMessage, setErrorMessage] = useState('');

  const [loadPublicationDate] = useLazyQuery(publicationDateQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const pubDateData = data && data.story && data.story.publicationDate;
      if (pubDateData) {
        storyContext.updatePublicationDate(new Date(pubDateData));
      }
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updatePublicationDate] = useMutation(publicationDateMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const pubDateData = data && data.story && data.story.publicationDate;
      if (pubDateData)
        storyContext.updatePublicationDate(new Date(pubDateData));
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadPublicationDate({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (date) => {
    const dateStr = date && moment(date).isValid() && date.toISOString();
    if (!dateStr) return;

    updatePublicationDate({
      variables: {
        id: storyContext.id,
        story: {
          publication_date: dateStr,
        },
      },
    });
  };

  return (
    <Grid item xs={12} md={8}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin='normal'
          id='date-picker-dialog'
          label={LABEL.DATEPICKER}
          format='MM/dd/yyyy'
          value={publicationDate}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          margin='normal'
          id='time-picker'
          label={LABEL.TIMEPICKER}
          value={publicationDate}
          onChange={onChange}
          fullwidth='true'
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </MuiPickersUtilsProvider>
    </Grid>
  );
}

export default PublicationDateSection;
