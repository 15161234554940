import {
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_FIELD,
  UPDATE_CATEGORY_FEATURED_ARTICLE,
  DELETE_CATEGORY_FEATURED_ARTICLE,
} from '../actions/category';

export const INITIAL_STATE = {
  id: null,
  name: '',
  slug: '',
  logo: '',
  featured_articles: [],
  category_id: null,
};

export default function categoryReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_CATEGORY:
      return { ...state, ...action.data };
    case UPDATE_CATEGORY_FIELD:
      return {
        ...state,
        [action.key]: action.value
      };
    // NOTE these two reducers only allow for addition/deleting on a single
    //      article right now
    case UPDATE_CATEGORY_FEATURED_ARTICLE:
      return {
        ...state,
        featured_articles: [action.data]
      };
    case DELETE_CATEGORY_FEATURED_ARTICLE:
      return {
        ...state,
        featured_articles: []
      };
    default:
      return state;
  }
}
