import { TOKEN_REFRESH_ERROR_CODES } from '../utils/token-refresh-error-codes';
import { INTERACTION_REQUIRED_AUTH_ERROR } from '../utils/auth-error-constants';

function refreshToken() {
  global.localStorage.clear();
  window.location.reload();
}

function acquireTokenRedirect(requestObject) {
  return global.PublicClientApplication.acquireTokenRedirect(
    requestObject
  ).catch((error) => {
    console.error(error);
  });
}

export function handleError(error, args) {
  if (TOKEN_REFRESH_ERROR_CODES.includes(error.errorCode)) {
    return refreshToken();
  }

  if (error.name === INTERACTION_REQUIRED_AUTH_ERROR) {
    return acquireTokenRedirect(args?.requestObject || {});
  }

  return null;
}
