import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import BackupIcon from '@material-ui/icons/Backup';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { colors } from '../../../../../styles/global/variables';

export default class UploadBox extends Component {
  static propTypes = {
    className: PropTypes.string,
    onImageUploaded: PropTypes.func.isRequired,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.openUploadDiaglog = this.openUploadDiaglog.bind(this);
  }

  openUploadDiaglog() {
    const opts = {
      theme: 'minimal',
      show_powered_by: false,
      sources: ['local'],
      multiple: true,
      upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
      max_file_size: '20000000',
      client_allowed_formats: ['png', 'jpg', 'gif', 'jpeg'],
      image_metadata: true,
    };

    const widget = global.cloudinary.createUploadWidget(
      opts,
      this.props.onImageUploaded,
    );
    widget.open();
  }

  render() {
    const { className: additionalClass } = this.props;
    const className = classnames('UploadBox', {
      [additionalClass]: !!additionalClass,
    });

    return (
      <section className={className}>
        <div>
          <BackupIcon
            color="disabled"
            style={{
              fontSize: '160px',
              width: '100%',
              textAlign: 'center',
              padding: '30px',
              border: `1px solid ${colors.lighterGrey}`,
            }}
          />

          <Button
            label="Upload"
            fullWidth
            className="ButtonWrapper--no-padding"
            onClick={this.openUploadDiaglog}
            style={{ marginTop: '8px' }}
          />
        </div>
      </section>
    );
  }
}
