import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { logError } from '../../helpers/logError';
import { handleError } from '../../helpers/global-error-handler';

export default function useActiveUsers() {
  const [activeUsers, setActiveUsers] = useState([]);
  const [pusher, setPusher] = useState(null);
  const [socket, setSocket] = useState(null);
  const [eventsBound, setEventsBound] = useState(false);

  useEffect(() => {
    (async function setupPusher() {
      let requestObject;

      try {
        const currentAccounts = global.PublicClientApplication.getAllAccounts();

        if (!currentAccounts?.length) {
          global.PublicClientApplication.loginRedirect().catch((error) => {
            logError(error, {
              afterCapture: handleError,
            });
          });
          return;
        }

        const currentAccount = currentAccounts[0];
        requestObject = {
          account: currentAccount,
          scopes: [process.env.AZURE_API_SCOPE],
        };

        const accessTokenData =
          await global.PublicClientApplication.acquireTokenSilent(
            requestObject
          );

        setPusher(
          new Pusher(process.env.PUSHER_KEY, {
            auth: {
              headers: {
                Authorization: `Bearer ${accessTokenData.accessToken}`,
              },
            },
            authEndpoint: `${process.env.API_HOST}/pusher/auth`,
            cluster: 'us2',
            forceTLS: true,
          })
        );
      } catch (error) {
        const args = {
          requestObject,
        };

        logError(error, {
          afterCapture: handleError(error, args),
        });
      }
    })();

    return function cleanup() {
      if (!socket) return;

      socket.unsubscribe('presence-homepage');
      setSocket(null);
      setPusher(null);
    };
  }, []);

  useEffect(() => {
    if (pusher && !socket) {
      setSocket(pusher.subscribe('presence-homepage'));
    }

    if (socket && !eventsBound) {
      setEventsBound(true);

      socket.bind('pusher:subscription_succeeded', ({ me, members }) => {
        setActiveUsers(
          Object.keys(members).reduce((accumulator, member) => {
            if (member === me.id) return accumulator;

            return [...accumulator, { ...members[member], id: member }];
          }, [])
        );
      });

      socket.bind('pusher:subscription_error', () => {
        setActiveUsers([]);
      });

      socket.bind('pusher:member_added', (member) => {
        // remove the member if they already exist (from multiple browser tabs!)
        const activeMembers = activeUsers.filter((m) => m.id !== member.id);

        // add them back in once
        setActiveUsers([...activeMembers, { id: member.id, ...member.info }]);
      });

      socket.bind('pusher:member_removed', (member) => {
        const activeMembers = activeUsers.filter((m) => m.id !== member.id);
        setActiveUsers([...activeMembers]);
      });
    }
  });

  return activeUsers;
}
