import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import BizToolsBasePage from '../BizToolsBasePage';
import ScoutedCardsPicker from './ScoutedCardsPicker';
import Button from '../../../common/ButtonWrapper/ButtonWrapper';
import curatedAndLatestScoutedQueryGQL from './graphql/curatedAndLatestScoutedQuery.gql';
import latestScoutedQueryGQL from './graphql/latestScoutedQuery.gql';
import updateListWithScheduleWindowGQL from './graphql/updateListWithScheduleWindow.gql';
import updateScheduleWindowGQL from './graphql/updateScheduleWindow.gql';
import { colors } from '../../../styles/global/variables';
import getGraphqlErrorMessage from '../../../helpers/graphqlError';

const CURATED_TYPE = 'Curated';
const LATEST_TYPE = 'Lastest';
const EPOCH_ISO = new Date(0).toISOString();
const DEFAULT_CURATED_CHEATS = [
  { id: null, longHeadline: null, publicationDate: null },
  { id: null, longHeadline: null, publicationDate: null },
  { id: null, longHeadline: null, publicationDate: null },
];
const DEFAULT_LATEST_CHEATS = [
  { id: null, longHeadline: null, publicationDate: null },
  { id: null, longHeadline: null, publicationDate: null },
  { id: null, longHeadline: null, publicationDate: null },
];

Scouted.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '100px',
  },
  headerLeftText: {
    fontWeight: 'bold',
  },
  headerRightText: {
    color: colors.materialBlue,
    fontWeight: 'bold',
  },
  expiresAtText: {
    fontStyle: 'italic',
    fontSize: '14px',
    color: colors.darkGrey,
  },
  typeContainer: {
    marginTop: '24px',
  },
  fieldTitle: {
    fontWeight: 600,
    color: colors.darkGrey,
    fontSize: '0.9rem',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  saveButton: {
    width: '100px',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    textTransform: 'none',
  },
  cancelButton: {
    backgroundColor: colors.darkGrey,
    width: '100px',
    marginRight: '20px',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.darkerGrey,
    },
  },
  editButton: {
    width: '100px',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    textTransform: 'none',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  expireContainer: {
    marginTop: '16px',
  },
  datePicker: {
    marginTop: '0px',
  },
}));

const curatedAndLatestScoutedQuery = gql`
  ${curatedAndLatestScoutedQueryGQL}
`;
const latestScoutedQuery = gql`
  ${latestScoutedQueryGQL}
`;
const updateScoutedMutation = gql`
  ${updateListWithScheduleWindowGQL}
`;
const updateScheduleWindowMutation = gql`
  ${updateScheduleWindowGQL}
`;

export default function Scouted({ onEnter, permissions }) {
  const classes = useStyles();

  // Cheats list
  const [curatedCheats, setCuratedCheats] = useState(DEFAULT_CURATED_CHEATS);
  const [latestCheats, setLatestCheats] = useState(DEFAULT_LATEST_CHEATS);

  const [editMode, setEditMode] = useState(false);
  const [scoutedType, setScoutedType] = useState(CURATED_TYPE);
  const [errorMessage, setErrorMessage] = useState('');

  // For curated scheduling
  const [scheduled, setScheduled] = useState(false);
  const [expireTime, setExpireTime] = useState(null);
  const [reorderMode, setReorderMode] = useState(false);

  const [loadScoutedCheats] = useLazyQuery(curatedAndLatestScoutedQuery, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      if (data?.scheduledList?.stories) {
        setCuratedCheats(data.scheduledList.stories);
      } else {
        setCuratedCheats(DEFAULT_CURATED_CHEATS);
      }

      if (data?.vertical?.stories?.edges) {
        const latest = data.vertical.stories.edges.map(node => node.node);
        setLatestCheats(latest);
      } else {
        setLatestCheats(DEFAULT_LATEST_CHEATS);
      }

      if (data?.scheduledList) {
        // Curated scouted cheats - is it scheduled or set to never expire?
        setScoutedType(CURATED_TYPE);
        if (data?.scoutedScheduleWindow?.endTime) {
          // If there is an end time, it is scheduled to expire then
          setScheduled(true);
          setExpireTime(data.scoutedScheduleWindow.endTime);
        } else {
          // If there is no end time, it is scheduled to expire never
          setScheduled(false);
          setExpireTime(null);
        }
      } else {
        // Latest scouted cheats - defaults to not scheduled and no expire time
        setScoutedType(LATEST_TYPE);
        setScheduled(false);
        setExpireTime(null);
      }

      setReorderMode(false);
    },
  });

  const [loadLatestScouted] = useLazyQuery(latestScoutedQuery, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      setErrorMessage('');
      if (data?.vertical?.stories?.edges) {
        const latest = data.vertical.stories.edges.map(node => node.node);
        setLatestCheats(latest);
      }
    },
  });

  const [updateScoutedCheats] = useMutation(updateScoutedMutation, {
    onError: err => {
      let errStr = getGraphqlErrorMessage(err);
      if (
        errStr ===
        'range lower bound must be less than or equal to range upper bound'
      ) {
        errStr = 'Date and time should not be in the past';
      }
      setErrorMessage(errStr);
    },
    onCompleted: () => {
      setErrorMessage('');
      setEditMode(false);
      loadScoutedCheats();
    },
  });

  const [updateLatest] = useMutation(updateScheduleWindowMutation, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: () => {
      setErrorMessage('');
      setEditMode(false);
      loadScoutedCheats();
    },
  });

  useEffect(() => {
    loadScoutedCheats();
  }, []);

  const getSaveError = () => {
    let err = null;
    if (scheduled) {
      if (!expireTime) {
        err = 'Please enter an expire time for scheduling.';
      } else if (!moment(expireTime).isValid()) {
        err = 'Invalid expire time.';
      }
    }

    if (curatedCheats.filter(c => !c.id).length) {
      err = 'Please select three cheats and try saving again.';
    }

    return err;
  };

  const onTypeChange = e => {
    const type = e.target.value;
    if (type === LATEST_TYPE) {
      loadLatestScouted();
    }

    setScoutedType(type);
  };

  const onExpireTimeChange = date => {
    setExpireTime(date.toISOString());
  };

  return (
    <BizToolsBasePage
      title="Scouted"
      onEnter={onEnter}
      permissions={permissions}
    >
      <Container className={classes.header}>
        <Typography className={classes.headerLeftText}>
          Scouted Cheat List
        </Typography>
        <Typography className={classes.headerRightText}>
          {scoutedType === CURATED_TYPE ? 'Curated' : 'Latest'}
        </Typography>
      </Container>

      {!editMode && expireTime && (
        <Container>
          <Typography className={classes.expiresAtText}>
            {`Expires at ${dateFormat(expireTime, 'm/d/yy, h:MM TT')}`}
          </Typography>
        </Container>
      )}

      {editMode && (
        <Container className={classes.typeContainer}>
          <Typography className={classes.fieldTitle}>Type</Typography>
          <FormControl>
            <RadioGroup
              className={classes.radioGroup}
              name="Type"
              value={scoutedType}
              onChange={onTypeChange}
            >
              <FormControlLabel
                className={classes.fieldTitle}
                value={CURATED_TYPE}
                label={
                  <Typography className={classes.fieldTitle}>
                    Curated
                  </Typography>
                }
                control={<Radio />}
              />
              <FormControlLabel
                className={classes.fieldTitle}
                value={LATEST_TYPE}
                label={
                  <Typography className={classes.fieldTitle}>Latest</Typography>
                }
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Container>
      )}
      {editMode && scoutedType === CURATED_TYPE && (
        <>
          <Container className={classes.expireContainer}>
            <Typography className={classes.fieldTitle}>
              Set Curation to Expire
            </Typography>
            <FormControl>
              <RadioGroup
                className={classes.radioGroup}
                name="Schedule"
                value={scheduled}
                onChange={e => {
                  const isScheduled = e.target.value === 'true';
                  if (!isScheduled) {
                    setExpireTime(null);
                  }
                  setScheduled(isScheduled);
                }}
              >
                <FormControlLabel
                  value={false}
                  label={
                    <Typography className={classes.fieldTitle}>
                      Never
                    </Typography>
                  }
                  control={<Radio />}
                />
                <FormControlLabel
                  value
                  label={
                    <Typography className={classes.fieldTitle}>
                      Schedule
                    </Typography>
                  }
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Container>
          <Container>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datePicker}
                minDateMessage="Date and time should not be in the past"
                disabled={!scheduled}
                margin="normal"
                id="unlock-date-picker-dialog"
                label="Date"
                format="MM/dd/yyyy"
                value={expireTime}
                onChange={onExpireTimeChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                minDate={new Date()}
              />
              <KeyboardTimePicker
                className={classes.datePicker}
                disabled={!scheduled}
                margin="normal"
                id="unlock-time-picker"
                label="Time (ET)"
                value={expireTime}
                onChange={onExpireTimeChange}
                fullwidth="true"
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </MuiPickersUtilsProvider>
          </Container>
        </>
      )}
      <ScoutedCardsPicker
        cheats={scoutedType === CURATED_TYPE ? curatedCheats : latestCheats}
        curatedCheats={curatedCheats}
        setCuratedCheats={setCuratedCheats}
        editMode={editMode}
        reorderMode={reorderMode}
        scoutedType={scoutedType}
      />
      <Container className={classes.buttons}>
        {editMode ? (
          <>
            <Button
              className={classes.cancelButton}
              label="Cancel"
              onClick={() => {
                loadScoutedCheats();
                setEditMode(false);
              }}
            />
            <Button
              className={classes.saveButton}
              label="Save"
              onClick={() => {
                if (scoutedType === CURATED_TYPE) {
                  const err = getSaveError();

                  if (err) {
                    setErrorMessage(err);
                    return;
                  }

                  const timeNow = new Date().toISOString();
                  let expireStr;
                  if (expireTime) {
                    expireStr = expireTime;
                  } else {
                    expireStr = null;
                  }

                  updateScoutedCheats({
                    variables: {
                      storyIds: curatedCheats.map(c => c.id),
                      startTime: timeNow,
                      endTime: expireStr,
                    },
                  });
                } else {
                  updateLatest({
                    variables: {
                      startTime: EPOCH_ISO,
                      endTime: EPOCH_ISO,
                    },
                  });
                }
              }}
            />
          </>
        ) : (
          <Button
            className={classes.editButton}
            label="Edit"
            onClick={() => setEditMode(true)}
          />
        )}
      </Container>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => {
          setErrorMessage('');
        }}
      >
        <Alert
          onClose={() => {
            setErrorMessage('');
          }}
          severity="error"
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </BizToolsBasePage>
  );
}
