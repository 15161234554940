query imageQuery($imageId: ID!) {
  image(id: $imageId) {
    id
    displayType: display_type
    url
    publicId: public_id
    altText: alt_text
    version
  }
}
