import React, { useState, useContext, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useLazyQuery, useMutation } from '@apollo/client'

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client'
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import { StoryContext } from '../../../../../context/StoryContext';
import updateExcludeFromRecirculation from '../../mutations/updateExcludeFromRecirculation.gql';
import excludeFromRecirculation from '../../queries/excludeFromRecirculation.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      'margin-top': '30px',
      'margin-bottom': '10px',
      'font-weight': '600',
    },
  }),
);

const excludeFromRecirculationMutation = gql`
  ${updateExcludeFromRecirculation}
`;

const excludeFromRecirculationQuery = gql`
  ${excludeFromRecirculation}
`;
export default function ExcludeFromRecirculation() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  const [message, setMessage] = useState(null);

  const [
    excludeFromRecirculationData,
    setExcludeFromRecirculationData,
  ] = useState(false);

  const [updateExcludeFromRecirculationMutation] = useMutation(
    excludeFromRecirculationMutation,
    {
      onError: err => {
        console.error(err);
        errorMessageAction(err);
        setMessage('Error saving exclude from recirculation settings');
      },
      onCompleted: data => {
        const { metadata } = data;
        setExcludeFromRecirculationData(metadata.exclude_from_recirculation);
      },
    },
  );

  const [loadExcludeFromRecirculation] = useLazyQuery(
    excludeFromRecirculationQuery,
    {
      onError: err => {
        console.error(err);
        errorMessageAction(err);
        setMessage('Error fetching exclude from recirculation settings');
      },
      onCompleted: data => {
        const {
          storyData: { metadata },
        } = data;
        if (metadata) {
          setExcludeFromRecirculationData(metadata.exclude_from_recirculation);
        }
      },
    },
  );

  useEffect(() => {
    if (id) {
      loadExcludeFromRecirculation({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  function onExcludeFromRelated(event, isChecked) {
    updateExcludeFromRecirculationMutation({
      variables: {
        id,
        metadataInputData: {
          exclude_from_recirculation: isChecked,
        },
      },
    });
  }

  return (
    <>
      <h3 className={classes.heading}>Recirculation</h3>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={excludeFromRecirculationData}
            onChange={onExcludeFromRelated}
          />
        }
        label="Exclude from on-site recirculation"
      />
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
