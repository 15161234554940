import PropTypes from 'prop-types';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SvgSymbol, {
  SVG_SYMBOLS,
} from '../../../../../common/SvgSymbol/SvgSymbol';

export const PULL_QUOTE_CARD_NAME = 'pt-pull-quote-card';
const useStyles = makeStyles({
  card: {
    maxWidth: '500px',
    width: '90%',
    margin: '0 auto',
  },
  svgIcon: {
    height: '120px',
    width: '120px',
    opacity: '0.6',
    margin: '0 auto 10px',
    display: 'block',
  },
  cardHeader: {
    'padding-bottom': 0,
  },
});

const PullQuoteCard = props => {
  const classes = useStyles();
  const {
    payload: {
      id,
      content: { quote },
    },
    actions: { updateQuotePickerOpen, updateQuoteId, updateSaveCardFunction },
    save,
    edit,
  } = props;

  const onEdit = () => {
    updateQuoteId(id);
    updateQuotePickerOpen(true);
    updateSaveCardFunction(save);

    edit();
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton onClick={onEdit} aria-label="embed">
            <EditIcon />
          </IconButton>
        }
        title="Pull Quote"
      />
      <CardContent>
        <SvgSymbol className={classes.svgIcon} name={SVG_SYMBOLS.QUOTE} />
        <p>{quote}</p>
      </CardContent>
    </Card>
  );
};

PullQuoteCard.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.object,
  }),
  actions: PropTypes.shape({
    updateQuotePickerOpen: PropTypes.func,
    updateQuoteId: PropTypes.func,
    updateSaveCardFunction: PropTypes.func,
  }),
  save: PropTypes.func,
  edit: PropTypes.func,
};

export default PullQuoteCard;
