import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import ConnectedCheatSheet from '../layouts/CheatSheet/CheatSheet';
import ConnectedHomepage from '../layouts/HomePage/HomePage';
import Screamer from '../layouts/Screamer/Screamer';
import DynamicNavigationTool from '../layouts/DynamicNavigationTool/DynamicNavigationTool';
import ObsessedDynamicNavigationTool from '../layouts/ObsessedDynamicNavTool/DynamicNavigationTool';
import ConnectedHomepageAnalytics from '../layouts/HomePage/HomepageAnalytics';
import ConnectedAllStories from '../layouts/AllStories/AllStories';
import ConnectedObsessedAdmin from '../layouts/ObsessedAdmin';
import ConnectedObsessedTag from '../layouts/ObsessedTag';
import ConnectedObsessedAnalytics from '../layouts/ObsessedLandingPage/ObsessedAnalytics';
import Body from '../features/article/body/components/Body/Body';
import Metadata from '../features/article/metadata/components/Metadata/Metadata';
import ConnectedKeywords from '../layouts/Keywords/Keywords';
import ConnectedAuthor from '../layouts/Author/Author';
import ConnectedAuthors from '../layouts/Authors/Authors';
import { MainIndex } from '../layouts/Navigations/MainIndex';
import ProgressiveScroll from '../layouts/ProgressiveScroll/ProgressiveScroll';
import LoginPage from '../layouts/LoginPage/LoginPage';
import LogoutRedirectPage from '../layouts/LogoutRedirectPage/LogoutRedirectPage';
import ConnectedCategory from '../layouts/Category/Category';
import isMobile from '../helpers/isMobile';
import { Context as AuthContext } from '../context/auth/AuthContext';
import { BizToolsIndex } from '../layouts/Navigations/BizToolsIndex';
import AdRefresh from '../layouts/BizTools/AdRefresh/AdRefresh';
import { BrandsPartners } from '../layouts/BizTools/BrandsPartners/BrandsPartners';
import { Adstxt } from '../layouts/BizTools/Adstxt/Adstxt';
import ArticleSlugRedirect from '../layouts/ArticleSlugRedirect/ArticleSlugRedirect';
import { PermissionsError } from '../layouts/PermissionsError/PermissionsError';
import Story from '../layouts/Article/Story';
import MainInfo from '../features/article/main-info/components/MainInfo/MainInfo';
import Images from '../features/article/images/components/Images/Images';
import CategorizationData from '../layouts/BizTools/CategorizationData/CategorizationData';
import Scouted from '../layouts/BizTools/Scouted/Scouted';

import ObsessedLandingPage from '../layouts/ObsessedLandingPage/ObsessedLandingPage';
import ObsessedClassification from '../features/article/obsessed/ObsessedClassification';

import { ErrorBoundary } from '../common/ErrorBoundary';
import { PERMISSIONS, ROUTE_NAME } from './constants';
import { logError } from '../helpers/logError';
import { handleError } from '../helpers/global-error-handler';

function acquireToken(requestObject) {
  const ONE_DAY_SECONDS = 60 * 60 * 24;
  const { DOMAIN_NAME, IS_PREVIEW_AUTHORIZED, SECURE_COOKIE_VALUE } =
    process.env;
  const secureCookie = SECURE_COOKIE_VALUE ? 'secure;' : '';

  if (!requestObject?.account) {
    global.PublicClientApplication.loginRedirect().catch((error) => {
      logError(error, {
        afterCapture: handleError,
      });
    });
  }

  global.PublicClientApplication.acquireTokenSilent(requestObject)
    .then(() => {
      // force a commit
      global.document.cookie = [
        `__isPreviewAuthorized=${IS_PREVIEW_AUTHORIZED};`,
        `domain=${DOMAIN_NAME};`,
        `max-age=${ONE_DAY_SECONDS + 1};`,
        `SameSite=lax;${secureCookie};`,
        `path=/`,
      ].join('');
    })
    .catch((error) => {
      const args = {
        requestObject,
      };

      logError(error, {
        afterCapture: handleError(error, args),
      });
    });
}

function getUser(registerUser) {
  const currentAccounts = global.PublicClientApplication.getAllAccounts();

  if (currentAccounts && currentAccounts.length) {
    const { username } = currentAccounts[0];
    registerUser({ variables: { email: username } });
    Sentry.setUser({ username });
    global.PublicClientApplication.setActiveAccount(currentAccounts[0]);
    global.apm.setUserContext({
      id: username,
    });
    return currentAccounts[0];
  }
  return null;
}

// eslint-disable-next-line consistent-return
function hasPermission(pagePermissions = [], userPermissions = []) {
  if (pagePermissions.length < 1) {
    return true;
    // eslint-disable-next-line no-else-return
  } else if (userPermissions.length > 0) {
    const splitUserPermissions = userPermissions[0].split(',');
    if (splitUserPermissions.includes(PERMISSIONS.ADMIN)) {
      return true;
      // eslint-disable-next-line no-else-return
    } else {
      return splitUserPermissions.some(
        (r) => pagePermissions.indexOf(r) !== -1
      );
    }
  }
}

const redirectToPath = (history, pathname, state) => {
  history.replace({ pathname, state });
};

function ArticleRoutes({ children, path, onEnter: requireAuth }) {
  let imageRoutes = null;
  if (isMobile()) {
    imageRoutes = (
      <Redirect
        exact
        from={`${path}/${ROUTE_NAME.IMAGES}`}
        to={ROUTE_NAME.MAIN_INFO}
      />
    );
  } else {
    imageRoutes = (
      <Route exact path={`${path}/${ROUTE_NAME.IMAGES}`}>
        <Images />
      </Route>
    );
  }

  return (
    <Route path={path}>
      <ErrorBoundary>
        <Story onEnter={requireAuth}>
          <Switch>
            <Route
              path={`${path}/${ROUTE_NAME.MAIN_INFO}`}
              component={MainInfo}
            />
            <Route path={`${path}/${ROUTE_NAME.BODY}`} component={Body} />
            {imageRoutes}
            <Route
              path={`${path}/${ROUTE_NAME.METADATA}`}
              component={Metadata}
            />
            {children}
          </Switch>
        </Story>
      </ErrorBoundary>
    </Route>
  );
}

ArticleRoutes.propTypes = {
  onEnter: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export function Router() {
  const { registerUser } = useContext(AuthContext);

  const requireAuth = useCallback(
    (history, pagePermissions) => {
      const cachedUser = getUser(registerUser);

      if (cachedUser) {
        acquireToken({
          account: cachedUser,
          scopes: [process.env.AZURE_API_SCOPE],
        });

        if (!hasPermission(pagePermissions, cachedUser?.idTokenClaims?.roles)) {
          const attemptedPath = history.location.pathname;

          redirectToPath(history, '/permission', {
            prevPathname: attemptedPath,
          });
        }
      } else {
        global.PublicClientApplication.handleRedirectPromise()
          .then((resp) => resp)
          .then((result) => {
            const newUser = getUser(registerUser);

            if (!result && !newUser) {
              const attemptedPath = history.pathname;

              redirectToPath(history, '/login', {
                nextPathname: attemptedPath,
              });
            } else {
              acquireToken({
                account: newUser,
                scopes: [process.env.AZURE_API_SCOPE],
              });

              if (
                !hasPermission(pagePermissions, newUser?.idTokenClaims?.roles)
              ) {
                const attemptedPath = history.location.pathname;

                redirectToPath(history, '/permission', {
                  prevPathname: attemptedPath,
                });
              }
            }
          })
          .catch((error) => {
            logError(error, {
              afterCapture: handleError,
            });
          });
      }
    },
    [registerUser]
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <MainIndex onEnter={requireAuth} />
        </Route>
        <Route exact path='/homepage-analytics'>
          <ConnectedHomepageAnalytics onEnter={requireAuth} />
        </Route>
        <Route exact path='/obsessed-analytics'>
          <ConnectedObsessedAnalytics onEnter={requireAuth} />
        </Route>
        <Route exact path='/homepage'>
          <ConnectedHomepage onEnter={requireAuth} />
        </Route>
        <Route exact path='/all-stories'>
          <ConnectedAllStories onEnter={requireAuth} />
        </Route>
        <Route exact path='/obsessed-admin'>
          <ConnectedObsessedAdmin onEnter={requireAuth} />
        </Route>
        <Route exact path='/obsessed-tag/:tagId'>
          <ConnectedObsessedTag onEnter={requireAuth} />
        </Route>
        <Route exact path='/cheatsheet'>
          <ConnectedCheatSheet onEnter={requireAuth} />
        </Route>
        <Route exact path='/keywords'>
          <ConnectedKeywords onEnter={requireAuth} />
        </Route>
        <Route exact path='/progressive-scroll'>
          <ProgressiveScroll onEnter={requireAuth} />
        </Route>
        <Route exact path='/authors'>
          <ConnectedAuthors onEnter={requireAuth} />
        </Route>
        <Route exact path='/author'>
          <ConnectedAuthor onEnter={requireAuth} />
        </Route>
        <Route exact path='/author/:authorSlug'>
          <ConnectedAuthor onEnter={requireAuth} />
        </Route>
        <Route exact path='/category'>
          <ConnectedCategory onEnter={requireAuth} />
        </Route>
        <Route exact path='/screamer'>
          <Screamer onEnter={requireAuth} />
        </Route>
        <Route exact path='/dynamic-navigation-tool'>
          <DynamicNavigationTool onEnter={requireAuth} />
        </Route>
        <Route exact path='/obsessed-dynamic-navigation-tool'>
          <ObsessedDynamicNavigationTool onEnter={requireAuth} />
        </Route>
        <Route exact path='/redirect-logout'>
          <LogoutRedirectPage />
        </Route>
        <Route exact path='/login'>
          <LoginPage />
        </Route>
        <Route exact path='/permission'>
          <PermissionsError />
        </Route>

        <Route exact path='/slug-redirect-tool'>
          <ArticleSlugRedirect
            onEnter={requireAuth}
            permissions={[PERMISSIONS.OPSSUPPORT]}
          />
        </Route>

        <Route exact path='/obsessed/homepage'>
          <ObsessedLandingPage onEnter={requireAuth} />
        </Route>

        <Route exact path='/biztools'>
          <BizToolsIndex
            onEnter={requireAuth}
            permissions={[PERMISSIONS.BUSINESS]}
          />
        </Route>
        <Route exact path='/biztools/refresh'>
          <AdRefresh onEnter={requireAuth} permissions={[PERMISSIONS.ADOPS]} />
        </Route>
        <Route exact path='/biztools/brandspartners'>
          <BrandsPartners
            onEnter={requireAuth}
            permissions={[PERMISSIONS.BUSINESS]}
          />
        </Route>
        <Route exact path='/biztools/adstxt'>
          <Adstxt onEnter={requireAuth} permissions={[PERMISSIONS.ADOPS]} />
        </Route>
        <Route exact path='/biztools/categorization-data'>
          <CategorizationData
            onEnter={requireAuth}
            permissions={[PERMISSIONS.ADOPS]}
          />
        </Route>
        <Route exact path='/biztools/scouted'>
          <Scouted onEnter={requireAuth} permissions={[PERMISSIONS.ADOPS]} />
        </Route>

        <Redirect exact from='/new' to={`/new/${ROUTE_NAME.MAIN_INFO}`} />
        <ArticleRoutes path='/new' onEnter={requireAuth} />

        <Redirect
          exact
          from='/obsessed/new'
          to={`/obsessed/new/${ROUTE_NAME.MAIN_INFO}`}
        />
        <ArticleRoutes path='/obsessed/new' onEnter={requireAuth}>
          <Route
            path={`/obsessed/new/${ROUTE_NAME.OBSESSED_CLASSIFICATION}`}
            component={ObsessedClassification}
          />
        </ArticleRoutes>

        <Redirect
          exact
          from='/:type/:year/:month/:day/:slug'
          to={`/:type/:year/:month/:day/:slug/${ROUTE_NAME.MAIN_INFO}`}
        />
        <ArticleRoutes
          path='/:type/:year/:month/:day/:slug'
          onEnter={requireAuth}
        />

        <Redirect
          exact
          from='/gallery'
          to={`/gallery/${ROUTE_NAME.MAIN_INFO}`}
        />
        <ArticleRoutes path='/gallery' onEnter={requireAuth} />

        <Redirect
          exact
          from='/obsessed/gallery'
          to={`/obsessed/gallery/${ROUTE_NAME.MAIN_INFO}`}
        />
        <ArticleRoutes path='/obsessed/gallery' onEnter={requireAuth}>
          <Route
            path={`/obsessed/gallery/${ROUTE_NAME.OBSESSED_CLASSIFICATION}`}
            component={ObsessedClassification}
          />
        </ArticleRoutes>

        <Redirect
          exact
          from='/obsessed/:slug'
          to={`/obsessed/:slug/${ROUTE_NAME.MAIN_INFO}`}
        />
        <ArticleRoutes path='/obsessed/:slug' onEnter={requireAuth}>
          <Route
            path={`/obsessed/:slug/${ROUTE_NAME.OBSESSED_CLASSIFICATION}`}
            component={ObsessedClassification}
          />
        </ArticleRoutes>

        <Redirect exact from='/:slug' to={`/:slug/${ROUTE_NAME.MAIN_INFO}`} />
        <ArticleRoutes path='/:slug' onEnter={requireAuth} />
      </Switch>
    </BrowserRouter>
  );
}
