import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { StoryContext } from '../../../../../context/StoryContext';
import ChipsContainer from '../../../../../common/ChipsContainer/ChipsContainer';
import { PLACEHOLDER } from '../../labels';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import editorsQueryGQL from './graphql/editorsQuery.gql';
import searchEditorByNameQueryGQL from './graphql/searchEditorByNameQuery.gql';
import addStoryEditorMutationGQL from './graphql/addStoryEditorMutation.gql';
import deleteStoryEditorMutationGQL from './graphql/deleteEditorMutation.gql';

const useStyles = makeStyles(() =>
  createStyles({
    dontScaleFont: {
      '& label': {
        fontSize: '16px!important',
      },
    },
  })
);

const editorsQuery = gql`
  ${editorsQueryGQL}
`;

const searchEditorByNameQuery = gql`
  ${searchEditorByNameQueryGQL}
`;

const addStoryEditorMutation = gql`
  ${addStoryEditorMutationGQL}
`;

const deleteStoryEditorMutation = gql`
  ${deleteStoryEditorMutationGQL}
`;

function EditorSection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [queriedEditors, setQueriedEditors] = useState([]);
  const [selectedEditors, setSelectedEditors] = useState();
  const [editorSearchText, setEditorSearchText] = useState('');

  const [loadEditors] = useLazyQuery(editorsQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const editorsData = data && data.story && data.story.editors;
      if (editorsData) setSelectedEditors(editorsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [loadQueriedEditors] = useLazyQuery(searchEditorByNameQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const queriedEditorsData = data && data.queriedEditors;
      if (queriedEditorsData) setQueriedEditors(data.queriedEditors);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [addStoryEditor] = useMutation(addStoryEditorMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const editorsData = data && data.story && data.story.editors;
      if (editorsData) setSelectedEditors(editorsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [deleteStoryEditor] = useMutation(deleteStoryEditorMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const editorsData = data && data.story && data.story.editors;
      if (editorsData) setSelectedEditors(editorsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadEditors(
        {
          variables: {
            versionId: storyContext.id,
          },
        },
        [storyContext.id]
      );
    }
  }, [storyContext.id]);

  const onChange = (e, value) => {
    const editorId = value.id;

    setEditorSearchText('');

    addStoryEditor({
      variables: {
        articleId: storyContext.id,
        editorId,
      },
    });
  };

  const onInputChange = (e, inputString) => {
    if (inputString) {
      loadQueriedEditors({
        variables: {
          limit: 5,
          name: inputString,
        },
      });
    }
  };

  const onDeleteEditor = (editorId) => {
    deleteStoryEditor({
      variables: {
        articleId: storyContext.id,
        editorId,
      },
    });
  };

  return (
    <Grid item xs={12} md={8} className={classes.dontScaleFont}>
      <Autocomplete
        getOptionLabel={(option) => option.name}
        hinttext={PLACEHOLDER.EDITORS}
        options={queriedEditors}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={editorSearchText}
        disableClearable
        limitTags={1}
        renderOption={(option) => <div id={option.id}>{option.name}</div>}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEditorSearchText(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
            label='Editors'
          />
        )}
      />
      <ChipsContainer
        removeAction={onDeleteEditor}
        valueName='name'
        values={selectedEditors}
      />
    </Grid>
  );
}

export default EditorSection;
