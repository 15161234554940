import React, { useContext } from 'react';
import dateFormat from 'dateformat';
import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '../../styles/global/variables';
import { StoryContext } from '../../context/StoryContext';

const useStyles = makeStyles(() => ({
  lastSavedText: {
    'font-family': fonts.helvetica,
    'font-size': '13px',
  },
}));

export default function LastSavedAt() {
  const storyContext = useContext(StoryContext);

  const classes = useStyles();
  let content;
  if (storyContext && storyContext.updatedAt) {
    content = (
      <p className={classes.lastSavedText}>
        last saved at{' '}
        {dateFormat(storyContext.updatedAt, "mm.dd.yy H:MM TT 'ET'")}
      </p>
    );
  }

  return <div>{content}</div>;
}
