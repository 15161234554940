import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '../../../common/ButtonWrapper/ButtonWrapper';
import DragAndDrop from '../../../utils/dnd-context';
import ScoutedAutocomplete from './ScoutedAutocomplete';
import CheatSheetDraggableCard from '../../../features/cheatsheet/components/CheatSheetDraggableCard/CheatSheetDraggableCard';
import { colors } from '../../../styles/global/variables';
import counter from '../../../common/counterGen';

const CURATED_TYPE = 'Curated';
const LATEST_TYPE = 'Lastest';

const useStyles = makeStyles(() => ({
  autocomplete: {
    paddingBottom: '20px',
  },
  storiesContainer: {
    marginTop: ' 24px',
  },
  reorderButton: {
    width: '100px',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    textTransform: 'none',
  },
  reorderButtonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '16px',
  },
  // css below is passed to CheatSheetDraggableCard
  reorderContainer: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  cheatLabel: {
    color: colors.materialBlue,
    margin: '0 8px',
    width: '30px',
    textAlign: 'left',
  },
  cheatInputBox: {
    flex: 1,
  },
  shadowPadding: {
    padding: '8px',
    '& .shadowBox': {
      boxShadow: '#02141F 6px 6px 0',
      border: '1px solid #ddd',
    },
  },
  cheatDate: {
    fontSize: '13px',
  },
}));

ScoutedCardsPicker.propTypes = {
  cheats: PropTypes.array.isRequired,
  curatedCheats: PropTypes.array,
  editMode: PropTypes.bool.isRequired,
  reorderMode: PropTypes.bool.isRequired,
  scoutedType: PropTypes.string,
  setCuratedCheats: PropTypes.func.isRequired,
  setReorderMode: PropTypes.func.isRequired,
};

export default function ScoutedCardsPicker({
  cheats,
  curatedCheats,
  editMode,
  reorderMode,
  scoutedType,
  setCuratedCheats,
  setReorderMode,
}) {
  const classes = useStyles();

  const onChange = (cheat, index, type) => {
    if (type === CURATED_TYPE) {
      const clonedCheats = cloneDeep(curatedCheats);
      clonedCheats.splice(index, 1, cheat);
      setCuratedCheats(clonedCheats);
    }
  };

  const moveCheat = (dragIndex, hoverIndex) => {
    const newCheats = [...curatedCheats];

    const temp = newCheats[dragIndex];
    newCheats[dragIndex] = newCheats[hoverIndex];
    newCheats[hoverIndex] = temp;

    setCuratedCheats(newCheats);
  };

  return (
    <>
      {editMode && scoutedType === CURATED_TYPE && (
        <Container className={classes.reorderButtonContainer}>
          <Button
            className={classes.reorderButton}
            label={reorderMode ? 'Done' : 'Reorder'}
            onClick={() => {
              setReorderMode(!reorderMode);
            }}
          />
        </Container>
      )}
      <Container className={classes.storiesContainer}>
        <DragAndDrop>
          {cheats.map((cheat, index) => {
            if (reorderMode && scoutedType === CURATED_TYPE) {
              return (
                <CheatSheetDraggableCard
                  classes={classes}
                  key={counter.next().value}
                  index={index}
                  label={index + 1}
                  moveCheat={moveCheat}
                  publicationDate={
                    cheat.publicationDate &&
                    moment(cheat.publicationDate).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )
                  }
                  title={cheat.longHeadline}
                />
              );
            }

            return (
              <ScoutedAutocomplete
                className={classes.autocomplete}
                key={counter.next().value}
                cheat={cheat}
                index={index}
                scoutedType={scoutedType}
                disabled={scoutedType === LATEST_TYPE || !editMode}
                onChange={onChange}
              />
            );
          })}
        </DragAndDrop>
      </Container>
    </>
  );
}
