query ObsesssedTags ($versionId: String!) {
  story: storyById(versionId: $versionId) {
    obsessed_tags {
      id
      name
      type
      slug
    }
  }
}
