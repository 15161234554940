import { useContext } from 'react';

export const ACTIONS = {
  SET_CAN_PUBLISH: 'SET_CAN_PUBLISH',
  SET_MODAL_OPEN: 'SET_MODAL_OPEN',
  SET_SNACKBAR: 'SET_SNACKBAR',
  SET_STEP2_IS_FETCHING: 'SET_STEP2_IS_FETCHING',
  SET_STEP2_OPTIONS: 'SET_STEP2_OPTIONS',
  SET_STEP2_PLACEHOLDER: 'SET_STEP2_PLACEHOLDER',
  SET_STEP3_EXISITING_ARTICLE: 'SET_STEP3_EXISITING_ARTICLE',
  SET_STEP3_IS_FETCHING: 'SET_STEP3_IS_FETCHING',
  SET_VALUE: 'SET_VALUE',
};

export const initialState = {
  step1: {
    value: null,
  },
  step2: {
    isFetching: false,
    options: [],
    placeholder: '',
    value: null,
  },
  step3: {
    existingArticle: null,
    isFetching: false,
    value: '',
  },
  canPublish: false,
  modal: {
    open: false,
    message: {
      line1: 'Are you sure you want to change the canonical slug from:',
      line2: 'to:',
    },
  },
  snackbar: {
    message: '',
    open: false,
    severity: 'info',
  },
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_CAN_PUBLISH:
      return { ...state, canPublish: action.payload };
    case ACTIONS.SET_MODAL:
      return { ...state, modal: { ...state.modal, ...action.payload } };
    case ACTIONS.SET_SNACKBAR:
      return { ...state, snackbar: action.payload };
    case ACTIONS.SET_VALUE:
      return {
        ...state,
        [`step${action.payload.step}`]: {
          ...state[`step${action.payload.step}`],
          value: action.payload.value,
        },
      };
    case ACTIONS.SET_STEP2_IS_FETCHING:
      return {
        ...state,
        step2: { ...state.step2, isFetching: action.payload },
      };
    case ACTIONS.SET_STEP2_OPTIONS:
      return {
        ...state,
        step2: { ...state.step2, options: action.payload },
      };
    case ACTIONS.SET_STEP3_IS_FETCHING:
      return {
        ...state,
        step3: { ...state.step3, isFetching: action.payload },
      };
    case ACTIONS.SET_STEP3_EXISITING_ARTICLE:
      return {
        ...state,
        step3: { ...state.step3, existingArticle: action.payload },
      };
    default:
      return initialState;
  }
}
