query StorySuggestions($query: String, $types: [StoryType] = [ARTICLE, GALLERY]) {
  suggestions(query: $query  types: $types first:5) {
    edges {
      node {
        ... on Article {
          ...StoryContent
        }

        ... on Cheat {
          ...StoryContent
        }

        ... on Gallery {
          ...StoryContent
        }
      }
    }
  }
}

fragment StoryContent on Story {
  longHeadline: long_headline
  publicationDate: publication_date
  slug
  authors {
    name
  }
}
