import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Transformation } from 'cloudinary-react';
import _ from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import SelectField from '@material-ui/core/Select';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '../../../../../common/Modal/Modal';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import CloudinaryImage from '../../../../../common/CloudinaryImage/CloudinaryImage';
import SlimMobiledoc from '../../../../../common/SlimMobiledoc/SlimMobiledoc';
import imageQueryGQL from './graphql/imageQuery.gql';
import IMAGE_DISPLAY_TYPE from '../../../../../utils/image-display-type';
import { MediaContext } from '../../../../../context/MediaContext/MediaContext';
import {
  Context as EditorContext,
  MOBILEDOC_VERSION,
} from '../../../../../context/EditorContext';
import Input from '../../../../../common/Input/Input';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import getImageUrl from '../../../../../helpers/images';
import updateImageMutationGQL from './graphql/updateImageMutation.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';
import { IMAGE_CARD_NAME } from '../../../body/components/ImageCard/ImageCard';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import mobiledocMutationGQL from '../../../../../mutations/mobiledoc.gql';
import { StoryContext } from '../../../../../context/StoryContext';

const mobiledocMutation = gql`
  ${mobiledocMutationGQL}
`;

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      'font-size': '12px',
    },
  }),
);

export const LABEL = {
  TITLE: 'Title',
  CAPTION: 'Caption',
  GALLERY_CAPTION: 'Gallery Caption',
  CREDIT: 'Credit',
  ALT_TEXT: 'Alt Text',
  RIGHTS_HOLDER: 'Rights Holder',
  RIGHTS: 'Rights',
};

export const PLACEHOLDER = {
  TITLE: 'Apprentice Staffer Claims Gary Busey ate worms, Donald Laughed',
  CAPTION:
    'An astouding number of court battles—from Trump University suits to libel cases—will accompany Trump even as he moves into the White House',
  CREDIT: "Photographer's name",
  ALT_TEXT: "Matt Damon's Emmys Best Dressed",
};

export const RIGHTS_HOLDERS = [
  { id: 0, name: null },
  { id: 1, name: 'Getty' },
  { id: 2, name: 'Reuters' },
  { id: 3, name: 'AP' },
  { id: 4, name: 'Shutterstock' },
  { id: 5, name: 'Other' },
];

export const RIGHTS = [
  { id: 0, name: null },
  { id: 1, name: 'Creative Commons' },
  { id: 2, name: 'All Rights' },
  { id: 3, name: 'Fair Use' },
  { id: 4, name: 'None' },
];

export const TAB_TYPE = {
  INFO: 'info',
  CROPS: 'crops',
};

const CROP_TYPES = {
  wideScreen: {
    id: '16_9',
    aspect: 16 / 9,
  },
  square: {
    id: '1_1',
    aspect: 1 / 1,
  },
};

const DEFAULT_IMAGE = {
  id: null,
  publicId: '',
  url: '',
  title: '',
  credit: '',
  altText: null,
  owner: null,
  rights: null,
  displayType: IMAGE_DISPLAY_TYPE.STANDARD_WIDTH,
  mainImage: false,
};

const imageQuery = gql`
  ${imageQueryGQL}
`;

const updateImageMutation = gql`
  ${updateImageMutationGQL}
`;

function ImageModal(props) {
  const { showImageDisplayType } = props;
  const [updateMobiledoc] = useMutation(mobiledocMutation);
  const storyContext = useContext(StoryContext);
  const { loadMobiledoc } = useContext(EditorContext);

  const {
    storyHero: { imageId, errorMessage },
    updateImageModalOpen,
    currentImageId,
    imageIds,
    updateStoryHeroError,
    deleteHeroImage,
    deleteImage,
    updateCurrentImageId,
  } = useContext(MediaContext);
  const { editor } = useContext(EditorContext);

  const [image, setImage] = useState(DEFAULT_IMAGE);
  const [mobiledocCaption, setMobiledocCaption] = useState(undefined);
  const [tabValue, setTabValue] = React.useState(0);
  const [cropElementWidth, setCropElementWidth] = React.useState(0);
  const [cropElementHeight, setCropElementHeight] = React.useState(0);
  const [creditErrorMessage, setCreditErrorMessage] = React.useState('');
  const [altTextErrorMessage, setAltTextImageErrorMessage] = React.useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [loadImage] = useLazyQuery(imageQuery, {
    onError: err => {
      console.log('ImageModal:loadImage:err', err);
      errorMessageAction(err);
    },
    onCompleted: data => {
      console.log('ImageModal:loadImage:data', data);

      const { image: newImage } = data;
      delete image.mobiledocCaption;
      setImage(newImage);

      // This is intentionally set separately, because mobiledoc postDidChange becomes out of sync with state hook
      setMobiledocCaption(data.image.mobiledocCaption);

      if (!props.inConversionCard && !props.inVideoModal && data) {
        if (!data.image.credit) {
          setCreditErrorMessage('Image credit can’t be empty');
        }
        if (!data.image.altText) {
          setAltTextImageErrorMessage('Image alt text can’t be empty');
        }
      }
    },
  });

  const [updateImage] = useMutation(updateImageMutation, {
    onError: err => {
      console.log('ImageModal:updateImage:err', err);

      const error = getGraphqlErrorMessage(err);
      updateStoryHeroError(error);
    },
    onCompleted: data => {
      if (errorMessage) updateStoryHeroError(null);
      const { image: img } = data;
      if (!img.displayType || !img.credit || !img.credit.length) {
        updateStoryHeroError('Error: Please check required image fields');
      }
    },
  });
  useEffect(() => {
    if (currentImageId) {
      loadImage({
        variables: {
          id: currentImageId,
        },
      });
    }
  }, [currentImageId]);
  useEffect(() => {
    if (!props.inConversionCard && !props.inVideoModal && storyContext.id) {
      loadMobiledoc({ variables: { versionId: storyContext.id } });
    }
  }, []);
  const onTabActiveToggle = (e, value) => {
    setTabValue(value);
  };

  const onImageDisplayTypeToggled = e => {
    if (e.target.checked) {
      setImage({
        ...image,
        displayType: IMAGE_DISPLAY_TYPE.FULL_WIDTH,
      });
    } else {
      setImage({
        ...image,
        displayType: IMAGE_DISPLAY_TYPE.STANDARD_WIDTH,
      });
    }
  };

  const onPreviousTap = () => {
    saveImage();

    const previousImageIndex =
      imageIds.findIndex(imageIdElement => imageIdElement === currentImageId) -
      1;
    const previousImageId = imageIds[previousImageIndex];
    updateCurrentImageId(previousImageId);
  };

  const onNextTap = () => {
    saveImage();

    const nextImageIndex =
      imageIds.findIndex(imageIdElement => imageIdElement === currentImageId) +
      1;
    const nextImageId = imageIds[nextImageIndex];
    updateCurrentImageId(nextImageId);
  };

  const widthScaleFactor = () => {
    return image.crops.original.width / cropElementWidth;
  };

  const heightScaleFactor = () => {
    return image.crops.original.height / cropElementHeight;
  };

  const onCropChange = (updatedCrops, cropType) => {
    if (!updatedCrops.width || !updatedCrops.height) return;

    const scaledCrops = {
      x: widthScaleFactor() * updatedCrops.x,
      y: heightScaleFactor() * updatedCrops.y,
      width: widthScaleFactor() * updatedCrops.width,
      height: heightScaleFactor() * updatedCrops.height,
    };

    const newCrops = {
      ...image.crops,
      [cropType.id]: scaledCrops,
    };

    if (!_.isEqual(scaledCrops, image.crops[cropType.id])) {
      setImage({
        ...image,
        crops: newCrops,
      });
      // TODO: update mobiledoc onImageUpdate();
    }
  };

  const cropsWithScaleOffset = cropType => {
    const crops = image.crops[cropType.id];
    const scaledCrops = {
      x: crops.x / widthScaleFactor(),
      y: crops.y / heightScaleFactor(),
      width: crops.width / widthScaleFactor(),
      height: crops.height / heightScaleFactor(),
      aspect: cropType.aspect,
      unit: 'px',
    };

    return scaledCrops;
  };

  const updateMobiledocCaption = mdCaption => {
    setMobiledocCaption(mdCaption);
  };

  const onChange = (field, fieldValue) => {
    if (!Object.keys(DEFAULT_IMAGE).includes(field)) return;

    setImage({
      ...image,
      [field]: fieldValue,
    });
  };

  // No need for full-sized raw image for crop dialog.
  const cropImageUrl = getImageUrl(image, {
    flags: 'lossy',
    quality: 'auto',
  });

  const saveImage = () => {
    const {
      publicId,
      url,
      title,
      credit,
      altText,
      owner,
      rights,
      crops,
      displayType,
      mainImage,
    } = image;

    if (!displayType || !credit || !credit.length) {
      updateStoryHeroError('Error: Please check required image fields');
    }
    updateImage({
      variables: {
        id: currentImageId,
        image: {
          public_id: publicId,
          url,
          title,
          mobiledoc_caption: mobiledocCaption,
          credit,
          alt_text: altText,
          owner,
          rights,
          display_type: displayType,
          crops,
        },
      },
    });

    // Manually update mobiledoc since mobiledockit does not support replaceSection without a valid mobiledoc editor in the dom.
    const sectionsToReplace = [];
    if (mainImage) return;
    editor.post.walkAllLeafSections(section => {
      if (
        section.type === 'card-section' &&
        section.name === IMAGE_CARD_NAME &&
        String(section.payload.id) === currentImageId
      ) {
        sectionsToReplace.push(section);
      }
    });

    const serializedDoc = editor.serialize(MOBILEDOC_VERSION);

    sectionsToReplace.forEach(section => {
      const indexToUpdate = serializedDoc.cards.findIndex(crd => {
        const matchesIdInPayload = crd[1].id === section.payload.id;
        const matchesCardName = crd[0] === IMAGE_CARD_NAME;
        return matchesIdInPayload && matchesCardName;
      });
      if (indexToUpdate > -1) {
        serializedDoc.cards[indexToUpdate][1] = {
          id: currentImageId,
          public_id: publicId,
          url,
          title,
          mobiledoc_caption: mobiledocCaption,
          credit,
          alt_text: altText,
          owner,
          rights,
          display_type: displayType,
          crops,
        };
      }
    });

    updateMobiledoc({
      variables: {
        id: storyContext.id,
        story: {
          mobiledoc: serializedDoc,
        },
      },
    });
  };

  const onClose = () => {
    saveImage();
    updateImageModalOpen(false);
  };

  const renderOwnerItems = () => {
    return RIGHTS_HOLDERS.map(rightsHolder => {
      return (
        <MenuItem value={rightsHolder.id} key={rightsHolder.id}>
          {rightsHolder.name}
        </MenuItem>
      );
    });
  };

  const renderRightsItems = () => {
    return RIGHTS.map(right => {
      return (
        <MenuItem value={right.id} key={right.id}>
          {right.name}
        </MenuItem>
      );
    });
  };

  const onOwnerChange = (e, selectedComponent) => {
    const {
      props: { value },
    } = selectedComponent;

    const owner = RIGHTS_HOLDERS.find(
      rightsHolder => rightsHolder.id === value,
    );

    if (owner) {
      setImage({
        ...image,
        owner: owner.name,
      });
    }
  };

  const onRightsChange = (e, selectComponent) => {
    const {
      props: { value },
    } = selectComponent;

    const right = RIGHTS.find(r => r.id === value);

    if (right) {
      setImage({
        ...image,
        rights: right.name,
      });
    }
  };

  const renderDeleteDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        text="Are you sure you want to delete this image?"
        onDelete={onDeleteImage}
        onClose={() => setDeleteDialogOpen(false)}
      />
    );
  };

  const onDeleteImage = () => {
    if (currentImageId === imageId) {
      deleteHeroImage({
        variables: {
          id: currentImageId,
        },
      });
    } else {
      deleteImage({
        variables: {
          id: currentImageId,
        },
      });
      const sectionsToReplace = [];
      if (image.mainImage) return;
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === IMAGE_CARD_NAME &&
          String(section.payload.id) === currentImageId
        ) {
          sectionsToReplace.push(section);
        }
      });
      editor.run(postEditor => {
        sectionsToReplace.forEach(section => {
          postEditor.removeSection(section);
        });
      });
      const serializedDoc = editor.serialize(MOBILEDOC_VERSION);
      updateMobiledoc({
        variables: {
          id: storyContext.id,
          story: {
            mobiledoc: serializedDoc,
          },
        },
      });
    }
    updateStoryHeroError('');
    updateImageModalOpen(false);
  };

  let imageOwnerId = 0;
  let imageRightsId = 0;
  let previousButtonDisabled = false;
  let nextButtonDisabled = false;

  // Enable/Disable next and previous buttons
  if (imageIds && currentImageId) {
    const currentImageIndex = imageIds.findIndex(i => i === currentImageId);
    previousButtonDisabled = currentImageIndex === 0;
    nextButtonDisabled = currentImageIndex + 1 === imageIds.length;
  }

  const { publicId, altText, displayType, title, credit, url, owner } = image;

  const urlParts = image.url && image.url.split('/');
  const imageName = urlParts[urlParts.length - 1];
  const classes = useStyles();

  const imageOwner = RIGHTS_HOLDERS.find(
    rightsHolder => rightsHolder.name === owner,
  );
  if (imageOwner) imageOwnerId = imageOwner.id;

  const imageRights = RIGHTS.find(right => right.name === image.rights);
  if (imageRights) imageRightsId = imageRights.id;

  const isHeroImage = currentImageId === imageId;

  return (
    <section>
      <Modal open onClose={onClose} style={{ minWidth: '570px' }}>
        {!isHeroImage && false && (
          <Grid container justify="space-between">
            <Grid item>
              <Button
                label="＜ Previous Image"
                disabled={previousButtonDisabled}
                onClick={onPreviousTap}
              />
            </Grid>
            <Grid item>
              <Button
                label="Next Image ＞"
                disabled={nextButtonDisabled}
                onClick={onNextTap}
              />
            </Grid>
          </Grid>
        )}

        <Grid container justify="center">
          <Grid
            item
            style={{
              flex: 1,
              background: '#f3f4f7',
              paddingTop: '5px',
              paddingBottom: '5px',
              textAlign: 'center',
            }}
          >
            <CloudinaryImage publicId={publicId} alt={altText}>
              <Transformation height="270" width="540" crop="limit" />
            </CloudinaryImage>
          </Grid>
        </Grid>

        <div>
          <Box
            p={2}
            style={{ justifyContent: 'space-between', display: 'flex' }}
          >
            <a href={url} target="_blank" rel="noopener noreferrer">
              {imageName}
            </a>

            {showImageDisplayType && (
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={displayType === IMAGE_DISPLAY_TYPE.FULL_WIDTH}
                      onChange={onImageDisplayTypeToggled}
                      value="full_width"
                    />
                  }
                  label="Full Width"
                />
              </FormControl>
            )}
          </Box>
        </div>

        <Box p={2}>
          <Paper square>
            <Tabs
              value={tabValue}
              onChange={
                (e, newTabValue) => onTabActiveToggle(e, newTabValue)
                // eslint-disable-next-line react/jsx-curly-newline
              }
              aria-label="image tabs"
            >
              <Tab label="Info" />
              <Tab label="Crops" />
            </Tabs>
          </Paper>

          {tabValue === 0 && (
            <Box py={2}>
              <Input
                value={title}
                label={LABEL.TITLE}
                placeholder={PLACEHOLDER.TITLE}
                onChange={fieldValue => onChange('title', fieldValue)}
                charCounter
                length={255}
              />
              <div style={{ minHeight: '300px' }}>
                <h3>Caption</h3>
                <SlimMobiledoc
                  mobiledoc={mobiledocCaption}
                  placeholder={PLACEHOLDER.CAPTION}
                  updateDoc={updateMobiledocCaption}
                />
              </div>
              <Box my={2}>
                <Input
                  value={credit}
                  label={LABEL.CREDIT}
                  placeholder={PLACEHOLDER.CREDIT}
                  onChange={fieldValue => {
                    const err =
                      fieldValue && fieldValue.length
                        ? ''
                        : 'Image credit can’t be empty';
                    setCreditErrorMessage(err);
                    onChange('credit', fieldValue);
                  }}
                  charCounter
                  length={255}
                  errorText={creditErrorMessage}
                />
              </Box>
              <Box my={2}>
                <Input
                  value={altText}
                  label={LABEL.ALT_TEXT}
                  placeholder={PLACEHOLDER.ALT_TEXT}
                  onChange={fieldValue => {
                    const err =
                      fieldValue && fieldValue.length
                        ? ''
                        : 'Image alt text can’t be empty';
                    setAltTextImageErrorMessage(err);
                    onChange('altText', fieldValue);
                  }}
                  charCounter
                  length={255}
                  errorText={altTextErrorMessage}
                />
              </Box>
              <Box my={2}>
                <InputLabel className={classes.inputLabel} id="label">
                  Rights Holder
                </InputLabel>
                <SelectField
                  id="select-field"
                  fullWidth
                  value={imageOwnerId}
                  onChange={onOwnerChange}
                >
                  {renderOwnerItems()}
                </SelectField>
              </Box>
              <Box my={2}>
                <InputLabel className={classes.inputLabel} id="label">
                  Rights
                </InputLabel>
                <SelectField
                  id="select-field"
                  fullWidth
                  value={imageRightsId}
                  onChange={onRightsChange}
                >
                  {renderRightsItems()}
                </SelectField>
              </Box>
            </Box>
          )}

          {tabValue === 1 && (
            <Box style={{ width: '570px' }}>
              <p>16:9</p>
              <ReactCrop
                src={cropImageUrl}
                crop={cropsWithScaleOffset(CROP_TYPES.wideScreen)}
                onChange={newCrop =>
                  onCropChange(newCrop, CROP_TYPES.wideScreen)
                }
                onImageLoaded={imageRef => {
                  if (imageRef) {
                    setCropElementWidth(imageRef.clientWidth);
                    setCropElementHeight(imageRef.clientHeight);
                  }
                }}
              />
              <p>1:1</p>
              <ReactCrop
                src={cropImageUrl}
                crop={cropsWithScaleOffset(CROP_TYPES.square)}
                onChange={newCrop => onCropChange(newCrop, CROP_TYPES.square)}
                onImageLoaded={imageRef => {
                  if (imageRef) {
                    setCropElementWidth(imageRef.clientWidth);
                    setCropElementHeight(imageRef.clientHeight);
                  }
                }}
              />
            </Box>
          )}
        </Box>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              label="Delete Image"
              onClick={() => setDeleteDialogOpen(true)}
              color="default"
            />
          </Grid>
          <Grid item>
            <Button color="secondary" label="Close" onClick={onClose} />
          </Grid>
        </Grid>
      </Modal>
      {renderDeleteDialog()}
    </section>
  );
}

ImageModal.propTypes = {
  showImageDisplayType: PropTypes.bool,
  inConversionCard: PropTypes.bool,
  inVideoModal: PropTypes.bool,
};

export default ImageModal;
