export const v0ThemeSettings = {
  fontFamily: 'Helvetica, sans-serif',
  palette: {
    primary1Color: '#3d659e',
    primary2Color: '#3d659e',
    accent1Color: '#525350',
    pickerHeaderColor: '#3d659e',
  },
};

export const v4ThemeSettings = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#3d659e',
      contrastText: '#FFFFFF',
    },
    secondary: {
      dark: '#201C37',
      main: '#3d659e',
    },
    text: {
      primary: '#2E2E2E',
      secondary: '#525350',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#FFFFFF',
        color: '#2E2E2E',
      },
    },
  },
};
