import PropTypes from 'prop-types';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '../../../../../../common/Input/Input';
import { UPDATE_SOCIAL } from '../EmbedPicker';

export const SOCIAL_TYPE = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
};

SocialPickerForm.propTypes = {
  inputValue: PropTypes.string,
  inputError: PropTypes.string,
  selectedSocialType: PropTypes.string,
  onSocialTypeChange: PropTypes.func,
  onInputUpdate: PropTypes.func,
};

export default function SocialPickerForm({
  inputValue,
  inputError,
  selectedSocialType,
  onSocialTypeChange,
  onInputUpdate,
}) {
  return (
    <FormControl fullWidth component="social">
      <RadioGroup
        aria-label="Social"
        name="Social"
        onChange={onSocialTypeChange}
        value={selectedSocialType}
      >
        <FormControlLabel
          control={<Radio />}
          label="Facebook"
          value="facebook"
        />
        <FormControlLabel control={<Radio />} label="Twitter" value="twitter" />
        <FormControlLabel
          control={<Radio />}
          label="Instagram"
          value="instagram"
        />
      </RadioGroup>
      <Input
        label="Social Url"
        placeholder="Paste your URL here..."
        keyToUpdate={UPDATE_SOCIAL}
        updateFieldAction={onInputUpdate}
        value={inputValue}
        errorText={inputError}
        focus
      />
    </FormControl>
  );
}
