import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client'
import createScreamerMutation from '../../../mutations/screamer/createScreamer.gql';
import updateScreamerMutation from '../../../mutations/screamer/updateScreamer.gql';

const CREATE_SCREAMER_MUTATION = gql`
  ${createScreamerMutation}
`;

const UPDATE_SCREAMER_MUTATION = gql`
  ${updateScreamerMutation}
`;

// eslint-disable-next-line import/prefer-default-export
export function useSaveScreamer() {
  const create = useMutation(CREATE_SCREAMER_MUTATION);
  const update = useMutation(UPDATE_SCREAMER_MUTATION);

  return { create, update };
}
