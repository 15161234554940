mutation ScheduleStoryMutation($id: ID!, $story: StoryInput!) {
  scheduleStory(id: $id, story: $story) {
    story {
      ... on Article {
        type
        id
        slug
        updatedAt: updated_at
        seoHeadline: seo_headline
      }
      ... on Cheat {
        type
        id
        slug
        updatedAt: updated_at
        seoHeadline: seo_headline
      }
      ... on Gallery {
        type
        id
        slug
        updatedAt: updated_at
        seoHeadline: seo_headline
      }
    }

    Validation {
      ... on PublishErrors {
        actionType
        Errors {
          message
          fieldName
        }
      }

    }
  }
}
