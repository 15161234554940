import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import Modal from '../../../../../common/Modal/Modal';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import Input from '../../../../../common/Input/Input';
import { SECTION_BREAK_CARD_NAME } from '../SectionBreakCard/SectionBreakCard';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      margin: 0,
    },
    group: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
  }),
);

export const SECTION_BREAK_TYPE = {
  DEFAULT: 'Default',
  SMALL_TEXT: 'Small Text',
  BIG_TEXT: 'Big Text',
};

export default function SectionBreakPicker({ selection }) {
  const classes = useStyles();
  const {
    editor,
    sectionBreakId,
    updateSectionBreakId,
    updateSectionBreakPickerOpen,
    saveCardFunction,
    updateSaveCardFunction,
  } = useContext(EditorContext);
  const [selectedType, setSelectedType] = useState(SECTION_BREAK_TYPE.DEFAULT);
  const [text, setText] = useState('');
  const [textError, setTextError] = useState(null);

  useEffect(() => {
    editor.post.walkAllLeafSections(section => {
      if (
        section.type === 'card-section' &&
        section.name === SECTION_BREAK_CARD_NAME &&
        section.payload.id === sectionBreakId
      ) {
        // set content
        setText(section.payload.content.text);
        setSelectedType(section.payload.content.type);
      }
    });
  }, []);

  const updateTypeValue = e => {
    const { value } = e.target;

    if (value === SECTION_BREAK_TYPE.DEFAULT) {
      setText('');
    }

    setSelectedType(value);
  };

  const updateTextValue = textValue => {
    setText(textValue);
  };

  const onPlace = () => {
    if (!isValid()) return;

    const cardPayload = {
      id: uuid(),
      content: {
        type: selectedType,
        text,
      },
    };

    if (saveCardFunction) {
      // saveCardFunction is set in the card's edit function
      saveCardFunction(cardPayload);

      // reset save card function
      updateSaveCardFunction(null);
      updateSectionBreakId(null);
    } else {
      editor.selectRange(selection);
      editor.insertCard(SECTION_BREAK_CARD_NAME, cardPayload);
    }

    updateSectionBreakPickerOpen(false);
  };

  const isValid = () => {
    if (
      selectedType !== SECTION_BREAK_TYPE.DEFAULT &&
      text.trim().length === 0
    ) {
      setTextError("Drats, text can't be empty");
      return false;
    }

    setTextError('');
    return true;
  };

  const renderTextInput = () => {
    if (selectedType === SECTION_BREAK_TYPE.DEFAULT) return null;

    return (
      <Input
        label="Text"
        placeholder="Breakers should be one to five words"
        onChange={updateTextValue}
        value={text}
        errorText={textError}
        focus
      />
    );
  };

  return (
    <Modal
      title="Section Break"
      open
      onClose={() => updateSectionBreakPickerOpen(false)}
      onEnter={onPlace}
    >
      <DialogContent>
        <div>
          <FormControl
            fullWidth
            className={classes.formControl}
            component="fieldset"
          >
            <FormLabel component="legend"> Breaker Type</FormLabel>
            <RadioGroup
              className={classes.group}
              aria-label="Quote Type"
              name="section_break_type"
              onChange={updateTypeValue}
              value={selectedType}
            >
              <FormControlLabel
                control={<Radio />}
                label="Default"
                value={SECTION_BREAK_TYPE.DEFAULT}
              />
              <FormControlLabel
                control={<Radio />}
                label="Small Text"
                value={SECTION_BREAK_TYPE.SMALL_TEXT}
              />
              <FormControlLabel
                control={<Radio />}
                label="Big Text"
                value={SECTION_BREAK_TYPE.BIG_TEXT}
              />
            </RadioGroup>
          </FormControl>
          {renderTextInput()}
        </div>
        <DialogActions>
          <Button
            label="Cancel"
            onClick={() => updateSectionBreakPickerOpen(false)}
          />
          <Button
            label={sectionBreakId ? 'Update' : 'Place'}
            onClick={onPlace}
          />
        </DialogActions>
      </DialogContent>
    </Modal>
  );
}

SectionBreakPicker.propTypes = {
  selection: PropTypes.object,
};
