mutation CreateObsessedTag(
  $type: String!
  $slug: String!, 
  $name: String!,
  $childIds: [ID!],
) {
  tag: createObsessedTag(type: $type, slug: $slug, name: $name, childIds: $childIds) {
    id
    type
    slug
    name
    children {
      id
      name
      type
      slug
    }
  }
}
