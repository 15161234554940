import React, { useCallback, useContext, useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { HomepageContext, SET_SNACKBAR } from './HomepageContext';

export const SNACKBAR_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export default function HomepageSnackbar() {
  const {
    dispatch,
    state: { snackbar },
  } = useContext(HomepageContext);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState(SNACKBAR_TYPE.SUCCESS);

  const handleSnackbarClose = useCallback(() => {
    setMessage('');
    dispatch({
      type: SET_SNACKBAR,
      payload: { type: null, message: null },
    });
  }, [dispatch, setMessage]);

  const handleSnackbarExited = useCallback(() => {
    setSeverity(SNACKBAR_TYPE.SUCCESS);
  }, [setSeverity]);

  useEffect(() => {
    if (snackbar.message) {
      setSeverity(snackbar.type);
      setMessage(snackbar.message);
    }
  }, [snackbar.message, snackbar.type]);

  return (
    <Snackbar
      open={!!message}
      onClose={handleSnackbarClose}
      onExited={handleSnackbarExited}
    >
      <Alert
        elevation={6}
        onClose={handleSnackbarClose}
        severity={severity}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
