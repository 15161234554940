mutation ListMutation($storyIds: [Int]!, $name: ListType!) {
  updateList(storyIds: $storyIds, name: $name) {
    name
    stories {
      authors {
        name
      }
      id
      longHeadline: long_headline
      metadata {
        branded
      }
      publicationDate: publication_date
      slug
      vertical {
        name
      }
    }
  }
}
