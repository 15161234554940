.section {
  display: grid;
  gap: 0.8rem;
  grid-template-areas:
    'title title'
    'slot1 slot1'
    'slot2 slot3'
    'slot4 slot4'
    'slot5 slot5'
    'slot6 slot6'
    'slot7 slot7';
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  width: 100%;

}

.title {
  grid-area: title;
}

@for $i from 1 through 7 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
}

@media screen and (min-width: 600px) {
  .section {
    grid-template-areas:
      'title title'
      'slot1 slot1'
      'slot2 slot3'
      'slot4 slot4'
      'slot5 slot5'
      'slot6 slot6'
      'slot7 slot7';
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 960px) {
  .section {
    grid-template-areas:
      '. title title .'
      'slot2 slot1 slot1 slot3'
      'slot4 slot5 slot6 slot7';
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 25rem auto;
  }
}
