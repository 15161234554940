import PropTypes from 'prop-types';
import React from 'react';

/*
Available props to a mobiledoc cards

static propTypes = {
  cancel: PropTypes.func,
  edit: PropTypes.func.isRequired,
  isInEditor: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  postModel: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
}

*/

const UnknownCard = (props) => (
  <div >
    <p >{props.name}</p>
  </div>
);

UnknownCard.propTypes = {
  name: PropTypes.string,
};

export default UnknownCard;
