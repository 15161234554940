import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { StoryContext } from '../../../../../context/StoryContext';
import audienceQueryGQL from './graphql/audienceQuery.gql';
import audienceMutationGQL from './graphql/audienceMutation.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import ProductNameSection from './components/ProductNameSection';
import UnlockPendingSection from './components/UnlockPendingSection';

export const AUDIENCE = {
  MEMBERS: 'members',
  PUBLIC: 'public',
};

const audienceQuery = gql`
  ${audienceQueryGQL}
`;

const audienceMutation = gql`
  ${audienceMutationGQL}
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    group: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
    root: {
      display: 'flex',
    },
  })
);

function Audience() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [audience, setAudience] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loadAudience] = useLazyQuery(audienceQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const audienceData = data && data.story && data.story.audience;
      if (audienceData) setAudience(data.story.audience);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateAudience] = useMutation(audienceMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const audienceData =
        data && data.audienceData && data.audienceData.audience;
      if (audienceData) setAudience(audienceData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadAudience({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) =>
    updateAudience({
      variables: {
        id: storyContext.id,
        story: { audience: e.target.value },
      },
    });

  return (
    <>
      <Grid item xs={12} md={8}>
        <div className={classes.root}>
          <FormControl error={!!errorMessage} component='fieldset'>
            <FormLabel component='legend'>Audience</FormLabel>
            <RadioGroup
              aria-label='Audience'
              name='audience'
              value={audience}
              className={classes.group}
              onChange={onChange}
            >
              <FormControlLabel
                value={AUDIENCE.PUBLIC}
                control={<Radio />}
                label='Everyone - Public'
              />
              <FormControlLabel
                value={AUDIENCE.MEMBERS}
                control={<Radio />}
                label='Members Only'
              />
            </RadioGroup>
            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </FormControl>
        </div>
      </Grid>
      {audience !== AUDIENCE.MEMBERS ? null : (
        <Grid item xs={12} md={8}>
          <ProductNameSection />
          <UnlockPendingSection />
        </Grid>
      )}
    </>
  );
}

export default React.memo(Audience);
