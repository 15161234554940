import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const SVG_SYMBOLS = {
  UPLOAD: 'symbol_upload',
  QUOTE: 'symbol_pull_quote',
  FANCY_LINKS: 'fancy_links',
};

const useStyles = makeStyles({
  svgSprite: {
    display: 'none',
  },
});

export default function SvgSprite() {
  const classes = useStyles();
  return (
    <svg className={classes.svgSprite}>
      <symbol id={SVG_SYMBOLS.FANCY_LINKS} viewBox="0 0 25 25">
        <path d="M16.7,11.4l-1.2,1.2c-0.7,0.7-1.8,1-2.8,0.8l1.9-1.9l1.2-1.3c0.4-0.3,0.6-0.7,0.6-1.2c0-0.5-0.2-1-0.6-1.4 C15.5,7.3,15,7.1,14.6,7c-0.5,0-1,0.2-1.4,0.6L10,10.7c-0.2-1,0.1-2.1,0.8-2.8l1.2-1.2c0.6-0.6,1.4-1,2.3-1 c0.4,0,0.9,0.1,1.2,0.2c0.4,0.2,0.7,0.4,1.1,0.7C18,8,18,10,16.7,11.4z" />
        <path d="M10.9,17.1l-1.2,1.2c-1.3,1.3-3.3,1.3-4.6,0c-0.6-0.6-1-1.4-1-2.3c0-0.9,0.3-1.7,1-2.3l1.2-1.2c0.6-0.6,1.4-0.9,2.2-0.9 c0.2,0,0.4,0,0.6,0.1l-1.6,1.6c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.1,0.1c-0.7,0.6-1.1,1.1-1.4,1.5l0,0c0,0,0,0,0,0 c0,0,0,0,0,0l0,0c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0,0c-0.1,0.1-0.1,0.2-0.1,0.3l0,0C5.3,16.4,5.5,17,6,17.5 c0.3,0.4,0.7,0.6,1.2,0.6c0.5,0,1-0.2,1.4-0.6l1.2-1.2l1.9-2C11.9,15.3,11.6,16.4,10.9,17.1z" />
        <path d="M13.9,10.4c0,0.2-0.1,0.3-0.2,0.5l-4.5,4.5c-0.3,0.3-0.7,0.3-0.9,0L8,15.1c-0.1-0.1-0.2-0.3-0.2-0.5 c0-0.2,0.1-0.3,0.2-0.5l4.5-4.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.3,0.1,0.5,0.2l0.3,0.3C13.9,10.1,13.9,10.2,13.9,10.4z" />
        <path d="M17.7,17.8l2,1.5l-0.8-2.4l2-1.4h-2.5l-0.8-2.4l-0.8,2.4h-2.5l2,1.4l-0.8,2.4L17.7,17.8z" />
      </symbol>

      <symbol id={SVG_SYMBOLS.QUOTE} viewBox="0 0 15 15">
        <path d="M9.3,9.1H7.8V8c0-0.5,0.1-0.9,0.4-1.2c0.3-0.3,0.6-0.5,1.1-0.6v0.5C8.9,6.8,8.7,7,8.6,7.3C8.5,7.5,8.5,7.6,8.5,7.7h0.8V9.1 z M6.9,9.1H5.4V8c0-0.5,0.1-0.9,0.4-1.2C6,6.5,6.4,6.3,6.8,6.2v0.5C6.5,6.8,6.2,7,6.1,7.3C6.1,7.4,6,7.6,6,7.7h0.8V9.1z M12.8,7.5 c0-2.9-2.4-5.3-5.3-5.3S2.2,4.6,2.2,7.5s2.4,5.3,5.3,5.3S12.8,10.4,12.8,7.5" />
      </symbol>
      <symbol
        id={SVG_SYMBOLS.UPLOAD}
        className="SvgSprite__upload"
        viewBox="0 0 50 50"
      >
        <path d="M40.7,27.2c0-4.2-3.4-7.6-7.6-7.6c-0.2,0-0.3,0-0.4,0c-1.2-3.8-4.8-6.5-8.9-6.5c-5.1,0-9.2,4.1-9.4,9.1c-3.1,0.4-5.4,3-5.4,6.2c0,3.2,2,5.9,5.1,6.3h0.4h7.7v-6h-2.6l4.8-6.3l4.8,6.3h-2.6v6h7.3v0C37.7,34.4,40.7,31.2,40.7,27.2" />
      </symbol>
    </svg>
  );
}
