import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import ARTICLE_SUBTYPE from '../../../../../utils/article-subtype';
import STORY_TYPE from '../../../../../utils/story-type';
import { StoryContext } from '../../../../../context/StoryContext';
import storyTypeMutationGQL from './graphql/storyTypeMutation.gql';
import subtypeMutationGQL from './graphql/subtypeMutation.gql';
import deleteSubtypeMutationGQL from './graphql/deleteSubtypeMutation.gql';
import storyTypeQueryGQL from './graphql/storyTypeQuery.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';

const storyTypeMutation = gql`
  ${storyTypeMutationGQL}
`;

const updateSubtypeMutation = gql`
  ${subtypeMutationGQL}
`;

const deleteSubtypeMutation = gql`
  ${deleteSubtypeMutationGQL}
`;

const storyTypeQuery = gql`
  ${storyTypeQueryGQL}
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    group: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: `${theme.spacing}px 0`,
    },
    root: {
      display: 'flex',
    },
  })
);

function StoryTypeSection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [type, setType] = useState(null);
  const [subtype, setSubtype] = useState('standard');
  const [typeErrorMessage, setTypeErrorMessage] = useState('');
  const [subtypeErrorMessage, setSubtypeErrorMesssage] = useState('');

  const [loadTypeAndSubtype] = useLazyQuery(storyTypeQuery, {
    onCompleted: (data) => {
      if (typeErrorMessage) setTypeErrorMessage('');
      if (subtypeErrorMessage) setSubtypeErrorMesssage('');

      const storyTypeData = data && data.story && data.story.type;
      const storySubtypeData =
        data &&
        data.story &&
        data.story.metadata &&
        data.story.metadata.subtype;
      if (storyTypeData) setType(storyTypeData);
      if (storySubtypeData) setSubtype(storySubtypeData);
    },
    onError: (err) => {
      const parsedErr = getGraphqlErrorMessage(err);
      setTypeErrorMessage(parsedErr);
      setSubtypeErrorMesssage(parsedErr);
    },
  });

  const [updateType] = useMutation(storyTypeMutation, {
    onCompleted: (data) => {
      if (typeErrorMessage) setTypeErrorMessage('');

      const storyTypeData = data && data.story && data.story.type;
      if (storyTypeData) {
        storyContext.updateStoryType(storyTypeData);
        setType(storyTypeData);
      }
    },
    onError: (err) => {
      setTypeErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateSubtype] = useMutation(updateSubtypeMutation, {
    onCompleted: (data) => {
      if (subtypeErrorMessage) setSubtypeErrorMesssage('');

      const storySubtype = data && data.story && data.story.subtype;
      if (storySubtype) {
        storyContext.updateSubtype(storySubtype);
        setSubtype(storySubtype);
      }
    },
    onError: (err) => {
      setSubtypeErrorMesssage(getGraphqlErrorMessage(err));
    },
  });

  const [deleteSubtype] = useMutation(deleteSubtypeMutation, {
    onCompleted: (data) => {
      if (subtypeErrorMessage) setSubtypeErrorMesssage('');

      if (data && data.story && data.story.subtype !== 'undefined') {
        storyContext.updateSubtype(data.story.subtype);
        setSubtype(data.story.subtype);
      }
    },
    onError: (err) => {
      setSubtypeErrorMesssage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadTypeAndSubtype({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onStoryTypeChange = (e) => {
    const typeValue = e && e.target && e.target.value;

    updateType({
      variables: {
        id: storyContext.id,
        story: {
          type: typeValue,
        },
      },
    });

    if (typeValue === 'cheat' && subtype !== null) {
      deleteSubtype({
        variables: {
          articleId: storyContext.id,
          column: 'subtype',
        },
      });
    }
  };

  const onSubtypeChange = (e) => {
    updateSubtype({
      variables: {
        articleId: storyContext.id,
        metadata: {
          subtype: e.target.value,
        },
      },
    });
  };

  return (
    <Grid item sm={12} md={8}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Story Type</FormLabel>
        <RadioGroup
          className={classes.group}
          aria-label='Story Type'
          name='Story Type'
          onChange={onStoryTypeChange}
          value={type}
        >
          <FormControlLabel
            label='Article'
            control={<Radio />}
            value={STORY_TYPE.ARTICLE}
          />
          <FormControlLabel
            label='Cheat'
            control={<Radio />}
            value={STORY_TYPE.CHEAT}
          />
        </RadioGroup>
        {typeErrorMessage && (
          <FormHelperText error>{typeErrorMessage}</FormHelperText>
        )}
        <RadioGroup
          className={classes.group}
          name='Subtype'
          onChange={onSubtypeChange}
          value={subtype}
        >
          <FormControlLabel
            label='Standard'
            control={<Radio />}
            disabled={type !== STORY_TYPE.ARTICLE}
            value={ARTICLE_SUBTYPE.STANDARD}
          />
          <FormControlLabel
            label='Express'
            control={<Radio />}
            value={ARTICLE_SUBTYPE.EXPRESS}
            disabled={type !== STORY_TYPE.ARTICLE}
          />
          <FormControlLabel
            label='Feature'
            control={<Radio />}
            value={ARTICLE_SUBTYPE.FEATURE}
            disabled={type !== STORY_TYPE.ARTICLE}
          />
        </RadioGroup>
        {subtypeErrorMessage && (
          <FormHelperText>{subtypeErrorMessage}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

export default StoryTypeSection;
