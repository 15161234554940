mutation createBrand(
  $brandName: String!
) {
  createBrandAndCampaign(name: $brandName) {
    id
    name
    slug
    updated_at
    created_at
  }
}
