export const LABEL = {
  AUTHORS: 'Authors',
  CATEGORY: 'Category',
  DATEPICKER: 'Publication Date',
  DEK: 'Dek',
  EDITORS: 'Editors',
  UNLOCK_PENDING_CHECKBOX:
    'Unlock this article in the future and bump it to the top of the vertical and all feeds.',
  LONG_HEADLINE: 'Article Headline *',
  NSFW: 'NSFW',
  PRODUCT_NAME: 'Product Name',
  TIMEPICKER: 'Publication Time (ET)',
  READ_IT_AT_NAME: 'Read It At Link',
  RUBRIC: 'Rubric',
  SEO_HEADLINE: 'SEO Headline *',
  SHORT_HEADLINE: 'Homepage Headline *',
  SITE: 'Site',
  SOCIAL_HEADLINE: 'Social Headline',
  SUBVERTICAL: 'Subvertical',
  TAGS: 'Keywords *',
  TRAGEDY: 'Tragedy or Death',
  VERTICAL: 'Vertical *',
  VIOLENCE: 'Violence or Harassment',
  FRANCHISE: 'Franchise',

  // Obsessed labels
  OBSESSED_TITLE: 'Title',
  OBSESSED_SOURCE: 'Where to Watch',
  OBSESSED_PERSON: 'Person',
  OBSESSED_GENRE: 'Genre',
  OBSESSED_RATING: 'Rating',
  OBSESSED_MEDIA: 'Media',
  OBSESSED_COVERAGE: 'Coverage',
};

export const PLACEHOLDER = {
  AUTHORS: 'e.g. Carl Bernstein',
  DEK:
    'Pastor Creflo Dollar has spoken to God, and God wants him to buy a $65 million private jet.\nHis congregation, thus far, has obliged his every whim.',
  EDITORS: 'e.g. Emma Carew Grovum',
  LONG_HEADLINE: 'This headline appears on the article page.',
  PRODUCT_NAME: 'e.g. Royalist, SneakPeek',
  READ_IT_AT_NAME: 'e.g. New York Times',
  READ_IT_AT_URL: 'e.g. www.nytimes.com',
  RUBRIC: 'OMG',
  SEO_HEADLINE:
    'Include all major, searchable keywords early on; this headline generates URL & only appears in search',
  SHORT_HEADLINE:
    'Appears on homepage, recirculation, newsletters; 60 characters',
  SOCIAL_HEADLINE:
    'Visible when shared to social; can have more voice; under 110 characters',
  TAGS: 'e.g. Climate Change',
};
