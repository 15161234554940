import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { deleteUser } from '../../redux/actions/user';
import { logError } from '../../helpers/logError';
import { handleError } from '../../helpers/global-error-handler';

NavigationIndex.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
  listItems: PropTypes.array,
  title: PropTypes.string,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    flex: {
      display: 'flex',
      flex: 1,
    },
    footer: {
      backgroundColor: theme.palette.secondary.main,
    },
    layout: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    container: {
      alignItems: 'flex-start',
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
    },
    heading: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      padding: '16px',
    },
    menu: {
      marginTop: '100px',
      width: 500,
    },
    page: {
      backgroundColor: theme.palette.grey['100'],
      width: '100%',
    },
  })
);

export function NavigationIndex({ onEnter, permissions, listItems, title }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);

  function logout() {
    deleteUser();
    global.PublicClientApplication.logoutRedirect().catch((error) => {
      logError(error, {
        afterCapture: handleError,
      });
    });
  }

  return (
    <>
      <CssBaseline />
      <div className={classes.layout}>
        <div className={classes.flex}>
          <div className={classes.page}>
            <Container className={classes.container}>
              <Paper className={classes.menu}>
                <List
                  component='nav'
                  subheader={
                    <Typography variant='h6' className={classes.heading}>
                      {title}
                    </Typography>
                  }
                >
                  <Divider />
                  {listItems.map((item, i) => (
                    <ListItem button component={Link} to={item.route} key={i}>
                      <ListItemText primary={item.displayText} />
                    </ListItem>
                  ))}
                </List>
                <Divider />
                <List component='nav'>
                  <ListItem button>
                    <ListItemText primary='Log Out' onClick={logout} />
                  </ListItem>
                </List>
              </Paper>
            </Container>
          </div>
        </div>
        <AppBar component='footer' position='static' className={classes.footer}>
          <Toolbar>
            <div className={classes.toolbar}>
              <div>
                <Typography variant='body1' color='inherit' noWrap>
                  The Daily Beast - Publishing Toolkit
                </Typography>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}
