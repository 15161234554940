import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import getGraphqlErrorMessage from '../../../helpers/graphqlError';
import BizToolsBasePage from '../BizToolsBasePage';
import Categorization from '../../../features/article/metadata/components/Metadata/Categorization';
import loadStoriesGQL from './queries/loadStories.gql';
import loadLastUpdatedGQL from './queries/loadLastUpdated.gql';
import deleteAutoDataMutationGQL from './queries/deleteAutoData.gql';
import { colors } from '../../../styles/global/variables';

CategorizationData.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(() => ({
  autoComplete: {
    maxWidth: '600px',
    marginTop: '30px',
    '& .MuiFormLabel-root': {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  header: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '600px',
    marginLeft: '0',
    marginRight: '0',
  },
  headerText: {
    fontSize: 'smaller',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    color: colors.red,
  },
  heading: {
    marginBlockStart: '0px',
    marginBlockEnd: '3px',
  },
  updateButton: {
    backgroundColor: colors.green,
    color: colors.white,
    borderRadius: '2px',
    width: '120px',
    height: '32px',
    marginTop: 'auto',
    marginBottom: 'auto',
    fontWeight: 'bold',
    textTransform: 'none',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: colors.greenDown,
      cursor: 'pointer',
    },
    '&:disabled': {
      backgroundColor: colors.mediumGrey,
      color: colors.white,
    },
  },
  updateStatus: {
    color: colors.materialBlue,
    fontStyle: 'italic',
    fontSize: '12px',
    marginTop: '4px',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    padding: '10px 0',
  },
}));

const loadStoriesQuery = gql`
  ${loadStoriesGQL}
`;

const loadLastUpdatedQuery = gql`
  ${loadLastUpdatedGQL}
`;

const deleteAutoDataMutation = gql`
  ${deleteAutoDataMutationGQL}
`;

const { NODE_ENV } = process.env;
const { WWW_DOMAIN } = process.env;
const { VERITY_API_KEY } = process.env;
const { PTS_HOST } = process.env;

export default function CategorizationData({ onEnter, permissions }) {
  const classes = useStyles();

  const [input, setInput] = useState('');
  const [story, setStory] = useState(null);
  const [options, setOptions] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [updateStatusText, setUpdateStatusText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loadStories] = useLazyQuery(loadStoriesQuery, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      const stories = data && data.suggestions && data.suggestions.edges;
      setOptions(stories);
    },
  });

  const [deleteAutoData] = useMutation(deleteAutoDataMutation, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: () => {
      if (!WWW_DOMAIN || !PTS_HOST || !story?.slug) return;

      const fetchUrl = `https://verity-api.gumgum.com/page/classify?pageUrl=${WWW_DOMAIN}/${story?.slug}&callBackUrl=${PTS_HOST}/content-classification&ignoreCache=true`;
      fetch(fetchUrl, {
        method: 'get',
        headers: { 'x-api-key': `${VERITY_API_KEY}` },
      })
        .then(() => {
          setUpdating(true);
          setUpdateStatusText('Updating');
        })
        .catch(err => {
          setErrorMessage('Error with requesting auto data');
          const fetchError = new Error(
            `Content Classification Fetch Error - fetchUrl: ${fetchUrl}, error: ${err}`,
          );
          console.error(fetchError);

          if (global?.apm?.captureError) global.apm.captureError(fetchError);
        });
    },
  });

  const [loadLastUpdated] = useLazyQuery(loadLastUpdatedQuery, {
    onError: err => {
      console.error(err);
    },
    onCompleted: data => {
      const lastUpdatedAt = data?.autoBrandSafe?.createdAt;
      if (lastUpdatedAt) {
        setUpdateStatusText(
          `Last updated ${dateFormat(lastUpdatedAt, "mm.dd.yy H:MM TT 'ET'")}`,
        );
      } else {
        setUpdateStatusText('');
      }
    },
  });

  const onChange = (e, value) => {
    setInput(value.node.longHeadline);
    setStory(value.node);

    loadLastUpdated({
      variables: {
        articleId: value.node.id,
      },
    });
    setUpdateStatusText(`Last updated ${value.node.createdAt}`);
  };

  const onInputChange = (e, inputString) => {
    if (inputString) {
      loadStories({
        variables: {
          query: inputString,
          types: ['ARTICLE', 'GALLERY', 'CHEAT'],
        },
      });
    }
  };

  const onClick = () => {
    if (NODE_ENV !== 'production' || !story?.slug || !story?.id) return;

    deleteAutoData({
      variables: {
        articleId: story.id,
      },
    });
  };

  return (
    <BizToolsBasePage
      title="Categorization Data"
      onEnter={onEnter}
      permissions={permissions}
    >
      <Autocomplete
        className={classes.autoComplete}
        getOptionLabel={option => option.node.longHeadline}
        getOptionSelected={option => option.node.longHeadline}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        disableClearable
        inputValue={input}
        disabled={updating}
        renderOption={option => (
          <div id={option.id}>{option.node.longHeadline}</div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => setInput(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
            label="Story"
          />
        )}
      />
      <Typography className={classes.updateStatus}>
        {updateStatusText}
      </Typography>

      <Container className={classes.header} disableGutters>
        <Container disableGutters>
          <h3 className={classes.heading}>Automated Categorization Data</h3>
          <Typography className={classes.headerText}>
            Updating data can take several hours.
          </Typography>
          <Typography className={classes.headerText}>
            Please check back later and refresh the page.
          </Typography>
        </Container>
        <Button
          className={classes.updateButton}
          onClick={onClick}
          disabled={updating || !story?.id}
        >
          Update
        </Button>
      </Container>

      {!updating && story?.id && <Categorization storyId={story?.id} />}

      <Snackbar open={!!errorMessage} autoHideDuration={3000}>
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </BizToolsBasePage>
  );
}
