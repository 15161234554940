import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SvgSymbol, {
  SVG_SYMBOLS,
} from '../../../../../common/SvgSymbol/SvgSymbol';

export const LEGACY_OEMBED_CARD_NAME = 'pt-legacy-oembed-card';

export default class LegacyOembedCard extends Component {
  static propTypes = {
    payload: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      content: PropTypes.string,
    }),
  };

  render() {
    return (
      <div>
        <div>
          <h3>Legacy Oembed</h3>
        </div>
        <div>
          <SvgSymbol name={SVG_SYMBOLS.EMBED} />
        </div>
        <p>{this.props.payload.content}</p>
      </div>
    );
  }
}
