import PropTypes from 'prop-types';
import React from 'react';
import MobiledocReactRenderer from '@dailybeast/mobiledoc-react-renderer';
import { Transformation } from 'cloudinary-react';
import CloudinaryImage from '../../../../../common/CloudinaryImage/CloudinaryImage';

export const IMAGE_CARD_NAME = 'pt-image';

const Caption = ({ mobiledoc }) => {
  const options = { version: '0.3.0', atoms: [], cards: [], markups: [] };
  const renderer = new MobiledocReactRenderer(options);

  return renderer.render(mobiledoc);
};

Caption.propTypes = {
  mobiledoc: PropTypes.object.isRequired,
};

const ImageCard = ({ payload }) => {
  const {
    public_id: publicId,
    alt_text: altText,
    credit,
    mobiledoc_caption: mobiledocCaption,
  } = payload;

  return (
    <div
      style={{
        textAlign: 'center',
      }}
      className="Mobiledoc-image"
    >
      <CloudinaryImage publicId={publicId} alt={altText}>
        <Transformation height="200" crop="limit" />
      </CloudinaryImage>
      <p>
        <small>{`Credit: ${credit}`}</small>
      </p>
      {mobiledocCaption && (
        <p>
          <Caption mobiledoc={mobiledocCaption} />
        </p>
      )}
    </div>
  );
};

ImageCard.propTypes = {
  payload: PropTypes.shape({
    alt_text: PropTypes.string,
    public_id: PropTypes.string,
    caption: PropTypes.string,
    createdAt: PropTypes.string,
    credit: PropTypes.string,
    crops: PropTypes.object,
    file_name: PropTypes.string,
    id: PropTypes.number,
    rights: PropTypes.string,
    title: PropTypes.string,
    updated_at: PropTypes.string,
    url: PropTypes.string,
    mobiledoc_caption: PropTypes.object,
  }),
};

export default ImageCard;
