import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useMutation } from '@apollo/client';

const PREPEND_MUTATION = gql`
  mutation prependSlugToArticleSlugs(
    $workingArticleID: ID!
    $initialSlug: String!
    $additionalSlug: String!
  ) {
    prependSlugToArticleSlugs(
      workingArticleID: $workingArticleID
      initialSlug: $initialSlug
      additionalSlug: $additionalSlug
    ) {
      article {
        id
        canonical_slug
      }
    }
  }
`;
const APPEND_MUTATION = gql`
  mutation appendSlugToArticleSlugs(
    $workingArticleID: ID!
    $initialSlug: String!
    $additionalSlug: String!
  ) {
    appendSlugToArticleSlugs(
      workingArticleID: $workingArticleID
      initialSlug: $initialSlug
      additionalSlug: $additionalSlug
    ) {
      article {
        id
        canonical_slug
      }
    }
  }
`;
const useStyles = makeStyles(() => ({ formLabel: { marginBottom: 12 } }));

Step1.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.shape({ label: PropTypes.string.isRequired }),
};

Step1.displayName = 'ArticleSlugRedirectStep1';

export default function Step1({ onChange, value }) {
  const classes = useStyles();
  const [prependSlugToArticleSlugs] = useMutation(PREPEND_MUTATION);
  const [appendSlugToArticleSlugs] = useMutation(APPEND_MUTATION);
  const STEP_1_OPTIONS = [
    {
      label: 'Option 1: Change canonical slug',
      mutation: prependSlugToArticleSlugs,
      option: 1,
    },
    {
      label: 'Option 2: Add slug to slugs array',
      mutation: appendSlugToArticleSlugs,
      option: 2,
    },
  ];

  return (
    <div>
      <h2 className={classes.formLabel}>Step 1:</h2>
      <Autocomplete
        clearOnBlur
        getOptionSelected={(option, selectedVal) =>
          option.label === selectedVal.label
        }
        getOptionLabel={(option) => option.label}
        onChange={onChange}
        options={STEP_1_OPTIONS}
        renderInput={(params) => (
          <TextField {...params} label='Choose Action' variant='outlined' />
        )}
        value={value}
      />
    </div>
  );
}
