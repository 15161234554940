import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import { Transformation } from 'cloudinary-react';
import { DragSource, DropTarget } from 'react-dnd';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import getImageUrl from '../../../../../helpers/images';
import CloudinaryImage from '../../../../../common/CloudinaryImage/CloudinaryImage';
import { colors } from '../../../../../styles/global/variables';
import IMAGE_DISPLAY_TYPE from '../../../../../utils/image-display-type';

/* istanbul ignore next */
const dragSourceSpec = {
  beginDrag(props) {
    return {
      index: props.index,
    };
  },
};

/* istanbul ignore next */
const dropTargetSpec = {
  canDrop(props, monitor) {
    const sourceIndex = monitor.getItem().index;
    const targetIndex = props.index;

    return sourceIndex !== targetIndex;
  },

  hover(props, monitor, component) {
    const fromIndex = monitor.getItem().index;
    const toIndex = props.index;

    if (props.useDragAndDrop && fromIndex !== toIndex) {
      const {
        top: targetTop,
        right: targetRight,
        bottom: targetBottom,
        left: targetLeft,
      } = findDOMNode(component).getBoundingClientRect(); // eslint-disable-line react/no-find-dom-node
      const {
        x: currentPositionX,
        y: currentPositionY,
      } = monitor.getClientOffset();
      const { x: shiftX, y: shiftY } = monitor.getDifferenceFromInitialOffset();
      const movingLeft = shiftX < 0;
      const movingRight = shiftX > 0;
      const movingUp = shiftY < 0;
      const movingDown = shiftY > 0;

      const dropPositionX = movingRight
        ? targetLeft + (targetRight - targetLeft) / 4
        : targetLeft + ((targetRight - targetLeft) / 4) * 3;
      const dropPositionY = movingDown
        ? targetTop + (targetBottom - targetTop) / 4
        : targetTop + ((targetBottom - targetTop) / 4) * 3;

      if (
        (movingRight && currentPositionX >= dropPositionX) ||
        (movingLeft && currentPositionX <= dropPositionX) ||
        (movingUp && currentPositionY <= dropPositionY) ||
        (movingDown && currentPositionY >= dropPositionY)
      ) {
        monitor.getItem().index = toIndex; // eslint-disable-line no-param-reassign
        props.reorderImagesAction(fromIndex, toIndex);
        props.resetImagesSlideNumberAction();

        // here we need to make the source to be transparent
        const droppedImageBox = global.window.document.querySelectorAll(
          '.ImageBox',
        )[fromIndex];
        droppedImageBox.className = `${droppedImageBox.className} ImageBox--dropped`;
      }
    }
  },
};

class ImageBox extends Component {
  static propTypes = {
    index: PropTypes.number,
    image: PropTypes.shape({
      altText: PropTypes.string,
      id: PropTypes.number,
      publicId: PropTypes.string,
      url: PropTypes.string,
      version: PropTypes.string,
      displayType: PropTypes.string,
    }),
    showImageDisplayType: PropTypes.bool,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    onEdit: PropTypes.func.isRequired,
    reorderImagesAction: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    resetImagesSlideNumberAction: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    connectDragSource: PropTypes.func.isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
    useDragAndDrop: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    url: '',
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.getImageName = this.getImageName.bind(this);
  }

  onEdit() {
    this.props.onEdit(this.props.image);
  }

  getImageName() {
    const url = this.props.image.url || '';
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  render() {
    const {
      index,
      image,
      imageWidth = 258,
      imageHeight = 145,
      showImageDisplayType,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      isOver,
      canDrop,
      useDragAndDrop,
    } = this.props;

    let url = '';
    let publicId = '';
    let altText = '';
    let imageName = '';

    if (image && image.publicId) {
      publicId = this.props.image.publicId;
    }
    if (image) {
      url = getImageUrl(image);

      altText = image.altText;
      imageName = this.getImageName();
    }

    let slideNumber = null;
    if (typeof index === 'number') {
      slideNumber = (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '18px',
            height: '20px',
            color: colors.white,
            backgroundColor: colors.darkerGrey,
          }}
        >
          {index + 1}
        </div>
      );
    }

    const className = classnames('ImageBox', {
      'ImageBox--without-data': !url,
      'ImageBox--with-drag-and-drop': useDragAndDrop,
      'ImageBox--is-dragging': isDragging,
      'ImageBox--ready-for-drop': isOver && canDrop,
    });

    const imageDisplayText =
      image && image.displayType === IMAGE_DISPLAY_TYPE.FULL_WIDTH
        ? 'Full Width'
        : 'Standard Width';

    return connectDropTarget(
      connectDragPreview(
        <section className={className}>
          {connectDragSource(
            <div
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                height: '100%',
                textAlign: 'center',
                backgroundColor: '#ddd',
                position: 'relative',
              }}
            >
              <CloudinaryImage publicId={publicId} alt={altText}>
                <Transformation width="258" height="145" crop="limit" />
              </CloudinaryImage>
              {slideNumber}
            </div>,
          )}

          {showImageDisplayType && <div>{imageDisplayText}</div>}

          <a
            href={url}
            src={url}
            alt={altText}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: `${imageWidth}px`, fontSize: '10px' }}
          >
            <div style={{ overflowWrap: 'break-word' }}>{imageName}</div>
          </a>

          <Button
            fullWidth
            color="default"
            label="Edit"
            onClick={this.onEdit}
            className="imgBtn"
          />
        </section>,
      ),
    );
  }
}

export default compose(
  DragSource(
    'image-box',
    dragSourceSpec,
    /* istanbul ignore next */
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  ),
  DropTarget(
    'image-box',
    dropTargetSpec,
    /* istanbul ignore next */
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  ),
)(ImageBox);
