import React, { useState, useContext, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation, useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import { StoryContext } from '../../../../../context/StoryContext';
import updatePartnerContent from '../../mutations/updatePartnerContent.gql';
import partnerContent from '../../queries/partnerContent.gql';
import CampaignAutoComplete from './CampaignAutoComplete';
import PreviewSlug from './PreviewSlug';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      'margin-top': '10px',
      display: 'flex',
    },
    heading: {
      'margin-top': '30px',
      'margin-bottom': '10px',
      'font-weight': '600',
    },
    checkbox: {
      display: 'flex',
    },
    storyTypeStyles: {
      flexDirection: 'row',
    },
  }),
);

export const PARTNER_TYPE = {
  EDITORIAL: 'editorial',
  BRANDED: 'branded',
  SPONSORED: 'sponsored',
};

const partnerContentMutation = gql`
  ${updatePartnerContent}
`;
const partnerContentQuery = gql`
  ${partnerContent}
`;

export default function PartnerContentSyndication() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  const [partnerContentData, setPartnerContentData] = useState({
    sponsored: false,
    branded: false,
    exclude_from_feeds: false,
  });
  const [message, setMessage] = useState(null);
  const [loadPartnerContentData] = useLazyQuery(partnerContentQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage(
        'Error loading story partner content and exclude from feeds data.',
      );
    },
    onCompleted: data => {
      const {
        metadata: { sponsored, branded, exclude_from_feeds: excludeFromFeeds },
      } = data.storyData;
      setPartnerContentData({
        sponsored,
        branded,
        exclude_from_feeds: excludeFromFeeds,
      });
    },
  });

  const [updateMetadata] = useMutation(partnerContentMutation, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage(
        'Error auto-saving story partner content and exclude from feeds data.',
      );
    },
    onCompleted: data => {
      const {
        metadataData: {
          sponsored,
          branded,
          exclude_from_feeds: excludeFromFeeds,
        },
      } = data;
      setPartnerContentData({
        sponsored,
        branded,
        exclude_from_feeds: excludeFromFeeds,
      });
    },
  });

  function renderCampaignsSelect() {
    return <CampaignAutoComplete />;
  }

  function handleExcludeFromFeeds(event, isChecked) {
    updateMetadata({
      variables: {
        id,
        metadataInputData: {
          exclude_from_feeds: isChecked,
        },
      },
    });
  }

  function renderPreviewSlug() {
    return <PreviewSlug />;
  }

  function onPartnerTypeChange(event) {
    let sponsored;
    let branded;
    let excludeFromFeeds;
    if (PARTNER_TYPE.EDITORIAL === event.target.value) {
      sponsored = false;
      branded = false;
      excludeFromFeeds = false;
    } else if (PARTNER_TYPE.BRANDED === event.target.value) {
      sponsored = false;
      branded = true;
      excludeFromFeeds = true;
    } else if (PARTNER_TYPE.SPONSORED === event.target.value) {
      sponsored = true;
      branded = false;
      excludeFromFeeds = false;
    }
    updateMetadata({
      variables: {
        id,
        metadataInputData: {
          sponsored,
          branded,
          exclude_from_feeds: excludeFromFeeds,
        },
      },
    });
  }

  useEffect(() => {
    if (id) {
      loadPartnerContentData({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);

  const {
    sponsored,
    branded,
    exclude_from_feeds: excludeFromFeeds,
  } = partnerContentData;

  let partnerTypeChecked = PARTNER_TYPE.EDITORIAL;
  if (sponsored) {
    partnerTypeChecked = PARTNER_TYPE.SPONSORED;
  } else if (branded) {
    partnerTypeChecked = PARTNER_TYPE.BRANDED;
  }
  let campaignsSelectComponent = null;
  let previewSlugComponent = null;
  if (
    partnerTypeChecked === PARTNER_TYPE.BRANDED ||
    partnerTypeChecked === PARTNER_TYPE.SPONSORED
  ) {
    campaignsSelectComponent = renderCampaignsSelect(
      partnerContentData.campaign_id,
    );
    previewSlugComponent = renderPreviewSlug();
  }

  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel className={classes.heading} component="legend">
          Partner Content
        </FormLabel>
        <RadioGroup
          name="Partner Content"
          onChange={onPartnerTypeChange}
          value={partnerTypeChecked}
          className={classes.storyTypeStyles}
        >
          <FormControlLabel
            value={PARTNER_TYPE.EDITORIAL}
            label={PARTNER_TYPE.EDITORIAL}
            control={<Radio />}
          />
          <FormControlLabel
            control={<Radio />}
            value={PARTNER_TYPE.BRANDED}
            label={PARTNER_TYPE.BRANDED}
          />
          <FormControlLabel
            label={PARTNER_TYPE.SPONSORED}
            value={PARTNER_TYPE.SPONSORED}
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
      {campaignsSelectComponent}
      {previewSlugComponent}

      <h3 className={classes.heading}>Restrict Syndication for this Article</h3>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={excludeFromFeeds}
            onChange={handleExcludeFromFeeds}
          />
        }
        label="Excludes this Article from all Feeds"
      />
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
