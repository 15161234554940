import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import createBrand from './queries/createBrand.gql';
import createPartner from './queries/createPartner.gql';
import getGraphqlErrorMessage from '../../../helpers/graphqlError';
import BizToolsBasePage from '../BizToolsBasePage';


BrandsPartners.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme => ({
  appBar: theme.mixins.toolbar,
  header: {
    marginTop: '38px',
    marginBottom: '48px',
  },
  gridSection: {
    margin: '16px',
    border: 'solid 1px black',
  },
  formLabels: {
    color: 'black',
    marginBottom: '20px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    flexGrow: 1,
    paddingRight: '4px',
  }
}));

export function BrandsPartners({ onEnter, permissions }) {
  const classes = useStyles();

  const [brandName, setBrandName] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);

  function handleSuccess(clearFieldFunction) {
    clearFieldFunction('');
    setAlertType('success');
    setAlertMessage('Success!');
  }

  function handleError(err, fieldName) {
    setAlertType('error');
    if (err.message.includes('duplicate key value')) {
      setAlertMessage(`${fieldName} already exists.`);
    } else {
      setAlertMessage(getGraphqlErrorMessage(err));
    }
  }

  const [runCreateBrandMutation] = useMutation(gql`${createBrand}`, {
    onCompleted: () => {
      handleSuccess(setBrandName);
    },
    onError: (err) => {
      handleError(err, 'Brand');
    },
    variables: {
      brandName
    }
  });

  const [runCreatePartnerMutation] = useMutation(gql`${createPartner}`, {
    onCompleted: () => {
      handleSuccess(setPartnerName);
    },
    onError: (err) => {
      handleError(err, 'Partner name');
    },
    variables: {
      partnerName
    }
  });

  return (
    <BizToolsBasePage title="Brands and Partners" onEnter={onEnter} permissions={permissions}>
      <h3 className={classes.header}>Brands and Partners</h3>
      <Grid spacing={10} container>
        <Grid className={classes.gridSection} item xs={12} md={8}>
          <FormControl fullWidth={true}>
            <FormLabel className={classes.formLabels}>
              Branded Content
            </FormLabel>
            <div className={classes.wrapper} >
              <TextField
                className={classes.textField}
                label="Add Brand Name"
                placeholder="Brand Name"
                onChange={(event) => setBrandName(event.target.value)}
                value={brandName}
              />
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={runCreateBrandMutation}
              >
                +
              </Button>
            </div>
          </FormControl>
        </Grid>
        <Grid className={classes.gridSection} item xs={12} md={8}>
          <FormControl fullWidth={true}>
            <FormLabel className={classes.formLabels}>
              Conversion Cards
            </FormLabel>
            <div className={classes.wrapper} >
              <TextField
                className={classes.textField}
                label="Add Partner Name"
                placeholder="Partner Name"
                onChange={(event) => setPartnerName(event.target.value)}
                value={partnerName}
              />
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={runCreatePartnerMutation}
              >
                +
              </Button>
            </div>
          </FormControl>
        </Grid>
        <Snackbar open={!!alertMessage} autoHideDuration={3000}>
          <Alert severity={alertType} onClose={() => setAlertMessage(null)}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </BizToolsBasePage>
  );
};
