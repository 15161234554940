import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Navbar from '../../common/Navbar/Navbar';
import AutoCompleteField from '../../common/AutoCompleteField/AutoCompleteField';
import ConfirmDialog from '../../common/ConfirmDialog/ConfirmDialog';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import {
  dismissMessageAction,
  successMessageAction,
} from '../../redux/actions/messages';
import { updateKeywordAction } from '../../redux/actions/keywords';
import {
  openConfirmDialogAction,
  closeConfirmDialogAction,
} from '../../redux/actions/ui';
import { saveKeyword } from '../../redux/dispatchers';

/* istanbul ignore next */
function mapStateToProps({ messages, ui }) {
  return {
    confirmDialogOpen: ui.confirmDialogOpen,
    message: messages.message,
    autoCompleteSearchText: ui.autoCompleteSearchText,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        saveKeyword,
        updateKeywordAction,
        successMessageAction,
        dismissMessageAction,
        openConfirmDialogAction,
        closeConfirmDialogAction,
      },
      dispatch,
    ),
  };
}
const styles = theme => ({
  appBar: theme.mixins.toolbar,
});

export const LABEL = {
  TITLE: 'Keywords',
  AUTOCOMPLETE_FIELD: 'Add Keywords',
  BUTTON_SUBMIT: 'Add Keyword',
  CONFIRM_DIALOG: "Are you sure you've spelled that keyword correctly?",
};

export const PLACEHOLDER = 'e.g. Tiny Trump';

class Keywords extends Component {
  static propTypes = {
    actions: PropTypes.object,
    classes: PropTypes.object,
    history: PropTypes.object,
    onEnter: PropTypes.func,
    message: PropTypes.string,
    confirmDialogOpen: PropTypes.bool,
    autoCompleteSearchText: PropTypes.string,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.handleExistingKeyword = this.handleExistingKeyword.bind(this);
    this.handleNavigateHome = this.handleNavigateHome.bind(this);
    this.handleOnConfirm = this.handleOnConfirm.bind(this);
    this.handleNavigateHome = this.handleNavigateHome.bind(this);
    this.onAddKeyword = this.onAddKeyword.bind(this);
    this.updateKeywordInStore = this.updateKeywordInStore.bind(this);
  }

  componentDidMount() {
    const { onEnter, history, permissions } = this.props;
    onEnter(history, permissions);
  }

  onAddKeyword() {
    this.props.actions.openConfirmDialogAction();
  }

  handleExistingKeyword() {
    this.props.actions.successMessageAction('Successfully saved keyword.');
  }

  handleOnConfirm() {
    this.props.actions.saveKeyword();
    this.props.actions.closeConfirmDialogAction();
  }

  handleNavigateHome() {
    this.props.history.push('/');
  }

  updateKeywordInStore(keyInStore, value) {
    this.props.actions.updateKeywordAction({ name: value });
  }

  render() {
    return (
      <div>
        <div className={this.props.classes.appBar} />
        <nav>
          <Navbar
            title={LABEL.TITLE}
            handleNavigateHome={this.handleNavigateHome}
          />
        </nav>
        <main>
          <AutoCompleteField
            label={LABEL.AUTOCOMPLETE_FIELD}
            placeholder={PLACEHOLDER}
            dataUrl={`${process.env.API_HOST}/v1/tag`}
            dataQueryParams={{ per_page: 10 }}
            dataValueName="name"
            updateFieldAction={this.handleExistingKeyword}
            createAction={this.onAddKeyword}
            updateStoreOnInputAction={this.updateKeywordInStore}
            searchText={this.props.autoCompleteSearchText}
          />
          <Button label={LABEL.BUTTON_SUBMIT} onClick={this.onAddKeyword} />
        </main>
        <Snackbar
          open={!!this.props.message}
          message={this.props.message}
          onRequestClose={this.props.actions.dismissMessageAction}
        />
        <ConfirmDialog
          open={this.props.confirmDialogOpen}
          onConfirm={this.handleOnConfirm}
          onClose={this.props.actions.closeConfirmDialogAction}
          title={LABEL.CONFIRM_DIALOG}
        />
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withRouter,
)(Keywords);
