query LatestScoutedQuery {
  vertical(slug: "scouted") {
    stories(
      types: CHEAT,
      first: 3,
      shouldExcludeFromRecirculation: true,
      excludeCrossPromotedStories: true,
      orderBy: { field: BUMP_DATE, direction: DESC },
    ) {
      edges {
        node {
          id,
          longHeadline: long_headline
          publicationDate: publication_date
        }
      }
    }
  }
}