mutation UpdateAuthorById($id: ID!, $authorInputData: AuthorInput!){ 
  authorData: updateAuthor(id: $id, author: $authorInputData) {
    id
    name
    email
    type
    slug
    bio
    mobiledocBio: mobiledoc_bio
    twitter
    facebook
    image
    jobTitle: job_title
    rights
    roleId: role_id
  }
}