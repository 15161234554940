mutation VerticalMutation($versionId: ID!, $verticalId: ID!) {
  story: addStoryVertical(versionId: $versionId, verticalId: $verticalId) {
    vertical {
      id
      name
      slug
      subverticals {
        id
        name
        slug
      }
    }
    subvertical {
      id
      name
      slug
    }
  }
}
