import React from 'react';

import PageControlsSections from './PageControlsSections';
import PageControlsMain from './PageControlsMain';

export default function PageControls() {
  return (
    <div className="PageControls">
      <PageControlsMain />
      <PageControlsSections />
    </div>
  );
}
