query storyForMediaQuery($versionId: String!, $imageIds: [ID]) {
  story: storyById(versionId: $versionId) {
    slug,
    type,
    metadata {
      subtype
    }
  }
  images(ids: $imageIds) {
    id
    altText: alt_text
    publicId: public_id
    url
    version
    displayType: display_type
  }
}
