query StorySuggestions(
  $query: String
) {
  suggestions: scoutedCheatSuggestions(query: $query, first: 5) {
    edges {
      node {
        ... on Cheat {
          ...StoryContent
        }
      }
    }
  }
}

fragment StoryContent on Story {
  id
  longHeadline: long_headline
  slug
}