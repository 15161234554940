import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LABEL, PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import socialHeadlineQueryGQL from './graphql/socialHeadlineQuery.gql';
import socialHeadlineMutationGQL from './graphql/socialHeadlineMutation.gql';

const socialHeadlineQuery = gql`
  ${socialHeadlineQueryGQL}
`;

const socialHeadlineMutation = gql`
  ${socialHeadlineMutationGQL}
`;

function SocialHeadline() {
  const storyContext = useContext(StoryContext);
  const [socialHeadline, setSocialHeadline] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loadSocialHeadline] = useLazyQuery(socialHeadlineQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const socialHeadlineData =
        data && data.story && data.story.socialHeadline;
      if (socialHeadlineData) setSocialHeadline(data.story.socialHeadline);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateSocialHeadline] = useMutation(socialHeadlineMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadSocialHeadline({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) => {
    const value = e && e.target && e.target.value;
    setSocialHeadline(value);
    updateSocialHeadline({
      variables: {
        id: storyContext.id,
        story: {
          social_headline: value,
        },
      },
    });
  };

  let helperMessage = `${socialHeadline.length}/110`;
  let hasError = false;

  if (errorMessage) {
    hasError = true;
    helperMessage = `${socialHeadline.length}/110 ${errorMessage}`;
  }

  return (
    <Grid item xs={12} md={8}>
      <TextField
        label={LABEL.SOCIAL_HEADLINE}
        placeholder={PLACEHOLDER.SOCIAL_HEADLINE}
        fullWidth
        onChange={onChange}
        value={socialHeadline}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={onChange}
        error={hasError}
        helperText={helperMessage}
      />
    </Grid>
  );
}

export default SocialHeadline;
