import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import capitalize from 'lodash/capitalize';
import ToolbarGroup from '@material-ui/core/Toolbar';

import { HomepageContext, SET_HOMEPAGE, SET_SNACKBAR } from './HomepageContext';
import { SNACKBAR_TYPE } from './HomepageSnackbar';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import Navbar from '../../common/Navbar/Navbar';
import RedDotActiveUsers from '../../common/RedDotActiveUsers/RedDotActiveUsers';
import activatePageMutation from '../../queries/homepage/activatePage.gql';
import createPageDraftMutation from '../../queries/homepage/createPageDraft.gql';

const ACTIVATE_PAGE_MUTATION = gql`
  ${activatePageMutation}
`;
const CREATE_PAGE_DRAFT_MUTATION = gql`
  ${createPageDraftMutation}
`;

function getError(state) {
  const { id, snackbar, ...sections } = state;

  // Check for story errors
  let storyError;
  Object.keys(sections).some(section =>
    sections[section].stories.some((story, i) => {
      if (!story.error) return false;

      storyError = `${capitalize(sections[section].title)} Slot ${i + 1}: ${
        story.error
      }`;
      return true;
    }),
  );
  if (storyError) return storyError;

  // Check for duplicates
  const allStoryIds = Object.keys(sections).reduce(
    (accumulator, sectionName) => {
      const ids = sections[sectionName].stories.map(story => story.id);
      return [...accumulator, ...ids];
    },
    [],
  );
  if (new Set(allStoryIds).size < allStoryIds.length) {
    // Find dupes and report.
    const seen = allStoryIds.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    console.log(allStoryIds, sections, new Set(allStoryIds).size, allStoryIds.length, seen);
    return 'You are attempting to publish duplicate stories.';
  }

  // Check for product callout errors
  if (
    state.section3.productCallout.type === 'newsletter' &&
    state.section3.productCallout.error
  ) {
    return state.section3.productCallout.error;
  }
  if (
    state.section5.productCallout.type === 'newsletter' &&
    state.section5.productCallout.error
  ) {
    return state.section5.productCallout.error;
  }

  return false;
}

export default function HomepageNav() {
  const { dispatch, state } = useContext(HomepageContext);
  const history = useHistory();
  const [disablePublish, setDisablePublish] = useState(!!state.id);
  const [activatePage] = useMutation(ACTIVATE_PAGE_MUTATION, {
    variables: { id: state.id },
  });
  const [createPageDraft] = useMutation(CREATE_PAGE_DRAFT_MUTATION, {
    fetchPolicy: 'no-cache',
    variables: { pageName: 'obsessed-landing' },
  });

  const handleNavigateHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const handlePublishClick = useCallback(() => {
    setDisablePublish(true);
    dispatch({
      type: SET_SNACKBAR,
      payload: {
        type: SNACKBAR_TYPE.INFO,
        message: 'Publishing homepage...',
      },
    });

    const error = getError(state);

    if (error) {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          type: SNACKBAR_TYPE.ERROR,
          message: error,
        },
      });
      setDisablePublish(false);
      return;
    }

    activatePage()
      .then(() => {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            type: SNACKBAR_TYPE.INFO,
            message: 'Creating a new page draft...',
          },
        });
        return createPageDraft();
      })
      .then(({ data }) => {
        setDisablePublish(false);
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            type: SNACKBAR_TYPE.SUCCESS,
            message: 'Successfully published homepage!',
          },
        });
        dispatch({
          type: SET_HOMEPAGE,
          payload: data.createDraft,
        });
      })
      .catch(err => {
        dispatch({
          type: SET_SNACKBAR,
          payload: {
            type: SNACKBAR_TYPE.ERROR,
            message: `${err}`,
          },
        });
      });
  }, [activatePage, createPageDraft, state]);

  return (
    <nav>
      <Navbar title="Homepage" handleNavigateHome={handleNavigateHome}>
        <ToolbarGroup>
          <RedDotActiveUsers />
          <Button
            disabled={disablePublish}
            label="Publish"
            onClick={handlePublishClick}
          />
        </ToolbarGroup>
      </Navbar>
    </nav>
  );
}
