import React from 'react';
import ReactDOM from 'react-dom';

/*
  Available props to a mobiledoc cards:
  static propTypes = {
    cancel: PropTypes.func,
    edit: PropTypes.func.isRequired,
    isInEditor: PropTypes.bool.isRequired,
    payload: PropTypes.object.isRequired,
    postModel: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
  }
*/

const cardRenderer = (component, actions) => ({ env, payload }) => {
  const targetNode = document.createElement('div');
  const { didRender, onTeardown } = env;
  const props = {
    ...env,
    payload,
    actions,
  };

  didRender(() => {
    const element = React.createElement(component, props);
    ReactDOM.render(element, targetNode);
  });

  onTeardown(() => ReactDOM.unmountComponentAtNode(targetNode));

  return targetNode;
};

export default function(component, name, actions) {
  return {
    name,
    type: 'dom',
    render: cardRenderer(component, actions),
    edit: cardRenderer(component, actions),
  };
}
