import bootstrap from './bootstrap';
import { createServiceFactory } from '@elastic/apm-rum-core';
import ApmBase from './apm-base';
var enabled = bootstrap();
var serviceFactory = createServiceFactory();
var apmBase = new ApmBase(serviceFactory, !enabled);

if (typeof window !== 'undefined') {
  window.elasticApm = apmBase;
}

var init = apmBase.init.bind(apmBase);
export default init;
export { init, apmBase, ApmBase, apmBase as apm };