import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export const SAVE_STATE = {
  ERROR: 'ERROR',
  SAVING: 'SAVING',
  SUCCESS: 'SUCCESS',
};

SavingSnackbar.propTypes = {
  saveState: PropTypes.oneOf(Object.keys(SAVE_STATE)),
};

export default function SavingSnackbar({ saveState }) {
  const [snackbarState, setSnackbarState] = useState({
    message: '',
    open: false,
    severity: 'info',
  });

  const handleSnackbarClose = useCallback(() => {
    setSnackbarState({ message: '', open: false, severity: 'info' });
  }, []);

  useEffect(() => {
    switch (saveState) {
      case SAVE_STATE.SAVING:
        setSnackbarState({
          message: 'Saving...',
          open: true,
          severity: 'info',
        });
        break;
      case SAVE_STATE.SUCCESS:
        setSnackbarState({
          message: 'Successfully saved',
          open: true,
          severity: 'success',
        });
        break;
      case SAVE_STATE.ERROR:
        setSnackbarState({
          message: 'Something went wrong',
          open: true,
          severity: 'error',
        });
        break;
      default:
        setSnackbarState({ message: '', open: false });
    }
  }, [saveState]);

  return (
    <Snackbar
      open={snackbarState.open}
      onClose={handleSnackbarClose}
      // onExited={handleSnackbarExited}
    >
      <Alert
        elevation={6}
        onClose={handleSnackbarClose}
        severity={snackbarState.severity}
        variant="filled"
      >
        {snackbarState.message}
      </Alert>
    </Snackbar>
  );
}
