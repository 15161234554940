import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import HomepageCheats from '../../queries/homepage/fetchHomepageCheats.gql';
import AnalyticsDataItem from './AnalyticsDataItem';

const N_CHEAT_SLOTS = 7;

AnalyticsCheatsheet.propTypes = {
  analyticsData: PropTypes.array,
  className: PropTypes.string,
};

export default function AnalyticsCheatsheet({ analyticsData, className }) {
  useEffect(() => {
    loadCheatsQuery();
  }, []);
  const [cheatData, setCheatData] = useState(
    [...Array(N_CHEAT_SLOTS).keys()].map(i => ({
      name: `Cheat${i + 1}`,
      canonicalName: `Cheat${i + 1}`,
      longHeadline: 'Headline...',
      authors: ['Authors...'],
      vertical: 'Vertical...',
    })),
  );
  const [analyticsState, setAnalyticsState] = useState({
    data: [...Array(N_CHEAT_SLOTS).keys()].map(i => ({
      realTimePercentOfTotalClicks: 0,
      thirtyDayPercentOfTotalClicks: 0,
      realTimeTotalClicks: 0,
      name: `Cheat${i + 1}`,
    })),
    isSet: false,
  });
  const [, setError] = useState(null);

  if (analyticsData && analyticsData.length > 0 && !analyticsState.isSet) {
    setAnalyticsState({
      data: analyticsData.map(analyticsItem => ({
        realTimePercentOfTotalClicks:
          analyticsItem.realTimePercentOfTotalClicks,
        thirtyDayPercentOfTotalClicks:
          analyticsItem.thirtyDayPercentOfTotalClicks,
        realTimeTotalClicks: analyticsItem.realTimeTotalClicks,
        name: analyticsItem.name,
      })),
      isSet: true,
    });
  }

  const cheatsQuery = gql`
    ${HomepageCheats}
  `;

  const [loadCheatsQuery] = useLazyQuery(cheatsQuery, {
    onError: err => {
      setError(err);
    },
    onCompleted: data => {
      const bizCheatIndexes = [2, 5];

      if (data.cheatsheet) {
        bizCheatIndexes.forEach((bizCheatIndex, i) => {
          const placeholderCheat = {
            displayedName: `Biz Cheat ${i + 1}`,
            isBizCheat: true,
            authors: [{ name: 'n/a' }],
            longHeadline: `Biz Cheat ${i + 1}`,
            vertical: { name: 'n/a' },
          };

          data.cheatsheet.splice(bizCheatIndex, 0, placeholderCheat);
        });

        const cheatsheet = data?.cheatsheet?.slice(0, N_CHEAT_SLOTS);
        const newCheatList = [];
        let cheatOffset = 0;

        cheatsheet.forEach((cheat, i) => {
          if (!cheat.isBizCheat) {
            cheatOffset += 1;
          }

          newCheatList.push({
            canonicalName: `Cheat${i + 1}`,
            name: cheat.isBizCheat
              ? cheat.displayedName
              : `Cheat${cheatOffset}`,
            longHeadline: cheat.longHeadline,
            author: cheat.authors[0]?.name,
            vertical: cheat.vertical?.name,
          });
        });
        setCheatData(newCheatList);
      }
    },
  });

  const mergedCheatData = cheatData.map(cheat => {
    const cheatAnalytics = analyticsState.data.find(
      stats => stats.name === cheat.canonicalName,
    );
    return { ...cheatAnalytics, ...cheat };
  });

  return (
    <section className={className}>
      <Typography align="center" style={{ textTransform: 'capitalize' }}>
        Cheatsheet
      </Typography>
      {mergedCheatData.map(cheat => (
        <Card key={cheat.id} style={{ display: 'block', marginTop: '.8rem' }}>
          <AnalyticsDataItem analyticsData={cheat} storyData={cheat} />
        </Card>
      ))}
    </section>
  );
}
