import React, { useCallback, useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import ToolbarGroup from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useHandleNavigateHome } from '../../hooks/useHandleNavigateHome';
import { useScreamerState } from './customHooks/useScreamerState';
import {
  filterOptions,
  getOptionLabel,
  getOptionSelected,
  renderOption,
  useAutocomplete,
} from './customHooks/useAutocomplete';
import { useArticleSelector } from './customHooks/useArticleSelector';
import { useSaveScreamer } from './customHooks/useSaveScreamer';

import Button from '../../common/ButtonWrapper/ButtonWrapper';
import Navbar from '../../common/Navbar/Navbar';
import SavingSnackbar, {
  SAVE_STATE,
} from '../../common/SavingSnackbar/SavingSnackbar';

Screamer.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme => {
  return {
    container: { marginTop: theme.spacing(3) },
    main: {
      marginTop: theme.spacing(8),
      padding: `0 ${theme.spacing(2)}px`,
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(9),
      },
    },
    textField: { marginTop: theme.spacing(2) },
    title: { ...theme.typography.body1 },
  };
});

export default function Screamer({ onEnter, permissions }) {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);
  const handleNavigateHome = useHandleNavigateHome();
  const [saveState, setSaveState] = useState(null);
  const [
    { screamer, loadingScreamerState, saveAllowed },
    setScreamer,
    setSaveAllowed,
  ] = useScreamerState();
  const {
    autocompleteOptions,
    autocompleteValue,
    getOptions,
    loadingAutocomplete,
    setAutocompleteOptions,
    setAutocompleteValue,
  } = useAutocomplete(screamer);
  const selectArticle = useArticleSelector(
    setScreamer,
    setAutocompleteValue,
    setAutocompleteOptions,
  );
  const handleBannerActiveChange = useCallback(
    event => {
      setScreamer({
        active: event.target.checked,
      });
    },
    [setScreamer],
  );
  const handleBannerHeadlineChange = useCallback(
    event => {
      setScreamer({
        bannerHeadline: event.target.value,
      });
    },
    [setScreamer],
  );

  const {
    create: [createScreamer],
    update: [updateScreamer],
  } = useSaveScreamer();
  const handleSaveClick = useCallback(() => {
    const mutation = screamer.id ? updateScreamer : createScreamer;
    setSaveState(SAVE_STATE.SAVING);
    mutation({
      variables: {
        id: screamer.id,
        screamer: {
          active: screamer.active,
          articleId: screamer.articleId,
          bannerHeadline: screamer.bannerHeadline,
        },
      },
    })
      .then(({ data }) => {
        const newScreamer = data.updateScreamer || data.createScreamer;
        setScreamer(newScreamer);
        setSaveState(SAVE_STATE.SUCCESS);
        setSaveAllowed(false);
      })
      .catch(error => {
        global.apm.captureError(error);
        setSaveState(SAVE_STATE.ERROR);
        setSaveAllowed(false);
      });
  }, [screamer]);

  return (
    <>
      <nav>
        <Navbar title="Screamer" handleNavigateHome={handleNavigateHome}>
          <ToolbarGroup>
            <Button
              disabled={!saveAllowed}
              label="Save"
              onClick={handleSaveClick}
            />
          </ToolbarGroup>
        </Navbar>
      </nav>
      <main className={classes.main}>
        <Typography align="left" className={classes.title} variant="h1">
          Screamer 2.0
        </Typography>
        <Container className={classes.container} maxWidth="sm">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Off</Grid>
            <Grid item>
              <Switch
                checked={!!screamer?.active}
                disableRipple
                disabled={loadingScreamerState}
                onChange={handleBannerActiveChange}
              />
            </Grid>
            <Grid item>On</Grid>
          </Grid>
          <TextField
            className={classes.textField}
            disabled={loadingScreamerState}
            error={screamer?.bannerHeadline.length > 60}
            fullWidth
            label="Banner Headline"
            helperText={`${screamer?.bannerHeadline.length || 0}/60${
              screamer?.bannerHeadline.length > 60
                ? ' Whoa!! Slow down. Too many characters.'
                : ''
            }`}
            onChange={handleBannerHeadlineChange}
            value={screamer?.bannerHeadline}
          />
          <Autocomplete
            className={classes.textField}
            value={autocompleteValue}
            disabled={loadingScreamerState}
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            onChange={selectArticle}
            onInputChange={getOptions}
            options={autocompleteOptions}
            renderOption={renderOption}
            renderInput={params => (
              <TextField
                {...params}
                label="Story"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingAutocomplete ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        params.InputProps.endAdornment
                      )}
                    </>
                  ),
                }}
                required
              />
            )}
          />
        </Container>
        <SavingSnackbar saveState={saveState} />
      </main>
    </>
  );
}
