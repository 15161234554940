import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { Image } from 'cloudinary-react';
import { CLOUDINARY_CONFIG } from '../../helpers/images';

export default class CloudinaryImage extends Component {
  static propTypes = {
    className: PropTypes.string,
    publicId: PropTypes.string,
    alt: PropTypes.string,
    children: PropTypes.node,
  };

  shouldComponentUpdate(nextProps) {
    const publicIdChanged = this.props.publicId !== nextProps.publicId;
    if (publicIdChanged) {
      return true;
    }
    if (!this.props.children && !nextProps.children) {
      return false;
    }
    const childProps = React.Children.map(this.props.children, c => c.props);
    const nextChildProps = React.Children.map(nextProps.children, c => c.props);
    const childPropsAreEqual = isEqual(childProps, nextChildProps);
    return !childPropsAreEqual;
  }

  render() {
    return (
      <Image
        style={{
          paddingTop: '4px',
        }}
        privateCdn={CLOUDINARY_CONFIG.private_cdn}
        secureDistribution={CLOUDINARY_CONFIG.secure_distribution}
        cname={CLOUDINARY_CONFIG.cname}
        cloudName={CLOUDINARY_CONFIG.cloud_name}
        className={this.props.className}
        publicId={this.props.publicId}
        alt={this.props.alt}
      >
        {this.props.children}
      </Image>
    );
  }
}
