import { SITE, Site as SiteInfo } from './site';

export const redirectObsessedStory = (id) => {
  if (!id) return false;

  if (id !== SITE.OBSESSED) return false;

  const { pathname } = window.location;
  const pathSegments = pathname.split('/');
  const firstPathSegment = pathSegments[1];

  if (firstPathSegment !== SiteInfo.obsessed.slugPrefix) return true;

  return false;
};
