import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Snackbar } from '@material-ui/core';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { colors } from '../../../styles/global/variables';
import Button from '../../../common/ButtonWrapper/ButtonWrapper';
import { StoryContext } from '../../../context/StoryContext';
import {
  Context as EditorContext,
  MOBILEDOC_VERSION,
} from '../../../context/EditorContext';
import saveStoryGQL from './mutations/saveStory.gql';
import { errorMessageAction } from '../../../redux/actions/messages';
import getGraphqlErrorMessage from '../../../helpers/graphqlError';
import { Context as ValidationContext } from '../../../context/ValidationContext';
import checkPublishedVersionGQL from './queries/checkPublishedVersion.gql';

ArticleNavbarButtonGroup.propTypes = {
  draftOrPublishLoading: PropTypes.bool,
  onManualBump: PropTypes.func,
  onSidebarToggle: PropTypes.func,
  openPublishModal: PropTypes.func,
  slug: PropTypes.string,
  unlockPending: PropTypes.bool,
};

const useStyles = makeStyles({
  preview: {
    '&:hover': {
      backgroundColor: colors.darkerGrey,
    },
    backgroundColor: colors.darkGrey,
    margin: '5px',
  },
  republish: {
    '& span': {
      'margin-right': '0px',
    },
    '&:hover': {
      backgroundColor: colors.greenDown,
    },
    backgroundColor: colors.green,
    color: colors.white,
  },
  save: {
    'margin-left': '5px',
  },
  sidebarToggle: {
    padding: '8px',
  },
  wrapper: {
    padding: '0px',
  },
});

const saveStoryMutation = gql`
  ${saveStoryGQL}
`;

const checkPublishedVersionQuery = gql`
  ${checkPublishedVersionGQL}
`;

export default function ArticleNavbarButtonGroup(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const storyContext = useContext(StoryContext);
  const [message, setMessage] = useState(null);
  const history = useHistory();
  const urlPath = history.location.pathname;
  const isBodyRoute = urlPath.match(`/body`);
  const { updateValidationContext } = useContext(ValidationContext);
  const { hasPublishedVersion, unlockPending } = storyContext;
  const {
    draftOrPublishLoading,
    openPublishModal,
    onManualBump,
    onSidebarToggle,
  } = props;
  const { editor, mobiledocLoading } = useContext(EditorContext);
  const [saveStory, { loading: saveLoading }] = useMutation(saveStoryMutation, {
    onCompleted: (data) => {
      if (data && data.saveStory && data.saveStory.story) {
        const { id, slug, updatedAt, seoHeadline, scheduled } =
          data.saveStory.story;
        let newUrlPath;
        if (urlPath.match(`/new/`)) {
          newUrlPath = urlPath.replace('/new/', `/${slug}/`);
        } else if (urlPath.match(`/${storyContext.slug}/`)) {
          newUrlPath = urlPath.replace(`/${storyContext.slug}/`, `/${slug}/`);
        } else {
          newUrlPath = `/${slug}/main-info`;
        }

        setMessage('Story Saved!');
        storyContext.updateId(id);
        storyContext.updateSlug(slug);
        storyContext.updateUpdatedAt(updatedAt);
        storyContext.updateSeoHeadline(seoHeadline);
        storyContext.updateScheduled(scheduled);

        history.push(newUrlPath);
      } else if (data.saveStory.Validation.Errors.length) {
        updateValidationContext(data.saveStory.Validation.Errors);
        setMessage(data.saveStory.Validation.Errors[0].message);
      } else {
        setMessage('Failed to save for an unknown reason');
      }
    },
    onError: (err) => {
      errorMessageAction(err);
      setMessage(getGraphqlErrorMessage(err));
    },
  });

  const shouldSchedule = moment(storyContext.publicationDate).isAfter(moment());

  const [checkPublishedVersion] = useLazyQuery(checkPublishedVersionQuery, {
    onCompleted: (data) => {
      if (!data.checkPublishedVersion) return;

      const {
        checkPublishedVersion: { hasPublishedVersion: publishedVersion },
      } = data;
      storyContext.updateHasPublishedVersion(publishedVersion);
    },
    onError: () => {
      setMessage('Error checking published versions');
    },
  });

  const handleManualBumpClick = () => {
    openPublishModal();
    onManualBump();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  let publishBtnText;

  if (hasPublishedVersion) {
    publishBtnText = 'Republish';
  } else if (!hasPublishedVersion && unlockPending && shouldSchedule) {
    publishBtnText = 'Schedule';
  } else if (!hasPublishedVersion && shouldSchedule) {
    publishBtnText = 'Schedule';
  } else if (!hasPublishedVersion && unlockPending) {
    publishBtnText = 'Publish';
  } else {
    publishBtnText = 'Publish';
  }
  const { id } = storyContext;

  useEffect(() => {
    if (storyContext.slug) {
      checkPublishedVersion({
        variables: {
          slug: storyContext.slug,
        },
      });
    }
  }, [storyContext.slug]);

  return (
    <>
      <Button
        className={classes.save}
        size='small'
        color='primary'
        label='Save'
        onClick={() => {
          let serializedDoc = null;
          if (isBodyRoute) {
            serializedDoc = editor && editor.serialize(MOBILEDOC_VERSION);
          }
          return saveStory({
            variables: { id, story: { mobiledoc: serializedDoc } },
          });
        }}
        disabled={draftOrPublishLoading || saveLoading || mobiledocLoading}
      />
      <Button
        size='small'
        className={classes.preview}
        label='Preview'
        href={`${process.env.WWW_DOMAIN}/${storyContext.slug}?preview=true`}
        target='_blank'
        disabled={draftOrPublishLoading || saveLoading || mobiledocLoading}
      />
      <ButtonGroup
        className={classes.wrapper}
        ref={anchorRef}
        size='small'
        aria-label='publish tools'
      >
        <Button
          className={classes.republish}
          label={publishBtnText}
          onClick={openPublishModal}
          disabled={draftOrPublishLoading || saveLoading || mobiledocLoading}
        />
        <Button
          className={classes.republish}
          startIcon={<ArrowDropDownIcon />}
          aria-controls={open && 'split-button-menu'}
          aria-expanded={open && 'true'}
          aria-label='republish and bump'
          aria-haspopup='menu'
          onClick={handleToggle}
          disabled={
            draftOrPublishLoading || saveLoading || !hasPublishedVersion
          }
        />
      </ButtonGroup>
      <IconButton className={classes.sidebarToggle} onClick={onSidebarToggle}>
        <MoreVertIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  <MenuItem onClick={(event) => handleManualBumpClick(event)}>
                    Republish and Bump
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
      />
    </>
  );
}
