import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';

export const KEY = {
  ESCAPE: 27,
};

export default class DeleteDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    text: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultPropTypes = {
    open: false,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    global.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    global.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(event) {
    if (this.props.open && !event.defaultPrevented) {
      if (event.keyCode === KEY.ESCAPE && this.props.onClose) {
        event.preventDefault();
        this.props.onClose();
      }
    }
  }

  render() {
    const { open, text = 'Are you sure?', onClose, onDelete } = this.props;
    return (
      <Dialog open={open} title={text} modal={false} onRequestClose={onClose}>
        <DialogTitle>{this.props.text}</DialogTitle>
        <DialogActions>
          <Button label="Nevermind" onClick={onClose} />
          <Button label="I'm sure" onClick={onDelete} />
        </DialogActions>
      </Dialog>
    );
  }
}
