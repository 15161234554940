import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Transformation } from 'cloudinary-react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import blue from '@material-ui/core/colors/blue';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useLazyQuery } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { MediaContext } from '../../../../../context/MediaContext/MediaContext';
import { StoryContext } from '../../../../../context/StoryContext';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import imagesQueryGQL from './graphql/imagesQuery.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';
import IMAGE_DISPLAY_TYPE from '../../../../../utils/image-display-type';
import CloudinaryImage from '../../../../../common/CloudinaryImage/CloudinaryImage';
import Modal from '../../../../../common/Modal/Modal';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { IMAGE_CARD_NAME } from '../ImageCard/ImageCard';
import ARTICLE_SUBTYPE from '../../../../../utils/article-subtype';

ImagePicker.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() =>
  createStyles({
    images: {
      cursor: 'pointer',
      width: '148px',
      height: '81px',
      textAlign: 'center',
      margin: '1px 7.5px 15px',
    },
    selectedImage: {
      outline: `3px ${blue[900]} solid`,
      background: blue[900],
    },
  }),
);

export const COLUMN_COUNT = 3;

const imagesQuery = gql`
  ${imagesQueryGQL}
`;

function ImagePicker(props) {
  const classes = useStyles();
  const { onClose } = props;

  const { imageIds } = useContext(MediaContext);
  const { subtype } = useContext(StoryContext);
  const { editor } = useContext(EditorContext);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [loadImages] = useLazyQuery(imagesQuery, {
    onError: err => {
      errorMessageAction(err);
    },
    onCompleted: data => {
      const imagesData = data && data.images;
      if (imagesData) setImages(imagesData);
    },
  });

  useEffect(() => {
    loadImages({
      variables: {
        imageIds,
      },
    });
  }, [imageIds]);

  const selectLeft = () => {
    const currentSelectedImageIndex = images.findIndex(
      image => image.id === selectedImage.id,
    );
    const nextImage = images[currentSelectedImageIndex - 1];

    if (nextImage) setSelectedImage(nextImage);
  };

  const selectRight = () => {
    const currentSelectedImageIndex = images.findIndex(
      image => image.id === selectedImage.id,
    );
    const nextImage = images[currentSelectedImageIndex + 1];

    if (nextImage) setSelectedImage(nextImage);
  };

  const selectDown = () => {
    const currentSelectedImageIndex = images.findIndex(
      image => image.id === selectedImage.id,
    );
    const nextImage = images[currentSelectedImageIndex + COLUMN_COUNT];

    if (nextImage) setSelectedImage(nextImage);
  };

  const selectUp = () => {
    const currentSelectedImageIndex = images.findIndex(
      image => image.id === selectedImage.id,
    );
    const nextImage = images[currentSelectedImageIndex - COLUMN_COUNT];

    if (nextImage) setSelectedImage(nextImage);
  };

  const onPlace = () => {
    if (!selectedImage) return;
    const {
      id,
      url,
      publicId,
      altText,
      title,
      credit,
      owner,
      rights,
      crops,
      file_name,
      version,
      imgDataFormat,
      mobiledocCaption,
      displayType,
    } = selectedImage;
    const mobiledocImage = {
      id,
      url,
      public_id: publicId,
      alt_text: altText,
      title,
      credit,
      owner,
      rights,
      crops,
      file_name,
      version,
      img_data_format: imgDataFormat,
      mobiledoc_caption: mobiledocCaption,
      display_type: displayType,
    };

    editor.insertCard(IMAGE_CARD_NAME, mobiledocImage);
    onClose();
  };

  const renderImages = () => {
    return images.map(image => {
      const { id, publicId, altText, displayType } = image;

      const imageDisplayText =
        displayType === IMAGE_DISPLAY_TYPE.FULL_WIDTH
          ? 'Full Width'
          : 'Standard Width';

      const imageContainerClasses = classnames(classes.images, {
        [classes.selectedImage]: selectedImage && image.id === selectedImage.id,
      });

      return (
        <Grid item xs={4} key={id} id={id}>
          <div
            className={imageContainerClasses}
            id={id}
            key={id}
            onClick={() => setSelectedImage(image)}
          >
            <CloudinaryImage publicId={publicId} alt={altText}>
              <Transformation width="148" height="81" crop="limit" />
            </CloudinaryImage>
            {subtype === ARTICLE_SUBTYPE.FEATURE && (
              <div>{imageDisplayText}</div>
            )}
          </div>
        </Grid>
      );
    });
  };

  const placeButtonDisabled = !selectedImage;
  const placeButtonClasses = classnames(
    'ImagePicker__button',
    'ImagePicker__button-place',
    {
      'ImagePicker__button-place--disabled': placeButtonDisabled,
    },
  );

  return (
    <section>
      <Modal
        open
        title="Choose an Inline Image"
        onClose={onClose}
        onEnter={placeButtonDisabled ? undefined : onPlace}
        onLeft={selectLeft}
        onRight={selectRight}
        onUp={selectUp}
        onDown={selectDown}
      >
        <DialogContent>
          <Grid container spacing={2}>
            {renderImages()}
          </Grid>
          <DialogActions>
            <Button label="Cancel" onClick={onClose} />
            <Button
              className={placeButtonClasses}
              label="Place Image"
              disabled={placeButtonDisabled}
              onClick={onPlace}
            />
          </DialogActions>
        </DialogContent>
      </Modal>
    </section>
  );
}

export default ImagePicker;
