import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import InputLabel from '@material-ui/core/InputLabel';
import SelectField from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { LABEL } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import { Context as ValidationContext } from '../../../../../context/ValidationContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import verticalsQueryGQL from './graphql/verticalsQuery.gql';
import verticalMutationGQL from './graphql/verticalMutation.gql';
import selectedVerticalQueryGQL from './graphql/selectedVerticalQuery.gql';

const verticalsQuery = gql`
  ${verticalsQueryGQL}
`;

const selectedVerticalQuery = gql`
  ${selectedVerticalQueryGQL}
`;

const updateVerticalMutation = gql`
  ${verticalMutationGQL}
`;

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      'font-size': '12px',
    },
  })
);

function VerticalSection() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [selectedVertical, setSelectedVertical] = useState({});
  const [selectedSubvertical, setSelectedSubvertical] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const { errors, getErrorByFieldName } = useContext(ValidationContext);

  const [loadVerticals, { data }] = useLazyQuery(verticalsQuery);
  const verticals = (data && data.verticals) || [];

  const [loadSelectedVertical] = useLazyQuery(selectedVerticalQuery, {
    onCompleted: (d) => {
      if (errorMessage) setErrorMessage('');

      const verticalData = d && d.story.vertical;
      const subverticalData = d && d.story.subvertical;
      if (verticalData) setSelectedVertical(verticalData);
      if (subverticalData) setSelectedSubvertical(subverticalData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateVertical] = useMutation(updateVerticalMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });
  useEffect(() => {
    if (getErrorByFieldName('vertical')) {
      setErrorMessage(getErrorByFieldName('vertical').message);
    }
  }, [errors]);

  useEffect(() => {
    if (storyContext.id) {
      loadVerticals();
      loadSelectedVertical({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onVerticalChange = (e, selectedComponent) => {
    const {
      props: { vertical },
    } = selectedComponent;

    updateVertical({
      variables: {
        versionId: storyContext.id,
        verticalId: vertical.id,
      },
    }).then(({ data: d }) => {
      setSelectedVertical(d.story.vertical);
      setSelectedSubvertical({});
    });
  };

  const onSubverticalChange = (e, selectedComponent) => {
    const {
      props: { vertical },
    } = selectedComponent;

    updateVertical({
      variables: {
        versionId: storyContext.id,
        verticalId: vertical.id,
      },
    }).then(({ data: d }) => {
      setSelectedSubvertical(d.story.subvertical);
    });
  };

  const onClick = (e) => {
    const selectWidth = e.currentTarget.offsetWidth;
    const selectOffsetLeft = e.currentTarget.offsetLeft;
    global.setTimeout(() => {
      const menuItem = global.document.querySelector('.Select__MenuItem');
      try {
        menuItem.parentElement.parentElement.parentElement.parentElement.style.width = `${selectWidth}px`;
        menuItem.parentElement.parentElement.parentElement.parentElement.style.left = `${selectOffsetLeft}px`;
      } catch (err) {
        // click on label, do nothing
      }
    }, 0);
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <InputLabel className={classes.inputLabel} id='label'>
          {LABEL.VERTICAL}
        </InputLabel>
        <SelectField
          id='select-field'
          fullWidth
          onClick={onClick}
          onChange={onVerticalChange}
          value={selectedVertical.id || ''}
        >
          {verticals.map((vertical) => (
            <MenuItem value={vertical.id} key={vertical.id} vertical={vertical}>
              {vertical.name}
            </MenuItem>
          ))}
        </SelectField>
        {!!errorMessage && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
      </Grid>

      {selectedVertical.id && (
        <Grid item xs={12} md={8}>
          <InputLabel className={classes.inputLabel} id='label'>
            {LABEL.SUBVERTICAL}
          </InputLabel>
          <SelectField
            id='select-field'
            fullWidth
            onClick={onClick}
            onChange={onSubverticalChange}
            value={selectedSubvertical.id || ''}
          >
            {selectedVertical.subverticals.map((subvertical) => (
              <MenuItem
                value={subvertical.id}
                key={subvertical.id}
                vertical={subvertical}
              >
                {subvertical.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      )}
    </>
  );
}

export default VerticalSection;
