import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import gql from 'graphql-tag';
import Navbar from '../../common/Navbar/Navbar';
import { fetchCategory, updateCategory } from '../../redux/dispatchers';
import {
  updateCategoryField,
  updateCategoryFeaturedArticle,
  deleteCategoryFeaturedArticle,
} from '../../redux/actions/category';
import getImageUrl from '../../helpers/images';
import { dismissMessageAction } from '../../redux/actions/messages';
import AutoCompleteField from '../../common/AutoCompleteField/AutoCompleteField';
import verticalsQuery from './verticals.gql';

Category.propTypes = {
  actions: PropTypes.object.isRequired,
  logo: PropTypes.string,
  onEnter: PropTypes.func,
  featured_articles: PropTypes.array,
  message: PropTypes.string,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: theme.mixins.toolbar,
    img: {
      maxWidth: '140px',
      display: 'block',
      marginBottom: '15px',
    },
    labelFixed: {
      '& label': {
        fontSize: '16px !important',
      },
    },
    btnRightPadding: {
      marginRight: '15px',
    },
  }),
);

export const PARTNER_TYPE = {
  EDITORIAL: 'editorial',
  SPONSORED: 'sponsored',
};

const VERTICALS_QUERY = gql`
  ${verticalsQuery}
`;

/* istanbul ignore next */
function mapStateToProps({ category, messages }) {
  return {
    id: category.id,
    name: category.name,
    slug: category.slug,
    logo: category.logo,
    message: messages.message,
    featured_articles: category.featured_articles,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchCategory,
        updateCategory,
        updateCategoryField,
        updateCategoryFeaturedArticle,
        deleteCategoryFeaturedArticle,
        dismissMessageAction,
      },
      dispatch,
    ),
  };
}
export function Category(props) {
  const history = useHistory();
  const [verticals, setVerticalsData] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState('');
  const [loadVerticals] = useLazyQuery(VERTICALS_QUERY, {
    onCompleted(data) {
      setVerticalsData(data.verticals);
    },
  });

  useEffect(() => {
    props.onEnter(history, props.permissions);
    loadVerticals();
  }, []);

  function onSubmit() {
    props.actions.updateCategory();
  }

  function onFeaturedArticleDelete() {
    props.actions.deleteCategoryFeaturedArticle();
  }

  function onFeaturedArticleUpdate(index, value, data) {
    props.actions.updateCategoryFeaturedArticle(index, value, data);
  }

  function handleNavigateHome() {
    history.push('/');
  }

  function handleSelect(e, selectedComponent) {
    const {
      props: { verticaltype },
    } = selectedComponent;

    if (verticaltype.id !== selectedVertical.id) {
      setSelectedVertical(verticaltype);
    }

    props.actions.fetchCategory(verticaltype.slug);
  }

  function handleLogoButtonClick() {
    if (!props.logo) {
      const opts = {
        theme: 'minimal',
        show_powered_by: false,
        sources: ['local'],
        upload_preset: process.env.CLOUDINARY_UPLOAD_PRESET,
        max_file_size: '20000000',
        client_allowed_formats: ['png', 'jpg', 'gif', 'jpeg'],
      };

      const widget = global.cloudinary.createUploadWidget(
        opts,
        (error, result) => {
          if (result && result?.event === 'success') {
            const response = result.info;
            const imageUrl = getImageUrl(
              {
                publicId: response.public_id,
                version: response.version
              },
              {
                flags: 'lossy',
                quality: 'auto',
              }
            );
            props.actions.updateCategoryField('logo', imageUrl);
          }

          if (error) {
            props.actions.errorMessageAction(error, error.message);
          }
        },
      );

      widget.open();
    } else {
      props.actions.updateCategoryField('logo', '');
    }
  }

  const featuredArticles = props.featured_articles;
  const longHeadline =
    featuredArticles[0] && featuredArticles[0].long_headline
      ? featuredArticles[0].long_headline
      : '';
  const logoUrl = props.logo ? props.logo.replace('http://', 'https://') : '';
  const classes = useStyles();
  const renderVerticals = () => {
    return verticals.map(v => {
      return (
        <MenuItem value={v.id} key={v.id} verticaltype={v}>
          {v.name}
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <nav>
        <Navbar title="Edit Category" handleNavigateHome={handleNavigateHome}>
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              className={classes.btnRightPadding}
            >
              Save & Publish
            </Button>
          </div>
        </Navbar>
      </nav>
      <div className={classes.appBar} />

      <Grid container spacing={3} alignItems="center" direction="column">
        <Grid container xs={6}>
          <h1>Update Category</h1>
        </Grid>
        <Grid container xs={6} className={classes.labelFixed}>
          <SelectField
            id="select-field"
            label="Type"
            fullWidth
            onChange={handleSelect}
          >
            {renderVerticals()}
          </SelectField>

          <div>
            <h3>Category Logo</h3>
            <img src={logoUrl} role="presentation" className={classes.img} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleLogoButtonClick}
            >
              {props.logo ? 'Delete' : 'Upload'}
            </Button>
          </div>
          <AutoCompleteField
            label="Featured Article"
            dataUrl={`${process.env.API_HOST}/v1/article/suggestions`}
            dataQueryParams={{
              limit: 5,
              type: 'article|gallery',
            }}
            keyToUpdate={0}
            dataValueName="long_headline"
            updateFieldAction={onFeaturedArticleUpdate}
            deleteAction={onFeaturedArticleDelete}
            searchText={longHeadline}
            displayAuthorsAndPubDate
            debounce
          />
        </Grid>
      </Grid>
      <Snackbar
        open={!!props.message}
        message={props.message}
        onRequestClose={props.actions.dismissMessageAction}
      />
    </div>
  );
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Category);
