import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/client'

// Material UI
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// TDB
import { TYPE_LABEL, LABEL, PLACEHOLDER } from './labels';
import { StoryContext } from '../../../context/StoryContext';
import { Context as ValidationContext } from '../../../context/ValidationContext';
import ChipsContainer from '../../../common/ChipsContainer/ChipsContainer';
import getGraphqlErrorMessage from '../../../helpers/graphqlError';
import TitleCard from '../../../layouts/ObsessedTag/TitleCard';

import useObsessedTags from '../../../obsessed/hooks/useObsessedTags';

import FranchiseSection from '../main-info/components/FranchiseSection/FranchiseSection';
import KeywordSection from '../main-info/components/KeywordSection/KeywordSection';

// GraphQL Queries
import tagsQuery from './graphql/tagsQuery.gql';
import searchTagsQuery from './graphql/searchTagsQuery.gql';
import addStoryTagMutation from './graphql/addTag.gql';
import deleteStoryTagMutation from './graphql/deleteTag.gql';

// Queries
const tagsQueryGql = gql`${tagsQuery}`;
const searchTagsQueryGql = gql`${searchTagsQuery}`;
const addStoryTagMutationGql = gql`${addStoryTagMutation}`;
const deleteStoryTagMutationGql = gql`${deleteStoryTagMutation} `;

// Components
const TagOption = option => <div id={option.id}>{option.name}</div>;

const TagInput = ({ 
  allTags,
  storyTags,
  tagType, 
  onChange, 
  onDeleteTag,
}) => {
  const menuStyle = {
    maxHeight: '250px',
  };

  const getOptionLabel = option => option.name;

  const { errors, getErrorByFieldName } = useContext(ValidationContext);
  const [errorMessage, setErrorMessage] = useState('');

  const [queriedTags, setQueriedTags] = useState([]);
  const [tagSearchText, setTagSearchText] = useState('');

  const onTagInputChange = (e, inputString) => {
  };

  const localOnChange = (...args) => {
    // Reset input text.
    setTagSearchText('');
    setErrorMessage()

    onChange(...args);
  };

  useEffect(() => {
    if (getErrorByFieldName(tagType)) {
      setErrorMessage(getErrorByFieldName(tagType).message);
    }
  }, [errors]);

  return (
    <>
      <Autocomplete
        menustyle={menuStyle}
        getOptionLabel={getOptionLabel}
        options={allTags?.filter(t => t.type === tagType)}
        onChange={localOnChange}
        inputValue={tagSearchText}
        onInputChange={onTagInputChange}
        disableClearable
        renderOption={TagOption}
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => setTagSearchText(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errorMessage}
            helperText={errorMessage}
            label={TYPE_LABEL[tagType]}
          />
        )}
      />

      <ChipsContainer
        removeAction={onDeleteTag}
        valueName="name"
        values={storyTags.filter(t => t.type === tagType)}
      />

      <br />
    </>
  );
};


function ObsessedSection() {
  const storyContext = useContext(StoryContext);

  const { errors, getErrorByFieldName } = useContext(ValidationContext);
  const [errorMessage, setErrorMessage] = useState('');

  const [tags, setTags] = useState([]);

  // Load all available tags in database.
  const { allTags } = useObsessedTags();

  // Load the tags actually assigned to the current story.
  const [loadTags] = useLazyQuery(tagsQueryGql, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      if (errorMessage) setErrorMessage('');

      const tagData = data && data.story && data.story.obsessed_tags;
      if (tagData) setTags(tagData);
    },
  });

  const [addStoryTag] = useMutation(addStoryTagMutationGql, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      if (errorMessage) setErrorMessage('');

      const tagData = data && data.story && data.story.obsessed_tags;
      if (tagData) setTags(tagData);
    },
  });

  const [deleteStoryTag] = useMutation(deleteStoryTagMutationGql, {
    onError: err => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
    onCompleted: data => {
      if (errorMessage) setErrorMessage('');

      const tagData = data && data.story && data.story.obsessed_tags;
      if (tagData) setTags(tagData);
    },
  });

  useEffect(() => {
    if (getErrorByFieldName('obsessed')) {
      setErrorMessage(getErrorByFieldName('obsessed').message);
    }
  }, [errors]);

  useEffect(() => {
    if (storyContext.id) {
      loadTags({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e, value) => {
    const obsessedTagId = value.id;

    addStoryTag({
      variables: {
        articleId: storyContext.id,
        obsessedTagId,
      },
    });
  };

  const onDeleteTag = obsessedTagId => {
    deleteStoryTag({
      variables: {
        articleId: storyContext.id,
        obsessedTagId,
      },
    });
  };

  return (
    <>

      <Grid item xs={12} md={8}>
        <TagInput allTags={allTags} tagType={'coverage'} storyTags={tags} onChange={onChange} onDeleteTag={onDeleteTag} />
        <TagInput allTags={allTags} tagType={'media'} storyTags={tags} onChange={onChange} onDeleteTag={onDeleteTag} />
        <TagInput allTags={allTags} tagType={'rating'} storyTags={tags} onChange={onChange} onDeleteTag={onDeleteTag} />
        <TagInput allTags={allTags} tagType={'award'} storyTags={tags} onChange={onChange} onDeleteTag={onDeleteTag} />
        <TagInput allTags={allTags} tagType={'universe'} storyTags={tags} onChange={onChange} onDeleteTag={onDeleteTag} />
        <KeywordSection /> <br />
        <FranchiseSection /> <br />
        <TagInput allTags={allTags} tagType={'title'} storyTags={tags} onChange={onChange} onDeleteTag={onDeleteTag} />
        {
          tags
          .filter(t => t.type === 'title')
          .map(title => <TitleCard onDeleteTag={onDeleteTag} tag={title} />)
        }
      </Grid>
    </>
  );

}

export default ObsessedSection
