.section {
  display: grid;
  grid-gap: 8px;
  grid-template-areas:
    'title title'
    'slot1 slot1'
    'slot2 slot3'
    'slot4 slot4'
    'slot5 slot5'
    'slot6 slot6'
    'slot7 slot7';
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  width: 100%;
}

@for $i from 1 through 7 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

.title {
  grid-area: title;
}

@media screen and (min-width: 600px) {
  .section {
    grid-template-areas:
      'title title title title title title title title'
      'slot1 slot1 slot1 slot1 slot1 slot2 slot2 slot2'
      'slot1 slot1 slot1 slot1 slot1 slot3 slot3 slot3'
      'slot4 slot4 slot4 slot4 slot6 slot6 slot6 slot6'
      'slot5 slot5 slot5 slot5 slot7 slot7 slot7 slot7';
    grid-template-columns: repeat(8, 1fr);
  }

  .slot4,
  .slot5,
  .slot6,
  .slot7 {
    display: grid;
    grid-gap: 1.2rem;
    grid-template: 'input image' auto / auto 103px;
  }

  .image {
    grid-area: image;
  }

  .input {
    grid-area: input;
  }
}


@media screen and (min-width: 960px) {
  .section {
    grid-template-areas:
      'title title title title title title title title title title title title'
      'slot1 slot1 slot1 slot1 slot1 slot2 slot2 slot2 slot4 slot4 slot4 slot4'
      'slot1 slot1 slot1 slot1 slot1 slot2 slot2 slot2 slot5 slot5 slot5 slot5'
      'slot1 slot1 slot1 slot1 slot1 slot3 slot3 slot3 slot6 slot6 slot6 slot6'
      'slot1 slot1 slot1 slot1 slot1 slot3 slot3 slot3 slot7 slot7 slot7 slot7';
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto repeat(4, 1fr);
  }
}
