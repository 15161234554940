import { logError } from './logError';
import { handleError } from './global-error-handler';
/*
 * Adds Authorization header to fetch
 * not suitable for third-party api calls e.g. intagram, twitter
 */
export default function (url, options = {}) {
  return new Promise((resolve, reject) => {
    const currentAccounts = global.PublicClientApplication.getAllAccounts();

    if (!currentAccounts?.length) {
      global.PublicClientApplication.loginRedirect().catch((error) => {
        logError(error, {
          afterCapture: handleError,
        });
      });
      return;
    }

    const currentAccount = currentAccounts[0];

    const requestObject = {
      account: currentAccount,
      scopes: [process.env.AZURE_API_SCOPE],
    };

    global.PublicClientApplication.acquireTokenSilent(requestObject)
      .then((accessTokenData) => {
        const defaults = {
          headers: {
            Authorization: `Bearer ${accessTokenData.accessToken}`,
            'X-PTS-Cache': 'bypass',
            'xxx-api-key': process.env.PTS_API_KEY,
          },
        };
        const mergedOptions = {
          ...options,
          headers: { ...options.headers, ...defaults.headers },
        };

        return resolve(fetch(url, mergedOptions));
      })
      .catch((error) => {
        const args = {
          requestObject,
        };

        logError(error, {
          afterCapture: handleError(error, args),
        });

        return reject(error);
      });
  });
}
