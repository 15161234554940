import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles({
  obsessed: {
    color: 'white',
    backgroundColor: '#ff329c',
    '&:hover': {
      backgroundColor: '#ff329c',
    },
  },
  article: {
    color: '#FFFFFF',
    backgroundColor: '#3d659e',
    '&:hover': {
      backgroundColor: '#3d659e',
    },
  },
  cheat: {
    color: '#FFFFFF',
    backgroundColor: '#ec4041',
    '&:hover': {
      backgroundColor: '#ec4041',
    },
  },
  gallery: {
    color: '#FFFFFF',
    backgroundColor: '#cccccc',
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  },
  sponsored: {
    color: '#FFFFFF',
    backgroundColor: '#4eb65f',
    '&:hover': {
      backgroundColor: '#4eb65f',
    },
  },
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  sponsored: PropTypes.bool,
};

function displayLabel(type, sponsored, isObsessed) {
  if (isObsessed) {
    return 'O';
  }
  if (sponsored) {
    return 'S';
  }
  if (type === 'cheat') {
    return 'C';
  }
  if (type === 'gallery') {
    return 'G';
  }

  return 'A';
}

function TypeIndicator({ story /* A story node form a graphQL response */}) {
  const isObsessed = story?.site?.id === '2';
  const type = isObsessed ? 'obsessed' : story.type;
  const isSponsored = story.Metadata.sponsored || story.Metadata.branded;

  const label = displayLabel(type, isSponsored, isObsessed);

  const style = useStyles();

  const classes = classnames(style[type], {
    [style.sponsored]: isSponsored,
    [style.obsessed]: isObsessed,
  });


  return (
    <Fab
      size="small"
      className={classes}
      aria-label="article-type"
    >
      <div>{label}</div>
    </Fab>
  );
}

TypeIndicator.propTypes = propTypes;

export default TypeIndicator;
