query allStoriesSearch(
  $query: String
  $after: String
  $types: [StoryType] = [ARTICLE, GALLERY, CHEAT]
) {
  stories: suggestions(query: $query, after: $after, types: $types, first: 30, status: [SAVED, UNSAVED, PUBLISHED]) {
    edges {
      cursor
      node {
        ... on Article {
          type
          ...StoryResult
        }
        ... on Cheat {
          type
          ...StoryResult
        }

        ... on Gallery {
          type
          ...StoryResult
        }
      }
    }
  }
}

fragment StoryResult on Story {
	longHeadline: long_headline
	updatedAt: updated_at
	site {
	  id
	  name
	}
	obsessedTags: obsessed_tags {
	  id
	  type
	  name
	  slug
	}
	mainImage: main_image {
	  id
	  title
	  credit
	  url
    crops 
    altText: alt_text
    publicId: public_id
    version
	}
	slug
	site {
	  id
	  name
	}
	Authors: authors {
		name
	}
	Metadata: metadata {
		sponsored
		branded
	}
}
