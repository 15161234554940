import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { SECTION_BREAK_TYPE } from '../SectionBreakPicker/SectionBreakPicker';
import { colors } from '../../../../../styles/global/variables';

export const SECTION_BREAK_CARD_NAME = 'pt-section-break-card';

const useStyles = makeStyles({
  wrapperSmallText: { 'font-size': '16px', 'line-height': '17px' },
  wrapperBigText: { 'font-size': '25px', 'line-height': '28px' },
  wrapperAll: {
    height: '100%',
    'align-items': 'center',
    display: 'flex',
    'flex-direction': 'column',
    position: 'relative',
    cursor: 'pointer',
  },
  lineContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
    'z-index': '-1',
  },
  line: {
    width: '100%',
    height: '1px',
    'background-color': colors.darkGrey,
  },
  breakText: {
    'background-color': colors.materialWhite,
    fontFamily: 'helvetica',
    padding: '8px',
  },
  diamondIcon: {
    width: '10px',
    height: '10px',
    'align-self': 'center',
    transform: 'rotate(45deg)',
    'background-color': colors.materialWhite,
    border: `1px solid ${colors.darkGrey}`,
  },
});

export default function SectionBreakCard(props) {
  const {
    payload: {
      id,
      content: { type, text },
    },
    actions: {
      updateSectionBreakPickerOpen,
      updateSectionBreakId,
      updateSaveCardFunction,
    },
    save,
    edit,
  } = props;

  const classes = useStyles();
  const sectionBreakTypeClasses = classnames([classes.wrapperAll], {
    [classes.wrapperSmallText]: type === SECTION_BREAK_TYPE.SMALL_TEXT,
    [classes.wrapperBigText]: type === SECTION_BREAK_TYPE.BIG_TEXT,
  });

  const onEdit = () => {
    updateSectionBreakId(id);
    updateSaveCardFunction(save);
    updateSectionBreakPickerOpen(true);

    edit();
  };

  const renderText = () => {
    let textNode = null;
    if (
      type === SECTION_BREAK_TYPE.SMALL_TEXT ||
      type === SECTION_BREAK_TYPE.BIG_TEXT
    ) {
      textNode = <div className={classes.breakText}>{text}</div>;
    } else {
      textNode = <div className={classes.diamondIcon} />;
    }

    return textNode;
  };

  return (
    <Tooltip title="Click to edit">
      <div className={sectionBreakTypeClasses} onClick={onEdit}>
        <div className={classes.lineContainer}>
          <div className={classes.line} />
        </div>
        {renderText()}
      </div>
    </Tooltip>
  );
}

SectionBreakCard.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.shape({
      text: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    updateSectionBreakPickerOpen: PropTypes.func,
    updateSectionBreakId: PropTypes.func,
    updateSaveCardFunction: PropTypes.func,
  }),
  save: PropTypes.func,
  edit: PropTypes.func,
};
