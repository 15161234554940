query StorySuggestions(
  $query: String
  $types: [StoryType] = [ARTICLE, CHEAT, GALLERY]
) {
  suggestions(query: $query, types: $types, first: 5) {
    edges {
      node {
        ... on Article {
          ...StoryContent
        }

        ... on Cheat {
          ...StoryContent
        }

        ... on Gallery {
          ...StoryContent
        }
      }
    }
  }
}

fragment StoryContent on Story {
  authors {
    name
  }
  id
  longHeadline: long_headline
  publicationDate: publication_date
  slug
  Authors: authors {
    name
  }
  mainImage: main_image {
    publicId: public_id
    version
  }
}
