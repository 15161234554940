import React, { useCallback, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import * as styles from './RedDotActiveUsers.module.scss';
import useActiveUsers from './useActiveUsers';

export default function RedDotActiveUsers() {
  const activeUsers = useActiveUsers();

  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return activeUsers.length ? (
    <div>
      <button
        className={styles['red-dot']}
        id="RedDot"
        onClick={handleClick}
        type="button"
      />
      <Popover
        anchorEl={document.getElementById('RedDot')}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClick}
        open={open}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <List aria-label="publishing-toolbar">
          <ListSubheader>Currently being edited by:</ListSubheader>
          <Divider />

          {activeUsers.map(user => (
            <ListItem key={user.id}>
              <ListItemText primary={user.name} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  ) : null;
}
