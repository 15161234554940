import PropTypes from 'prop-types';
import React, { useState, useContext, useReducer, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';
import fetchJsonp from 'fetch-jsonp';
import Input from '../../../../../common/Input/Input';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import { Context as EditorContext } from '../../../../../context/EditorContext';
import VideoPickerForm, { VIDEO_TYPE } from './VideoPickerForm/VideoPickerForm';
import SocialPickerForm, {
  SOCIAL_TYPE,
} from './SocialPickerForm/SocialPickerForm';
import { SNIPPET_CARD_NAME } from '../SnippetCard/SnippetCard';
import { VIDEO_CARD_NAME } from '../VideoCard/VideoCard';
import { PDF_CARD_NAME } from '../PdfCard/PdfCard';
import { SOCIAL_CARD_NAME } from '../SocialCard/SocialCard';
import { TRACKING_CARD_NAME } from '../TrackingCard/TrackingCard';
import { SOUNDCLOUD_CARD_NAME } from '../SoundcloudCard/SoundcloudCard';
import { ACTION_BUTTON_CARD_NAME } from '../ActionButtonCard/ActionButtonCard';
import NewsletterPickerForm, {
  NEWSLETTER_TYPE,
} from './NewsletterPickerForm/NewsletterPickerForm';
import { NEWSLETTER_CARD_NAME } from '../NewsletterCard/NewsletterCard';
import { HIMALAYA_CARD_NAME } from '../HimalayaCard/HimalayaCard';

const EMBED_TYPE = {
  PDF: 'PDF',
  TRACKING_PIXEL: 'Tracking Pixel',
  SNIPPET: 'Snippet',
  SOCIAL: 'Social',
  VIDEO: 'Video',
  SOUNDCLOUD: 'Soundcloud',
  ACTION_BUTTON: 'Action Button',
  NEWSLETTER: 'Newsletter Signup',
  HIMALAYA: 'Himalaya',
};

const EMBEDS_LIST = [
  { id: 1, name: EMBED_TYPE.VIDEO },
  { id: 2, name: EMBED_TYPE.PDF },
  { id: 3, name: EMBED_TYPE.SOCIAL },
  { id: 4, name: EMBED_TYPE.TRACKING_PIXEL },
  { id: 5, name: EMBED_TYPE.SOUNDCLOUD },
  { id: 6, name: EMBED_TYPE.ACTION_BUTTON },
  { id: 7, name: EMBED_TYPE.NEWSLETTER },
  { id: 8, name: EMBED_TYPE.HIMALAYA },
];

export const OEMBED_API_URLS = {
  DOCUMENTCLOUD: 'https://www.documentcloud.org/api/oembed.json?',
  TWITTER: 'https://publish.twitter.com/oembed?omit_script=true&',
  INSTAGRAM: `https://graph.facebook.com/instagram_oembed?&access_token=${process.env.FACEBOOK_ACCESS_TOKEN}&`,
  FACEBOOK: `https://graph.facebook.com/oembed_post?&access_token=${process.env.FACEBOOK_ACCESS_TOKEN}&omitscript=true&`,
};

export const UPDATE_VIDEO = 'UPDATE_VIDEO';
const UPDATE_VIDEO_CHECKED = 'UPDATE_VIDEO_CHECKED';
const UPDATE_VIDEO_AND_VIDEO_CHECKED = 'UPDATE_VIDEO_AND_VIDEO_CHECKED';
const UPDATE_PDF = 'UPDATE_PDF';
const UPDATE_SOCIAL_CHECKED = 'UPDATE_SOCIAL_CHECKED';
export const UPDATE_SOCIAL = 'UPDATE_SOCIAL';
const UPDATE_SOCIAL_AND_SOCIAL_CHECKED = 'UPDATE_SOCIAL_AND_SOCIAL_CHECKED';
const UPDATE_TRACKING_PIXEL = 'UPDATE_TRACKING_PIXEL';
const UPDATE_SOUNDCLOUD = 'UPDATE_SOUNDCLOUD';
const UPDATE_ACTION_BUTTON = 'UPDATE_ACTION_BUTTON';
export const UPDATE_NEWSLETTER_SELECTED = 'UPDATE_NEWSLETTER_SELECTED';
const UPDATE_HIMALAYA = 'UPDATE_HIMALAYA';
const UPDATE_SNIPPET = 'UPDATE_SNIPPET';

const DEFAULT_STATE = {
  pdf: '',
  trackingPixel: '',
  snippet: '',
  social: '',
  video: '',
  soundcloud: '',
  actionButton: '',
  himalaya: '',
  socialChecked: SOCIAL_TYPE.FACEBOOK,
  videoChecked: VIDEO_TYPE.YOUTUBE,
  newsletterSelected: NEWSLETTER_TYPE.SCOUTED,
};

const SOCIAL_REGEX = {
  TWITTER: /https:\/\/twitter\.com\//i,
  X: /https:\/\/x\.com\//i,
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_VIDEO:
      return {
        ...state,
        video: action.data,
      };
    case UPDATE_VIDEO_CHECKED:
      return {
        ...state,
        videoChecked: action.data,
      };
    case UPDATE_VIDEO_AND_VIDEO_CHECKED:
      return {
        ...state,
        video: action.data.video,
        videoChecked: action.data.videoChecked,
      };
    case UPDATE_PDF:
      return {
        ...state,
        pdf: action.data,
      };
    case UPDATE_SOCIAL_CHECKED:
      return {
        ...state,
        socialChecked: action.data,
      };
    case UPDATE_SOCIAL:
      return {
        ...state,
        social: action.data,
      };
    case UPDATE_SOCIAL_AND_SOCIAL_CHECKED:
      return {
        ...state,
        social: action.data.social,
        socialChecked: action.data.socialChecked,
      };
    case UPDATE_TRACKING_PIXEL:
      return {
        ...state,
        trackingPixel: action.data,
      };
    case UPDATE_SOUNDCLOUD:
      return {
        ...state,
        soundcloud: action.data,
      };
    case UPDATE_ACTION_BUTTON:
      return {
        ...state,
        actionButton: action.data,
      };
    case UPDATE_NEWSLETTER_SELECTED:
      return {
        ...state,
        newsletterSelected: action.data,
      };
    case UPDATE_HIMALAYA:
      return {
        ...state,
        himalaya: action.data,
      };
    case UPDATE_SNIPPET:
      return {
        ...state,
        snippet: action.data,
      };
    default:
      throw new Error('Error updating embed state');
  }
}

export default function EmbedPicker({ selection }) {
  const {
    editor,
    videoCardId,
    pdfCardId,
    socialCardId,
    updateVideoCardId,
    trackingPixelId,
    soundcloudId,
    actionButtonId,
    newsletterCardId,
    himalayaId,
    updateEmbedPickerOpen,
    saveCardFunction,
    updateSaveCardFunction,
    updatePdfCardId,
    updateSocialCardId,
    updateTrackingPixelId,
    updateSoundcloudId,
    updateActionButtonId,
    updateNewsletterCardId,
    updateHimalayaId,
    resetEmbedCardIds,
    updateSnippetId,
  } = useContext(EditorContext);
  const [selectedEmbedType, setSelectedEmbedType] = useState(EMBEDS_LIST[0]);
  const [state, updateState] = useReducer(reducer, DEFAULT_STATE);
  const [inputError, setInputError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (saveCardFunction) {
      setEditMode(true);
    }

    if (videoCardId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === VIDEO_CARD_NAME &&
          section.payload.id === videoCardId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[0]);
          updateState({
            type: UPDATE_VIDEO_AND_VIDEO_CHECKED,
            data: {
              video: section.payload.content,
              videoChecked: section.payload.videoChecked,
            },
          });
        }
      });
    } else if (pdfCardId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === PDF_CARD_NAME &&
          section.payload.id === pdfCardId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[1]);
          updateState({
            type: UPDATE_PDF,
            data: section.payload.content,
          });
        }
      });
    } else if (socialCardId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === SOCIAL_CARD_NAME &&
          section.payload.id === socialCardId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[2]);
          updateState({
            type: UPDATE_SOCIAL_AND_SOCIAL_CHECKED,
            data: {
              social: section.payload.content,
              socialChecked: section.payload.socialChecked,
            },
          });
        }
      });
    } else if (trackingPixelId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === TRACKING_CARD_NAME &&
          section.payload.id === trackingPixelId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[3]);
          updateState({
            type: UPDATE_TRACKING_PIXEL,
            data: section.payload.content,
          });
        }
      });
    } else if (soundcloudId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === SOUNDCLOUD_CARD_NAME &&
          section.payload.id === soundcloudId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[4]);
          updateState({
            type: UPDATE_SOUNDCLOUD,
            data: section.payload.content,
          });
        }
      });
    } else if (actionButtonId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === ACTION_BUTTON_CARD_NAME &&
          section.payload.id === actionButtonId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[5]);
          updateState({
            type: UPDATE_ACTION_BUTTON,
            data: section.payload.content,
          });
        }
      });
    } else if (newsletterCardId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === NEWSLETTER_CARD_NAME &&
          section.payload.id === newsletterCardId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[6]);
          updateState({
            type: UPDATE_NEWSLETTER_SELECTED,
            data: section.payload.newsletterSelected,
          });
        }
      });
    } else if (himalayaId) {
      editor.post.walkAllLeafSections(section => {
        if (
          section.type === 'card-section' &&
          section.name === HIMALAYA_CARD_NAME &&
          section.payload.id === himalayaId
        ) {
          setSelectedEmbedType(EMBEDS_LIST[7]);
          updateState({
            type: UPDATE_HIMALAYA,
            data: section.payload.content,
          });
        }
      });
    }
  }, []);

  const updateFormValue = (key, value) => {
    const sanitizedValue = value.trim();
    updateState({
      type: key,
      data: sanitizedValue,
    });
  };

  const fetchOembedResponse = (formData, embedType, socialChecked) => {
    let baseURL;
    let pageQuery = '';
    if (embedType.name === EMBED_TYPE.PDF) {
      baseURL = OEMBED_API_URLS.DOCUMENTCLOUD;
      const pageMatchResults = formData.content.match(/#document\/p(\d+)/);

      if (pageMatchResults) {
        pageQuery = `&page=${pageMatchResults[1]}`;
      }
    }

    if (embedType.name === EMBED_TYPE.SOCIAL) {
      baseURL = OEMBED_API_URLS[socialChecked.toUpperCase()];
    }

    let formContent = formData.content;

    if (socialChecked === SOCIAL_TYPE.TWITTER) {
      formContent = formContent.replace('https://x.com', 'https://twitter.com');
    }

    const encodedURL = global.window.encodeURIComponent(formContent);

    const instaOrPdf =
      socialChecked === SOCIAL_TYPE.INSTAGRAM ||
      embedType.name === EMBED_TYPE.PDF;
    const fetchLib = instaOrPdf ? global.window.fetch : fetchJsonp;
    const requestUrl = `${baseURL}url=${encodedURL}${pageQuery}`;

    return fetchLib(requestUrl)
      .then(res => res.json())
      .then(data => {
        const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
        parsedData.responseType = parsedData.type;
        delete parsedData.type;

        return { ...formData, ...parsedData };
      })
      .catch(err => console.log(err));
  };

  const onEmbedTypeChange = (e, selectedComponent) => {
    const {
      props: { embedtype },
    } = selectedComponent;

    if (embedtype.id !== selectedEmbedType.id) {
      setSelectedEmbedType(embedtype);
    }
  };

  const updateVideoChecked = e => {
    updateState({
      type: UPDATE_VIDEO_CHECKED,
      data: e.target.value,
    });
  };

  const updateSocialChecked = e => {
    updateState({
      type: UPDATE_SOCIAL_CHECKED,
      data: e.target.value,
    });
  };

  const updateNewsletterSelected = (keyToUpdate, value, data) => {
    updateState({
      type: keyToUpdate,
      data: data.name,
    });
  };

  const renderEmbedTypeView = () => {
    let form = null;
    switch (selectedEmbedType.name) {
      case EMBED_TYPE.VIDEO:
        form = (
          <VideoPickerForm
            inputValue={state.video}
            inputError={inputError}
            selectedVideoType={state.videoChecked}
            onVideoTypeChange={updateVideoChecked}
            onInputUpdate={updateFormValue}
          />
        );
        break;
      case EMBED_TYPE.PDF:
        form = (
          <Input
            key={EMBED_TYPE.PDF}
            label="Document Cloud"
            placeholder="Paste your URL here..."
            keyToUpdate={UPDATE_PDF}
            updateFieldAction={updateFormValue}
            value={state.pdf}
            errorText={inputError}
            focus
          />
        );
        break;
      case EMBED_TYPE.SOCIAL:
        form = (
          <SocialPickerForm
            inputValue={state.social}
            inputError={inputError}
            selectedSocialType={state.socialChecked}
            onSocialTypeChange={updateSocialChecked}
            onInputUpdate={updateFormValue}
          />
        );
        break;
      case EMBED_TYPE.TRACKING_PIXEL:
        form = (
          <Input
            key={EMBED_TYPE.TRACKING_PIXEL}
            label="Pixel Code"
            placeholder="Paste your code here..."
            keyToUpdate={UPDATE_TRACKING_PIXEL}
            updateFieldAction={updateFormValue}
            value={state.trackingPixel}
            errorText={inputError}
            multiline
            focus
          />
        );
        break;
      case EMBED_TYPE.SOUNDCLOUD:
        form = (
          <Input
            key={EMBED_TYPE.SOUNDCLOUD}
            label="Soundcloud"
            placeholder="Paste your embed code here..."
            keyToUpdate={UPDATE_SOUNDCLOUD}
            updateFieldAction={updateFormValue}
            value={state.soundcloud}
            errorText={inputError}
            multiline
            focus
          />
        );
        break;
      case EMBED_TYPE.ACTION_BUTTON:
        form = (
          <Input
            key={EMBED_TYPE.ACTION_BUTTON}
            label="Action Button"
            placeholder="Paste your embed code here..."
            keyToUpdate={UPDATE_ACTION_BUTTON}
            updateFieldAction={updateFormValue}
            value={state.actionButton}
            errorText={inputError}
            multiline
            focus
          />
        );
        break;
      case EMBED_TYPE.NEWSLETTER:
        form = (
          <NewsletterPickerForm
            selectedNewsletterType={state.newsletterSelected}
            onNewsletterTypeChange={updateNewsletterSelected}
          />
        );
        break;
      case EMBED_TYPE.HIMALAYA:
        form = (
          <Input
            key={EMBED_TYPE.HIMALAYA}
            label="Himalaya"
            placeholder="Paste your code here..."
            keyToUpdate={UPDATE_HIMALAYA}
            updateFieldAction={updateFormValue}
            value={state.himalaya}
            errorText={inputError}
            multiline
            focus
          />
        );
        break;
      case EMBED_TYPE.SNIPPET:
        form = (
          <Input
            key={EMBED_TYPE.SNIPPET}
            label="Snippet"
            placeholder="Paste your code here..."
            keyToUpdate={UPDATE_SNIPPET}
            updateFieldAction={updateFormValue}
            value={state.snippet}
            errorText={inputError}
            multiline
            focus
          />
        );
        break;
      default:
        break;
    }

    return form;
  };

  const renderEmbedTypeItems = () => {
    return EMBEDS_LIST.map(embed => {
      return (
        <MenuItem value={embed.id} key={embed.id} embedtype={embed}>
          {embed.name}
        </MenuItem>
      );
    });
  };

  const validateVideoInput = () => {
    const inputValue = state.video.trim();
    let errorText = null;
    if (inputValue.length === 0) {
      errorText = 'Drats, field cannot be empty';
    } else if (/[\u201C\u201D]/.test(inputValue)) {
      errorText = 'Oops, replace curly quotes with straight quotes';
    } else if (
      state.videoChecked === VIDEO_TYPE.YOUTUBE &&
      !/youtu.be|youtube.com/i.test(inputValue)
    ) {
      errorText = "Oops, that doesn't look like a Youtube link";
    } else if (
      state.videoChecked === VIDEO_TYPE.JW &&
      !/jwplatform.com|jwplayer.com/i.test(inputValue)
    ) {
      errorText = "Oops, that doesn't look like JW code";
    }

    return errorText;
  };

  const validatePdfInput = () => {
    const inputValue = state.pdf.trim();
    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Drats, URL cannot be empty';
    } else if (!/documentcloud.org\/documents\//i.test(inputValue)) {
      errorText = "Oops, that doesn't look like a DocumentCloud URL.";
    } else if (
      !/https:\/\/www.documentcloud.org\/documents\//i.test(inputValue)
    ) {
      errorText = 'DocumentCloud URL must include https://www.';
    }

    return errorText;
  };

  const validateSocialInput = () => {
    const inputValue = state.social.trim();

    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Drats, URL cannot be empty';
    } else if (
      state.socialChecked === SOCIAL_TYPE.TWITTER &&
      !(SOCIAL_REGEX.X.test(inputValue) || SOCIAL_REGEX.TWITTER.test(inputValue))
    ) {
      errorText = "Oops, that doesn't look like a Twitter link";
    } else if (
      state.socialChecked === SOCIAL_TYPE.FACEBOOK &&
      !/facebook.com/i.test(inputValue)
    ) {
      errorText = "Oops, that doesn't look like a Facebook link";
    } else if (
      state.socialChecked === SOCIAL_TYPE.INSTAGRAM &&
      !/instagram.com/i.test(inputValue)
    ) {
      errorText = "Oops, that doesn't look like a Instagram link";
    } else if (!/(http|https)/i.test(inputValue)) {
      errorText = 'Oops, URL must begin with http or https';
    }

    return errorText;
  };

  const validateTrackingPixelInput = () => {
    const inputValue = state.trackingPixel.trim();
    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Drats, the field cannot be empty';
    }

    return errorText;
  };

  const validateSoundcloudInput = () => {
    const inputValue = state.soundcloud.trim();
    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Drats, soundcloud cannot be empty';
    } else if (!/soundcloud.com/.test(inputValue)) {
      errorText = "Oops, that doesn't look like Soundcloud embed code.";
    }

    return errorText;
  };

  const validateActionButtonInput = () => {
    const inputValue = state.actionButton.trim();

    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Drats, Action Button cannot be empty';
    } else if (!/actionbutton.co/.test(inputValue)) {
      errorText = "Oops, that doesn't look like Action Button embed code.";
    }

    return errorText;
  };

  const validateHimalayaInput = () => {
    const inputValue = state.himalaya.trim();
    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Drats, himalaya cannot be empty';
    } else if (!/himalaya.com/.test(inputValue)) {
      errorText = "Oops, that doesn't look like Himalaya embed url.";
    }

    return errorText;
  };

  const validateSnippetInput = () => {
    const inputValue = state.snippet.trim();
    let errorText = '';
    if (inputValue.length === 0) {
      errorText = 'Snippet cannot be empty.';
    }

    return errorText;
  };

  const handleSubmit = () => {
    let cardPayload;
    let errorText;
    let cardName;
    let updateCardIdFunction;

    switch (selectedEmbedType.name) {
      case EMBED_TYPE.VIDEO:
        cardPayload = {
          type: EMBED_TYPE.VIDEO,
          content: state.video,
          videoChecked: state.videoChecked,
          class: state.videoChecked,
        };
        if (state.videoChecked === VIDEO_TYPE.JW) {
          cardPayload.mute = 'true';
          cardPayload.autoplay = 'true';
        }
        errorText = validateVideoInput();
        cardName = VIDEO_CARD_NAME;
        updateCardIdFunction = updateVideoCardId;
        break;
      case EMBED_TYPE.PDF:
        cardPayload = {
          type: EMBED_TYPE.PDF,
          content: state.pdf,
          socialChecked: state.socialChecked,
        };

        return fetchOembedResponse(
          cardPayload,
          selectedEmbedType,
          state.socialChecked,
        ).then(newFormData => {
          errorText = validatePdfInput();
          cardName = PDF_CARD_NAME;
          updateCardIdFunction = updatePdfCardId;
          return saveCard(
            cardName,
            { ...cardPayload, ...newFormData },
            updateCardIdFunction,
            errorText,
          );
        });

      case EMBED_TYPE.SOCIAL:
        cardPayload = {
          type: EMBED_TYPE.SOCIAL,
          content: state.social,
          socialChecked: state.socialChecked,
        };
        return fetchOembedResponse(
          cardPayload,
          selectedEmbedType,
          state.socialChecked,
        ).then(newFormData => {
          errorText = validateSocialInput();
          cardName = SOCIAL_CARD_NAME;
          updateCardIdFunction = updateSocialCardId;
          return saveCard(
            cardName,
            { ...cardPayload, ...newFormData },
            updateCardIdFunction,
            errorText,
          );
        });

      case EMBED_TYPE.TRACKING_PIXEL:
        cardPayload = {
          type: EMBED_TYPE.TRACKING_PIXEL,
          content: state.trackingPixel,
        };
        errorText = validateTrackingPixelInput();
        cardName = TRACKING_CARD_NAME;
        updateCardIdFunction = updateTrackingPixelId;
        break;
      case EMBED_TYPE.SOUNDCLOUD:
        cardPayload = {
          type: EMBED_TYPE.SOUNDCLOUD,
          content: state.soundcloud,
        };
        errorText = validateSoundcloudInput();
        cardName = SOUNDCLOUD_CARD_NAME;
        updateCardIdFunction = updateSoundcloudId;
        break;
      case EMBED_TYPE.ACTION_BUTTON:
        cardPayload = {
          type: EMBED_TYPE.ACTION_BUTTON,
          content: state.actionButton,
        };
        errorText = validateActionButtonInput();
        cardName = ACTION_BUTTON_CARD_NAME;
        updateCardIdFunction = updateActionButtonId;
        break;
      case EMBED_TYPE.NEWSLETTER:
        cardPayload = {
          type: EMBED_TYPE.NEWSLETTER,
          newsletterSelected: state.newsletterSelected,
        };
        cardName = NEWSLETTER_CARD_NAME;
        updateCardIdFunction = updateNewsletterCardId;
        break;
      case EMBED_TYPE.HIMALAYA:
        cardPayload = {
          type: EMBED_TYPE.HIMALAYA,
          content: state.himalaya,
        };
        errorText = validateHimalayaInput();
        cardName = HIMALAYA_CARD_NAME;
        updateCardIdFunction = updateHimalayaId;
        break;
      case EMBED_TYPE.SNIPPET:
        cardPayload = {
          type: EMBED_TYPE.SNIPPET,
          content: state.snippet,
        };
        errorText = validateSnippetInput();
        cardName = SNIPPET_CARD_NAME;
        updateCardIdFunction = updateSnippetId;
        break;
      default:
        break;
    }

    return saveCard(cardName, cardPayload, updateCardIdFunction, errorText);
  };

  const saveCard = (cardName, cardPayload, updateCardIdFunction, errorText) => {
    if (errorText) {
      setInputError(errorText);
      return;
    }
    setInputError(null);

    if (saveCardFunction) {
      saveCardFunction({ ...cardPayload, id: uuid() });

      updateSaveCardFunction(null);
      updateCardIdFunction(null);
    } else {
      editor.selectRange(selection);
      editor.insertCard(cardName, { ...cardPayload, id: uuid() });
    }

    updateEmbedPickerOpen(false);
  };

  const onClose = () => {
    updateEmbedPickerOpen(false);
    updateSaveCardFunction(null);
    resetEmbedCardIds();
  };

  if (global.window.settings.ENABLE_SNIPPET) {
    EMBEDS_LIST.push({ id: 9, name: EMBED_TYPE.SNIPPET });
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <div style={{ width: '600px' }}>
        <DialogTitle id="form-dialog-title">Embed</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={editMode}>
                <SelectField
                  id="select-field"
                  label="Type"
                  fullWidth
                  onChange={onEmbedTypeChange}
                  value={selectedEmbedType.id || ''}
                >
                  {renderEmbedTypeItems()}
                </SelectField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {renderEmbedTypeView()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} label="Cancel" color="primary" />
          <Button
            onClick={handleSubmit}
            label={editMode ? 'Update' : 'Place'}
            color="primary"
          />
        </DialogActions>
      </div>
    </Dialog>
  );
}

EmbedPicker.propTypes = {
  selection: PropTypes.object,
};
