import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';

const useStyles = makeStyles(() => ({ byline: { fontSize: 12 } }));

Option.propTypes = {
  option: PropTypes.shape({
    node: PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired }),
      ),
      longHeadline: PropTypes.string.isRequired,
      publicationDate: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default function Option({ option }) {
  const classes = useStyles();

  return !option ? null : (
    <div>
      <Typography>{option.node.longHeadline}</Typography>
      <Typography className={classes.byline} color="secondary" variant="body1">
        {`${option.node?.authors?.map?.(a => a.name).join(', ')} - ${dateFormat(
          option.node.publicationDate,
          "mm.dd.yy H:MM TT 'ET'",
        )}`}
      </Typography>
    </div>
  );
}
