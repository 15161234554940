query AnalyticsPageStoryData ($section: Int!, $slot: Int!) {
  page(name: OBSESSED_LANDING) {
    section(atPosition: $section) {
      id
      story(atPosition: $slot) {
        id
        vertical {
          name
        }
        subvertical {
          name
        }
        authors {
          id
          name
        }
        longHeadline: long_headline
      }
    }
  }
}
