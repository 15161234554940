query StorySuggestions($query: String) {
  suggestions(query: $query, types: [CHEAT], first: 5) {
    edges {
      node {
        authors {
          name
        }
        id
        longHeadline: long_headline
        metadata {
          branded
        }
        publicationDate: publication_date
        slug
        vertical {
          name
        }
      }
    }
  }
}
