query allStories(
	$beforeDate: String
	$after: String
	$types: [StoryType] = [ARTICLE, GALLERY, CHEAT]
	$status: [StatusType] = [SAVED, UNSAVED, PUBLISHED]
) {
	stories(
		beforeDate: $beforeDate
		after: $after
		types: $types
		first: 30
		orderBy: { direction: DESC, field: UPDATED_DATE }
		status: $status
	) {
		edges {
			cursor
			node {
				... on Article {
					type
					...StoryContent
				}

				... on Cheat {
					type
					...StoryContent
				}

				... on Gallery {
					type
					...StoryContent
				}
			}
		}
	}
}

fragment StoryContent on Story {
	longHeadline: long_headline
	updatedAt: updated_at
	slug
	site {
	  id
	  name
	}
	Authors: authors {
		name
	}
	Metadata: metadata {
		sponsored
		branded
	}
}
