import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import { Edit, ShoppingCart } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { colors } from '../../../../../styles/global/variables';

export const CONVERSION_CARD_NAME = 'pt-conversion-card';

const useStyles = makeStyles({
  card: {
    maxWidth: '500px',
    width: '90%',
    margin: '0 auto',
  },
  icon: {
    height: '100px',
    width: '100px',
    opacity: '0.8',
    margin: '0 auto 10px',
    display: 'block',
  },
  cardHeader: {
    'padding-bottom': 0,
    'text-transform': 'capitalize',
  },
  link: {
    color: colors.blue,
  },
});

const ConversionCard = props => {
  const classes = useStyles();
  const onEdit = () => {
    const {
      payload: { promoItemId },
      actions: {
        updateConversionPickerOpen,
        updateConversionId,
        updateSaveCardFunction,
      },
      save,
      edit,
    } = props;

    updateConversionId(promoItemId);
    updateConversionPickerOpen(true);
    updateSaveCardFunction(save);

    edit();
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton aria-label="embed">
            <Edit onClick={onEdit} />
          </IconButton>
        }
        title="Conversion Card"
      />
      <CardContent>
        <ShoppingCart className={classes.icon} />
      </CardContent>
    </Card>
  );
};

export default ConversionCard;

ConversionCard.propTypes = {
  payload: PropTypes.shape({
    promoItemId: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.object,
  }),
  actions: PropTypes.shape({
    updateConversionPickerOpen: PropTypes.func,
    updateConversionId: PropTypes.func,
    updateSaveCardFunction: PropTypes.func,
  }),
  save: PropTypes.func,
  edit: PropTypes.func,
};
