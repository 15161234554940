//import cloudinary from 'cloudinary/lib/cloudinary';
import { Cloudinary } from 'cloudinary-core';

const config = {
  cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
  private_cdn: true,
};

if (
  process.env.CLOUDINARY_CNAME &&
  process.env.CLOUDINARY_CNAME.length &&
  process.env.CLOUDINARY_CNAME.toLowerCase() != 'null'
) {
  config.cname = process.env.CLOUDINARY_CNAME;
  config.secure_distribution = process.env.CLOUDINARY_CNAME;
}

const cloudinary = new Cloudinary();
cloudinary.config(config);

export default function getImageUrl(image, options = {}) {
  if (image && image.publicId && image.version) {
    return cloudinary.url(image.publicId, {
      version: image.version,
      secure: true,
      ...options,
    });
  }

  return null;
}

export { config as CLOUDINARY_CONFIG };
