import _ from 'lodash';
import slugify from 'slug';
import React, { useEffect, useState, useReducer } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import TagTag from './TagTag';
import Button from '../../common/ButtonWrapper/ButtonWrapper';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: '70px',
    },
    textField: {
      width: '100%',
      marginTop: '16px',
    },
    saveButton: {
      fontWeight: 'bold',
      margin: '5px',
    },
    typeOption: {
      padding: '16px 32px',
    },
    typeInput: {
      display: 'none',
    },
  })
);

const validate = formValues => {
  return (
    !_.isEmpty(formValues.name)
    && !_.isEmpty(formValues.children.filter(t => t.type === 'genre'))
  );
};

export default function TagInfo ({
  sources,
  genres,
  formValues,
  onChange,
  onSave,
  lastSave,
  fieldErrors,
}) {
  const classes = useStyles();
  const isNew = formValues.id === 'new';

  const shouldShowTitleSection = formValues.type === 'title';
  const shouldShowSlugSection = !isNew;
  const saveLabel = isNew ? 'Create New Title' : 'Save';

  const canSave = validate(formValues);
  const lastSaveLabel = lastSave && `Last Save: ${lastSave}`;

  const titleError = (
    fieldErrors.find(e => e.field = 'slug') ||
    fieldErrors.find(e => e.field = 'title')
  )?.message ;

  return (
    <Paper className={classes.root}>
      <List>

        <ListItem>
          <TextField
            disabled={!isNew}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.textField}
            label={'Title*'}
            value={formValues.name}
            onChange={e => onChange('name', e.target.value)}
            error={!!titleError}
            helperText={titleError}
          />
        </ListItem>
        { shouldShowSlugSection && 
          <ListItem>
            <TextField
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              label={'Slug'}
              value={formValues.slug}
              onChange={e => onChange('slug', e.target.value)}
            />
          </ListItem>
        }

        {
          shouldShowTitleSection &&

          <TagTag 
            tag={formValues}
            sources={sources}
            genres={genres}
            onChange={children => onChange('children', children)}
          />
        }

        <Divider />

        <ListItem>
          <Button
            disabled={!canSave}
            className={classes.saveButton}
            onClick={onSave}
            label={saveLabel}
          />
        </ListItem>


        <ListItem>
          {lastSaveLabel}
        </ListItem>
      </List>
    </Paper>
  );
}
