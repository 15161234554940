import {
  UPDATE_KEYWORD,
} from '../actions/keywords';

export const INITIAL_STATE = {
  name: '',
};

export function updateKeywordReducer(state, action) {
  return {
    ...state,
    ...action.data,
  };
}

export default function keywordsReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_KEYWORD:
      return updateKeywordReducer(state, action);
    default:
      return state;
  }
}
