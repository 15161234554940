import fetch from '../helpers/pt-fetch';
import {
  successMessageAction,
  errorMessageAction,
  dismissAllErrorsAction,
} from './actions/messages';
import { updateUser } from './actions/user';
import { updateCategoryData } from './actions/category';

const API_URL = process.env.API_HOST;

export const ERROR_MESSAGE = {
  MISSING_PRODUCT_NAME: 'Product name is required for members only articles.',
  MISSING_VERTICAL: 'Verticals are required for publication.',
  EMPTY_BODY: 'Save unsuccessful. Body cannot be left blank.',
  MISSING_KEYWORDS: 'A post cannot be published without at least one keyword.',
  UNLOCK_MISSING_DATE: 'Unlock & bump requires a specified date/time.',
};

export function saveKeyword() {
  return (dispatch, getState) => {
    const { keywords } = getState();
    return fetch(`${API_URL}/v1/tag`, {
      method: 'POST',
      body: JSON.stringify(keywords),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.ok) {
          dispatch(dismissAllErrorsAction());
          return dispatch(successMessageAction('Successfully saved keyword.'));
        }

        return res.json().then(errorObject => {
          dispatch(errorMessageAction({}, errorObject.message));
        });
      })
      .catch(err => dispatch(errorMessageAction(err, err.toString())));
  };
}

export function fetchCategory(categorySlug) {
  return dispatch => {
    return fetch(`${API_URL}/v2/category/${categorySlug}`)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return dispatch(errorMessageAction({}, 'Could not fetch categories'));
      })
      .then(category => {
        dispatch(updateCategoryData(category));
      });
  };
}

export function updateCategory() {
  return (dispatch, getState) => {
    const { category } = getState();
    const featuredArray = category.featured_articles.length
      ? [category.featured_articles[0].id]
      : [];

    return fetch(`${API_URL}/v2/category/${category.slug}`, {
      method: 'PATCH',
      body: JSON.stringify({
        ...category,
        featured_articles: featuredArray,
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => {
        if (res.ok) {
          dispatch(dismissAllErrorsAction());
          return res.json().then(returnedCategory => {
            dispatch(updateCategoryData(returnedCategory));
            return dispatch(
              successMessageAction(
                `${returnedCategory.name} category updated.`,
              ),
            );
          });
        }

        return res.json().then(errorObject => {
          dispatch(errorMessageAction(errorObject, errorObject.message));
        });
      })
      .catch(err => dispatch(errorMessageAction(err, err.toString())));
  };
}
