query CrossPromotionQuery ($versionId: String!) {
  story: storyById(versionId: $versionId) {
    crossPromotion: cross_promotion {
      vertical {
        id
        name
        subverticals {
          id
          name
        }
      }
      subvertical {
        id
        name
      }
      franchise {
        id
        name
      }
    }
  }
}