import PropTypes from 'prop-types';
import React, { useMemo, useReducer, useContext } from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import InsertLink from '@material-ui/icons/InsertLink';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  FormatItalic,
  FormatBold,
  FormatUnderlined,
  StrikethroughS as LineThrough,
  List as UnorderedList,
  FormatListNumbered as OrderedList,
} from '@material-ui/icons';
import { colors } from '../../styles/global/variables';
import { MARKUP_TAG } from '../../helpers/mobiledoc-helpers';
import { StoryContext } from '../../context/StoryContext';
import { mobiledocStyles, fonts } from '../../styles/global/variables';

export const mobiledocToolbarSize = 55;
const verticalToolbarHeight = 'auto';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: theme.mixins.toolbar,
    headingButton: {
      display: 'flex',
    },
    verticalDrawerPaper: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      width: mobiledocToolbarSize,
      height: verticalToolbarHeight,
    },
    horizontalList: {
      backgroundColor: colors.white,
      width: '100%',
      overflow: 'hidden',
      padding: '0',
      margin: '0 auto',
      display: 'inline-flex',
      justifyContent: 'center',
      borderBottom: `1px solid ${colors.lighterGrey}`,
      zIndex: 2,
    },
    fixed: {
      position: 'fixed',
    },
    horizontalListWithDraftBar: {
      paddingTop: '43px',
    },
    horizontalListItem: {
      height: mobiledocToolbarSize,
      width: 'auto',
    },
    horizontalDrawerPaper: {
      width: '100%',
      height: '120px',
    },
    horizontalDrawerPaperWithDraftBar: {
      height: '160px',
    },
    fancylinksIcon: {
      height: mobiledocToolbarSize / 2,
      width: mobiledocToolbarSize / 2,
    },
    bodyFont: {
      color: colors.black,
    },
    heading: {
      ...mobiledocStyles.heading,
      fontFamily: fonts.helveticaHeader,
    },
    subHeading: {
      ...mobiledocStyles.subHeading,
      fontFamily: fonts.helveticaHeader,
    },
    headingIconText: {
      fontSize: '17px',
    },
  }),
);

export const TOOLBAR_BUTTONS = {
  BOLD: 'bold',
  ITALIC: 'italic',
  LINE_THROUGH: 'line-through',
  UNDERLINE: 'underline',
  LINK: 'link',
  EMBED: 'embed',
  FANCY_LINKS: 'fancy-links',
  IMAGE: 'image',
  SLIDESHOW: 'slideshow',
  QUOTE: 'quote',
  UNORDERED_LIST: 'unodered-list',
  ORDERED_LIST: 'ordered-list',
  SECTION_BREAK: 'section-break',
  HEADINGS: 'headings',
  CONVERSION: 'conversion',
};

function formatState() {
  return {
    bold: false,
    italic: false,
    underline: false,
    lineThrough: false,
    link: false,
    linkHref: null,
    unorderedList: false,
    orderedList: false,
  };
}

function reducer(state, action) {
  const newState = { ...state };

  switch (action.type) {
    case 'TOGGLE_MARKUP':
      if (action.tag === 'strong' || action.tag === 'b') {
        newState.bold = !state.bold;
      }

      if (action.tag === 'em' || action.tag === 'i') {
        newState.italic = !state.italic;
      }

      if (action.tag === 'u') {
        newState.underline = !state.underline;
      }

      if (action.tag === 's') {
        newState.lineThrough = !state.lineThrough;
      }

      if (action.tag === 'a') {
        newState.link = !state.link;
      }

      if (action.tag === 'ul') {
        newState.unorderedList = !state.unorderedList;
      }

      if (action.tag === 'ol') {
        newState.orderedList = !state.orderedList;
      }

      return newState;
    case 'RESET_MARKUP':
      return formatState();
    default:
      throw new Error();
  }
}

function MobiledocToolbar(props) {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const { linkClickHandler, features = [], inEditor, editor } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, dispatch] = useReducer(reducer, formatState());

  const handlePopvoerClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useMemo(() => {
    if (editor) {
      editor.cursorDidChange(() => {
        dispatch({ type: 'RESET_MARKUP' });

        // this is to check if we're currently in side a list (ul/ol)
        if (editor.activeSection) {
          if (editor.activeSection.type === 'list-item') {
            dispatch({
              type: 'TOGGLE_MARKUP',
              tag: editor.activeSection.parent.tagName, // get parent because this is the actual li
            });
          }
        }

        editor.activeMarkups.forEach(markup => {
          dispatch({ type: 'TOGGLE_MARKUP', tag: markup.tagName });
        });
      });
    }
  }, [editor]);

  const toggleMarkup = markupType => {
    editor.toggleMarkup(markupType);
    dispatch({ type: 'TOGGLE_MARKUP', tag: markupType });
  };

  const toggleSection = sectionType => {
    editor.run(postEditor => {
      postEditor.toggleSection(sectionType);
    });

    dispatch({ type: 'TOGGLE_MARKUP', tag: sectionType });
  };

  const toolbarItems = [];

  const listClasses = classnames({
    [classes.horizontalList]: true,
    [classes.fixed]: inEditor,
    [classes.horizontalListWithDraftBar]:
      inEditor && !storyContext.hasPublishedVersion,
  });

  // open prop for the bodu font popover
  const open = Boolean(anchorEl);

  if (features.includes(TOOLBAR_BUTTONS.BOLD)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => {
          editor.activeMarkups.forEach(markup => {
            if (markup.tagName === 'b') toggleMarkup('b');
          });

          return toggleMarkup(MARKUP_TAG.BOLD);
        }}
        selected={state.bold}
      >
        <Tooltip title="Bold">
          <FormatBold />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.ITALIC)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => {
          editor.activeMarkups.forEach(markup => {
            if (markup.tagName === 'i') toggleMarkup('i');
          });

          return toggleMarkup(MARKUP_TAG.ITALIC);
        }}
        selected={state.italic}
      >
        <Tooltip title="Italic">
          <FormatItalic />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.UNDERLINE)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => toggleMarkup(MARKUP_TAG.UNDERLINE)}
        selected={state.underline}
      >
        <Tooltip title="Underline">
          <FormatUnderlined />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.LINE_THROUGH)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => toggleMarkup(MARKUP_TAG.LINE_THROUGH)}
        selected={state.lineThrough}
      >
        <Tooltip title="Line Through">
          <LineThrough />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.LINK)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => linkClickHandler(state.link)}
        selected={state.link}
      >
        <Tooltip title="Hyperlink">
          <InsertLink />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.UNORDERED_LIST)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => toggleSection(MARKUP_TAG.UNORDERED_LIST)}
        selected={state.unorderedList}
      >
        <Tooltip title="Unordered List">
          <UnorderedList />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.ORDERED_LIST)) {
    toolbarItems.push(
      <ListItem
        className={classes.horizontalListItem}
        onClick={() => toggleSection(MARKUP_TAG.ORDERED_LIST)}
        selected={state.orderedList}
      >
        <Tooltip title="Ordered List">
          <OrderedList />
        </Tooltip>
      </ListItem>,
    );
  }

  if (features.includes(TOOLBAR_BUTTONS.HEADINGS)) {
    toolbarItems.push(
      <ListItem className={classes.horizontalListItem}>
        <Button
          className={classes.bodyFont}
          color="primary"
          endIcon={<ArrowDropDownIcon />}
          onClick={handlePopvoerClick}
        >
          Body Font
        </Button>
        <Menu
          open={open}
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            className={classes.heading}
            onClick={() => {
              toggleSection(MARKUP_TAG.HEADING);
            }}
          >
            Heading
          </MenuItem>
          <MenuItem
            className={classes.subHeading}
            onClick={() => {
              toggleSection(MARKUP_TAG.SUBHEADING);
            }}
          >
            Subheading
          </MenuItem>
        </Menu>
      </ListItem>,
    );
  }

  return (
    <Paper elevation={0}>
      <List className={listClasses}>{toolbarItems}</List>
    </Paper>
  );
}

MobiledocToolbar.propTypes = {
  linkClickHandler: PropTypes.func,
  features: PropTypes.arrayOf(PropTypes.string),
  inEditor: PropTypes.bool,
  editor: PropTypes.object,
};

export default MobiledocToolbar;
