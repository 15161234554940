.section {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-areas:
    'title'
    'slot1'
    'slot2'
    'slot3'
    'slot4'
    'slot5'
    'slot6';
  grid-template-columns: 1fr;
  margin: 0 auto;
  width: 100%;
}

.title {
  grid-area: title;
}

@for $i from 1 through 6 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

@media screen and (min-width: 704px) {
  .section {
    grid-template-areas:
      '. title title .'
      'slot1 slot2 slot3 slot5'
      'slot1 slot2 slot4 slot6';
    grid-template-columns: repeat(4, 1fr);
  }
}
