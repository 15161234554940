import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.25),
    },
  },
}));

function onRemove(chipId, removeAction) {
  return () => {
    removeAction(chipId);
  };
}

function renderChips(values, valueName, removeAction, notDeletable) {
  return values.map(value => {
    return (
      <Chip
        size="small"
        label={_.get(value, valueName)}
        onDelete={notDeletable ? false : onRemove(value.id, removeAction)}
        key={shortid.generate()}
      />
    );
  });
}

export default function ChipsContainer({
  values,
  valueName,
  removeAction,
  notDeletable,
}) {
  const classes = useStyles();
  let chipsContainer = null;
  if (values) {
    chipsContainer = (
      <div className={classes.root}>
        {renderChips(values, valueName, removeAction, notDeletable)}
      </div>
    );
  }

  return chipsContainer;
}

ChipsContainer.propTypes = {
  values: PropTypes.array,
  valueName: PropTypes.string.isRequired,
  removeAction: PropTypes.func.isRequired,
  notDeletable: PropTypes.bool,
};
