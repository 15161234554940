import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import getGraphqlErrorMessage from '../../../helpers/graphqlError';
import uploadAdstxt from './queries/uploadAdstxt.gql';
import BizToolsBasePage from '../BizToolsBasePage';


Adstxt.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme => ({
  headingGap: {
    marginTop: '38px',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    padding: '10px 0',
  },
}));

function base64EncodeFile(file) {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function(event) {
      resolve(event.target.result);
    };
    
    reader.readAsDataURL(file);
  });
}

export function Adstxt({ onEnter, permissions }) {
  const classes = useStyles();

  const [adstxtBase64Data, setAdstxtBase64Data] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);

  const inputId = 'adstxt-input';

  const [uploadAdstxtBase64Data] = useMutation(gql`${uploadAdstxt}`, {
    onCompleted: () => {
      setAdstxtBase64Data('');
      document.getElementById(inputId).value = '';
      setAlertType('success');
      setAlertMessage('Success!');
    },
    onError: (err) => {
      setAlertType('error');
      setAlertMessage(getGraphqlErrorMessage(err));
    },
    variables: {
      base64Data: adstxtBase64Data,
    }
  });


  return (
    <BizToolsBasePage title="Ads.txt File Upload" onEnter={onEnter} permissions={permissions}>
      <h3>Ads.txt File Upload</h3>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          uploadAdstxtBase64Data();
        }}
      >
        <div className={classes.row}>
          <input
            className={classes.input}
            accept=".txt"
            id={inputId}
            type="file"
            onChange={(event) => {
              base64EncodeFile(event.target.files[0]).then(base64Data => {
                setAdstxtBase64Data(base64Data);
              });
            }}
          />
        </div>
        <div className={classes.row}>
            <input type="submit" value="Submit" disabled={!adstxtBase64Data} />
        </div>
      </form>
      <Snackbar open={!!alertMessage} autoHideDuration={3000}>
        <Alert severity={alertType} onClose={() => setAlertMessage(null)}>
          {alertMessage}
        </Alert>
      </Snackbar>

    </BizToolsBasePage>
  );
};
