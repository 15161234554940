query HomepageCheats {
  cheatsheet {
    id
    authors {
      name
    }
    longHeadline:long_headline
    vertical {
      name
    }
  }
}
