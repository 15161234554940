import {
  UPDATE_USER,
  DELETE_USER,
} from '../actions/user';

export const INITIAL_STATE = {
  id: null,
  name: null,
  email: null,
  slug: null,
  bio: null,
  twitter: null,
  facebook: null,
  image: null,
  rights: [],
  created_at: null,
  updated_at: null,
  role_id: null,
};

export default function userReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, ...action.user };
    case DELETE_USER:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
}
