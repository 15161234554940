import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogContent } from '@material-ui/core';
import Button from '../ButtonWrapper/ButtonWrapper';

export const DIALOG_TYPE = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

export const KEY = {
  ESCAPE: 27,
  ENTER: 13,
};

export default class InputDialog extends Component {
  static propTypes = {
    dialogType: PropTypes.string.isRequired,
    error: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    inputLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    inputValue: PropTypes.string,
    onClose: PropTypes.func,
    onRemove: PropTypes.func,
    onSave: PropTypes.func,
    onInputChange: PropTypes.func,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.renderCancelButton = this.renderCancelButton.bind(this);
    this.renderRemoveButton = this.renderRemoveButton.bind(this);
    this.renderSaveButton = this.renderSaveButton.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(event) {
    switch (event.nativeEvent.keyCode) {
      case KEY.ENTER:
        if (this.props.onSave) {
          this.props.onSave();
        }
        break;
      case KEY.ESCAPE:
        if (this.props.onClose) {
          this.props.onClose();
        }
        break;
      default:
        break;
    }
    return false;
  }

  renderCancelButton() {
    return <Button key={1} label="Cancel" onClick={this.props.onClose} />;
  }

  renderRemoveButton() {
    return <Button key={2} label="Remove" onClick={this.props.onRemove} />;
  }

  renderSaveButton() {
    return <Button key={3} primary label="Save" onClick={this.props.onSave} />;
  }

  render() {
    const {
      dialogType,
      error,
      open,
      title,
      inputLabel,
      inputValue,
      inputPlaceholder,
      onInputChange,
    } = this.props;

    let actionButtons;
    if (dialogType === DIALOG_TYPE.ADD) {
      actionButtons = [this.renderCancelButton(), this.renderSaveButton()];
    } else {
      actionButtons = [this.renderRemoveButton(), this.renderSaveButton()];
    }

    return (
      <Dialog maxWidth="sm" modal={false} open={open}>
        <div style={{ width: '500px' }}>
          <DialogTitle id="modal-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <TextField
              error={error}
              helperText={error ? 'Invalid URL' : ''}
              id="InputDialog#link"
              floatingLabelText="Link URL"
              floatingLabelFixed
              label={inputLabel}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={inputPlaceholder}
              onChange={onInputChange}
              onKeyDown={this.onKeyDown}
              value={inputValue}
              style={{ width: '100%' }}
            />
          </DialogContent>
          <DialogActions>{actionButtons}</DialogActions>
        </div>
      </Dialog>
    );
  }
}
