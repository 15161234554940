import * as Sentry from '@sentry/react';
import { init as initApm } from '@elastic/apm-rum';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  // Performance Monitoring
  tracesSampleRate: 0.0001, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  sampleRate: 1, // let's see how this goes
});

global.apm = initApm({
  environment: process.env.NODE_ENV,
  serviceName: process.env.RUM_SERVICE,
  serverUrl: process.env.RUM_ENDPOINT,
  serviceVersion: process.env.GIT_REV,
});
