import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import AnalyticsPageStoryData from '../../queries/obsessed/fetchObsessedArticle.gql';
import AnalyticsDataItem from './AnalyticsDataItem';

const storyQuery = gql`
  ${AnalyticsPageStoryData}
`;

AnalyticsSectionCard.propTypes = {
  analyticsData: PropTypes.object,
  section: PropTypes.number.isRequired,
  slot: PropTypes.number.isRequired,
  styles: PropTypes.object,
};

export default function AnalyticsSectionCard({
  analyticsData,
  section,
  slot,
  styles,
}) {
  useEffect(() => {
    loadStoryQuery();
  }, []);
  const [story, setStory] = useState({
    name: `Story #${slot}`,
    longHeadline: 'Headline...',
    author: 'Authors...',
    vertical: 'Vertical...',
  });
  const [, setError] = useState(null);

  const [loadStoryQuery] = useLazyQuery(storyQuery, {
    onError: err => {
      setError(err);
    },
    onCompleted: data => {
      const storyData = data?.page?.section?.story;
      if (storyData) {
        setStory({
          author: storyData.authors[0]?.name,
          id: storyData.id,
          longHeadline: storyData.longHeadline,
          vertical: storyData.vertical?.name,
        });
      }
    },
    variables: { section, slot },
  });

  return (
    <Card
      className={styles[`slot${slot}`]}
      key={story.id}
      style={{ display: 'block' }}
    >
      {analyticsData && (
        <AnalyticsDataItem analyticsData={analyticsData} storyData={story} />
      )}
    </Card>
  );
}
