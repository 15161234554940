import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import CheatSheetDraggableCard from '../../features/cheatsheet/components/CheatSheetDraggableCard/CheatSheetDraggableCard';
import counter from '../../common/counterGen';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import CheatAutoComplete from './CheatAutoComplete';

const NUM_OF_CHEATS = 10;

CheatPane.propTypes = {
  reorderMode: PropTypes.bool,
  cheats: PropTypes.array,
  moveCheat: PropTypes.func,
  classes: PropTypes.object,
  onUpdateCheatList: PropTypes.func,
  swapPanes: PropTypes.func,
};

export default function CheatPane({
  reorderMode,
  cheats,
  moveCheat,
  classes,
  swapPanes,
  onUpdateCheatList,
}) {
  let cheatsProp = cheats.slice(0, NUM_OF_CHEATS);
  if (cheatsProp.length < NUM_OF_CHEATS) {
    // backend requires at least NUM_OF_CHEATS cheats to submit. In case db has less than NUM_OF_CHEATS, [].map would show only that many fields. To fix this, empty fields are shown so user can fill them out with cheats.
    // [1,2,3] -> [1,2,3,{},{},{}...]
    cheatsProp = cheatsProp
      .concat(new Array(NUM_OF_CHEATS).fill({}, 0, NUM_OF_CHEATS))
      .slice(0, NUM_OF_CHEATS);
  }
  let cheatPane = null;

  function getCheatPublicationDate(cheat) {
    return (
      cheat.publicationDate &&
      moment(cheat.publicationDate).format('MMMM Do YYYY, h:mm:ss a')
    );
  }

  cheatPane = cheatsProp.map(({ metadata, vertical, ...cheat }, index) => {
    const cheatLabel = `${index + 1}`;
    return reorderMode ? (
      <CheatSheetDraggableCard
        classes={classes}
        key={counter.next().value}
        index={index}
        label={cheatLabel}
        moveCheat={moveCheat}
        publicationDate={getCheatPublicationDate(cheat)}
        title={cheat.longHeadline}
      />
    ) : (
      <Grid
        className={`${classes.cheatContainer}`}
        container
        direction="column"
        key={`${counter.next().value}`}
      >
        <Grid alignItems="center" container>
          <Grid item>
            <h3 className={classes.cheatLabel}>{cheatLabel}</h3>
          </Grid>
          <Grid item className={classes.cheatInputBox}>
            <CheatAutoComplete
              cheat={cheat}
              index={index}
              onUpdateCheatList={onUpdateCheatList}
            />
          </Grid>
        </Grid>
        <Grid align="right" className={classes.cheatDate} item>
          {cheat &&
            moment(cheat.publicationDate).format('MMMM Do YYYY, h:mm:ss a')}
        </Grid>
      </Grid>
    );
  });

  return (
    <main>
      <Grid className={classes.reorderBtn} container justify="flex-end">
        <Box py={3}>
          <Button
            label={reorderMode ? 'Done' : 'Reorder'}
            onClick={swapPanes}
          />
        </Box>
      </Grid>
      <Grid justify="center" container>
        <Grid className={classes.cheat_list} item md={8} xs={12}>
          {cheatPane}
        </Grid>
      </Grid>
    </main>
  );
}
