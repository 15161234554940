import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { StoryContext } from '../../../../../context/StoryContext';
import { PLACEHOLDER } from '../../labels';
import ChipsContainer from '../../../../../common/ChipsContainer/ChipsContainer';
import searchAuthorByNameQueryGQL from './graphql/searchAuthorsQuery.gql';
import authorsQueryGQL from './graphql/authorsQuery.gql';
import deleteAuthorMutationGQL from './graphql/deleteAuthorMutation.gql';
import addStoryAuthorMutationGQL from './graphql/addStoryAuthorMutation.gql';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';

const useStyles = makeStyles(() =>
  createStyles({
    dontScaleFont: {
      '& label': {
        fontSize: '16px!important',
      },
    },
  })
);

const searchAuthorsByNameQuery = gql`
  ${searchAuthorByNameQueryGQL}
`;

const addStoryAuthorMutation = gql`
  ${addStoryAuthorMutationGQL}
`;

const authorsQuery = gql`
  ${authorsQueryGQL}
`;

const deleteStoryAuthorMutation = gql`
  ${deleteAuthorMutationGQL}
`;

function Author() {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [queriedAuthors, setQueriedAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState();
  const [authorSearchText, setAuthorSearchText] = useState('');

  const [loadAuthors] = useLazyQuery(authorsQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const authorsData = data && data.story && data.story.authors;
      if (authorsData) setSelectedAuthors(authorsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [loadQueriedAuthors] = useLazyQuery(searchAuthorsByNameQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const queriedAuthorsData = data && data.queriedAuthors;
      if (queriedAuthorsData) setQueriedAuthors(data.queriedAuthors);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [addStoryAuthor] = useMutation(addStoryAuthorMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const authorsData = data && data.story && data.story.authors;
      if (authorsData) setSelectedAuthors(authorsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [deleteStoryAuthor] = useMutation(deleteStoryAuthorMutation, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const authorsData = data && data.story && data.story.authors;
      if (authorsData) setSelectedAuthors(authorsData);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadAuthors({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e, value) => {
    const authorId = value.id;

    setAuthorSearchText('');

    addStoryAuthor({
      variables: {
        articleId: storyContext.id,
        authorId,
      },
    });
  };

  const onInputChange = (e, inputString) => {
    if (inputString) {
      loadQueriedAuthors({
        variables: {
          limit: 5,
          name: inputString,
        },
      });
    }
  };

  const onDeleteAuthor = (authorId) => {
    deleteStoryAuthor({
      variables: {
        articleId: storyContext.id,
        authorId,
      },
    });
  };

  return (
    <Grid item xs={12} md={8} className={classes.dontScaleFont}>
      <Autocomplete
        getOptionLabel={(option) => option.name}
        hinttext={PLACEHOLDER.AUTHORS}
        options={queriedAuthors}
        onChange={onChange}
        onInputChange={onInputChange}
        disableClearable
        inputValue={authorSearchText}
        renderOption={(option) => <div id={option.id}>{option.name}</div>}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setAuthorSearchText(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
            label='Authors'
          />
        )}
      />
      <ChipsContainer
        removeAction={onDeleteAuthor}
        valueName='name'
        values={selectedAuthors}
      />
    </Grid>
  );
}

export default Author;
