query heroImageAndVideoQuery($imageId: ID!, $videoId: ID!) {
  image(id: $imageId) {
    id
    publicId: public_id
    altText: alt_text
  }
  video(id: $videoId) {
    id
    mixedContent: mixed_content
  }
}
