import React, { useCallback, useEffect, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import articlesForAutocomplete from '../../../queries/articlesForAutocomplete.gql';
import Option from '../Option';

const AUTOCOMPLETE_QUERY = gql`
  ${articlesForAutocomplete}
`;

export function useAutocomplete(screamer) {
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultValueHasBeenSet, setDefaultValueHasBeenSet] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const client = useApolloClient();

  useEffect(() => {
    if (defaultValueHasBeenSet) return;
    if (screamer?.articleId) {
      const option = {
        node: {
          id: screamer.articleId,
          longHeadline: screamer.articleHeadline,
          publicationDate: screamer.articlePubDate,
          slug: screamer.articleSlug,
        },
      };
      setAutocompleteOptions([option]);
      setAutocompleteValue(option);
      setDefaultValueHasBeenSet(true);
    }
  }, [screamer]);

  const getOptions = useCallback(
    (event, inputValue) => {
      if (loading) return;

      setLoading(true);
      client
        .query({
          fetchPolicy: 'no-cache',
          query: AUTOCOMPLETE_QUERY,
          variables: {
            query: inputValue,
          },
        })
        .then(({ data }) => {
          setLoading(false);
          setAutocompleteOptions(data.suggestions.edges);
        })
        .catch((error) => {
          setLoading(false);
          global.apm.captureError(error);
        });
    },
    [client]
  );

  return {
    autocompleteOptions,
    autocompleteValue,
    getOptions,
    loadingAutoComplete: loading,
    setAutocompleteOptions,
    setAutocompleteValue,
  };
}

export function filterOptions(options) {
  return options;
}

export function getOptionLabel(option) {
  return option?.node?.longHeadline || '';
}

export function getOptionSelected(option, currentValue) {
  return option.node.id === currentValue.node.id;
}

export function renderOption(option) {
  return <Option option={option} />;
}
