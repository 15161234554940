import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

AnalyticsDataItem.propTypes = {
  analyticsData: PropTypes.object,
  storyData: PropTypes.object,
};

const smallTextStyle = { fontSize: '.72rem', lineHeight: 1.35 };
const upOrDownStyle = { color: 'black' };

export default function AnalyticsDataItem({ analyticsData, storyData }) {
  const clicksDown =
    analyticsData.realTimePercentOfTotalClicks + 1 <
    analyticsData.thirtyDayPercentOfTotalClicks;
  const clicksUp =
    analyticsData.realTimePercentOfTotalClicks - 1 >
    analyticsData.thirtyDayPercentOfTotalClicks;

  if (clicksUp) {
    upOrDownStyle.color = 'limegreen';
  } else if (clicksDown) {
    upOrDownStyle.color = 'red';
  }
  return (
    <CardContent>
      <Typography style={smallTextStyle}>{`${analyticsData.name}`}</Typography>
      <div style={{ display: 'flex' }}>
        <Typography style={{ ...upOrDownStyle, fontWeight: 600 }} variant="h4">
          {`${analyticsData.realTimePercentOfTotalClicks}%`}
        </Typography>
        <Typography
          style={{
            ...smallTextStyle,
            alignSelf: 'flex-end',
            marginLeft: '.8rem',
            marginBottom: '.3rem',
          }}
        >
          {`${analyticsData.thirtyDayPercentOfTotalClicks}% 30d`}
        </Typography>
      </div>
      <Typography style={smallTextStyle}>
        {`${analyticsData.realTimeTotalClicks} clicks`}
      </Typography>
      <Typography
        style={{
          ...smallTextStyle,
          marginBottom: '.3rem',
          marginTop: '.3rem',
        }}
      >
        {storyData.longHeadline}
      </Typography>
      {(storyData.author || storyData.vertical) && (
        <Typography style={smallTextStyle}>
          {`${storyData.author} | ${storyData.vertical}`}
        </Typography>
      )}
    </CardContent>
  );
}
