query allStoriesSearch(
  $query: String
  $after: String
  $types: [StoryType] = [ARTICLE, GALLERY, CHEAT]
) {
  stories: suggestions(query: $query, after: $after, types: $types, first: 30, status: [SAVED, UNSAVED, PUBLISHED]) {
    edges {
      cursor
      node {
        ... on Article {
          type
          ...StoryContent
        }
        ... on Cheat {
          type
          ...StoryContent
        }

        ... on Gallery {
          type
          ...StoryContent
        }
      }
    }
  }
}

fragment StoryContent on Story {
  site {
    id
    name
  }
  longHeadline: long_headline
  updatedAt: updated_at
  slug
  Authors: authors {
    name
  }
  Metadata: metadata {
    sponsored
    branded
  }
}
