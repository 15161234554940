import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom';
import ToolbarGroup from '@material-ui/core/Toolbar';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client'
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { colors } from '../../styles/global/variables';
import Button from '../../common/ButtonWrapper/ButtonWrapper';
import Navbar from '../../common/Navbar/Navbar';
import manualProgressiveScroll from '../../queries/list.gql';
import manualProgressiveScrollMutation from '../../mutations/list.gql';
import allStoriesSearch from '../../queries/articlesForAutocomplete.gql';
import { errorMessageAction } from '../../redux/actions/messages';

ProgressiveScroll.propTypes = {
  onEnter: PropTypes.func,
  permissions: PropTypes.array,
};

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: theme.mixins.toolbar,
    main: {
      paddingTop: '20px',
      '& label': {
        fontSize: '16px !important',
      },
    },
    listItem: {
      display: 'inline-flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    authorWithDate: {
      color: colors.blue,
      justifySelf: 'flex-end',
      flexWrap: 'nowrap',
    },
    headline: {
      maxWidth: '70%',
    },
  }),
);

function formatDate(date) {
  return moment(date).format('YYYY.MM.DD');
}

const manualProgressiveScrollQuery = gql`
  ${manualProgressiveScroll}
`;
const allStoriesSearchQuery = gql`
  ${allStoriesSearch}
`;
const progressiveScrollMutation = gql`
  ${manualProgressiveScrollMutation}
`;

const emptyProgressiveScroll = {
  longHeadline: null,
  authors: null,
  id: null,
};

export default function ProgressiveScroll({ onEnter, permissions }) {
  const [selectedStory, setSelectedStory] = useState(emptyProgressiveScroll);
  const [querySearchTerm, setQuerySearchTerm] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  useQuery(manualProgressiveScrollQuery, {
    onError: err => {
      errorMessageAction(err);
      setError('Error fetching existing progressive scroll article');
    },
    onCompleted: data => {
      const { stories } = data.list;
      if (!stories.length) return;
      setSelectedStory(
        stories
          ? {
              longHeadline: stories[0].longHeadline,
              authors: stories[0].authors && stories[0].authors.length && stories[0].authors[0].name,
              id: stories[0].id,
            }
          : null,
      );
    },
    variables: {
      name: 'MANUALPROGRESSIVESCROLL',
      limit: 5,
      type: 'article|gallery',
    },
  });

  const {
    data: autocompleteData,
    refetch: refetchAllStoriesDataSource,
  } = useQuery(allStoriesSearchQuery, {
    onError: err => {
      errorMessageAction(err);
      setError('Error fetching list of stories');
    },
    variables: {
      query: querySearchTerm,
      types: ['ARTICLE', 'GALLERY'],
    },
  });

  const [updateProgressiveScrollArticle] = useMutation(
    progressiveScrollMutation,
    {
      onError: err => {
        errorMessageAction(err);
        setMessage('Failed To Save');
      },
      onCompleted: data => {
        setMessage('Successfully Saved');
        const { stories } = data.updateList;
        if (!stories.length) return;
        setSelectedStory(
          stories
            ? {
                longHeadline: stories[0].longHeadline,
                authors: stories[0].authors?.length && stories[0].authors[0].name,
                id: stories[0].id,
              }
            : null,
        );
      },
    },
  );

  function updateQuery({ searchTerm }) {
    setQuerySearchTerm(searchTerm);
    refetchAllStoriesDataSource();
  }

  function handleNavigateHome() {
    history.push('/');
  }

  const saveAndPublish = useCallback(() => {
    const idToUpdate = selectedStory && selectedStory.id;
    updateProgressiveScrollArticle({
      variables: {
        name: 'MANUALPROGRESSIVESCROLL',
        storyIds: idToUpdate ? [Number(idToUpdate)] : [],
      },
    });
  }, [selectedStory]);

  const onClose = useCallback(() => {
    setMessage(null);
  }, []);

  const flattenedAutocompleteStories = useCallback(() => {
    if (autocompleteData) {
      const { edges } = autocompleteData.suggestions;
      const stories = edges.map(e => {
        return {
          authors:
            (e.node.authors?.length && e.node.authors[0].name) || '',
          longHeadline: e.node.longHeadline,
          publicationDate: e.node.publicationDate,
          id: e.node.id,
        };
      });
      return stories;
    }
    return [];
  }, [autocompleteData]);

  const history = useHistory();
  useEffect(() => {
    onEnter(history, permissions);
  }, []);
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      <nav>
        <Navbar handleNavigateHome={handleNavigateHome}>
          <ToolbarGroup>
            <Button
              color="primary"
              label="Save & Publish"
              onClick={saveAndPublish}
            />
          </ToolbarGroup>
        </Navbar>
      </nav>
      <div className={classes.appBar} />
      <main className={classes.main}>
        <Autocomplete
          getOptionLabel={option => option.longHeadline || null}
          filterOptions={x => x}
          value={selectedStory}
          options={flattenedAutocompleteStories()}
          renderOption={option => (
            <div className={classes.listItem}>
              <div className={classes.headline}>{option.longHeadline}</div>
              <div className={classes.authorWithDate}>
                {option.authors} | {formatDate(option.publicationDate)}
              </div>
            </div>
          )}
          renderInput={params => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              error={error}
              helperText={error}
              label="Article in Progressive Scroll"
            />
          )}
          onChange={(e, story) => {
            setSelectedStory(story);
          }}
          onInputChange={e => {
            if (e) updateQuery({ searchTerm: e.target.value || '' });
          }}
        />
      </main>
      <Snackbar
        open={!!message}
        message={message}
        onClose={onClose}
        autoHideDuration={5000}
      />
    </Container>
  );
}
