import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import dateFormat from 'dateformat';
import { v4 as uuid } from 'uuid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Delete, Add } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client'
import { Context as EditorContext } from '../../../../../context/EditorContext';
import Modal from '../../../../../common/Modal/Modal';
import Button from '../../../../../common/ButtonWrapper/ButtonWrapper';
import suggestionsQueryGQL from '../../../../../queries/suggestions.gql';
import { FANCY_LINKS_CARD_NAME } from '../FancyLinksCard/FancyLinksCard';
import validateSlugQueryGQL from './graphql/validateSlugQuery.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const { WWW_DOMAIN } = process.env;

const suggestionsQuery = gql`
  ${suggestionsQueryGQL}
`;

const validateSlugQuery = gql`
  ${validateSlugQueryGQL}
`;

const EMPTY_LINK = {
  longHeadline: '',
  slug: '',
};

export default function FancyLinksPicker({ selection }) {
  const {
    editor,
    fancyLinksId,
    updateFancyLinksPickerOpen,
    saveCardFunction,
    updateSaveCardFunction,
    updateFancyLinksId,
  } = useContext(EditorContext);

  const [links, setLinks] = useState([EMPTY_LINK]);
  const [suggestions, setSuggestions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [currentLinkIndex, setCurrentLinkIndex] = useState(0);

  useEffect(() => {
    editor.post.walkAllLeafSections(section => {
      if (
        section.type === 'card-section' &&
        section.name === FANCY_LINKS_CARD_NAME &&
        section.payload.id === fancyLinksId
      ) {
        const linksData = section.payload.linksData.map(link => {
          return { slug: link.slug, longHeadline: link.long_headline };
        });
        setLinks(linksData);
      }
    });
  }, []);

  const [loadSuggestions] = useLazyQuery(suggestionsQuery, {
    onError: err => {
      errorMessageAction(err);
    },
    onCompleted: data => {
      if (data && data.suggestions && data.suggestions.edges) {
        const newSuggestions = [];
        data.suggestions.edges.forEach(edge => {
          newSuggestions.push(edge.node);
        });
        setSuggestions(newSuggestions);
      }
    },
  });

  const [validateSlug] = useLazyQuery(validateSlugQuery, {
    onError: err => {
      errorMessageAction(err);
    },
    onCompleted: data => {
      const slugs = links.map(link => link.slug);
      const validatedSlugs =
        data && data.stories && data.stories.map(story => story.slug);
      const newErrors = [];
      slugs.forEach(slug => {
        const slugExists = validatedSlugs.includes(slug);

        if (!slugExists) {
          newErrors.push("Oops, that doesn't look like a valid story title.");
        } else {
          newErrors.push(null);
        }
      });

      if (newErrors.every(error => error === null)) {
        handleSubmit();
      } else {
        setErrors(newErrors);
      }
    },
  });

  const onAddLink = () => {
    setLinks([...links, EMPTY_LINK]);
    setErrors([...errors, null]);
  };

  const onDeleteLink = index => {
    const newLinks = _.cloneDeep(links);
    newLinks.splice(index, 1);

    const newErrors = errors;
    newErrors.splice(index, 1);

    setLinks(newLinks);
    setErrors(newErrors);
  };

  const onInputChange = (e, inputString, reason) => {
    if (reason === 'reset') return;

    const newLinks = _.cloneDeep(links);
    const newLink = {
      ...EMPTY_LINK,
      longHeadline: inputString,
    };
    newLinks.splice(currentLinkIndex, 1, newLink);

    setLinks(newLinks);
    loadSuggestions({
      variables: {
        query: inputString,
        types: ['ARTICLE', 'GALLERY', 'CHEAT'],
      },
    });
  };

  const renderOption = option => {
    const { longHeadline, publicationDate, authors } = option;
    const author = authors.map(a => a.name).join(', ');
    const convertedPubDate = dateFormat(
      publicationDate,
      "mm.dd.yy H:MM TT 'ET'",
    );

    return (
      <div id={longHeadline} style={{ lineHeight: '23px' }}>
        {longHeadline}
        <div
          style={{
            fontSize: '10px',
            color: 'blue',
            lineHeight: '10px',
          }}
        >
          {author} {convertedPubDate}
        </div>
      </div>
    );
  };

  const onChange = (e, selectedComponent) => {
    if (typeof selectedComponent !== 'object') return;
    const newLinks = _.cloneDeep(links);
    const newLink = {
      slug: selectedComponent.slug,
      longHeadline: selectedComponent.longHeadline,
    };
    newLinks.splice(currentLinkIndex, 1, newLink);

    const newErrors = errors;
    newErrors.splice(currentLinkIndex, 1, null);

    setLinks(newLinks);
    setErrors(newErrors);
  };

  const handleValidate = () => {
    const slugs = links.map(link => link.slug);
    validateSlug({
      variables: {
        slugs,
      },
    });
  };

  const handleSubmit = () => {
    const linksPayload = links.map(link => `${WWW_DOMAIN}/${link.slug}`);
    const linksDataPayload = links.map(link => {
      return {
        slug: link.slug,
        long_headline: link.longHeadline,
      };
    });

    const cardPayload = {
      id: uuid(),
      links: linksPayload,
      linksData: linksDataPayload,
    };

    if (saveCardFunction) {
      // saveCardFunction is set in the card's edit function
      saveCardFunction(cardPayload);

      // reset save card function
      updateSaveCardFunction(null);
      updateFancyLinksId(null);
    } else {
      editor.selectRange(selection);
      editor.insertCard(FANCY_LINKS_CARD_NAME, cardPayload);
    }

    updateFancyLinksPickerOpen(false);
  };

  const addFab = {
    icon: <Add />,
    label: 'Add',
  };

  return (
    <Modal
      open
      title="Fancy Links"
      onClose={() => updateFancyLinksPickerOpen(false)}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {links.map((link, index) => (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <Autocomplete
                      filterOptions={x => x}
                      freeSolo
                      getOptionLabel={option => option.longHeadline}
                      inputValue={links[index].longHeadline}
                      linkIndex={index}
                      onChange={onChange}
                      onInputChange={onInputChange}
                      options={suggestions}
                      renderOption={option => renderOption(option)}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onFocus={() => {
                            setSuggestions([]);
                            setCurrentLinkIndex(index);
                          }}
                          error={!!errors[index]}
                          helperText={errors[index]}
                        />
                      )}
                    />
                  </Grid>
                  {links.length > 1 && (
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          onDeleteLink(index);
                        }}
                      >
                        <Delete size="small" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
            ))}
          </Grid>
          <Grid item xs={8}>
            <Fab
              size="small"
              aria-label={addFab.label}
              onClick={onAddLink}
              color={addFab.color}
            >
              {addFab.icon}
            </Fab>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => updateFancyLinksPickerOpen(false)}
          label="Cancel"
          color="primary"
        />
        <Button
          onClick={handleValidate}
          label={fancyLinksId ? 'Update' : 'Place'}
          color="primary"
        />
      </DialogActions>
    </Modal>
  );
}

FancyLinksPicker.propTypes = {
  selection: PropTypes.object,
};
