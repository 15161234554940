import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { StoryContext } from '../../context/StoryContext';
import { colors } from '../../styles/global/variables';

export const drawerWidth = 180;

Sidebar.propTypes = {
  open: PropTypes.bool,
  extraTopMargin: PropTypes.bool,
};

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
    },
    nav: {
      padding: 0,
    },
    appBar: theme.mixins.toolbar,
    appBarBigger: {
      marginTop: `108px`,
    },
    link: {
      color: colors.darkGrey,
      'text-decoration': 'none',
    },
    active: {
      backgroundColor: '#ddd',
    },
  }),
);

export function Sidebar({ open, listItems, extraTopMargin }) {
  const classes = useStyles();
  const storyContext = useContext(StoryContext);

  const items = listItems || storyContext.getListItems() || [];

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List
        component="nav"
        className={classes.nav}
        aria-label="article-sidebar"
      >
        <Divider className={
          extraTopMargin ? classes.appBarBigger : classes.appBar
        } />
        {items.map((item, i) => {
          const pathList = [...useLocation().pathname.split('/')];
          const activeSlug = pathList[pathList.length - 1];
          pathList.splice(pathList.length - 1, 1, item.slug);
          return (
            <div key={i}>
              <Link to={pathList.join('/')} className={`${classes.link}`}>
                <ListItem
                  button
                  className={activeSlug === item.slug ? `${classes.active}` : ''}
                >
                  {item.displayText}
                </ListItem>
              </Link>
              <Divider />
            </div>
          )
        })}
      </List>
    </Drawer>
  );
}
