export const LABEL = {
  OBSESSED_TITLE: 'Title',
  OBSESSED_SOURCE: 'Where to Watch',
  OBSESSED_PERSON: 'Person',
  OBSESSED_GENRE: 'Genre',
  OBSESSED_RATING: 'Rating',
  OBSESSED_MEDIA: 'Media',
  OBSESSED_COVERAGE: 'Coverage',
  OBSESSED_AWARD: 'Award',
  OBSESSED_UNIVERSE: 'Universe'
};

export const TYPE_LABEL = {
  'title': 'Title',
  'source': 'Where to Watch',
  'person': 'Person',
  'genre': 'Genre',
  'rating': 'Rating',
  'media': 'Media',
  'coverage': 'Coverage',
  'award': 'Award',
  'universe': 'Universe'
};

export const PLACEHOLDER = {
  AUTHORS: 'e.g. Carl Bernstein',
  DEK:
    'Pastor Creflo Dollar has spoken to God, and God wants him to buy a $65 million private jet.\nHis congregation, thus far, has obliged his every whim.',
  EDITORS: 'e.g. Emma Carew Grovum',
  LONG_HEADLINE: 'This headline appears on the article page.',
  PRODUCT_NAME: 'e.g. Royalist, SneakPeek',
  READ_IT_AT_NAME: 'e.g. New York Times',
  READ_IT_AT_URL: 'e.g. www.nytimes.com',
  RUBRIC: 'OMG',
  SEO_HEADLINE:
    'Include all major, searchable keywords early on; this headline generates URL & only appears in search',
  SHORT_HEADLINE:
    'Appears on homepage, recirculation, newsletters; 60 characters',
  SOCIAL_HEADLINE:
    'Visible when shared to social; can have more voice; under 110 characters',
  TAGS: 'e.g. Climate Change',
};
