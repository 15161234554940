import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { gql, useMutation } from '@apollo/client';
import {
  HomepageContext,
  UPDATE_SECTION_TITLE,
  UPDATE_SECTION_SUBTITLE,
} from './HomepageContext';
import ProductCallout from './ProductCallout';
import SectionCard from './SectionCard';
import updateHomepageSectionTitle from '../../queries/homepage/updateHomepageSectionTitle.gql';

const UPDATE_SECTION_TITLE_MUTATION = gql`
  ${updateHomepageSectionTitle}
`;

Section.propTypes = {
  isTitleEditable: PropTypes.bool,
  isSubtitleEditable: PropTypes.bool,
  section: PropTypes.oneOf([
    'section1',
    'section2',
    'section3',
    'section4',
    'section5',
  ]).isRequired,
  includeProductCallout: PropTypes.bool,
  styles: PropTypes.object,
};

export default function Section({
  isTitleEditable,
  isSubtitleEditable,
  section,
  includeProductCallout,
  styles,
}) {
  const { state, dispatch } = useContext(HomepageContext);
  const [updateSectionTitle] = useMutation(UPDATE_SECTION_TITLE_MUTATION, {
    variables: {
      sectionId: state[section].id,
      title: state[section].title || '',
      subtitle: state[section].subtitle || '',
    },
  });
  const handleTitleInputChange = useCallback(
    event => {
      dispatch({
        type: UPDATE_SECTION_TITLE,
        payload: {
          section,
          title: event.target.value,
        },
      });
    },
    [dispatch, section],
  );
  const handleSubtitleInputChange = useCallback(
    event => {
      dispatch({
        type: UPDATE_SECTION_SUBTITLE,
        payload: {
          section,
          subtitle: event.target.value,
        },
      });
    },
    [dispatch, section],
  );
  const handleInputBlur = useCallback(() => updateSectionTitle(), []);

  const { stories } = state[section];

  const selectedStoryCards = useMemo(() => {
    return stories.map((story, i) => (
      <SectionCard
        key={story.id}
        section={section}
        slot={i + 1}
        styles={styles}
      />
    ));
  }, [section, stories]);

  return (
    <section className={`${styles.additionalClass} ${styles.section}`}>
      {isTitleEditable ? (
        <TextField
          className={styles.title}
          inputProps={{ onBlur: handleInputBlur }}
          InputLabelProps={{ shrink: true }}
          label="Section Title"
          onChange={handleTitleInputChange}
          value={state[section].title}
        />
      ) : (
        <Typography align="center" className={styles.title}>
          {state[section].title}
        </Typography>
      )}

      {isSubtitleEditable ? (
        <TextField
          className={styles.subtitle}
          inputProps={{ onBlur: handleInputBlur }}
          InputLabelProps={{ shrink: true }}
          label="Section Subtitle"
          onChange={handleSubtitleInputChange}
          value={state[section].subtitle}
        />
      ) : (
        state[section].subtitle && (
          <Typography align="center" className={styles.subtitle}>
            {state[section].subtitle}
          </Typography>
        )
      )}

      {selectedStoryCards}
      {includeProductCallout && (
        <ProductCallout
          className={styles['product-callout']}
          section={section}
        />
      )}
    </section>
  );
}
