.section {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-areas:
    'title title'
    'slot1 slot1'
    'slot2 slot3'
    'slot4 slot4'
    'slot5 slot5'
    'slot6 slot6'
    'slot7 slot7';
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  width: 100%;
}

@for $i from 1 through 7 {
  .slot#{$i} {
    grid-area: slot#{$i};
  }
}

.title {
  grid-area: title;
}

@media screen and (min-width: 600px) {
  .section {
    grid-template-areas:
      'title title title'
      'slot1 slot1 slot4'
      'slot1 slot1 slot4'
      'slot1 slot1 slot4'
      'slot1 slot1 slot5'
      'slot1 slot1 slot5'
      'slot1 slot1 slot5'
      'slot1 slot1 slot6'
      'slot1 slot1 slot6'
      'slot2 slot3 slot6'
      'slot2 slot3 slot7'
      'slot2 slot3 slot7'
      'slot2 slot3 slot7';
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content repeat(12, 1fr);
  }
}

@media screen and (min-width: 960px) {
  .section {
    grid-template-areas:
      'title title title title'
      'slot1 slot1 slot2 slot4'
      'slot1 slot1 slot2 slot5'
      'slot1 slot1 slot3 slot6'
      'slot1 slot1 slot3 slot7';
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto repeat(4, 1fr);
  }
}
