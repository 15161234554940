import { useCallback } from 'react';
import { gql } from '@apollo/client';
import slugify from 'slug';
import { logError } from '../../helpers/logError';
import { handleError } from '../../helpers/global-error-handler';

import { ACTIONS } from './state';

const FIND_BY_SLUG = gql`
  query story($slug: String!) {
    story(slug: $slug) {
      id
      canonicalSlug: canonical_slug
      slug
      publicationDate: publication_date
    }
  }
`;

export const useStep2Handlers = ({ client, dispatch }) => {
  const onInputChangeHandler = useCallback((event, newInputVal) => {
    dispatch({ payload: false, type: ACTIONS.SET_CAN_PUBLISH });
    if (!newInputVal) return;
    dispatch({ payload: true, type: ACTIONS.SET_STEP2_IS_FETCHING });
    client
      .query({
        fetchPolicy: 'no-cache',
        query: FIND_BY_SLUG,
        variables: {
          slug: newInputVal,
        },
      })
      .then((response) => {
        dispatch({ payload: false, type: ACTIONS.SET_STEP2_IS_FETCHING });
        if (!response?.data?.story) return;

        dispatch({
          payload: [response.data.story],
          type: ACTIONS.SET_STEP2_OPTIONS,
        });
      })
      .catch((error) => {
        logError(error, {
          afterCapture: handleError,
        });
        dispatch({ payload: false, type: ACTIONS.SET_STEP2_IS_FETCHING });
      });
  });

  return {
    onChangeHandler(event, newValue) {
      dispatch({
        payload: { step: 2, value: newValue },
        type: ACTIONS.SET_VALUE,
      });
      dispatch({ payload: [], type: ACTIONS.SET_STEP2_OPTIONS });
    },
    onInputChangeHandler,
  };
};

export const useStep3Handlers = ({ client, dispatch }) => ({
  onBlurHandler(event) {
    const slug = slugify(event.target.value, { trim: true });
    dispatch({
      payload: { step: 3, value: slug },
      type: ACTIONS.SET_VALUE,
    });
    dispatch({ payload: false, type: ACTIONS.SET_CAN_PUBLISH });
    dispatch({ payload: true, type: ACTIONS.SET_STEP3_IS_FETCHING });
    client
      .query({
        fetchPolicy: 'no-cache',
        query: FIND_BY_SLUG,
        variables: {
          slug,
        },
      })
      .then((response) => {
        dispatch({
          payload: false,
          type: ACTIONS.SET_STEP3_IS_FETCHING,
        });
        const redirectError = response?.errors?.find(
          (error) => error?.extensions?.code === 'SLUG_REDIRECT_ERROR'
        );

        if (response?.data?.story || redirectError) {
          dispatch({
            payload: response.data.story || redirectError,
            type: ACTIONS.SET_STEP3_EXISITING_ARTICLE,
          });
        } else {
          dispatch({ payload: true, type: ACTIONS.SET_CAN_PUBLISH });
          dispatch({
            payload: null,
            type: ACTIONS.SET_STEP3_EXISITING_ARTICLE,
          });
        }
      });
  },
  onChangeHandler(event) {
    dispatch({
      payload: {
        step: 3,
        value: slugify(event.target.value, { trim: false }),
      },
      type: ACTIONS.SET_VALUE,
    });
  },
});

export const useModalHandlers = ({ dispatch, step1, step2, step3 }) => {
  const resetValues = useCallback(() => dispatch({ type: '' }));

  const onConfirmHandler = useCallback(() => {
    const options = {
      variables: {
        additionalSlug: step3.value,
        initialSlug: step2.value.slug,
        workingArticleID: step2.value.id,
      },
    };

    step1.value
      .mutation(options)
      .then(() => {
        resetValues();
        dispatch({
          payload: { message: 'Success', open: true, severity: 'success' },
          type: ACTIONS.SET_SNACKBAR,
        });
      })
      .catch((error) => {
        logError(error, {
          afterCapture: handleError,
        });
        dispatch({ payload: false, type: ACTIONS.SET_CAN_PUBLISH });
        dispatch({ payload: false, type: ACTIONS.SET_MODAL });
        dispatch({
          payload: {
            message: 'Something went wrong. Please check the console',
            open: true,
            severity: 'error',
          },
          type: ACTIONS.SET_SNACKBAR,
        });
      });
  }, [step1.value, step2.value, step3.value]);

  return {
    onCancelHandler() {
      dispatch({ payload: { open: false, type: ACTIONS.SET_MODAL } });
    },
    onConfirmHandler,
  };
};
