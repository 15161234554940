import isEqual from 'lodash/isEqual';

export const MARKUP_TAG = {
  BOLD: 'strong',
  ITALIC: 'em',
  LINE_THROUGH: 's',
  UNDERLINE: 'u',
  LINK: 'a',
  IMG: 'img',
  UNORDERED_LIST: 'ul',
  ORDERED_LIST: 'ol',
  LIST_ITEM: 'li',
  EMBED: 'embed',
  FANCY_LINKS: 'fancy-links',
  SLIDESHOW: 'slideshow',
  QUOTE: 'quote',
  SECTION_BREAK: 'section-break',
  HEADING: 'h2',
  SUBHEADING: 'h3',
  CONVERSION: 'conversion',
};

export const EMPTY_MOBILEDOC = {
  version: '0.3.0',
  atoms: [],
  cards: [],
  markups: [],
  sections: [[1, 'p', []]],
};

export function findLinkPosition(editor) {
  let linkRange = editor.range;

  const activeMarkers = editor.activeMarkups;

  const linkMarkup = activeMarkers.find(m => m.tagName === 'a');

  if (editor.range.isCollapsed && activeMarkers) {
    linkRange = editor.range.expandByMarker(marker => {
      return !!marker.markups.find(m => m === linkMarkup);
    });
  }

  return { linkRange, linkMarkup };
}

export function updateHyperlinkMarkup(editor, postEditor, linkHref) {
  const { linkRange, linkMarkup } = findLinkPosition(editor);

  if (linkMarkup) {
    postEditor.removeMarkupFromRange(linkRange, linkMarkup);
  }

  const newLink = editor.builder.createMarkup(MARKUP_TAG.LINK, {
    href: linkHref,
  });

  postEditor.addMarkupToRange(linkRange, newLink);
}

export function isEmptyMobiledoc(mobiledoc) {
  return isEqual(mobiledoc, EMPTY_MOBILEDOC);
}
