query ListQuery($name: ListType) {
  list(name: $name) {
    stories {
      authors {
        name
      }
      id
      longHeadline: long_headline
      metadata {
        branded
      }
      publicationDate: publication_date
      slug
      vertical {
        name
      }
    }
  }
}
