import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import AnalyticsSectionCard from './AnalyticsSectionCard';
import ObsessedSectionQuery from '../../queries/obsessed/fetchObsessedSection.gql';

const sectionQuery = gql`
  ${ObsessedSectionQuery}
`;

AnalyticsSection.propTypes = {
  analyticsData: PropTypes.array,
  includeProductCallout: PropTypes.bool,
  nStories: PropTypes.number,
  section: PropTypes.number,
  styles: PropTypes.object,
};

export default function AnalyticsSection({
  analyticsData,
  includeProductCallout,
  nStories,
  section,
  styles,
}) {
  useEffect(() => {
    loadSectionQuery();
  }, []);
  const [sectionData, setSectionData] = useState({
    title: `section #${section}`,
  });
  const [analyticsState, setAnalyticsState] = useState({
    data: [...Array(nStories).keys()].map(i => ({
      realTimePercentOfTotalClicks: 0,
      thirtyDayPercentOfTotalClicks: 0,
      realTimeTotalClicks: 0,
      name: `Story${i + 1}`,
      slot: i + 1,
    })),
    isSet: false,
  });
  const [, setError] = useState(null);

  if (analyticsData && analyticsData.length > 0 && !analyticsState.isSet) {
    setAnalyticsState(({ data: prevDataState }) => ({
      data: prevDataState.map((item, i) => {
        // For every slot, we search for it's corresponding analytics data
        // if we don't find it, we set the slot with the slot data from the prevDataState
        // else we set the slot with the newly fetched analytics data
        const analyticsItem = analyticsData.find(stats => stats.slot === i + 1);

        if (!analyticsItem) return { ...item };

        return {
          realTimePercentOfTotalClicks:
            analyticsItem.realTimePercentOfTotalClicks,
          thirtyDayPercentOfTotalClicks:
            analyticsItem.thirtyDayPercentOfTotalClicks,
          realTimeTotalClicks: analyticsItem.realTimeTotalClicks,
          name: analyticsItem.name,
          slot: i + 1,
        };
      }),
      isSet: true,
    }));
  }

  const [loadSectionQuery] = useLazyQuery(sectionQuery, {
    onError: err => {
      setError(err);
    },
    onCompleted: data => {
      if (data?.page?.section) {
        setSectionData({
          lists: data?.page?.section.productCallout?.lists,
          title: data?.page?.section.title,
          type: data?.page?.section.productCallout?.type,
        });
      }
    },
    variables: { section },
  });

  return (
    <section className={`${styles.additionalClasses} ${styles.section}`}>
      <Typography
        align="center"
        className={styles.title}
        style={{ textTransform: 'capitalize' }}
      >
        {sectionData?.title}
      </Typography>
      {[...Array(nStories).keys()].map(n => {
        return (
          <AnalyticsSectionCard
            analyticsData={analyticsState.data.find(
              stats => stats.slot === n + 1,
            )}
            key={n + 1}
            section={section}
            slot={n + 1}
            styles={styles}
          />
        );
      })}
      {includeProductCallout && (
        <Paper
          className={styles['product-callout']}
          section={section}
          lists={sectionData?.lists}
          type={sectionData?.type}
          style={{
            textAlign: 'center',
            fontWeight: 600,
            textTransform: 'uppercase',
            padding: '1rem',
          }}
        >
          {`${sectionData.type} Product Callout `}
          {sectionData?.lists && (
            <Typography align="center" className={styles.title}>
              {sectionData?.lists.join(', ')}
            </Typography>
          )}
        </Paper>
      )}
    </section>
  );
}
