import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ui from '../redux/reducers/ui';
import user from '../redux/reducers/user';
import keywords from '../redux/reducers/keywords';
import category from '../redux/reducers/category';
import messages from '../redux/reducers/messages';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    ui,
    user,
    messages,
    keywords,
    category
  });

export default createRootReducer;
