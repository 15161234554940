mutation UpdateObsessedTag(
  $tagId: ID!, 
  $slug: String!, 
  $name: String!,
  $childIds: [ID!],
) {
  tag: updateObsessedTag(tagId: $tagId, slug: $slug, name: $name, childIds: $childIds) {
    id
    type
    slug
    name
    children {
      id
      name
      type
      slug
    }
  }
}

