import React, { useState, useContext, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useMutation, useLazyQuery } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { gql } from '@apollo/client'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import { StoryContext } from '../../../../../context/StoryContext';
import updateSpecialContentFlag from '../../mutations/updateSpecialContentFlag.gql';
import specialContentFlag from '../../queries/specialContentFlag.gql';
import { errorMessageAction } from '../../../../../redux/actions/messages';

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      'margin-top': '10px',
      display: 'flex',
    },
    heading: {
      'margin-top': '30px',
      'margin-bottom': '10px',
      'font-weight': '600',
    },
    checkbox: {
      display: 'flex',
    },
  }),
);

export const SPECIAL_CONTENT_FLAG = {
  OPINION: 'opinion',
  EXCLUSIVE: 'exclusive',
};

const specialContentFlagMutation = gql`
  ${updateSpecialContentFlag}
`;

const specialContentFlagQuery = gql`
  ${specialContentFlag}
`;

export default function SpecialContentFlag() {
  const classes = useStyles();
  const { id } = useContext(StoryContext);
  const [message, setMessage] = useState(null);
  const [specialContentFlagData, setSpecialContentFlagData] = useState('');
  const [updateSpecialContentFlagMutation] = useMutation(
    specialContentFlagMutation,
    {
      onError: err => {
        console.error(err);
        errorMessageAction(err);
        setMessage('Error auto-saving special content flag.');
      },
      onCompleted: data => {
        const { metadataData } = data;
        setSpecialContentFlagData(metadataData.special_content_flag);
      },
    },
  );

  function onSpecialContentFlagChange(event, isChecked) {
    updateSpecialContentFlagMutation({
      variables: {
        id,
        metadataInputData: {
          special_content_flag: isChecked ? event.target.value : '',
        },
      },
    });
  }

  const [loadSpecialContent] = useLazyQuery(specialContentFlagQuery, {
    onError: err => {
      console.error(err);
      errorMessageAction(err);
      setMessage('Error loading special content flag.');
    },
    onCompleted: data => {
      const {
        storyData: { metadata },
      } = data;
      if (metadata) {
        setSpecialContentFlagData(metadata.special_content_flag);
      }
    },
  });

  useEffect(() => {
    if (id) {
      loadSpecialContent({
        variables: {
          versionId: id,
        },
      });
    }
  }, [id]);
  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel className={classes.heading}>Special Content Flags</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={
                  specialContentFlagData === SPECIAL_CONTENT_FLAG.OPINION
                }
                onChange={onSpecialContentFlagChange}
                value={SPECIAL_CONTENT_FLAG.OPINION}
              />
            }
            label="Opinion"
          />
          <FormControlLabel
            control={
              <Switch
                checked={
                  specialContentFlagData === SPECIAL_CONTENT_FLAG.EXCLUSIVE
                }
                onChange={onSpecialContentFlagChange}
                value={SPECIAL_CONTENT_FLAG.EXCLUSIVE}
              />
            }
            label="Exclusive"
          />
        </FormGroup>
      </FormControl>
      <Snackbar
        open={!!message}
        message={message}
        onClose={() => {
          setMessage(null);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}
