import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formLabel: { marginBottom: 12 },
  selectedArticle: {
    marginTop: 12,
    padding: 24,
  },
}));

Step2.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  step1Value: PropTypes.object.isRequired,
  value: PropTypes.shape({
    canonicalSlug: PropTypes.string,
    longHeadline: PropTypes.string,
  }),
};

Step2.displayName = 'ArticleSlugRedirectStep2';

export default function Step2({
  isFetching,
  onChange,
  onInputChange,
  options,
  step1Option,
  value,
}) {
  const classes = useStyles();
  let label;
  if (step1Option === 1) {
    label = 'Enter slug that needs to be changed.';
  } else if (step1Option === 2) {
    label = 'Enter existing slug.';
  } else {
    label = 'Please select an option in Step 1';
  }

  return (
    <div>
      <h2 className={classes.formLabel}>Step 2:</h2>
      <Autocomplete
        disabled={!step1Option}
        getOptionLabel={(option) => option?.slug || ''}
        onInputChange={onInputChange}
        onChange={onChange}
        options={options}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={!isFetching ? label : 'Searching...'}
              variant="outlined"
            />
          );
        }}
        value={value}
      />

      {value && (
        <Paper className={classes.selectedArticle} variant="outlined">
          <h3>Selected Article</h3>
          <p>
            Current URL:{' '}
            <a
              href={`${process.env.WWW_DOMAIN}/${value.canonicalSlug}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${process.env.WWW_DOMAIN}/${value.canonicalSlug}`}
            </a>
          </p>
        </Paper>
      )}
    </div>
  );
}
