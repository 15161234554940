import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import AutoComplete from 'material-ui/AutoComplete';
import AutoCompleteMenuItem, {
  MENU_ITEM_TYPE,
} from './AutoCompleteMenuItem/AutoCompleteMenuItem';
import fetch from '../../helpers/pt-fetch';

export const generateNewKey = () => {
  return Math.ceil(Math.random() * 10000);
};

export const floatingLabelStyle = {
  color: '#595b59',
};

export const floatingLabelFocusStyle = {
  color: '#366eb5',
  fontSize: '30px',
};

export default class AutoCompleteField extends Component {
  static propTypes = {
    createAction: PropTypes.func,
    dataQueryParams: PropTypes.object,
    dataUrl: PropTypes.string,
    dataValueName: PropTypes.string.isRequired,
    debounce: PropTypes.bool,
    defaultAvatarImage: PropTypes.string,
    deleteAction: PropTypes.func,
    dismissErrorsAction: PropTypes.func,
    displayAuthorsAndPubDate: PropTypes.bool,
    displayPubDate: PropTypes.bool,
    errorText: PropTypes.string,
    graphQuery: PropTypes.string,
    graphResultName: PropTypes.string,
    graphVars: PropTypes.array,
    hasAvatar: PropTypes.bool,
    keyToUpdate: PropTypes.string,
    label: PropTypes.string,
    menuStyle: PropTypes.object,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onError: PropTypes.func,
    placeholder: PropTypes.string,
    saveAction: PropTypes.func,
    searchText: PropTypes.string,
    updateFieldAction: PropTypes.func,
    updateSearchTextAction: PropTypes.func,
    updateStoreOnInputAction: PropTypes.func,
  };

  /* istanbul ignore next */
  constructor(props) {
    super(props);

    this.state = {
      textValues: [],
      originalData: [],
      key: generateNewKey(),
    };

    this.rootElement = null;
    this.field = null;

    this.handleUpdateInput = this.handleUpdateInput.bind(this);
    this.onNewRequest = this.onNewRequest.bind(this);
    this.getMenuItems = this.getMenuItems.bind(this);
    this.fetchEndpoint = this.fetchEndpoint.bind(this);
    this.executeQuery = this.executeQuery.bind(this);
    this.fetchData = props.debounce
      ? _.debounce(this.fetchData.bind(this), 500)
      : this.fetchData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchText === '') {
      this.field.setState({ searchText: '' });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.searchText !== this.props.searchText ||
      nextProps.errorText !== this.props.errorText ||
      !_.isEqual(nextState.textValues, this.state.textValues)
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorText === this.props.errorText) {
      this.field.focus();
    }
  }

  onNewRequest(value) {
    if (this.props.debounce) this.fetchData.cancel();

    let selectedTextValue = value.text;
    if (this.state.textValues.length === 1) {
      selectedTextValue = this.state.textValues[0];
    }

    const selectedItem = this.state.originalData.find(item => {
      return _.get(item, this.props.dataValueName) === selectedTextValue;
    });

    if (selectedItem) {
      if (this.props.saveAction) {
        this.props.saveAction(selectedItem);
      } else if (this.props.updateFieldAction) {
        this.props.updateFieldAction(
          this.props.keyToUpdate,
          selectedTextValue,
          selectedItem,
        );
      }

      if (this.props.dismissErrorsAction) {
        this.props.dismissErrorsAction(this.props.keyToUpdate);
      }

      this.setState({
        textValues: [],
        originalData: [],
        key: generateNewKey(),
      });
    } else if (!selectedItem && this.props.createAction) {
      this.props.createAction(value);
    }
  }

  getMenuItems() {
    let menuItems;
    if (this.props.hasAvatar) {
      menuItems = this.state.originalData.map(data => ({
        text: data.name,
        value: (
          <AutoCompleteMenuItem
            menuItemType={MENU_ITEM_TYPE.WITH_AVATAR}
            data={data}
            defaultAvatarImage={this.props.defaultAvatarImage}
            onSelect={this.onNewRequest}
          />
        ),
      }));
    } else if (this.props.displayAuthorsAndPubDate) {
      menuItems = this.state.originalData.map(data => ({
        text: data.long_headline,
        value: (
          <AutoCompleteMenuItem
            menuItemType={MENU_ITEM_TYPE.WITH_AUTHORS_AND_PUB_DATE}
            data={data}
            onSelect={this.onNewRequest}
          />
        ),
      }));
    } else if (this.props.displayPubDate) {
      menuItems = this.state.originalData.map(data => ({
        text: data.long_headline,
        value: (
          <AutoCompleteMenuItem
            menuItemType={MENU_ITEM_TYPE.WITH_PUB_DATE}
            data={data}
            onSelect={this.onNewRequest}
          />
        ),
      }));
    } else {
      menuItems = this.state.textValues.map(text => ({
        text,
        value: (
          <AutoCompleteMenuItem
            menuItemType={MENU_ITEM_TYPE.PLAIN_TEXT}
            text={text}
            onSelect={this.onNewRequest}
          />
        ),
      }));
    }

    return menuItems;
  }

  handleUpdateInput(value) {
    if (this.props.updateStoreOnInputAction) {
      this.props.updateStoreOnInputAction(this.props.keyToUpdate, value);
    }

    if (this.props.updateSearchTextAction) {
      this.props.updateSearchTextAction(value);
    }

    if (!value.length && this.props.deleteAction) {
      return this.props.deleteAction(this.props.keyToUpdate);
    }

    // Prevents fetch when the input is empty
    if (value.length) {
      return this.fetchData(value);
    }

    return null;
  }

  fetchData(value) {
    if (this.props.dataUrl) {
      return this.fetchEndpoint(value);
    }
    if (this.props.graphQuery) {
      return this.executeQuery(value);
    }

    return null;
  }

  fetchEndpoint(value) {
    const queryKeys = this.props.dataQueryParams
      ? Object.keys(this.props.dataQueryParams)
      : [];
    const query = queryKeys.reduce(
      (reducedValue, currentKey) =>
        `${reducedValue}&${currentKey}=${this.props.dataQueryParams[currentKey]}`,
      `?search=${global.encodeURIComponent(value)}`,
    );
    const requestUrl = `${this.props.dataUrl}${query}`;

    return fetch(requestUrl, {
      headers: {
        'cache-control': 'no-cache',
      },
    })
      .then(res => res.json())
      .then(data => {
        let textValues = [];
        let originalData = [];
        const dataToMap = data && data.articles ? data.articles : data;
        if (dataToMap && dataToMap.length) {
          textValues = dataToMap.map(item => {
            return _.get(item, this.props.dataValueName);
          });
          originalData = dataToMap;
        }

        return this.setState({
          textValues,
          originalData,
        });
      })
      .catch(error => {
        if (typeof this.props.onError === 'function') {
          this.props.onError(this.props.keyToUpdate, error);
          this.setState({
            textValues: [],
            originalData: [],
          });
        }
      });
  }

  executeQuery(value) {
    return fetch(`${process.env.PTS_HOST}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        query: this.props.graphQuery,
        variables: {
          query: value,
          types: ['ARTICLE', 'GALLERY'],
          ...this.props.graphVars,
        },
      }),
      headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        'xxx-api-key': process.env.PTS_API_KEY,
      },
    })
      .then(res => res.json())
      .then(queryResult => {
        let textValues = [];
        let originalData = [];
        if (queryResult && queryResult.data) {
          const results = queryResult.data[this.props.graphResultName];

          const dataToMap = results.edges || [];
          if (dataToMap && dataToMap.length) {
            textValues = dataToMap.map(item => {
              return _.get(item.node, this.props.dataValueName);
            });

            originalData = dataToMap.map(i => i.node);
          }

          return this.setState({
            textValues,
            originalData,
          });
        }

        return null;
      })
      .catch(error => {
        if (typeof this.props.onError === 'function') {
          this.props.onError(this.props.keyToUpdate, error);
          this.setState({
            textValues: [],
            originalData: [],
          });
        }
      });
  }

  render() {
    const {
      label,
      name,
      placeholder,
      searchText,
      errorText,
      onBlur,
      menuStyle,
    } = this.props;

    return (
      <AutoComplete
        ref={field => {
          this.field = field;
        }}
        key={this.state.key}
        name={name}
        hintText={placeholder}
        searchText={searchText}
        errorText={errorText}
        dataSource={this.getMenuItems()}
        fullWidth
        filter={() => true}
        menuStyle={menuStyle}
        floatingLabelText={label}
        floatingLabelFixed
        floatingLabelStyle={floatingLabelStyle}
        floatingLabelFocusStyle={floatingLabelFocusStyle}
        onUpdateInput={this.handleUpdateInput}
        onNewRequest={this.onNewRequest}
        onBlur={onBlur}
      />
    );
  }
}
