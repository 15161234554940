import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LABEL, PLACEHOLDER } from '../../labels';
import { StoryContext } from '../../../../../context/StoryContext';
import getGraphqlErrorMessage from '../../../../../helpers/graphqlError';
import DescriptionQueryGQL from './graphql/descriptionQuery.gql';
import DescriptionMutationGQL from './graphql/descriptionMutation.gql';

const descriptionQuery = gql`
  ${DescriptionQueryGQL}
`;

const descriptionMutation = gql`
  ${DescriptionMutationGQL}
`;

function DekSection() {
  const storyContext = useContext(StoryContext);
  const [description, setDescription] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loadDescription] = useLazyQuery(descriptionQuery, {
    onCompleted: (data) => {
      if (errorMessage) setErrorMessage('');

      const descriptionData = data && data.story && data.story.description;
      if (descriptionData) setDescription(data.story.description);
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  const [updateDescription] = useMutation(descriptionMutation, {
    onCompleted: () => {
      if (errorMessage) setErrorMessage('');
    },
    onError: (err) => {
      setErrorMessage(getGraphqlErrorMessage(err));
    },
  });

  useEffect(() => {
    if (storyContext.id) {
      loadDescription({
        variables: {
          versionId: storyContext.id,
        },
      });
    }
  }, [storyContext.id]);

  const onChange = (e) => {
    const value = e && e.target && e.target.value;
    setDescription(value);
    updateDescription({
      variables: {
        id: storyContext.id,
        story: {
          description: value,
        },
      },
    });
  };

  let helperMessage = `${description.length}/180`;
  let hasError = false;

  if (errorMessage) {
    hasError = true;
    helperMessage = `${description.length}/180 ${errorMessage}`;
  }

  return (
    <Grid item xs={12} md={8}>
      <TextField
        label={LABEL.DEK}
        placeholder={PLACEHOLDER.DEK}
        fullWidth
        onChange={onChange}
        value={description}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={onChange}
        error={hasError}
        helperText={helperMessage}
        multiline
      />
    </Grid>
  );
}

export default DekSection;
